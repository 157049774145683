import BasePage from 'pages/auth';
import { RealtiesContent } from './Content';
// import RealtyLists from './realty-lists';
// import OffersLists from './offer-lists';
// import Realties from './realties';
// import CustomContacts from './custom-contacts';

export default BasePage.extend({
	id:'realties-with-contacts',
	routes: 'realties',
	label: 'Недвижимость',
	relativeRoutes: true,
	Content: RealtiesContent
	//children: [RealtyLists, OffersLists, Realties, CustomContacts],
	//noAside: true,
});
