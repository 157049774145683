import _ from 'underscore';
import { CollectionView as BaseCollectionView, View as BaseView, Collection } from 'base';
import { mix, buildViewByKey } from 'bbmn-utils';
import { LinkView } from 'components/views';

import { scrollHandlerMixin, fetchNextMixin } from 'bbmn-mixins';

const pageMixin = Base => Base.extend({
	constructor: function(options){
		this.mergeOptions(options, 'page', 'ac', 'selector', 'filterer');
		Base.apply(this, arguments);
	},
	getDefaultOptions(opts){
		return _.extend({ 
			model: this.model,
			collection: this.getCollection(),
			page: this.page,
			ac: this.ac,
			selector: this.getSelector(),
			filterer: this.getFilterer()
		}, opts);
	},
	getSelector(){
		return this.selector;
	},
	getFilterer(){
		return this.filterer;
	},		
});

const View = pageMixin(BaseView);
const CollectionView = pageMixin(BaseCollectionView);




const BasePageLayout = mix(CollectionView).with(scrollHandlerMixin, fetchNextMixin);
const PageLayout = BasePageLayout.extend({
	renderAllCustoms: true,
	renderCollection: false,
	fetchNextEdge: false,
	scrollHandlingEnabled: false,
	constructor: function(){
		BasePageLayout.apply(this, arguments);
		this.addCssClassModifier('page-layout', this.page.id);
	},
	customs: [v => v.getContentView()],
	getContentView(){
		let options = this.getDefaultOptions({ 
			cssClassModifiers:['page-content'],
		});
		let view = this.buildContentView(options);
		if (view) return view;

		if (this.page.getOption('pageLinksAsContent')) {
			return new PageLinks(options);
		}

		return new View({ template: () => '...' });
		
	},
	buildContentView(options){
		return buildViewByKey(this.page, 'Content', { options });
	}
});

const PageLinks = CollectionView.extend({
	renderCollection: false,
	renderAllCustoms: true,
	customs:[
		v => v.getLinksView('children'),
		v => v.getLinksView('siblings'),
	],
	getLinksView(name){
		let hash = this.page.getHashes();

		let collection = new Collection(hash[name]);
		if(!collection.length) return;
		let header = ({ children: 'Выберите раздел: ', siblings: 'Смотрите также: '})[name];
		return new CollectionView({
			renderAllCustoms: true,
			className: 'page-links ' + name,
			tagName: 'nav',
			collection,
			childView: LinkView,
			viewComparator(v1,v2){
				return (v1.model.get('order') || 0) - (v2.model.get('order') || 0);
			},
			childViewOptions: {
				text: m => m.get('label'),
				href: m => m.get('url'),
			},
			customs:[() => name == 'siblings' && new View({ tagName: 'header', template: () => header })]
		});		
	}
});

export {
	View, CollectionView, PageLinks, PageLayout
};
