import { paths } from 'helpers';

paths.set('api', 'news', 'news', { prefix: 'ent' });
paths.set('url', 'news', 'news');


paths.set('api', 'news:company', 'company', { relative: true });
paths.set('url', 'news:company', 'comp', { relative: true });

paths.set('api', 'news:building', 'building', { relative: true });
paths.set('url', 'news:building', 'bc', { relative: true });
