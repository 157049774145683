import { Content } from './Content';
import { Filterer } from 'components/filterer';

export const commonModuleListMixin = {
	noAside: false,
	Content,
	LayoutOptions: p => ({
		selector: p.selector,
		filterer: p.filterer,
		fetchNextEdge: 'bottom',
		scrollHandlingEnabled: true,		
	}),
	initialize(){
		this.filterer = new Filterer({ formatDataForQueryString: false });
		const Collection = this.NewsCollection;
		this.collection = new Collection();
		this.collection.setFilterer(this.filterer);

		// this.selector = new Selector({ 
		// 	source: this.collection, 
		// 	multiple: true,
		// });

		// this.selector.actions = realtiesActions(this.selector);
		
		// this.addUrlChanger(this.filterer);

	},
	onBeforeStart(p,ac){
		
		console.log('BEFORE START BEGINS');
		// if (this.filterer._inited) {
		// 	console.log('not in first time, so skip');
		// 	return;
		// }
		// this.filterer.setFilters(getFilters(), { qs: ac.qs });
		// this.filterer._inited = true;
		// console.log('Filters settled', this.filterer);
		// if (!this.filterer.hasValues() && !this.collection.isFetching()) {
		// 	console.log('filterer is empty, and there is no fetch on coll, so doing fetch');
		// 	this.collection.query();
		// }
		// if (!this.collection.isFetching()) {
		// 	console.log('not fetched yet! doing fetch');
		// 	this.collection.query();
		// }
		if (!this.collection.isFetching()) {
			this.collection.query();
		}
		// const view = new Content({ collection: this.collection });
		
		// setTimeout(() => {
		// 	const parent = document.querySelector('.page-content');
		// 	parent.appendChild(view.el);
		// 	view.render();
		// }, 5000);


		// console.log('#', view);
	},
	onBeforeStop(){
		console.log('BEFORE STOP!');
		this.stopListening();
	},
}