import BasePage from 'pages/auth';
// import Import from './import';
// import Roles from './roles';
// import Users from './users';
import Config from './config';
import RedirectTable from './redirect-table';
import AsteriskTable from './asterisk-table';
// import Phones from './phones';

export default BasePage.extend({
	id:'adm-phones',
	routes: 'phones',
	label: 'Контакты на страницах недвижимости',
	noAside: true,
	relativeRoutes: true,
	children: [Config, RedirectTable, AsteriskTable],
	rights:{
		admin: 'view',
	}
});