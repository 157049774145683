import _ from 'underscore';
import { defineControl, getControl, controlMixin } from 'bbmn-controls';
import { View } from 'base';
import { buildViewByKey } from 'bbmn-utils';
import { TemplatesView } from './templates-view';
import TemplateReplaces from './template-replaces';

export const AdvertTemplateControl = controlMixin(View).extend({
	className: 'edit-advert-template',
	regions:{
		tab: '.tab-content'
	},
	template: _.template(`
		<div class="tabs">
			<span data-tab="edit" class="tab-trigger">редактирование</span>
			<span data-tab="params" class="tab-trigger">подстановки</span>
			<span data-tab="templates" class="tab-trigger">шаблоны</span>
		</div>
		<div class="tab-content">
		</div>
	`),
	events:{
		'click [data-tab]'(e){
			let $t = $(e.target).closest('[data-tab]');
			let tabName = $t.data('tab');
			this.showTab(tabName);
		}
	},
	onRender(){
		let tab = this.currentTab || 'edit';
		this.showTab(tab);
	},
	showTab(tab)
	{
		this.$('[data-tab]').removeClass('active');
		let view = this.getTabView(tab);
		this.$(`[data-tab="${tab}"]`).addClass('active');
		if (view) {
			this.currentTab = tab;
			this.showChildView('tab', view);
		} else {
			this.getRegion('tab').empty();
		}
	},
	getTabView(tab){
		if (tab == 'edit') {
			return this.createEditControlView();
		} else {
			return buildViewByKey(this, tab + 'View');
		}
	},
	createEditControlView()
	{
		let View = getControl('textarea');
		let options = {
			controlName: 'textarea',
			value: this.getControlValue(),
		};
		let view = new View(options);
		view.on('destroy', () => this.stopListening(view));
		this.listenTo(view, 'control:change', this.handleTextareaChange);
		return view;
	},
	handleTextareaChange(value){
		this.setControlValue(value);
	},

	paramsView: TemplateReplaces,	

	templatesView: TemplatesView,
	templatesViewOptions(){
		let templates = {};
		let feed = this.getOption('feed');
		let pack = this.getOption('pack');
		let packDescTemp = pack && pack.get('descriptionTemplate') || undefined;		
		let category = this.getOption('category');

		let catDescTemp = category && category.descriptionTemplate || undefined;

		if (feed) {
			templates.base = feed.get('defaultDescriptionTemplate');
			templates.ungroupedBase = feed.get('defaultUngroupedDescriptionTemplate');
			let feedTemplate = feed.get('descriptionTemplate');
			if (feedTemplate) {
				templates.feed = feedTemplate;
			}
		}
		if (packDescTemp) {
			templates.pack = packDescTemp;
		}
		if(catDescTemp) {
			templates.category = catDescTemp;
		}
		return {
			templates,
		};
	}

});

defineControl('advert:template',AdvertTemplateControl);
