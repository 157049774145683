import _ from 'underscore';
import { defineControl, getControl, ControlView, EditProperty, EditModel } from 'bbmn-controls';
import { phoneSchema } from 'components/phone';



const TextNumberView = ControlView.extend({
	isControlWrapper: true,
	renderAllCustoms: true,
	controlView: () => getControl('text'),
	controlViewOptions(){
		return {
			foo:'bar',
			placeholder: 'kuku-v-ruku'
		};
	}
});

const PhoneControl = EditModel.extend({
	className: 'phone-control',
	isControlWrapper: false,
	constructor: function(){
		EditModel.apply(this, arguments);
		this.schema = this.options.schema = phoneSchema;
	},
	propertiesToShow: ['country', 'code', 'number'],
	schema: phoneSchema,
	getEditPropertyOptions(defaultOptions){
		return _.extend({ className: defaultOptions.schema.name }, defaultOptions, this.getOption('editPropertyOptions'));
	}
	/*renderAllCustoms: true,
	isControlWrapper: false,
	renderCollection: false,
	className: 'phone-control',
	customs: [
		(v) => v.getCountryView(),
		(v) => v.getCodeView(),
		(v) => v.getNumberView(),
	],
	_getPropertyView(propertyName){
		let schema = phoneSchema.getProperty(propertyName);
		let value = this.getControlValue(propertyName);
		return new EditProperty({ schema, value });
	},
	getCountryView(){
		return this._getPropertyView('country');
	},
	getCodeView(){
		return this._getPropertyView('code');
	},
	getNumberView(){
		return this._getPropertyView('number');
	},*/
});

defineControl('phone', PhoneControl);

const PhoneWithEditableId = PhoneControl.extend({
	propertiesToShow: ['id', 'country', 'code', 'number'],
});

defineControl('phone-with-editableId', PhoneWithEditableId);

export default PhoneControl; 
