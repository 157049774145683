import BasePage from 'pages/auth';
import { Feed, FeedResults } from 'mod/export/feeds/models';
import { getFilters } from 'mod/export/feeds/filters';
import Content from './content';
import FeedResultsInfo from './info';
import { Filterer } from 'components/filterer';


const FeedPackPage = BasePage.extend({
	id:'export-feed-results-page',
	routes: 'results',
	label: 'объявления',
	Content,
	onBeforeStart(proc, ac){
		
		this.filterer = new Filterer({ backend: false });
		

		let feed = this.feed = new Feed({ id: ac.args.feedId });
		let url = feed.getFeedUrl();
		let model = this.model = new FeedResults({}, { feed });
		model.url = url;
		return feed.fetchIfNot().then(() => {
			this.filterer.setFilters(getFilters(feed));
			return model.fetch();
		});
	},
	onStart(){
		console.log('MY FEEED:', this.feed);
		console.log('MY FEEED RESULT:', this.model);
	},
	//accepts: ac
	data(){
		return {
			feedLabel: this.feed.display('name'),
			feedId: this.feed.id
		};
	},
	asideTabs:[
		{
			id:'info',
			text: 'инфо',
			isDefault: true,
			view: FeedResultsInfo,
			viewOptions: (tab) => ({
				model: tab.page.model,
				feed: tab.page.feed
			})
		}
	]
});


export default FeedPackPage;
