import { defineControl, SelectControl } from 'bbmn-controls';


const InlineSelect = SelectControl.extend({
	className: 'inline-select-control',
	prepareValueBeforeSet(val) {
		let modval = SelectControl.prototype.prepareValueBeforeSet.apply(this, arguments);
		console.warn('#<prepareValueBeforeSet># ', val, modval, this);
		return modval;
	},
	getSource(){
		if (this.valueOptions.type == 'boolean' && !this.valueOptions.sourceValues) {
			return {
				true: 'Да',
				false: 'Нет'
			};
		}
		return SelectControl.prototype.getSource.apply(this, arguments);
	},	
});

defineControl('select:inline', InlineSelect);
defineControl('boolean', InlineSelect);

export default SelectControl;
