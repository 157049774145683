import _ from 'underscore';
import './api-config';

import { Collection, Model } from 'base';
import { ActionModel } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';

import { action, ActionStore } from 'components/actions';
import { modals } from 'bbmn-components';

import { enums } from 'bbmn-utils';

import { busViews } from 'bus';


/**
 * FeedPromotionQuotas
 */

const FeedPromotionQuotas = Model.extend({});
ModelSchemas.initialize(FeedPromotionQuotas,{});


/**
 * FeddPromotion
 */

export const FeedPromotion = ActionModel.extend({
	isDisabled(){
		return this.get('disabled') === true;
	},
	nestedEntities:{
		quotas: FeedPromotionQuotas,
		phones: {
			class: Model
		}
	}
});
ModelSchemas.initialize(FeedPromotion, {
	id:{
		value:{
			type:'enum',
			sourceValues (m)  {
				if (!m || !m.parent || !m.parent.parent || !m.parent.parent.getFeedConfigPromotions) {
					console.warn('promotion model has no feed reference', m);
					return {};
				}
				return m.parent.parent.getFeedConfigPromotions({ enum: true });
			}
		},
		display:{
			label:'тип продвижения'
		}
	},
	disabled: {
		value:{
			type:'boolean',
			control: 'select:inline',
			sourceValues: ['Активировано','Отключено']
		},
		display:{
			label:'состояние',
		}
	},
	weekendBehavior: {
		value: {
			type:'enum',
			sourceValues:() => enums.get('weekendPromotions')
		},
		display:{
			label:'поведение на выходных'
		}
	},
	applyQuota:{
		value:{
			type:'boolean',
			sourceValues:['Не применяются','Применяются']
		},
		display:{
			label:'квоты по количеству',
			ifEmpty: '&ndash;',
		}
	},
	addUsualAdds: {
		value: {
			type: 'number',
		},
		display: {
			label: 'количество дополнительных обычных объявлений для заполнения квоты',
			ifEmpty: '&ndash;',
		}
	}
});
ActionStore.initialize(FeedPromotion, [
	action('show:details', 'открыть детали', function(){
		
		modals.show({
			header: this.display('id'),
			content: busViews.getClass('FeedPromotionDetailsView'),
			contentOptions:{
				model: this
			}
		});
		
	}, ['export: managePromotions'])
]);


export const FeedPromotions = ActionModel.extend({
	initialize(){
		_.each(this.attributes, (value, key) => {
			this.registerNestedEntity(key, FeedPromotion);
		});
	},
	getPromotions(){
		let models = _.map(this.attributes, (value, key) => this.entity(key));
		// let models = [
		// 	this.entity('highlight'),
		// 	this.entity('premium'),
		// 	this.entity('top3'),
		// ];
		return new Collection(models);
	},
	/*
	nestedEntities:{
		highlight: {
			class: FeedPromotion,
			data: { id: 'highlight', disabled: true, }
		},
		premium: {
			class: FeedPromotion,
			data: { id: 'premium', disabled: true, }
		},
		top3: {
			class: FeedPromotion,
			data: { id: 'top3', disabled: true, }
		}
	},*/
});
