import _ from 'underscore';
//Instance
((proto) => {
	_.extend(proto,{
		isValid(){
			return !isNaN(this.valueOf());
		},
		getMonths() {
			var y = this.getFullYear();
			var m = y * 12;
			return m + this.getMonth() + 1;
		},
		getDays() {
			var val = this.valueOf();
			return Math.floor(val / Date.DAY);
		},
	});
})(Date.prototype);

//STATIC
_.extend(Date, {
	NOW:'now',
	PAST:'past',
	FUTURE: 'future',
	SECOND: 1000,
	MINUTE: 60000,
	HOUR: 3600000,
	DAY: 86400000,
	isValid(arg){
		return _.isDate(arg) && arg.isValid();
	},
	sameYear(d1,d2){
		if(!this.isValid(d1) || !this.isValid(d2))
			return false;
		return d1.getFullYear() === d2.getFullYear();
	},
	sameMonth(d1, d2){
		return this.sameYear(d1,d2) && d1.getMonth() === d2.getMonth();
	},
	sameDay(d1, d2){
		return this.sameYear(d1,d2) && this.sameMonth(d1,d2) && d1.getDate() === d2.getDate();
	},
	info(d1, d2 = new Date()){
		if(_.isString(d1)) d1 = new Date(d1);
		if(_.isString(d2)) d2 = new Date(d2);

		let result = {
			dateValid:this.isValid(d1),
			compareValid:this.isValid(d2),
		};
		result.valid = result.dateValid && result.compareValid;
		if(!result.valid) return result;

		_.extend(result, {
			date: d1,
			compare: d2,
			when: this.when(d1, d2),
			absolute: this.absoluteDifference(d1, d2),
			calendar: this.calendarDifference(d1, d2),
			sameYear: this.sameYear(d1,d2),
			sameMonth: this.sameMonth(d1,d2),
			sameDay: this.sameDay(d1,d2),
		});
		return result;
	},
	when(d1, d2 = new Date()){
		if(this.isValid(d1))
			d1 = d1.valueOf();
		if(this.isValid(d2))
			d2 = d2.valueOf();
		if(!_.isNumber(d1))
			throw new Error('Date when first argument is wrong. accepts number or Date instance');
		if(!_.isNumber(d2))
			throw new Error('Date when compare argument is wrong. accepts number or Date instance');

		return d1 === d2 ? this.NOW
			: d1 < d2 ? this.PAST
				: this.FUTURE;
		
	},
	absoluteDifference(d1, d2 = new Date()) {

		if(!this.isValid(d1))
			throw new Error('Date absoluteDifference first argument is not a Date instance');

		if(!this.isValid(d2))
			throw new Error('Date absoluteDifference compare argument is not a Date instance');
			

		var dif = Math.abs(d1.valueOf() - d2.valueOf());
		var fn = Math.floor;
		var r = {
			ms: dif,
			seconds: fn(dif / this.SECOND),
			minutes: fn(dif / this.MINUTE),
			hours: fn(dif / this.HOUR),
			days: fn(dif / this.DAY),
		};
		return r;
	},
	calendarDifference(d1, d2) {

		if(!this.isValid(d1))
			throw new Error('Date calendarDifference first argument is not a Date instance');

		if(!this.isValid(d2))
			throw new Error('Date calendarDifference compare argument is not a Date instance');


		var y1 = d1.getFullYear();
		var y2 = d2.getFullYear();
		var years = y1 - y2;
		var m1 = d1.getMonths();
		var m2 = d2.getMonths();
		var months = m1 - m2;
		var da1 = d1.getDays();
		var da2 = d2.getDays();
		var days = da1 - da2;
		var r = {
			years: Math.abs(years),
			months: Math.abs(months),
			days: Math.abs(days),
		};
		return r;
	}	
});
