import _ from 'underscore';
import { Model, Collection } from 'base';

export const Value = Model.extend({	
	tokens:{
		value:'v',
		from:'f',
		to:'t',
		valueMode: 'vm',
		valueType: 'vt',
		compare: 'c'
	},
	isRange(data){
		if (data == null && this.range != null) {
			return this.range;
		}
		!data && (data = this.attributes);
		if('from' in data || 'to' in data) {
			return true;
		} else {
			return false;
		}		
	},
	getValue(){
		if (this.isRange()) {
			return _.pick(this.attributes, 'from','to');
		} else {
			return this.get('value');
		}
	},
	toObject({ transform } = {}){
		let hash = _.pick(this.attributes, ..._.keys(this.tokens));
		if (this.isRange()){
			delete hash.value;
		} else {
			delete hash.from;
			delete hash.to;
		}
		let result = _.reduce(hash, (memo, value, key) => {
			let name = this.tokens[key];
			if (value == null) {
				return memo;
			}
			if (_.isFunction(transform)) {
				value = transform(key, value);
			}
			memo[name] = value;
			return memo;
		}, {});
		return result;
	},
	parse(data){
		let tokens = _.reduce(this.tokens, (memo, key, value) => {
			memo[key] = value;
			return memo;
		}, {});
		let parsed = {};
		let founded = false;
		_.each(tokens, (key, token) => {
			if(token in data){
				founded = true;
				parsed[key] = data[token];
			}
		});

		!founded && (parsed = data);		
		this.range = this.isRange(parsed);

		return parsed;
	}
});

export const Values = Collection.extend({
	model: Value,
	parse(values){
		if (!_.isArray(values)) {
			values = [values];
		}
		return _.map(values, value => {
			if(value instanceof Value){
				return value;
			} else{
				return this.convert(value);
			}
		});
	},
	convert(value){
		if(_.isObject(value)){
			return value;
		} else {
			return { value };
		}
	},
	toObject(itemKey = '', opts){
		let res = this.reduce((memo, model) => {

			let hash = model.toObject(opts);
			if (!memo[itemKey]) {
				memo[itemKey] = hash;
			} else {
				if(!_.isArray(memo[itemKey])){
					memo[itemKey] = [memo[itemKey]];
				}
				memo[itemKey].push(hash);
			}
			return memo;
		}, {});
		return _.size(res) ? res : undefined;
	}
});
