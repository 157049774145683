
import _ from 'underscore';

import FormAction from 'components/form-action';
import { EditModel } from 'bbmn-controls';
import { ForgotPass } from 'mod/acc/models';
import { ModelSchemas } from 'bbmn-components';

const Layout = FormAction.extend({
	getFormView(){
		return new EditModel({
			cssClassModifiers: ['big forgot-pass'],
			schema: ModelSchemas.get(ForgotPass),
			buttons: [{ name:'resolve', text:'далее' }],
			value: {},
			onResolve: values => this.submit(values)
		});
	},
	postFormTemplate: '<big>Вам отправлено письмо с дальнейшими инструкциями.</big>',
	job(values) {
		var post = new ForgotPass();
		return post.save(values, { 
			notifyId: _.uniqueId('urn'),
			wait:'человек, подожди. \r\nотправляется письмо...',
			success: 'человек, теперь иди в почту \r\nи следуй инструкциям в письме',
			error: 'человек, что-то пошло не так',
		});
	}
});


export default Layout;
