import { ListItemView, ModelCoreView } from 'components/views';
import { View } from 'base';
import { enums } from 'bbmn-utils';

// const importsEnum = {
// 	realties:'недвижимость',
// 	realtyObjects: 'площади',
// 	realtyOffers: 'предложения',
// 	realtyAddresses: 'адреса',
// 	metroStations: 'станции метро',
// 	metroLines: 'линии метро',
// 	owners: 'собственники',
// 	realtyImages: 'картинки недвижимости'
// };

const ImportCoreView = ModelCoreView.extend({
	topText: m => m.get('lastStatus') + ': ' + m.get('lastDuration'),
	text: m => enums.get('migrations', m.id),
	bottomText: m => m.getLastRunOffset()
});

export const ImportItemView = ListItemView.extend({
	className: 'import-item',
	modelView: ImportCoreView, 
	attributes: {
		title: 'ctrl-click: single'
	},
	cssClassModifiers:[
		m => m.get('inProcess') ? 'in-process' : '',
		m => 'last-' + m.get('lastStatus')
	],
	leftItems:[
		() => new View({ template: () => '<i></i>', tagName: 'span', className: 'state' })
	],
	rightButtons:[
		'run'
	],
	onRunClick(e){
		let diff = !!e.shiftKey;
		let single = !!e.ctrlKey;
		//console.log(e.ctrlKey, e.shiftKey);
		console.warn('run click', this.model);
		this.model.run({ single, diff });
	}
});

