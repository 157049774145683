import _ from 'underscore';
import { CollectionView, View } from 'base';
import { buildViewByKey } from 'bbmn-utils';
import { TextView } from 'bbmn-components';
import { buildActionsListView } from '../actions/views';

export const UiBlockView = CollectionView.extend({	
	renderAllCustoms: true,
	renderCollection: false,
	alwaysShowHeader: true,
	enableInitializeHook: true,
	cssClassModifiers: ['ui-block'],
	getCustoms(){
		return [
			this.getHeader(),
			this.getContent(),
			this.getFooter()
		];
	},
	getContent(){
		let defaultOptions = { model: this.model, collection: this.collection };
		let view = buildViewByKey(this, 'content', { defaultOptions });
		if(view){
			view.addCssClassModifier('ui-block-content');
		}
		return view;
	},
	getHeader(){
		let options = { model: this.model, tagName: 'header' };
		let view = buildViewByKey(this, 'header', { TextView, options });
		if (!view && this.getOption('alwaysShowHeader')) {
			view = new View({ tagName:'header', className:'empty-header', template: _.noop });
		}
		return view;
	},
	getFooter(){
		if (this.hasOption('footer')) {
			let options = { model: this.model, tagName: 'footer' };
			return buildViewByKey(this, 'footer', { options });
		}

		if (this.hasOption('actions')) {
			let actions = this.getOption('actions');
			if (actions && actions[0] == 'add:phone') {
				console.log('ADD:PHONE');
			}
			let actionArguments = this.getOption('actionArguments');
			let view = buildActionsListView(this.model, { in: actions }, { tagName:'footer', className: 'ui-block-actions', actionArguments });			
			return view;
		}
	}
});
