import 'mod/acc/api-config';
import { ForgotPassConfirm } from 'mod/acc/models';
import FormAction from 'components/form-action';
import { EditModel } from 'bbmn-controls';
import { ModelSchemas } from 'bbmn-components';


const Layout = FormAction.extend({
	getFormView(){
		return new EditModel({
			cssClassModifiers: ['big forgot-pass-confirm'],
			schema: ModelSchemas.get(ForgotPassConfirm),
			buttons: [{name:'resolve', text:'изменить пароль'}],
			value: {},
			onResolve: values => this.submit(values)
		});
	},
	postFormTemplate: 'Отлично! Пароль изменён.',
	job(values) {
		var post = new ForgotPassConfirm();
		values.code = this.getOption('ac').qs.code;
		return post.save(values, { method: 'put' });
	}
});


export default Layout;
