
function loadScript(url, dependOn) {

	var script = document.createElement('script');
	script.src = url;

	let scriptPromise = new Promise((resolve, reject) => {
		script.onload = () => resolve();
		script.onerror = () => reject();
	});

	console.log('loadScript:dependOn', dependOn, url);
	Promise.all([dependOn]).then(
		() => {
			console.log('loadScript:before:add', url);
			document.body.appendChild(script);
		}
	);

	return scriptPromise;
}

export default loadScript;

