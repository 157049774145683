import { View, CollectionView, Collection, Model } from 'base';
import { paths } from 'helpers';
import { _ } from 'vendors';
import { bus } from './search-block';


const EmptyView = View.extend({
	className: 'empty-view',
	template: _.template('<%= message %>'),
	templateContext() {
		const message = this.getMessage();
		return {
			message
		}
	},
	getMessage() {
		const state = this.getOption('state');
		let msg = '';
		switch(state) {
			case undefined:
				msg = 'Для получения списка нажмите кнопку "получить список"'; break;
			case 'fetching':
				msg = "Подождите, идёт загрузка данных"; break;
			case 'processing':
				msg = "Подождите, идёт обработка данных"; break;
			case 'empty':
				msg = "По вашему запросу ничего не нашлось"; break;
		}
		return msg;
	}
})

export const SearchList = CollectionView.extend({
	className: 'realty-contacts-list',
	initialize() {
		this.listenTo(bus, 'fetch:click', this.startFetch);
	},
	async startFetch() {
		bus.set({
			count: null,
			offersCount: null,
			realtiesCount: null
		});
		this.showEmptyMessage('fetching', true);
		//debugger;
		const fetcher = new Model();
		const apiPath = this.getOption('apiUrl');
		fetcher.url = paths.apiUrl(apiPath, 'pub');
		const attrs = this.model.attributes;
		await fetcher.save(null, { method:"POST", attrs });
		const data = {
			count: fetcher.get('count'),
			offersCount: fetcher.get('offersCount'),
			realtiesCount: fetcher.get('realtiesCount'),
		}
		bus.set(data);
		this.showEmptyMessage('processing');		
		this.collection.reset(fetcher.get('items'));
	},
	showEmptyMessage(state) {
		this._fetchState = state;
		this.collection.reset();
		//this._showEmptyView();
	},
	emptyViewOptions() {
		let state = this._fetchState;
		if (!this.collection.length && state === 'processing') {
			state = 'empty';
		}
		console.log('getting empty options', state);
		return {
			state,
			preventRender: false
		}
	},
	emptyView: EmptyView
})
