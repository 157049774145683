import { ModelSchemas } from 'bbmn-components';
import { Model } from 'base';

/* hey ho */

export const PhoneModel = Model.extend({
	display(){
		if (arguments.length) {
			return Model.prototype.display.apply(this, arguments);
		}
		let str = [
			this.display('country'),
			this.display('code'),
			this.display('number')
		].join(' ');
		return str.trim() || 'не задан';
	}
});

export const phoneSchema = ModelSchemas.initialize(PhoneModel, {
	id:{
		display:{
			label: 'название (идентификатор)',
			transform(v){
				return v == 'default' ? 'основной' 
					: v == 'promotions' ? 'для продвижений'
						: v;
			}
		}
	},
	country: {
		validation: {
			charPattern: /\d/gmi
		},
		value:{
			type:'text',
		},
		display:{
			label: 'код страны',
			transform(v){
				if (v == null) return '';
				return '+' + v;
			}
		}
	},
	code: {
		validation: {
			charPattern: /\d/gmi
		},
		value:{
			type:'text',
		},
		display:{
			label: 'код региона'
		}
	},
	number: {
		validation: {
			charPattern: /\d/gmi
		},
		value:{
			type:'text',
		},
		display:{
			label: 'номер',
			transform(v){
				if(!v) return '';
				v = v.split('');
				let res = '';
				let taked = 0;
				while(v.length){
					let last = v.pop();
					res = last + res;
					taked++;
					if (v.length <= 1) {
						continue;
					} else if (taked >= 2) {
						res = '-' + res;
						taked = 0;
					}
				}
				return res;
			}
		}
	}
});
