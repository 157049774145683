import _ from 'underscore';
import { CollectionView } from 'pages/views';
// import { View } from 'base';
// import { RealtyItemView, RealtyItemCard } from 'mod/realties/realties/views';
import { ContactCard } from 'mod/realties/custom-contacts/views';
import { mix } from 'bbmn-utils';
import { initSelectorMixin } from 'bbmn-components';


// const Founded = View.extend({
// 	renderOnModelChange: true,
// 	className: 'entries-founded',
// 	tagName: 'header',
// 	template: _.template('нашлось: <%= _m.get("founded") %>'),
// });


export default mix(CollectionView).with(initSelectorMixin).extend({
	renderAllCustoms: true,
	isEmpty(){
		return !this.collection.length;
	},
	className: 'contacts-cards',
	childView: ContactCard,
	childViewOptions:{
		mainAction: 'open:card',
	},
	// customs:[ v => new Founded({ model: v.collection.manager })],
});
