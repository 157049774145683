import { ListItemView } from 'components/views';
import { BaseFeedItemView } from './base-feed-item';

export const FeedItemView = ListItemView.extend({
	className: 'feed-list-item',
	optionsButton: true,
	modelView: BaseFeedItemView,
	cssClassModifiers: [
		m => m.get('public') ? 'public' : ''
	]
});
