import _ from 'underscore';
import { setByPath, getFlag, clone } from 'bbmn-utils';
const baseMethods = ['read', 'delete', 'create', 'update', 'patch'];
const actions = {
	'read.ing': 'загружа<%= countVerb %>тся',
	'delete.ing': 'удаля<%= countVerb %>тся',
	'create.ing': 'созда<%= countVerb %>тся',
	'update.ing': 'сохраня<%= countVerb %>тся',
	'patch.ing': 'сохраня<%= countVerb %>тся',
	'read.ed': 'загрузил<%= pastVerbEnd %>',
	'delete.ed': 'удалил<%= pastVerbEnd %>',
	'create.ed': 'создал<%= pastVerbEnd %>',
	'update.ed': 'сохранил<%= pastVerbEnd %>',
	'patch.ed': 'сохранил<%= pastVerbEnd %>',	
};


const methods = _.reduce(baseMethods, (memo, method) => {
	let ing = `${method}.ing`;
	let ed = `${method}.ed`;
	setByPath(memo, ing, actions[ing]);
	setByPath(memo, ed, actions[ed]);
	return memo;
}, {});

const countVerbs = {
	single: 'е',
	many: 'ю'
};
const genderedPastVerbPostfix = {
	many: 'ись',
	male:'ся',
	female: 'ась',
	it: 'ось'
};

const itemDefaults = {
	wait:{
		text: 'Подождите, <%= name %> <%= takingAction %>'
	},
	error: {
		text: '<%= name %> не <%= takedAction %>',
		store: false
	},
	success: {
		text: '<%= name %> <%= takedAction %>',
		viewedDelay: 1,
		store: false
	}
};



export default function createSchemaNotifies({ name, generate = true, gender = 'male', many = false } = {}){
	let processMethods = generate === true ? clone(baseMethods) : getFlag(baseMethods, generate);
	let result = _.reduce(processMethods, (memo, method) => {
		let value = clone(itemDefaults);
		let data = {
			name,
			takingAction: methods[method].ing,
			takedAction: methods[method].ed
		};
		if(many){
			gender = 'many';
		}
		let genderData = {
			countVerb: countVerbs[many ? 'many' : 'single'],
			pastVerbEnd: genderedPastVerbPostfix[gender]
		};
		_.each(value, (typeValue) => {
			let template = _.template(typeValue.text);
			let text = template(data);
			template = _.template(text);
			typeValue.text = template(genderData);
		});
		memo[method] = value;
		return memo;
	}, {});	
	return result;
}
