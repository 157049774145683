import _ from 'underscore';
import { Collection } from 'base';
//import { nestedEntitiesMixin } from 'bbmn-mixins';
import { Values } from './values';
import { ModelSchemas, PropertySchema,  } from 'bbmn-components';
import { action, ActionStore } from 'components/actions';
import { ActionModel } from 'mod/model-mixins';
import editor from 'components/editor';
import { getByPath, hasFlag, isEmptyValue } from 'bbmn-utils';
export const Item = ActionModel.extend({
	constructor: function(opts){
		ActionModel.apply(this, arguments);
		if (opts.compareModelValue != null) {
			this.compareModelValue = opts.compareModelValue;
		}
		if (opts.compare != null) {
			this.compare = opts.compare;
		}
	},
	nestedEntities:{
		values: {
			class: Values,
			parse: true,
		}
	},
	isPined(){
		return this.get('pined') || this.isFilled();
	},
	isCompareAllowed(){
		return (this.compareProperty || this.compareModelValue || this.compare);
	},	
	getUrlKey(){
		return this.urlKey || this.id;
	},
	isMultiple(){
		return this.multiple === true;
	},
	isEnum(){
		return this.valueType === 'enum';
	},
	isBoolean(){
		return this.valueType === 'boolean';
	},
	isWeakTypedBoolean(){
		return this.isBoolean() && !this.sourceValues;
	},
	isRange(data){
		if (!arguments.length) {
			return this.modelType === 'range';
		} else {
			return _.isObject(data) && ('from' in data || 'to' in data);
		}
	},
	getValueSchema(){
		if (this.isWeakTypedBoolean()) {
			this.sourceValues = {
				true: 'Да',
				false: 'Нет'
			};
			this.control = 'boolean';
		}

		if(!this.valueSchema) {

			let value = {
				type: this.valueType,
				modelType: this.modelType,
				sourceValues: this.sourceValues,
				control: this.control,
				multiple: this.isMultiple(),
			};



			let options = {
				name: this.id,
				property:{
					value,
					edit: this.schemaEditOptions,
					display: _.extend({
						label: this.display('name')
					}, this.schemaDisplayOptions)
				}
			};
			this.valueSchema = new PropertySchema(options);
		}
		return this.valueSchema;
	},
	getValue(){
		let col = this.entity('values');
		let values = col.map(value => value.getValue());
		if (this.isMultiple() && !this.isEnum()) {
			return values.length ? values : undefined;
		} else {
			return values[0];
		}
	},
	isFilled(){
		return this.getValue() != null;
	},
	getValueLabel(){

		let value = this.getValue();
		let range = this.isRange(value);
		let schema = this.getValueSchema();
		if	(!range) {
			return schema.getDisplayValue(value);
		} else {
			let from = schema.getDisplayValue(value.from, null, { ignoreIfEmpty: true });
			let to = schema.getDisplayValue(value.to, null, { ignoreIfEmpty: true });
			let res = [];
			if (from) {
				from = 'от ' + from;
				res.push(from);
			}
			if (to) {
				to = 'до ' + to;
				res.push(to);
			}
			return res.join(' ');
		}
	},
	setValues(newvalues){
		let values = this.entity('values');
		if (newvalues == null) {
			newvalues = [];
		} else if (!_.isArray(newvalues)){
			newvalues = [newvalues];
		}
		values.reset(newvalues, { parse: true });
	},
	resetValues(){
		this.setValues();
	},
	parse(data, { qs } = {}){
		let ext = _.omit(data, 'name', 'values');
		_.extend(this, ext);
		
		let raw = _.pick(data, 'id', 'name', 'values');

		let urlKey = this.getUrlKey();
		if(!qs) return raw;

		if(urlKey in qs) {
			let qsValue = qs[urlKey];
			// if (qsValue != null && !_.isArray(qsValue)) {
			// 	qsValue = [qsValue];
			// }
			raw.values = qsValue;			
		}
		return raw;
	},
	toObject(){
		let values = this.entity('values');
		return values && values.toObject(this.id) || undefined;
	},
	modelFilter(model){
		
		if (!this.isCompareAllowed()) {
			return true;
		}
		let value = this.getValue();
		if (isEmptyValue(value)) return true;
		return this.compareModel(model, value);
	},
	getCompareModelValue(model){
		if(_.isFunction(this.compareModelValue)){
			return this.compareModelValue(model, this);
		} else if (this.compareProperty) {
			return getByPath(model, this.compareProperty);
		}
	},
	compareModel(model, value){
		
		if(_.isFunction(this.compare)) {
			return this.compare(value, model, this);
		}
		let modelValue = this.getCompareModelValue(model);

		if (isEmptyValue(modelValue)) {
			return false;
		}
		else if (modelValue == value) {
			return true;
		}

		let schema = this.getValueSchema();
		let valuetype = schema.getType();
		switch(valuetype.type){
		case 'enum':
			return hasFlag(value, modelValue);
		case 'text':
		case 'bigtext':
		case undefined:
			return modelValue.toLowerCase().indexOf(value.toLowerCase()) > -1;
		}

		if (this.isRange()) {
			return modelValue >= (value.from || -Infinity) && modelValue <= (value.to || Infinity);
		}
		
	}
});

ModelSchemas.initialize(Item, {
	value:{
		display:{
			transform(){
				return this.getValueLabel();
			},
			ifEmpty:'&ndash;'
		}
	}
});

ActionStore.initialize(Item, [
	action.hidden('edit',function(){
		let model = this;
		let schema = this.getValueSchema();
		let value = this.getValue();

		editor.editProperty({
			schema,
			propertiesToShow: null,
			value,
			onResolve(values){
				console.log('filterItem: on resolve', values);
				model.setValues(values);
			},
			onRejectHard(){
				model.setValues(null);
				this.destroy();
			},
			buttons: [{name:'resolve', text:'изменить'}, {name:'rejectHard', text:'очистить'}],
		}, model.display('name'));
	})
]);


export const Items = Collection.extend({
	model: Item,
	toObject(opts){
		let res = this.reduce((memo, model) => {
			_.extend(memo, model.toObject(opts));
			return memo;
		}, {});
		return _.size(res) ? res : undefined;
	},
	modelFilter(model){
		return this.every(item => item.modelFilter(model));
	},
	resetValues(){
		this.invoke('resetValues');
	},
	hasValues(){
		return this.some(model => model.isFilled());
	}
});
