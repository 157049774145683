import BasePage from 'pages/auth';
import { RealtyList } from 'mod/realties/realty-lists/models';
import { Realties } from 'mod/realties/realties/models';
import { Filterer } from 'components/filterer';
import { getFilters } from 'mod/realties/realties/filters';
import Content from './content';

const RealtyListPage = BasePage.extend({
	id:'realties-realtylist-page',
	isEntityPage: true,
	routes: ':listId',
	skipMenu: true,
	label: (p, data = {}) => p.model && p.model.get('name') || data.label,
	LayoutOptions: p => ({
		selector: p.selector,
		filterer: p.filterer,
		fetchNextEdge: 'bottom',
		scrollHandlingEnabled: true,		
	}),
	Content,
	onBeforeStart(proc, ac){
		
		let model = this.model = new RealtyList({ id: ac.args.listId });

		this.filterer = new Filterer({ formatDataForQueryString: false });
		this.filterer.setFilters(getFilters());

		let collection = this.collection = new Realties();
		this.collection.setFilterer(this.filterer);

		collection.url = model.url() + '/items/search';
		collection.query();

		return model.fetchIfNot().then(() => {
			this.model = model;			
		});
	},
	//accepts: ac
	data(){
		return {
			label: this.model.display('name'),			
		};
	}
});


export default RealtyListPage;
