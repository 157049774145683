import { CollectionView } from 'pages/views';
import { Button } from 'bbmn-controls';
import { OfferListItemView } from 'mod/realties/offer-lists/views';

export default CollectionView.extend({
	name: 'lists',
	className: 'offerlists-cards',
	// renderAllCustoms: true,	
	childView: OfferListItemView,
	// customs:[v => [v.createListButton(), { index: Infinity }]],
	childViewOptions:{
		mainAction: 'open:card'
	},
	createListButton(){

		let action = this.page.getAction('create:list');
		if(action){
			return new Button({
				text: 'создать новый список предложений',
				onClick: (e) => {
					action.exec(this.page, e, this.page);
				}
			});
		}
		
	}
});
