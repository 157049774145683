import _ from 'underscore';

const resolvers = {
	complete: undefined,
	interactive: undefined,
};

const ready = new Promise((resolve) => {
	resolvers.complete = resolve;
});

const interactive = new Promise((resolve) => {
	resolvers.interactive = resolve;
});


document.onreadystatechange = function () {
	//console.log('readystate:', document.readyState);
	const resolve = resolvers[document.readyState];
	//console.log('resolve:', resolve);
	_.isFunction(resolve) && resolve();
};

const domReady = new Promise((resolve) => {
	$(() => {
		//console.log('dom ready.');
		resolvers.interactive();
		resolve();
	});
});

export {
	ready,
	interactive,
};

export default ready;
