import _ from 'underscore';
import { clone } from 'bbmn-utils';
import { Collection } from 'base';
import { busModels } from 'bus';
import { ActionModel } from 'mod/model-mixins';
import { enums } from 'bbmn-utils';
import { ActionStore, action } from 'components/actions';
import { busViews } from 'bus';
import { modals, PropertySchema } from 'bbmn-components';
import { EditProperty } from 'bbmn-controls';
import { ModelSchemas } from 'bbmn-components';



export function getDefaultCategories(){
	return _.map(enums.get('cianObjectCategories'), (l,id) => ({
		id,
		disabled: true,
		maximum: null,
	}));
}

export const AdCategory = ActionModel.extend({
	isDisabled(){
		return this.get('disabled') === true;
	},
});
ActionStore.initialize(AdCategory, [
	action('show:details','редактировать', function(e, opts = {}){ 

		modals.show({
			header: this.display('id'),
			content: busViews.getClass('FeedCategoryDetailsView'),
			contentOptions:{
				model: this
			}
		});

	}, { export: 'editFeeds'}),
]);
ModelSchemas.initialize(AdCategory, {
	id:{
		value:{
			type:'enum',
			sourceValues (m)  {
				if (!m || !m.parent || !m.parent.parent || !m.parent.parent.getFeedConfigCategories) {
					console.warn('promotion model has no feed reference', m);
					return {};
				}
				return m.parent.parent.getFeedConfigCategories({ enum: true });
			}
		},
		display:{
			label:'категория объявления'
		}
	},	
	disabled: {
		value: {
			type:'boolean',
			control: 'select:inline',
			sourceValues: ['Работает','Отключено']
		},
		display: {
			label:'состояние',
			ifEmpty: '&ndash;',
		}
	},
	maximum: {
		value: {
			type: 'number',
		},
		display: {
			label: 'квота по количеству объявлений',
			ifEmpty: '&ndash;'
		}
	},
	phoneId: {
		value: {
			type: 'enum',
			multiple: false,
			sourceValues(){
				console.log('arguments', this);
				return this.parent.parent.getCustomPhonesEnum();
			}
		},
		display:{
			label: 'телефон',
			ifEmpty: '&ndash;',
			transform(v, opts){
				let phone = opts.model.parent.parent.getCustomPhone(v);
				if (!phone) return '';
				return phone.display();
			}
		}		
	},
	descriptionTemplate: {
		value:{
			type: 'text',
			control: 'advert:template'
		},
		display: {
			label: 'шаблон для категории объявлений',
			ifEmpty: '&ndash;',
			ifNotEmpty: 'установлен собственный шаблон',
		}
	}
});

// export const AdCategories = Collection.extend({
// 	model: AdCategory
// });

export const AdCategories = ActionModel.extend({
	// initialize(){
	// 	let cats = getDefaultCategories();
	// 	this.nestedEntities = _.reduce(cats, (memo, cat) => {
	// 		memo[cat.id] = {
	// 			class: AdCategory,
	// 			data: cat
	// 		};
	// 		return memo;
	// 	}, {});
	// },
	initialize(){
		_.each(this.attributes, (value, key) => {
			this.registerNestedEntity(key, AdCategory);
		});
	},	
	getCategories(){
		//let models = _.map(getDefaultCategories(), cat => this.entity(cat.id));
		let models = _.map(this.attributes, (value, key) => this.entity(key));
		return new Collection(models);
	},
});
