import BasePage from 'pages/auth';
import Content from './Content';
// import Import from './import';
// import Roles from './roles';
// import Users from './users';
// import Config from './config';
// import Phones from './phones';

export default BasePage.extend({
	id:'adm-phones-asterisk-list',
	routes: 'asterisk-table',
	label: 'Таблица переадресации номеров на АТС',
	Content,
	//noAside: true,
	relativeRoutes: true,
	children: [],
	rights:{
		admin: 'view',
	}
});