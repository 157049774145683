
import { View, CollectionView, Collection, Model } from 'base';
import { paths } from 'helpers';
import { _ } from 'vendors';
import { ContactAtomItem } from 'mod/realties/custom-contacts/views';
import { SearchBlock, bus, realtiesSearch } from '../search-block';
import { SearchList } from '../list-view';
import { RealtyItemInfo } from '../RealtyItemInfo';
import { ResultInfoView } from '../result-info';

//const bus = new Model();
const realties = new Collection();


// const RealtyItemInfo = View.extend({
// 	className: 'realty-info',
// 	template: _.template(`
// 		<div class="name"><%= name %></div>
// 		<div class="address"><%= fullAddress %></div>
// 	`),
// });

const RealtyItemCard = CollectionView.extend({
	className: 'card-item white-box realty-contact-entry',
	renderCollection: false,
	renderAllCustoms: true,
	customs: [
		RealtyItemInfo,
		ContactAtomItem
	],
	customViewOptions() {
		return {
			model: this.model
		}
	}

})

const RealtiesList = SearchList.extend({
	collection: realties,
	model: realtiesSearch,
	childView: RealtyItemCard,
	apiUrl: 'contacts/realties'
});

export const RealtiesContent = CollectionView.extend({
	initialize() {
		realties.reset();
	},
	renderCollection: false,
	renderAllCustoms: true,
	customViewOptions: {
		searchType: 'realties'
	},
	customs:[
		SearchBlock,
		ResultInfoView,
		RealtiesList
		//v => v.getLinksView('children'),
		//v => v.getLinksView('siblings', { addRoot: true }),
	],
	//customs
});