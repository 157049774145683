import _ from 'underscore';
import { Model } from 'base';

export const Paginator = Model.extend({
	constructor: function(){
		Model.apply(this, arguments);
	},

	edge: 3,

	defaults:{
		entries: 0,
		skip: 0,
		size: 10,
	},

	entries(n){
		this.set('entries', n);
	},
	setPage(n){
		n <= 0 && (n = 1);
		n--;
		let size = this.getSize();
		this.skip(n * size);
	},
	skip(n){
		n < 0 && (n = 0);
		this.set('skip', n);
	},


	getSize(){
		let size = this.get('size') || 1;
		size <= 0 && (size = 1);
		return size;
	},
	getPagesCount(){
		let pages = Math.ceil(this.get('entries') / this.getSize());
		pages < 1 && (pages = 1);
		return pages;
	},
	getCurrentPage(){
		let size = this.getSize();
		let current = Math.ceil((size + this.get('skip')) / size);
		current <= 0 && (current = 1);
		let pages = this.getPagesCount();
		current > pages && (current = pages);
		return current;
	},


	getPrevs(){
		let res = [];
		let current = this.getCurrentPage();
		current > 1 && (res.unshift('prev'));
		current > this.edge && (res.unshift('start'));
		return res;
	},
	getNexts(){
		let res = [];
		let pages = this.getPagesCount();
		let current = this.getCurrentPage();
		current < pages && (res.push('next'));
		current + this.edge <= pages && (res.push('end'));
		return res;
	},

	getPages(){
		let res = [];
		let current = this.getCurrentPage();
		let pages = this.getPagesCount();
		res.push(current);

		if (current < pages) {
			let nearEdge = current + this.edge - 1;
			nearEdge > pages && (nearEdge = pages);
			let next = current + 1;
			for(; next <= nearEdge; next++)
				res.push(next);

			let half = next + Math.ceil((pages - next) / 2);
			if(half > next && half < (pages - 1)){
				res.push(half);
			}
		}
		
		if(current > 1) {
			let nearEdge = current - this.edge + 1;
			nearEdge < 1 && (nearEdge = 1);
			let prev = current - 1;
			for(; prev >= nearEdge; prev--)
				res.unshift(prev);

			let half = Math.ceil(prev / 2);
			if(half < prev && half > 2){
				res.unshift(half);
			}
		}
		return res;
	},
	getAll(){
		return [
			...this.getPrevs(),
			...this.getPages(),
			...this.getNexts(),
		];
	},
	isCurrent(num){
		return num === this.getCurrentPage();
	},
	isInPage(num){

		if (this.isRebuilding()) {
			this.attributes.entries = num;
		}


		let cur = this.getCurrentPage();
		let size = this.getSize();
		let end = cur * size;
		let start = end - size;
		return num >= start && num < end;
	},
	isHelper(num){
		if(!_.isNumber(num)) return false;
		let current = this.getCurrentPage();
		return num > 0 && ( num < (current - this.edge) || num > (current + this.edge) );
	},
	toJSON(){
		let current = this.getCurrentPage();
		let pages = this.getPages();
		return {
			entries: this.get('entries'),
			size: this.getSize(),
			pages: this.getPagesCount(),
			nexts: this.getNexts(),
			prevs: this.getPrevs(),
			page: current,
			before: pages.filter(page => page < current),
			after: pages.filter(page => page > current),
		};
	},
	request(page){
		let hash = {
			start:1,
			prev: this.getCurrentPage() - 1,
			next: this.getCurrentPage() + 1,
			end: this.getPagesCount()
		};
		
		if(!_.isNumber(page) && page in hash) {
			page = hash[page];
		}
		_.isNumber(page) && this.setPage(page);
	},
	isRebuilding(){
		return this.getState() == 'rebuilding';
	},
	rebuildPages(){
		this.set({ entries: 0, skip: 0 }, { silent: true });
		this.setState('rebuilding');
	},
	stopRebuildPages(){
		this.setState(null);
		this.trigger('rebuild');
	},
	setState(state){
		this.state = state;
	},
	getState(){
		return this.state;
	},
});
