import BasePage from 'pages/auth';
//import { RealtyList } from 'mod/realties/realty-lists/models';
import { Role } from 'mod/adm/roles/models';
import Content from './content';

const Page = BasePage.extend({
	id:'adm-roles-role',
	routes: ':roleId',
	isEntityPage: true,
	skipMenu: true,
	label: (p, data = {}) => p.model && p.model.get('name') || data.label,
	Content,
	onBeforeStart(proc, ac){
		let model = this.model = new Role({ id: ac.args.roleId });
		return model.fetchIfNot();
	},
	//accepts: ac
	data(){
		return {
			label: this.model.display('name'),			
		};
	}
});


export default Page;
