import { paths } from 'helpers';

paths.set('api', 'export', 'export', { prefix: 'ent' });
paths.set('api', 'export:feeds', 'feeds', { relative: true });
paths.set('api', 'exporturl', 'xml', { relative: false });

paths.set('url', 'export', 'export');
paths.set('url', 'export:feeds', 'feeds', { relative: true });
paths.set('url', 'export:feeds:feed', '{feedId}', { relative: true });
paths.set('url', 'export:feeds:feed:results', 'results', { relative: true });
