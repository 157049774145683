import { proxyPhones } from "./proxyPhones";
import { SelectProxyPhoneView } from "./views/SelectProxyPhoneView";

export const newContactSchema = {
	name: {
		validation: {
			required: true,
		},
		value: {
			type: 'string',
			inputType: 'text',
		},
		display:{
			label: 'название (для внутрен. использования)'
		},
	},
	value: {
		validation: {
			required: true,
		},
		value: {
			type: 'string',
			inputType: 'text',
		},
		display:{
			label: 'телефон (только цифры и "+", пример: "+71234567890")'
		},
	},
	// isRedirection: {
	// 	value: {
	// 		type: 'boolean',
	// 	},
	// 	display: {
	// 		label: 'Номер для переадресации'
	// 	}
	// },
	proxyPhoneId: {
		value: {
			control:'select',
			sourceValues: () => {
				proxyPhones.fetchIfNot();
				return proxyPhones
			},
		},
		edit: {
			extractValue: value => value.id,
			childView: SelectProxyPhoneView,
			rejectHard: true,
			// filtered: true,
			// childView: () => busViews.getClass('RealtyListItemView')
		},		
		display: {
			label: 'Подстановочный номер',
			ifEmpty: '&mdash;'
		}
	},
	proxyPhoneExtension: {
		value: {
			type: 'string',
		},
		display: {
			label: 'Добавочный для Подстановочного номера (разрешены с 000 по 999)'
		}
	},	
	displayValue: {
		value: {
			type: 'string',
			inputType: 'text',
		},
		display:{
			label: 'Отображение телефона (опционально, как должен отображаться номер на странице сайта)'
		},
	},	
	label: {
		value: {
			type: 'string',
			inputType: 'text',
		},
		display:{
			label: 'текст подписи (пример: "Отдел продаж")'
		},
	},
	notVisible: {
		value: {
			type: 'boolean',
		},
		display:{
			label: 'не публикуется (скрыт)',
			transform(v) {
				
				const cc = this.get('ownCustomContact') || this.get('customContact') || {};
				if (cc.value) {
					return v;
				} else {
					return;
				}
			}
		},
	}
}

export const editOwnContactSchema = {
	...newContactSchema,

}