
import { View, CollectionView, Collection, Model } from 'base';
import { _ } from 'vendors';
import { bus } from './search-block';


const ItemView = View.extend({
	className: 'count-item',
	template: _.template(`<% if (show) { %><span><%= label %></span><big><%= count %></big><% } %>`),
	templateContext() {
		return {
			show: this.shouldShow(),
			label: this.getLabel(),
			count: this.getCount()
		}
	},
	shouldShow() {
		return this.getCount() != null;
	},
	getLabel() {
		return this.getOption('label');
	},
	_getCount(prop) {
		prop = prop || this.getOption('property');
		return this.model.get(prop);
	},
	getCount() {
		return this._getCount();
	},
	modelEvents: {
		change: 'render'
	}
})

const OffersCount = ItemView.extend({
	property: 'offersCount',
	label: 'Страниц предложений: ',
})

const RealtiesCount = ItemView.extend({
	property: 'realtiesCount',
	label: 'Страниц зданий: '
})

const TotalCount = ItemView.extend({
	label: 'Всего страниц: ',
	getCount() {
		const offs = this._getCount('offersCount');
		if (offs == null) { return; }
		const rlts = this._getCount('realtiesCount');
		return offs + rlts;
	}
})

const CommonCount = ItemView.extend({
	property: 'count',
	label: 'Найдено страниц: '
})

export const ResultInfoView = CollectionView.extend({
	className: 'contatcs-result-info',
	renderCollection: false,
	renderAllCustoms: true,
	customViewOptions: {
		model: bus,
	},
	customs: [
		CommonCount
		// OffersCount,
		// RealtiesCount,
		//TotalCount
	]
})