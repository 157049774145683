import _ from 'underscore';
import { CollectionView, View } from 'base';
import { buildViewByKey } from 'bbmn-utils';
import { camelCase, isViewClass, isView } from 'bbmn-utils';
import { Button } from 'bbmn-controls';
import { AtomTextView } from 'components/views';
import { mainAction } from './helpers';
import { actionsPopover } from 'components/actions';

// const SimpleButton = View.extend({
// 	tagName:'button',
// 	template: () => '<i></i>'
// });

const BaseIconView = View.extend({
	template:_.template('<%= content %>'),
	className: 'ui-item-list-icon',
	cssClassModifiers:[
		(m,v) => v.getOption('side')
	],
	templateContext(){
		const content = this.getOption('content', { args: [this.model, this] }) || '<i></i>';
		return {
			content
		};
	}
});


export const ListItemView = CollectionView.extend({
	constructor: function(){
		CollectionView.apply(this, arguments);
		this.setButtonListeners();
		this.addCssClassModifier('ui-list-item');
		let clickable = this.mainAction = mainAction(this);
		if (clickable) {
			this.addCssClassModifier('clickable');
			if(_.isString(clickable)) {
				this.delegate('click', '> .item-core', (event) => {
					event.stopPropagation();
					let eventName = clickable.replace('trigger:','');

					this.triggerMethod(eventName, event);
				});
			}
		}
	},

	renderCollection: false,
	renderAllCustoms: true,

	_getCustomDefaultOptions(opts){
		return _.extend({
			model: this.model,
		}, opts);
	},
	_getModelView(opts){
		const options = this._getCustomDefaultOptions(opts);
		return buildViewByKey(this, 'modelView', { options });
	},
	getModelView(opts){
		let view = this._getModelView(opts);
		if(view){
			view.addCssClassModifier('item-core');
		}
		return view;
	},

	getCustoms(){
		let left = this.getSideItems('left');
		let mainAction = _.isObject(this.mainAction) && this.mainAction;
		let core = this.getModelView({ mainAction: mainAction });
		let right = this.getSideItems('right');
		let customs = [];
		customs.push(...left, core, ...right);
		if (this.getOption('disableCustomsEarlyPrepare')) {
			return customs;
		}
		// console.log('#customs: ', core.el);
		return this._prepareCustoms(customs);
	},
	getSideItems(side){
		let buttons = this._getButtons(side);
		let items = this._getItems(side);
		let icon = this._getIcon(side, true);
		if (side == 'left') {
			return  [icon, ...buttons, ...items];			
		} else {
			return [...items, ...buttons, icon];
		}
	},


	_getIcon(side, hasPriority) {
		if (hasPriority && !this.getOption(camelCase(side, 'icon:priority'))) {
			return;
		}
		let key = camelCase(side, 'icon');
		const options = this._getCustomDefaultOptions({ side });
		let IconView = this.getOption('IconView') || BaseIconView;
		let icon = buildViewByKey(this, key, { TextView: IconView, options });
		if (!icon && side === 'left') {
			icon = buildViewByKey(this, 'icon', { TextView: IconView, options });
		}
		return icon;
	},
	hasSelectButton(side){
		let selectbutton = this.getOption('selectButton', { args: [this.model, this] }) || this.getOption('selectable', { args: [this.model, this] });
		let onTheRight = this.getOption('selectOnTheRight', { args: [this.model, this] });
		if(!side){
			return selectbutton;
		} else if (side == 'right') {
			return selectbutton && onTheRight;
		} else if (side == 'left') {
			return selectbutton && !onTheRight;
		}
	},
	hasOptionsButton(){
		return this.getOption('optionsButton');
	},
	_getButtons(side){
		let buttons = [];
		const buildButton = _.partial(this._buildButton.bind(this), _, side);

		if(side == 'left'){
			if(this.hasSelectButton(side)){
				buttons.push(buildButton({ name: 'select' }));
			}
			if(this.hasOptionsButton()){
				buttons.push(buildButton({ name: 'options' }));
			}
			buttons.push(this._getIcon(side));
		}
		let btns = this.getOption(camelCase(side, 'Buttons'), { args: [this.model, this]}) || [];
		btns = _.map(btns, btn => buildButton(btn));
		buttons.push(...btns);
		if(side == 'right'){
			if (this.getOption('editButton')) {
				buttons.push(buildButton({ name: 'edit' }));
			}
			buttons.push(this._getIcon(side));
			if(this.hasSelectButton(side)){
				buttons.push(buildButton({ name: 'select' }));
			}

			if(this.getOption('rejectButton')){
				buttons.push(buildButton({ name: 'reject' }));
			}
			if(this.getOption('removeButton')){
				buttons.push(buildButton({ name: 'remove' }));
			}			
		}
		return buttons;
	},
	_getItems(side){
		const defOpts = this.getOption('customViewOptions');
		const modelOptions = this.getOption('passDownModel') || this.getOption('passDownModels') ? { model: this.model } : undefined;
		const collectionOptions = this.getOption('passDownCollection') || this.getOption('passDownModels') ? { collection: this.collection } : undefined;
		const itemOptions = this.getOption('itemOptions', { force: true });
		const sideItemOptions = this.getOption(side + 'ItemOptions', { force: true });
		const options = Object.assign({}, modelOptions, collectionOptions, defOpts, itemOptions, sideItemOptions);

		let raw = this.getOption(camelCase(side, 'Items'), { args: [this.model, this]}) || [];
		return _.map(raw, item => this._buildItem(item, side, options));
	},
	_buildButton(raw, side){
		let View = this.getOption('ButtonView') || Button;
		if(!raw) return;
		if (_.isString(raw)) {
			raw = { name: raw };
		} else if (isViewClass(raw)) {
			View = raw;
			raw = {};
		}
		else if (_.isFunction(raw)) {
			return this._buildButton(raw.call(this, this.model, this), side);
		} else if(!_.isObject(raw)) {
			return;
		}
		let options = _.extend({ 
			className: raw.name,
			noevent: true,
			attributes:{
				'data-name': raw.name
			}
		},raw);
		this.fixButton(options, side);
		let button = new View(options);
		this.setupButton(button);
		return button;
	},
	_buildItem(raw, side, _itemOptions){
		if (raw == null) return;
		let item;

		if (_.isString(raw)) {
			item = {
				text: raw,
				..._itemOptions,
			};
		} else if (isViewClass(raw)) {
			item = new raw(_itemOptions);
		}
		else if (_.isFunction(raw)) {
			return this._buildItem(raw.call(this, this.model, this), side, _itemOptions);
		} else if (_.isObject(raw)) {
			item = raw;			
		}

		if(_.isObject(item) && !isView(item)) {
			let View = item.View || AtomTextView;
			let options = Object.assign({}, _itemOptions, _.omit(item, 'View'));			
			item = new View(options);
		}
		
		item.addCssClassModifier(side + '-item');
		this.setupItem(item);

		return item;
	},

	fixButton: _.noop,
	setupButton: _.noop,
	setupItem: _.noop,


	setButtonListeners(){
		this.delegate('click', '>button:not(:disabled):not(.disabled)', this.handleButtonClick.bind(this));
	},
	handleButtonClick(event){
		console.log(' >> blyam');
		let $btn = $(event.target).closest('button');
		let role = $btn.data('name');
		if(!role) return;

		event.stopPropagation();
		let eventName = role + ':click';
		this.triggerMethod(eventName, event, this);
		
	},
	onSelectClick(event){
		this.triggerMethod('toggle:select', this, event);
	},
	onOptionsClick(){
		this.showActionsPopover(this.model);	
	},
	showActionsPopover(model, view){
		let inst = actionsPopover(model, view || this);
		if (inst) {
			this.triggerMethod('actions:show', inst);
		}		
	},
	isSelectable(){
		return this.getOption('selectable', { args: [this.model, this] }) === true;
	},
	// isSelected(){
	// 	return this.triggerMethod('check:select');
	// }
});
