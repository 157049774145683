import { PagedApp } from 'bbmn-routing';
import layout from './layout';
import { interactive, ready } from '../_env/dom-ready-promise';
import { Notifier } from 'components/notifier';
import config from './config';
import user from './user';
import data from './data';
import { notAllowed, notFound, apiErrorZero } from 'pages/errors';
import { historyApi } from 'bbmn-routing';
import sockets from 'components/sockets';
//import Radio from 'backbone.radio';
import busApp from 'bus/app';

export default PagedApp.extend({
	historyWatcher: true,
	startHistory: { pushState: config.pushState },
	layout,
	appEl: '#app-placeholder', // 'body > section:first-child',
	initialize(){
		//this.on('all', c => console.log('%capp:','color:teal',c));
		let userReady = user.getReady();
		let userAuth = user.authPromise;
		userAuth.then(() => {
			busApp.trigger('user:auth');
			return sockets.start() ;
		});

		this.addStartPromise(userReady);
		this.addStartPromise(interactive);
		
		this.addStartPromise(data.fetch());

		this.doOnResolve(interactive, this.onDomInteractive);
		this.doOnResolve(userReady, this.setUserListebers);
		
	},
	setUserListebers(){
		//this.listenTo(user, 'changed', this.restartPage);
	},
	onDomInteractive(){
		this.initializeNotifier();
		//console.log('rendering...');
		this.renderLayout();
		//console.log('redering done');
	},
	onRouterCreate(router){
		// router.on('all', (c, ...args) => {
		// 	if(c.startsWith('before:') || c.startsWith('after:')) return;
		// 	console.log('%crouter:','color:tomato',c, ...args);
		// });
	},
	showPage(page, process, ac){

		this.ac = ac;
		this.layoutView.showPage(page, ac);
	},
	restartPage(user, { preventRestart } = {}){		

		if (!this.ac || preventRestart) return;
		this.ac.restart();
	},
	initializeNotifier(){
		this.notifier = new Notifier({
			el: document.getElementById('notifier')
		});
		this.notifier.render();
	},
	routeErrors:{
		//accepts: xhr, ac
		'jq:xhr'(xhr){
			if (xhr.status === 404) {
				this.showPage(notFound);	
			} else {
				this.showPage(apiErrorZero);
			}
		},
		'not:found'(){
			this.showPage(notFound);
		},
		'must:login'(original){
			historyApi.execute('acc/login', { original });
		},
		'not:allowed'(){
			this.showPage(notAllowed);
		},
		'execute'(fragment, original){
			historyApi.execute(fragment, { original });
		}
	}
});

export {
	interactive as domInteractive,
	ready as domReady
};
