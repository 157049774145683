import _ from 'underscore';
import { CollectionView } from 'pages/views';
import { RealtyItemCard } from 'mod/realties/realties/views';
import { mix, comparator } from 'bbmn-utils';
import { initSelectorMixin } from 'bbmn-components';
import { collectionViewFiltererMixin } from 'components/filterer';
import { viewWithPaginatorMixin } from 'components/paginator';

import { View } from 'base';

const Founded = View.extend({
	renderOnModelChange: true,
	className: 'entries-founded',
	tagName: 'header',
	template: _.template('нашлось: <%= _m.get("founded") %>'),
});


export default mix(CollectionView).with(initSelectorMixin).extend({
	renderAllCustoms: true,
	isEmpty(){
		return !this.collection.length;
	},
	className: 'realties-cards',
	childView: RealtyItemCard,
	childViewOptions(){
		
		let listModel = this.model;
		let collection = this.getCollection();
		let options = {
			mainAction: 'open:card',
			modelViewOptions:{
				mainAction: 'open:card',
				removeButton: true,
				onRemoveClick(){
					listModel.removeItems([this.model.id], () => {
						collection.remove(this.model);
						collection.manager.changeFounded(-1);
					});
				},
			}
		};
		return options;	
	},
	customs:[ v => new Founded({ model: v.collection.manager })],
});

/*
export default mix(CollectionView).with(collectionViewFiltererMixin, viewWithPaginatorMixin, initSelectorMixin).extend({
	isStaticCollection: true,
	renderAllCustoms: true,	
	pageSize: 50,
	className: 'realties-cards',
	childView: RealtyItemCard,
	childViewOptions:{
		modelViewOptions:{
			mainAction: 'open:card',
			removeButton: true,
			onRemoveClick(){
				let model = this.model;
				model.save({ ids: [this.model.id] }, {
					method:'delete',
					url: model.collection.url,
				}).then(() => {
					return model.collection.remove(model);
				});
			},
		}
	},
	viewComparator:(v1,v2) => comparator(v1,v2, m => m.get('name')),
});

*/
