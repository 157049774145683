import _ from 'underscore';
export const collectionFiltererMixin = Base => Base.extend({
	setFilterer(filterer){
		if (this.filterer) {
			this.stopListening(this.filterer);
		}

		this.filterer = filterer;

		if (!this.handleFiltererChange) {
			this.handleFiltererChange = _.debounce(this._handleFiltererChange, 300);
		}

		this._setupFiltererListeners();

		this.forQueryString = !filterer || filterer.isQueryString();

	},
	_setupFiltererListeners(){
		if(!this.filterer) return;

		this.listenTo(this.filterer, 'change', this.handleFiltererChange);
		if (this.manager) {
			this.listenTo(this.manager, 'fetched', () => {
				this.filterer.afterFilter();
			});
		}
	},
	_handleFiltererChange(){
		if (!this.filterer.isBackend()) {
			this.trigger('filterer:change', this);
		} else {
			let obj = this.filterer.getData();
			if (this.manager) {

				this.manager.set({
					skip:0,
					query: obj
				});
			}
		}
	}
});
