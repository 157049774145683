import _ from 'underscore';
import envCfg from 'app/config';

let get = function(type, name){
	return paths[type].entries[name];
};

let getPrefix = function(type, options = {}){
	if(options.relative)
		return '';

	let hash = paths[type];
	let defPrefix = [];
	let addServer = options.server !== false;
	let addVersion = options.version !== false;
	let addPrefix = options.prefix !== false;
	let prefix = typeof options.prefix === 'string' ? options.prefix : hash.prefix;

	if(addServer && hash.server && hash.protocol)
		defPrefix.push(hash.protocol);

	if(addServer) defPrefix.push(hash.server);
	if(addVersion) defPrefix.push(hash.version);
	if(addPrefix) defPrefix.push(prefix);
	let result = _(defPrefix).filter((p) => !!p).join('/');
	return result;
};

function getRelativePrefix(type, name){
	let chunks = (name || '').split(':');
	chunks.pop();
	let prefix = get(type, chunks.join(':'));
	return prefix;
}


let api = function(name, ...add){
	let res = get('api', name);
	if (res && add && add.length) {
		
		res += '/' + add.join('/');
	}
	return res;
};
let url = function(name){
	let res = get('url', name);
	return res;
};
let route = function(name){
	let res = get('route', name);
	return res;
};

let link = function(url){
	let prefix = !envCfg.pushState ? '#' : '';
	return prefix + url;
};

//const paths = Yat.createConfig('paths');

const paths = {
	api: {
		protocol: envCfg.apiProtocol,
		server: envCfg.apiServer,
		version: envCfg.apiVersion,
		prefix: envCfg.apiPrefix,
		entries:{},
	},
	url: {
		entries:{},
	},
	route: {
		entries:{},
	},
};

let set = function(type, name, url, options = {}){
	let hash = paths[type];
	if(hash == null)
		throw new Error('path chunk not found', type);
	if(name == null)
		throw new Error('path name is empty', name, url);

	url = url || '';
	let prefix = options.relative ? getRelativePrefix(type, name) : getPrefix(type, options);
	if(prefix.length){
		if(!prefix.endsWith('/'))
			prefix = prefix + '/';
		url = prefix + url;
	}	
	
	hash.entries[name] = url;

	

	return this;
};

//console.warn('urls', paths.url.entries);

function apiUrl(path, add) {

	if (add == 'ent' || add == 'pub') {
		add = add;
	}	else {
		add = 'ent';
	}

	//debugger;
	const c = paths.api;
	const base = `${c.protocol}/${c.server}`;
	path = path.startsWith('/') ? path : `/${c.version}/${add}/` + path;
	const url = base + path;
	return url;	
}

export default {
	link,
	api,
	url,
	route,
	set,
	paths,
	apiUrl
};
