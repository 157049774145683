import _ from 'underscore';
import { CollectionView, View } from 'base';
import { getCollection } from './helpers';

export const ActionView = View.extend({
	cssClassModifiers:['instance-action'],
	initialize(options){
		this.mergeOptions(options, 'instance');
	},
	onRender(){
		this._setAttributes({
			'data-action': this.model.get('name')
		});
	},	
	template: _.template('<i></i><span><%= label %></span>'),
	events:{
		'click'(event){
			let args = this.getOption('actionArguments') || [];
			this.model.exec(event, ...args);
			this.trigger('action:clicked', this);
		}
	}
});

export const ActionsView = CollectionView.extend({
	cssClassModifiers:['instance-actions'],
	childView: ActionView,
	childViewOptions(){
		return {
			instance: this.model,
			actionArguments: this.getOption('actionArguments'),			
		};
	},
	childViewEvents:{
		'action:clicked'(){
			if(this.getOption('placement') == 'popup')
				this.destroy();
		}
	}
});


export function buildActionsListView(instance, getActionsOptions = {}, viewOptions) {
	let collection = getCollection(instance, getActionsOptions);
	if (!collection) return;
	let options = _.extend({ collection }, viewOptions);
	return new ActionsView(options);
}
