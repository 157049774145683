import _ from 'underscore';
import { CollectionView } from 'base';
import { mix, buildViewByKey } from 'bbmn-utils';
import { commonMixin } from './helpers';
import PropertyView, { EditPropertyExtended as EditProperty} from './property-view';
import { ButtonView } from 'components/views';

const BaseDisplaySchema = mix(CollectionView).with(commonMixin);

const DisplaySchema = BaseDisplaySchema.extend({
	constructor: function(){
		BaseDisplaySchema.apply(this, arguments);
		if (!this.model) {
			this.value = _.extend({}, this.getOption('value'));
		}
		this.addCssClassModifier('display-schema');
	},
	renderAllCustoms: true,
	getCustoms(){
		const properties = this.getPropertiesView() || [];
		const buttons = this.getFooterButtons() || [];
		const views = [
			...properties,
			...buttons
		]
		return views;
	},

	propertiesToShow: undefined,
	getPropertiesConfigs(){
		if (this._propertiesConfigs) {
			return this._propertiesConfigs;
		}
		let names = this.getOption('propertiesToShow');
		if (!names || !names.length) return;
		let properties = _.reduce(names, (memo, item) => {
			if(_.isString(item)) {
				item = { name: item };
			}
			
			memo[item.name] = item;
			return memo;
		}, {});
		this._propertiesConfigs = properties;
		return properties;
	},
	getPropertiesNames(){
		let all = this.getOption('showAllProperties');
		let names;
		if(!all) {
			let configs = this.getPropertiesConfigs();
			names = configs && _.map(configs, v => v.name);
		}

		let schema = this.getSchema();
		if(!names && schema) {
			names = schema.getPropertiesNames();
		} else if (!names) {
			names = _.keys(this.model.attributes);
		}
		return names;
	},
	getPropertiesView(){
		let names = this.getPropertiesNames();
		return this._buildPropertiesViews(names);
	},

	_buildPropertiesViews(names){
		return _.map(names, (name, key) => this._buildPropertyView(name, key));
	},
	_buildPropertyView(property) {

		let configs = this.getPropertiesConfigs();

		let propertyOptions = configs && configs[property];
		let schema = this.getPropertySchema(property);

		let options = this.getPropertyViewOptions({
			schema,
			property,
			model: this.model,
			allValues: this.value,
			propertyOptions,
			propertyOnResolve: this.getOption('propertyOnResolve', { force: false }),
			saveOnResolveEditProperty: !this.getOption('createMode'),
			mainAction: this.getOption('mainPropertyAction')
		});

		let view = buildViewByKey(this, 'propertyView', { options });
		if(view){
			this.listenTo(view,'property:change', () => this.render());
		}
		return view;
	},

	getPropertyViewOptions(opts){
		let options = _.extend({
			editEnabled: !this.getOption('readOnly')
		}, opts);
		return options;
	},
	getFooterButtons() {
		if (!this.getOption('createMode')) { return [];}
		let view = new ButtonView({ text: 'создать', name: 'resolve' });
		this.listenTo(view, 'click', () => this.triggerMethod('create:click', this))
		return [
			view
		];
	},
	propertyView: PropertyView
});


export {
	PropertyView as DisplayPropertySchemaView,
	DisplaySchema as DisplayModelSchemaView,
	EditProperty
};


export default DisplaySchema;
