import { CollectionView, View } from 'base';
import { RealtyObjectsList, RealtyObjectsListContainer } from 'mod/realties/objects/views';

export const Content = CollectionView.extend({
	//template: false,
	//template: () => {},
	renderAllCustoms: true,
	customs:[RealtyObjectsListContainer],
	customViewOptions() {
		return {
			model: this.model
		}
	},
	// initialize() {
	// 	console.warn("INITI")
	// }
});