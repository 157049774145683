import { defineControl, SelectControl } from 'bbmn-controls';
import { isCollectionClass } from 'bbmn-utils';

const ApiSelect = SelectControl.extend({
	className: 'api-select-control',
	constructor: function(){
		SelectControl.apply(this, arguments);
		this.selector.source.fetch();
	},
	childViewOptions:{
		mainAction:'trigger:select:click'
	},
	getSource(){
		let src = this.valueOptions.sourceValues;
		if(isCollectionClass(src)){
			return new src();
		}
		return SelectControl.prototype.getSource.apply(this, arguments);
	}
});

defineControl('select:api', ApiSelect);




export default ApiSelect;
