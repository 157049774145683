import Base from './base';
import { user } from 'app/context';

export default Base.extend({
	canNotStart(){
		if(user.isLogged()) {
			return;
		}
		else {
			return 'must:login';
		}
	},

	visibility: 'auth',
});
