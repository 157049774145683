import _ from 'underscore';
import { CollectionView, View } from 'base';
import adItemTemplate from './ad-item.html';
import { viewWithPaginatorMixin } from 'components/paginator';
import { modals, PropertySchema, TextView } from 'bbmn-components';
import { EditProperty } from 'bbmn-controls';
import { mix, enums } from 'bbmn-utils';
import { collectionViewFiltererMixin } from 'components/filterer';
import { Offer } from 'mod/realties/offers/models';
import { busViews } from 'bus';
import { DisplayModelSchemaView } from 'components/display-schema';
import { ModelCoreView, ListItemView, AtomTextView } from 'components/views';
import { switcherInverse } from 'controls/boolean-switch';
import { Collection } from 'bbmn-core';

import { actionsPopover } from 'components/actions';

import { AdImageModel } from '../models/ads';

import editor from 'components/editor';

export const FeedResultView = View.extend({});

const PhotoView = View.extend({
	initialize() {
		this.listenTo(this.model, 'change', this.render);
	},
	className: 'photo-item',
	cssClassModifiers: [m => m.get('isDisabled') ? 'disabled' : ''],
	template: _.template(`
		<img src="http://media.nrg.plus/imgs/<%= hash %>/w88-h88-c-rbc/jpg"/>
		<span class="badges origin"><%= _v.getOrigin() %></span>
		<span class="badges order"><%= ownOrder %></span>
		<span class="badges disabled"><%= _v.getDisabled() %></span>
	`),
	getDisabled() {
		return this.model.get('isDisabled') ? 'отлючена' : '';
	},
	getOrigin() {
		let type = this.model.get('entityType');
		if (type == 'realty') {
			return 'здание';
		} else if (type == 'offer') {
			return 'площадь';
		} else {
			return '-';
		}
	},
	events: {
		'mouseover img'(e) {
			console.log('overed');
			let img = $(`<img src="http://media.nrg.plus/imgs/${this.model.get('hash')}/w200-h200-c-rbc/jpg"/>`);
			img.css({
				left: 60 + e.pageX + 'px',
				top: 60 + e.pageY + 'px',
				position: 'fixed',
			});
			let moving = ee => {
				img.css({
					left: 60 + ee.pageX + 'px',
					top: 60 + ee.pageY + 'px',
					'box-shadow': '0 0 10px 3px rgba(0,0,0,.2)'
				});
			};
			this.$el.on('mousemove', moving);
			img.appendTo(document.body);
			img.customDestroy = () => {
				this.$el.off('mousemove');
				img.remove();
				delete this.enlargedImg;
			};
			if (this.enlargedImg) {
				this.enlargedImg.customDestroy();
			}
			this.enlargedImg = img;
		},
		'mouseout img'(e) {
			if (this.enlargedImg) {
				this.enlargedImg.customDestroy();
			}
		},
		'click img'(e) {
			let model = this.model;
			let view = this;
			editor.edit({
				schema: model.getSchema(),
				value: model.toJSON(),
				propertiesToShow: null,
				onResolve(values) {
					let vals = _.pick(values, 'isDisabled', 'ownOrder');
					model.set(vals);
					view.updateImages();
				}
			}, 'Изменение порядка выгрузки картинки');
		}
	},
	updateImages() {
		let advert = this.getOption('advert');
		let feed = advert.getFeed();
		let custom = feed.getCustom(advert.id);
		custom.images = this.model.collection.toJSON();
		feed.setCustom(advert.id, custom);
	},
	onBeforeDestroy() {
		if (this.enlargedImg) {
			this.enlargedImg.customDestroy();
		}
	}
});
const PhotosView = CollectionView.extend({
	className: 'photos-items',
	initialize() {
		this.collection = new Collection(this.model.get('images'), { model: AdImageModel });
	},
	childView: PhotoView,
	childViewOptions() {
		return {
			advert: this.model,
		};
	},
	viewComparator(v1,v2) {
		return v2.model.get('ownOrder') - v1.model.get('ownOrder');
	},
	collectionEvents: {
		'change:ownOrder':'sort'
	}
});


/**
			Func<Match, string, string> replacer = (match, _eol) =>
			{
				string v = match.ToString();
				v = v.Replace("{eol}", "");
				v = Regex.Replace(v, "[}{\r\n]", "");

				var key = compiledData.Keys.FirstOrDefault(_key => _key.ToLower() == v.ToLower());
				if (string.IsNullOrWhiteSpace(key)) return "";
				var r = compiledData[key];
				if (string.IsNullOrWhiteSpace(r)) return "";
				return r + _eol;
			};

			template = template.Replace("\r", "");
			template = template.Replace("\n", "{eol}\n");

			//var terminPat = new Regex(@"^\s*\{[^}]+\}\s*$");
			var terminPat = new Regex(@"^\s*\{[^}]+\}\{eol\}\n", RegexOptions.Multiline | RegexOptions.IgnoreCase);

			var builded = terminPat.Replace(template, delegate (Match match) {
				return replacer(match, "\r\n");
			});

			if (!eol.EndsWith("\r\n"))
			{
				eol += "\r\n";
			}
			builded = builded.Replace("{eol}\n", eol);

			builded = Regex.Replace(builded, @"\{[^}]+\}", delegate (Match match)
			{
				return replacer(match, "");
			}, RegexOptions.Multiline | RegexOptions.IgnoreCase);

			return builded;
*/


const ReploPattern = /^\{([^}]+)\}\r*\n/gmi;
function bldDesc(text, data) {
	data = data || {};
	let ndata = {};
	for(let k in data) {
		let kn = k.toLowerCase();
		ndata[kn] = data[k];
	}

	text = text.replace(/\r/g, "");
	text = text.replace(/\n/g, "{eol}\n");
	let terminPat = /^\s*\{([^}]+)\}\{eol\}\n/gmi;

	let replacer = (key, eol) => {
		let nkey = key.toLowerCase();
		let value = ndata[nkey];
		if (value == null || value === '') return '';
		return value + eol;
	}

	let builded = text.replace(terminPat, (match, g1) => {
		return replacer(g1, "\r\n");
	});
	builded = builded.replace(/\{eol\}\n/g, "\r\n");

	builded = builded.replace(/\{([^}]+)\}/gmi, (match, g1) => {
		return replacer(g1, "");
	});

	// let res = text.replace(/^\{([^}]+)\}\r*\n/gmi, (matched, key) => {
	// 	let nkey = key.toLowerCase();
	// 	if (nkey in ndata) {
	// 		return ndata[nkey] + '\r\n';
	// 	}
	// 	return '';
	// 	//data[key] ? data[key] + '\r\n' : ''
	// });
	return builded;
}

const FullInfoView = View.extend({
	cssClassModifiers:[ m => m.get('desc.isOwnTemplate') ? 'own-text' : ''],
	regions: {
		photos: '.photos'
	},
	template: _.template(`
<div class="photos"></div>
<div class="desc"><%= text %></div>
`),
	onRender() {
		let view = new PhotosView({ model: this.model });
		this.showChildView('photos', view, { replaceElement: true });
	},
	buildDescription(){
		let desc = this.model.get('desc');
		if (!desc.template) return '';

		return bldDesc(desc.template, desc.data);
		// let data = desc.data || {};
		// let text = desc.template.replace(/^\{([^}]+)\}\r*\n/gmi, (matched, key) => data[key] ? data[key] + '\r\n' : '');
		// text = text.replace(/\{([^}]+)\}/gmi, (matched, key) => data[key] || '');
		//return text;
	},
	getPhotos(){
		let arr = this.model.getPhotos();
		console.log('ARRR', arr);
		let photos = _.map(arr, photo => '<img src="http://media.nrg.plus/imgs/' + photo + '/w88-h88-c-rbc/jpg"/>').join('');
		return photos || 'без фото';
	},	
	templateContext(){
		return {
			text: this.buildDescription(),
			//photos: this.getPhotos(),
		};
	}
});

export const AdItem = View.extend({
	renderOnModelChange: true,
	className:'ad-item',
	regions:{
		fullinfo: '.full-info',
	},
	cssClassModifiers:[
		m => m.isTaked() ? 'taked' : 'skiped',
		m => m.isPromoted() ? 'promoted' : '',
		(m,v) => v.isEdited() ? 'edited' : '',
		m => m.getAuctionBet() ? 'auctioned' :''
	],
	template: adItemTemplate,
	templateContext() {
		let disp_priceMeter = '';
		let disp_priceSquare = '';
		const prices = this.model.get('prices') || [];
		if (prices.length === 1) {
			const o = prices[0];
			const smol = t => `<small>${t}</small>`;
			disp_priceMeter = o.pricePerMeter.toLocaleString('ru') + smol(' р. за м<sup>2</sup>');
			let suf = o.isRent ? ' р. в месяц' : ' р.';
			disp_priceSquare = o.pricePerSquare.toLocaleString('ru') + smol(suf);
		}
		

		// console.log(':', this.model.toJSON());
		return {
			hideAdditional: this.getOption('hideAdditional'),
			hideActions: this.getOption('hideActions'),
			categoryName: this.getCategoryName(),
			disp_priceMeter,
			disp_priceSquare
		};
	},
	getCategoryName(){
		return this.getOption('feed').getFeedConfigCategories({ enum: true })[this.model.get('categoryId')];
	},
	isEdited(){
		return this.model.isEdited(this.getOption('feed'));
	},
	isIncluded(){
		return this.model.isIncluded();
	},
	isExcluded(){
		return this.model.isExcluded(this.getOption('feed'));
	},
	getPhotos(){
		let arr = this.model.getPhotos();
		let photos = _.map(arr, photo => '<img src="http://media.nrg.plus/imgs/' + photo.chunk + '/w88-h88-c-rbc/jpg"/>').join('');
		return photos || 'без фото';
	},
	getPromos(){
		//let promos = this.model.getPromos(this.getOption('feed'));
		let promos = this.model.getPromotions({ all: true });
		let html = this.buildPromos(promos);
		return html;
	},

	getIndicators() {
		var res = [];
		if(this.model.get('desc.isOwnTemplate')) {
			res.push(this._buildBadge({ classes: 'indicator owntext', label: 'своё описание', id: '', type: '', action: '', title: 'у объявления установлено собственное описание'}));
		}
		if(!this.model.getPhotos().length) {
			res.push(this._buildBadge({
				classes: 'indicator nophotos', label: 'без фотографий', id: '', type: '', action: '', title: 'у объявления отсутствуют фотографии'	
			}));
		}
		let error = this.model.get('error');
		if(error) {
			res.push(this._buildBadge({
				classes: 'indicator error',
				label: error,
			}));
		}
		return res.join('');
	},
	getAuctionBet(){
		let bet = this.model.getAuctionBet();
		return bet ? ': ' + bet : '';
	},
	areaHtml(){
		if (this.model.get('category').startsWith('commercialLand')) {
			return '<span>' + this.model.get('land.area') + ' сот.</span>';
		} else {
			let min = this.model.getMinArea();
			let area = this.model.get('totalArea');
			if (min)
				return `<span>${min}</span><span>${area}</span>`;
			else
				return `<span>${area}</span>`;
		}

	},
	prevpromoTemplate:_.template(`
	<span class="promo-button button-group promo-<%= state %> promo-<%=type%>" data-promo=<%= id %> data-aplied="<%= state %>" data-type=<%= type %>>
		<span><%= (add + name) %></span>
		<% if (!manual) {%>
		<button class="apply-promo-button pin"><i></i></button>
		<% } %>
		<% if (state) {%>
		<button class="remove-promo-button remove"><i></i></button>
		<% } %>
		<% if (!state && manual) {%>
			<button class="remove-disallow-promo-button remove"><i></i></button>
		<% } %>		
	</span>
	`),
	badgeTemplate: _.template(`
		<span class="badge <%= classes %>" title="<%= title %>"
			data-id="<%= id %>" 
			data-type="<%= type %>" 
			<% if(action) { %> data-action="<%= action %>" <% } %>
			>
			<%= label %>
		</span>
	`),
	_buildBadge(data, merge){
		let defs = { classes: '', label: '', id: '', type: '', action: '', title: ''};
		return this.badgeTemplate(_.extend(defs, data, merge));
	},
	buildPromos(promos){
		let feed = this.model.getFeed();
		let cfgPromos = feed.getFeedConfigPromotions({ enum: true });
		let promosLabels = {
			autoPromotion: 'авто',
			preventPromotions: 'запрещено',
			forcedPromotion: 'ручное'
		};
		return _.map(promos, promo => this._buildBadge(promo, { 
			type: 'promo',
			label: cfgPromos[promo.id] + ': ' + promosLabels[promo.state],
			classes: 'promo ' + promo.state,
			action: 'promote',
		}));

		/*return _.map(promos, promo => {
			if (!promo.state && promo.type=='auto') {
				return '';
			} else {
				return this.promoTemplate(promo);
			}
		}).join('');*/
	},
	getForced() {
		if (this.isIncluded()) {
			return this._buildBadge({
				type:'forced',
				classes:'forced included',
				label: 'принудительно включено',
				title: 'кликните, чтобы отменить принудительное включение',
				action: 'cancel:include',
			});
		} else if (this.isExcluded()) {
			return this._buildBadge({
				type:'forced',
				classes:'forced excluded',
				label: 'запрет публикации',				
				title: 'кликните, чтобы отменить запрет публикации',
				action: 'cancel:exclude',
			});
		}
		return '';
	},
	toggleFullInfo(){
		if (!this.isRendered()) return;
		let region = this.getRegion('fullinfo');
		if (region.currentView) {
			region.empty();
		} else {
			let view = new FullInfoView({ model: this.model });
			region.show(view);
		}
	},
	events:{
		'click'(){
			console.log(this.model.toJSON());
		},
		'click .folder'(e){
			this.toggleFullInfo();
		},
		'click [data-action]:not([data-action=""])'(e){
			e.stopImmediatePropagation();
			let $el = $(e.target).closest('[data-action]');
			let action = $el.data('action');
			this.model.executeAction(action);
		},
		'click [data-role="show-actions"]'(e) {
			e.stopPropagation();
			e.stopImmediatePropagation();
			actionsPopover(this.model, this);
		},
		'click .offers-count'(){
			/*let offers = this.model.get('offersData') || [];
			let collection = new Collection(offers, { model: Offer });
			modals.show({
				header: 'предложения объявления',
				content: AdOffers,
				contentOptions:{
					collection,
					feed: this.getOption('feed'),
					ad: this.model
				}
			});*/
			//console.log('offersData', this.get('offersData'));
		},

		//ready
		'click .apply-promo-button'(e){
			let $btn = $(e.target).closest('.promo-button');
			let promoId = $btn.data('promo');
			let type = $btn.data('type');

			this.model.executeAction('apply:promo', e, {
				view: this,
				feed: this.getOption('feed'),
				promoId, type,
			});

		},

		//ready
		'click .remove-promo-button'(e){
			let $btn = $(e.target).closest('.promo-button');
			let promoId = $btn.data('promo');
			let type = $btn.data('type');

			this.model.executeAction('remove:promotion', e, {
				view: this,
				feed: this.getOption('feed'),
				promoId, type,
			});

		},

		//ready
		'click .remove-disallow-promo-button'(e){
			let $btn = $(e.target).closest('.promo-button');
			let promoId = $btn.data('promo');
			let type = $btn.data('type');

			this.model.executeAction('remove:disallow:promo', e, {
				view: this,
				feed: this.getOption('feed'),
				promoId, type,
			});
		},

		//ready
		'click .actions .promote'(e){

			this.model.executeAction('promote', e, {
				view: this,
				feed: this.getOption('feed')
			});

		},

		//ready
		'click .actions .edit'(e){

			this.model.executeAction('edit', e, {
				view: this,
				feed: this.getOption('feed')
			});

		},


		//ready
		'click .actions .exclude'(e){
			this.model.executeAction('exclude', e, {
				view: this,
				feed: this.getOption('feed')
			});
		},

		//ready
		'click .actions .cancel-exclude'(e){
			this.model.executeAction('cancel:exclude', e, {
				view: this,
				feed: this.getOption('feed')
			});
		},

		// ready
		'click .actions .include'(e){
			this.model.executeAction('include', e, {
				view: this,
				feed: this.getOption('feed')
			});
		},

		//ready
		'click .actions .cancel-include'(e){
			this.model.executeAction('cancel:include', e, {
				view: this,
				feed: this.getOption('feed')
			});
		},

		//ready
		'click .actions .edit-auction'(e){
			this.model.executeAction('edit:auction', e, {
				view: this,
				feed: this.getOption('feed')
			});
		}
	},
	updateDescription(text){		
		var data = this.model.get('templateData');
		!text && (text = this.model.get('descriptionTemplate'));
		text || (text = '');
		let res = bldDesc(text, data);
		this.model.set('description', res);
		// // text = text.replace(/\r/, "");
		// // text = text.replace(/\n/, "{eol}\n");

		// var terminPat = /^\s*\{[^}]+\}\{eol\}\n/gmi;


		// let template = text.replace(/({([^}]+)})/g, (all, g1, g2) => {
		// 	console.log('// --', g1, g2);
		// 	return `<%= d['${g2}'] %>`;
		// });

		// let compiled = _.template(template, {variable:'d'});
		// let builded = compiled(data);
		// this.model.set('description', builded);
	}
});

const BaseAdsList = mix(CollectionView).with(collectionViewFiltererMixin, viewWithPaginatorMixin);
export const AdsList = BaseAdsList.extend({
	constructor: function(options){
		BaseAdsList.apply(this, arguments);
		this.mergeOptions(options, 'feed');
	},
	pageSize: 50,
	className:'ads-list',
	childView: AdItem,
	isStaticCollection: true,
	renderAllCustoms: true,	
	childViewOptions(){
		return {
			feed: this.feed
		};
	},

});

export const FeedStatsView = CollectionView.extend({
	className:'feed-stats-view',
	renderOnModelChange: true,
	renderAllCustoms: true,
	initialize(options){
		this.model = options.feed.createFeedStatsModel();
		this.model.fetch().then(() => {
			this._counted = true;
			this.triggerMethod('data:ready');
		});
	},
	getFeedModel()
	{
		let feed = this.model.feed || this.options.feed;
		if (!feed) {
			console.warn('no feed instance');
			return;
		}
		return this.model.feed;
	},
	getCategoryName(name){
		let feed = this.getFeedModel();
		let cfg = feed && feed.get('config') || {};
		let dict = cfg.categories || {};
		return dict[name] || name;
	},
	getPromotionName(name){
		let feed = this.getFeedModel();
		let cfg = feed && feed.get('config') || {};
		let dict = cfg.promotions || {};
		return dict[name] || name;
	},
	onDataReady(){
		this.render();
	},
	getCustoms(){
		if (!this._counted) {
			return [
				new View({ className: 'stats-preloader', template: _.template('<div><span class="wait-spiner"></span></div>') })
			];
		} else {
			let categories = [];
			let taked = this.model.get('taked');
			
			let total = taked.total;
			
			let cats = _.omit(taked, 'total');

			categories.push(new TextView({
				tagName: 'header',
				text: `<span>Всего объявлений в выгрузке: </span><big>${total}</big>`,
				shouldEscapeValue: false,
			}));
	
			_.reduce(cats, (memo, value, key) => {
				if(value > 0)
					memo.push(new TextView({
						className: 'line',
						text: `<big>${value}</big><small>${this.getCategoryName(key)}</small>`,
						shouldEscapeValue: false,
					}));
				return memo;
			}, categories);

			let promos = this.model.get('promoted');
			let totalPromos = 0;
			let promoViews = [];
			_.each(promos, (catPromos, category) => {
				let catName = this.getCategoryName(category);

				_.each(catPromos, (count, promoId) => {
					let promoName = this.getPromotionName(promoId);
					if (!count) return;
					totalPromos += count;
					promoViews.push(new TextView({
						className: 'line',
						text: `<big>${count}</big><small>${promoName} ${catName}</small>`,
						shouldEscapeValue: false,
					}));
				});
			});

			promoViews.unshift(new TextView({
				tagName: 'header',
				text: `<span>Всего продвижений: </span> <big>${totalPromos}</big> `,
				shouldEscapeValue: false,
			}));
			categories.push(...promoViews);
			//console.log('~~', promos);

			// _.reduce(promos, (memo, value, key) => {
			// 	if(value > 0)
			// 		memo.push(new TextView({
			// 			className: 'line',
			// 			text: `<big>${value}</big><small>${this.getCategoryName(key)}</small>`,
			// 			shouldEscapeValue: false,
			// 		}));
			// 	return memo;
			// }, categories);


			return categories;
		}

		// let keys = _.keys(_.omit(this.model.attributes, 'feedId'));
		// var views = _.map(keys, key => {


		// 	let value = this.model.get(key, { byPath: false });
		// 	if (value == null || value <= 0) {
		// 		return;
		// 	}
		// 	return new TextView({
		// 		className: 'line',
		// 		text: `<big>${value}</big><small>${key}</small>`,
		// 		shouldEscapeValue: false,
		// 	});
		// });
		// return [];
	}
});
busViews.class('FeedStatsView', FeedStatsView);
