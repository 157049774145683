import _ from 'underscore';
import { BaseClass, MnObject } from './vendors.js';
import { mix } from 'bbmn-utils';
import { Events } from 'bbmn-core';
import { getOption, triggerMethod, mergeOptions  } from 'bbmn-utils';

const BaseThing = mix(BaseClass).with(Events, {
	getOption(){
		return getOption(this, ...arguments);
	},
	triggerMethod,
	mergeOptions
});

const Thing = BaseThing.extend({
	constructor: function(options){
		this.options = _.extend({}, options);
		BaseThing.apply(this, arguments);
	},
});

const BaseObject = MnObject.extend({});

export {
	Thing,
	MnObject,
	BaseObject
};
