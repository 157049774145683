import { CollectionView } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';


import { busViews } from 'bus';

const BaseConfigItemView = ModelCoreView.extend({
	text: m => m.get('name') || m.get('name'),
});

export const ConfigItemView = ListItemView.extend({
	className:'config-item',
	optionsButton: true,
	modelView: BaseConfigItemView,
});

busViews.class('ConfigItemView', ConfigItemView);

export const ConfigsView = CollectionView.extend({
	childView: ConfigItemView
});
