import _ from 'underscore';
//import { View as MnView, CollectionView as MnCollectionView } from './vendors';
import { View as BbmnView, CollectionView as BbmnCollectionView } from 'bbmn-components';

import { destroyViewMixin, nextCollectionViewMixin, emptyViewMixin } from 'bbmn-mixins';
import { mix } from 'bbmn-utils';
import url from 'helpers/url';
import plural from 'helpers/plural';
const helpers = {
	url,
	plural
};

const templateMixin = {
	serializeData() {
		// If we have a model, we serialize that
		if (this.model) {
			if (this.model.serialize) {
				return this.model.serialize({ display: true });
			}
			return this.serializeModel();
		}

	},
	mixinTemplateContext(serializedData) {		
		const context = {
			_v: this,
			_m: this.model,
			_h: helpers,
			_j: serializedData
		};
		if (serializedData) {
			_.extend(context, serializedData);
		}
		if (this.templateContext) {
			_.extend(context, _.result(this, 'templateContext'));
		}
		return context;
	},
};

const AfterInit = Base => Base.extend({
	constructor: function(){
		Base.apply(this, arguments);
		if (this.getOption('enableInitializeHook')) {
			this.triggerMethod('initialized');
		}
		if(this.getOption('renderAfterInitialize') && !this.isRendered()) {
			this.render();
		}
	}
});




const overrides = {

};

export const View = mix(BbmnView).with(templateMixin, AfterInit, overrides, { template: _.noop });



export const CollectionView = mix(BbmnCollectionView)
	.with(nextCollectionViewMixin, templateMixin, AfterInit, emptyViewMixin, overrides)
	.extend({
		noDataView: View.extend({ emptyType:'noData', template: () => 'пусто...' }),
		waitView: View.extend({ emptyType:'wait', template: () => '<i class="wait-spiner"></i> подождите пожалуйста...' }),
		isEmpty(){
			let noCollection = this.getOption('renderCollection') === false || !this.collection;
			if (noCollection) {
				return false;
			} else {
				let col = this.getCollection();
				return col && !col.length;
			}
		},
	});
