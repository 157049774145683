import { ModelSchemas } from 'bbmn-components';

export const commonMixin = {
	getSchema(){
		if('_schema' in this) {
			return this._schema;
		}
		let schema = this.getOption('schema');
		if (!schema) {
			schema = ModelSchemas.get(this.model);
		}
		this._schema = schema;
		return schema;
	},
	getPropertySchema(key){
		let schema = this.getSchema();
		if(schema) {
			return schema.getProperty(key);
		}
	},	
};
