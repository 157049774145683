import { CollectionView } from 'pages/views';
// import { NewsCardView, BaseNewsItemView, NewsListItemView } from './NewsListItemView';
import { DisplayModelSchemaView } from 'components/display-schema';
import { modals } from 'bbmn-components';

export const EditContent = CollectionView.extend({
	// childView: NewsCardView,
	initialize() {
		console.log('# ura ura', this);
	},
	renderAllCustoms: true,
	renderCollection: false,

	customs: [
		v => new DisplayModelSchemaView({
			// class: DisplayModelSchemaView,
			model: v.model,
			mainPropertyAction:'trigger:edit:click',
			createMode: v.getOption('createMode'),
			propertiesToShow: v.getOption('createMode') ? ['status', 'date', 'realtyId', 'relatedToMarkets', 'header', 'anons', 'text'] : undefined,
			onCreateClick: s => {
				if (s._creating) { return; }
				s._creating = true;
				const afterCreate = () => { s._creating = false; }
				s.model.save().then(() => {
					afterCreate();
					s.model.openCard();
				}, (err) => {
					console.error(err);
					// modals.error(err);
					afterCreate();
				});
			}
		})
	]
});

// content: DisplayModelSchemaView,
// contentOptions:{
// 	value:{},
// 	schema: ModelSchemas.get(Property),
// 	mainPropertyAction:'trigger:edit:click'
// }