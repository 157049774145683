import { getByPath } from 'bbmn-utils';

export const gbp = getByPath;

export const displayValue = function(m, key, postfix) {
	if (postfix === 'м2') {
		postfix = 'м<sup>2</sup>';
	}
	if (postfix) {
		postfix = `<small class="postfix">${postfix}</small>`;		
	}
	let value = gbp(m, key);
	if (value == null) {
		return '&ndash;'
	}
	return `<span class="val">${value}</span>` + postfix;
}

export const displayItem = function(m, key, postfix, cls = '') {
	const val = displayValue(m,key,postfix);
	if (!val) {
		return '';
	}
	return `<span class="${cls}">${val}</span>`;
}