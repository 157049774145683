import pkg from '../package.json';
const VERSION = pkg.version;

import './_env/clean-globals';
import './_env/remove-console';
import './_env/intercept-links';
import './_env/extends';
import 'es6-promise/auto';

import 'components/validator/setup';
import 'base';

import 'controls';

import App from './app';
import RootPage from './pages/root';
import { historyWatcher } from 'bbmn-routing';
import { notify } from 'components/notifier';

import './less-bundle.less';

let note = notify.wait('Подождите, приложение запускается...');
//console.warn('note', note);
const app = new App({
	RootPage,	
	onStart(){
		console.log('app started');
		note.removed();
	}
});
//app.on('all', n => console.log('[app]', n))
const prom = app.start();
//console.warn('app:', app, prom);
//console.warn('watcher:', historyWatcher);

//console.log('lets go');


export default {
	VERSION,
};
