import { View, CollectionView, Collection } from 'base';
import { EditBoxHeader } from './ContactEditBoxHeader';
import { ContactsList } from '../../owner-contacts/views/ContactsList';
import { OwnersList } from '../../owner-contacts/views/OwnersList';

export const OwnerContactsBox = CollectionView.extend({
	className: 'contacts-edit-box',
	renderCollection: false,
	renderAllCustoms: true,
	customs() {
		//console.warn('proverja', this);
		//const contactsCollection = this.getContactsCollection();
		const ownersCollection = this.getOwnersCollection();
		const views = [
			{ class: EditBoxHeader, header: this.getOption('header') },
			//{ class: ContactsList, collection: contactsCollection, ownersInfo: this.getOwnersInfo() },
			{ class: OwnersList, collection: ownersCollection, ownersInfo: this.getOwnersInfo() },
		];
		return views;
	},
	getOwnersInfo() {
		let realty = this.getRealty();
		if (!realty) { return; }
		return realty.get('ownersInfo') || {};
	},
	getOwnersCollection() {

		let realty = this.getRealty();
		if (!realty) { return; }
		let contacts = realty.get('ownerContacts') || [];

		const info = this.getOwnersInfo();
		if (!info) { return; }

		const owners = Object.keys(info).map(key => {
			const owner = Object.assign({ id: key }, info[key]);
			owner.contacts = contacts.filter(f => f.ownerId == key);
			return owner;
		})

		return new Collection(owners);

	},
	getContactsCollection() {
		let realty = this.getRealty();
		if (!realty) { return; }
		let contacts = realty.get('ownerContacts') || [];
		let ownerId = this.getOwnerId();
		if (ownerId) {
			contacts = contacts.filter(f => f.ownerId == ownerId);
		}
		const ownersInfo = this.getOwnersInfo();
		//console.log('OWNER ID:', ownerId, contacts, ownersInfo);
		return new Collection(contacts);
	},
	getRealty() {
		let type = this.getOption('entityType');
		let key = type == 'realty' ? 'entity' : 'realty';
		return this.getOption(key);
	},
	getObject() {
		let type = this.getOption('entityType');
		let key = type == 'object' ? 'entity' : 'object';
		return this.getOption(key);
	},
	getOwnerId() {
		let obj = this.getObject();
		if (!obj) { return; }
		return obj.get('ownerId') || '000-000';
	}
});