

import { defineControl, SelectControl, controlMixin } from 'bbmn-controls';
import { View } from 'base';
import { _ } from 'vendors';
import { CollectionView } from 'base';
import { Selector } from 'bbmn-components';
import { isView } from 'bbmn-utils';

// import { SelectControl } from 'bbmn-controls';

// console.log(' controlMixin ', controlMixin);

const SearchInputView = View.extend({
	initialize() {
		// const callback = function(event) {
		// 	this.value = event.target.value;
		// 	this.trigger('search:text', this.value);
		// };
		// this._onInput = _.debounce(callback.bind(this), 300);
		this.triggerChange = _.debounce(() => this._triggerChange(), 300);
	},
	template: _.template('<input />'),
	events: {
		'input input':'inputCallback'
	},
	inputCallback(e) {
		this.value = e.target.value;
		this.triggerChange(); 
	},
	_triggerChange() {
		console.log('change:', this.value);
		this.triggerMethod('change', this.value);
	},
});

const Base = controlMixin(View);

const SelectApiSearch = Base.extend({
	constructor: function() {
		Base.apply(this, arguments);
		this._initSelectApiSearch();
	},
	doneOnSelect: true,
	
	setControlValue() {
		console.warn('#setControlValue#', arguments);
		return Base.prototype.setControlValue.apply(this, arguments);
	},
	// ...controlMixin,

	_initSelectApiSearch() {
		const _schema = this.getOption('schema') || {};
		const { modelSchema, schema } = _schema;
		this.modelSchema = modelSchema;
		this.schema = schema;
		this.collection = schema.value.sourceValues();
		this.edit = (schema || {}).edit || {};

		this.selector = new Selector({ 
			source: this.collection, 
			multiple: false,
		});
		this.listenTo(this.selector, 'change', changes => this.triggerMethod('selector:change', changes));

		// this.selector.on('change', (changes) => {
		// 	changes.selected.forEach(m => m.trigger('change', m));
		// 	changes.unselected.forEach(m => m.trigger('change', m));
		// 	this.setControlValue(changes.selected);
		// });

	},

	template: _.template('<div class="segments"></div><div class="search"></div><div class="result"></div>'),

	regions: {
		segments: '.segments',
		search: '.search',
		result: '.result'
	},

	onRender() {
		this.showSegments();
		this.showSearch();
		this.showResult();
		console.log('searchapi', this);
	},
	showSegments() {

	},
	showSearch() {
		const view = new SearchInputView();
		this.listenTo(view, 'change', value => this.search(value));
		this.showChildView('search', view);
	},
	getChildView() {
		return this.edit.childView;
	},
	showResult() {
		const view = new CollectionView({
			childView: this.getChildView(),

			collection: this.collection,
			selector: this.selector,
			childViewOptions: {
				selector: this.selector,
				optionsButton: false,
				selectable: true,
				// selectButton: true,
				// modelEvents: {
				// 	'toggle':'render'
				// },
				cssClassModifiers:[
					m => this.selector.isSelected(m) ? 'selected' : ''
				],
				onToggleSelect: (arg1, arg2) => {

					let view = isView(arg1) ? arg1 : arg2;
					let event = isView(arg1) ? arg2 : arg1;
					
					event && event.stopPropagation && event.stopPropagation();
			
					let selector = this.selector;
					if (!selector.isMultiple() || !this.lastClickedModel || !event.shiftKey) {

						this.lastClickedModel = view.model;
						selector.toggle(view.model);

					} else {

						let lastclicked = this.lastClickedModel;
						delete this.lastClickedModel;
						selector.toggleRange(view.model, lastclicked);

					}
					
				}
				// onSelected: () => console.log('selected'),
				// onSelect: () => console.log('select'),
			}
		});
		this.showChildView('result', view);
	},
	buildSearchUrl(text) {
		if (text == null || text.length < 2) {
			return;
		}

		const addSearch = '?text=' + encodeURIComponent(text);

		let { searchUrl } = this.edit;

		if (searchUrl) {
			return searchUrl += addSearch;
		}

		let url = this.collection.url;
		if (typeof url === 'function') {
			url = url.call(this.collection);
		}
		if (!url) { return; }
		url += addSearch;
	},
	search(value) {
		const url = this.buildSearchUrl(value);
		if (url) {
			this.collection.fetch({ url });
		}
		else {
			this.collection.reset();
		}
	},
	isMultiple() {
		console.log(this);
		return this.schema.value.multiple === true;
	},
	onSelectorChange(changes){
		changes.selected.forEach(m => m.trigger('change', m));
		changes.unselected.forEach(m => m.trigger('change', m));

		let setPromise = this.setControlValue(this.selector.getValue());
		if (!this.isMultiple() && this.getOption('doneOnSelect')) {
			setPromise.then(() => {
				this.controlDone();
			});
		}	
	},
});
export default SelectApiSearch;

defineControl('select:api:search', SelectApiSearch);