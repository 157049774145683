import _ from 'underscore';
import { clone } from 'bbmn-utils';
import { Collection } from 'base';
import { busModels } from 'bus';
import { ActionModel } from 'mod/model-mixins';
import { enums, hasFlag } from 'bbmn-utils';
import { ActionStore, action } from 'components/actions';
import { modals, PropertySchema, ModelSchemas } from 'bbmn-components';
import { EditProperty } from 'components/display-schema';
import { ManageAdPromotions } from '../views/advert-promotions-edit';
import { AdOffers } from '../views/advert-offers-edit';

//console.warn('ENUMS', enums);

export const AdImageModel = ActionModel.extend({

});

ModelSchemas.initialize(AdImageModel, {
	isDisabled: {
		value: {
			type: 'boolean',
			control: 'select:inline',
			sourceValues: ['Попадает в выгрузку','Не выгружается']			
		},
		display:{
			label:'Состояние'
		}
	},
	ownOrder: {
		value: {
			type:'number',
		},
		display:{
			label: 'порядковый номер',
		}
	},
});


export const Ad = ActionModel.extend({
	initialize(){
		this.initializePromotions();
	},
	getTakedOffersIds(){
		return this.get('takedOffersIds') || [];
	},
	getAllOffersIds(){
		return this.get('offersIds') || [];
	},
	getOffersCount()
	{
		return this.getTakedOffersIds().length;
	},
	getPhone({ display = false} = {}){
		return '';
		/*
		let phones = this.get('phones') || [];
		let phone = _.first(phones);
		if(!display) return phone;
		let number = (phone.number || '');
		return `${phone.countryCode} ${number.substring(0,3)} ${number.substring(3)}`;
		*/
	},
	getFeed(){
		try {
			let feed = this.collection.parent.feed;
			if (feed) return feed;
		} finally { /**/ }
		let Feed = busModels.getClass('Feed');
		return new Feed({ id: this.get('feedId') });
	},
	isEdited(feed){
		!feed && (feed = this.getFeed());
		return feed && feed.isRedefinedDescription(this.id);
	},
	hasAuction(){
		return this.get('auction.bet') > 0;
	},
	isTaked(){
		return this.get('skiped') != true;
	},
	isExcluded(feed){
		!feed && (feed = this.getFeed());
		//console.log('	exlc');
		let items = feed.get('skiped') || [];
		let result = items.indexOf(this.id) > -1;
		if (!this.isTaked()) {
			//console.log('>>', result, items, this.id);
		}
		return result;
	},
	isIncluded(feed){
		!feed && (feed = this.getFeed());

		let items = feed.get('taked') || [];
		return items.indexOf(this.id) > -1;
	},
	isInvalid(){
		return !this.get('isValid') || !!this.get('error');
	},
	isValid(){
		return !this.isInvalid();
	},
	isPromoted(promoId){
		let promos = this.get('promotions') || {};
		//this.get('jsonPromotions') || {};
		if(!arguments.length)
			return _.some(promos, (promo) => {
				return hasFlag('autoPromotion, forcedPromotion', promo);
			});
		
		return promoId && promos[promoId] && hasFlag('autoPromotion, forcedPromotion', promos[promoId]);
	},
	getPromotions(opts = {})
	{

		let suggested = this.get('suggestedPromotions') || {};		
		let promos = this.get('promotions') || {};

		_.each(suggested, (val, key) => {
			if (val != 'preventPromotions') return;
			if (key in promos) return;
			promos[key] = val;
		});
		if (opts.enum) {
			return promos;
		}
		return _.reduce(promos, (memo, state, id) => {
			if (opts.all || hasFlag('autoPromotion, forcedPromotion', state)) {
				memo.push({ id, state });
			}
			return memo;
		},[]);
	},
	getPriceLabel(){
		return enums.get('bargainPriceTypes', this.get('bargainTerms.priceType'));
	},
	getPriceString(){
		return this.get('bargainTerms.price');
	},
	getMinArea(){
		return this.get('minArea') || '';
	},
	getVatType(){
		return enums.get('vatTypes', this.get('bargainTerms.vatType'));		
	},
	getBcClass(){
		return this.get('realty.class');
		//return enums.get('buildingClasses', this.get('building.classType'));				
	},
	getCategory(){
		return enums.get('cianObjectCategories', this.get('category'));		
	},
	getPhotos() {
		let hashes = this.get('images') || [];
		//this.get('realty.imageHashes') || [];
		return hashes;
		// let arr = (this.get('photos') || []).sort((a,b) => {
		// 	let aa = a.isDefault ? 1 : 0;
		// 	let bb = b.isDefault ? 1 : 0;
		// 	return bb - aa;
		// });
		// return arr;
	},


	/** old promotions -> */


	initializePromotions(){
		if (this.backendPromotions) return;

		let enumName = 'servicesEnum';

		let promoenums = enums.get(enumName);
		let defs = _.reduce(promoenums, (memo, label, id) => {
			memo[id] = {
				state: false,
				type:'auto'
			};
			return memo;
		},{});

		let modelPromos = this.get('jsonPromotions') || {};
		_.defaults(modelPromos, defs);
		if(!this.backendPromotions){
			this.backendPromotions = clone(modelPromos);
		}
		this.set('jsonPromotions', modelPromos, { silent: true});		
	},
	getPromos(){
		// есть два енума
		// PromotionEnums - мой, ограниченный
		// ServicesEnum - циановский полный.
		let enumName = 'servicesEnum';

		let modelPromotions = this.get('jsonPromotions');

		let models = _.reduce(modelPromotions, (memo, promo, id) => {

			if (!promo.state && !promo.wasAutoAllowed && !promo.wasDisallowed && !promo.wasAllowed)
				return memo;

			let model = {
				id,
				name: enums.get(enumName, id),
				state: promo.state,
				type: promo.type,
				add:'авто ',
			};
			

			model.manual = model.type == 'manual';
			if(model.manual) {
				model.state && (model.add = 'принудительно ');
				!model.state && (model.add = 'запрет ');
			}
			//return model;
			memo.push(model);
			return memo;
		}, []);

		return models;

	},
	_storeBackendPromo(){
		if (!this._backendPromo) {
			this._backendPromo = clone(this.get('jsonPromotions')) || {};
		}
	},
	addPromo(promoId){
		this._storeBackendPromo();

		let promotions = clone(this.get('jsonPromotions'));
		let promo = promotions[promoId];
		if(!promo) {
			promo = {};
			promotions[promoId] = promo;
		}
		promo.state = true;
		promo.type = 'manual';
		this.once('change', () => console.log('YEEEEEAH'));
		this.set('jsonPromotions', promotions);
		//console.log('* ', this.isPromoted());
	},
	removePromo(promoId, feed, opts){
		this._storeBackendPromo();

		!feed && (feed = this.getFeed());
		let backvalue = this._backendPromo[promoId] || {};


		let promotions = clone(this.get('jsonPromotions')) || {};
		if (promotions[promoId]) {
			if (!promotions[promoId].state && promotions[promoId].wasDisallowed) {
				promotions[promoId].wasDisallowed = false;
				promotions[promoId].state = backvalue.wasAutoAllowed;
				promotions[promoId].type = 'auto';
			} else if (promotions[promoId].state && promotions[promoId].type == 'auto') {
				promotions[promoId].wasDisallowed = true;
				promotions[promoId].state = false;
				promotions[promoId].type = 'manual';
			} else if (promotions[promoId].state && promotions[promoId].type == 'manual') {
				promotions[promoId].wasAllowed = true;
				promotions[promoId].state = backvalue.wasAutoAllowed;
				promotions[promoId].type = 'auto';

			}

		}
		this.set('jsonPromotions', promotions);
	},
	setPromo(promoId){
		let pt = _.clone(this.get('publishTerms')) || {};
		!pt.terms && (pt.terms = []);
		!pt.terms[0] && (pt.terms.push({ services: [] }));
		let data = pt.terms[0].services;
		if (data.indexOf(promoId) == -1) {
			data.push(promoId);
		}
		this.set('publishTerms', data);
	},
	setAuction(val){
		let auction = _.clone(this.get('auction')) || {};

		if(val == null || val === '')
			auction = null;
		
		val = parseFloat(val, 10);
		if(_.isNumber(val) && !isNaN(val))
			auction.bet = val;

		this.set('auction', auction);
	},
	getAuctionBet(){
		return this.get('auction.bet');
	},

	/** <- old promotions */

	/** new promotions -> */
	_ensureBackendPromotions(promoId){
		this._backendPromotions || (this._backendPromotions = {});
		let promos = this._backendPromotions;
		if (promos[promoId] == null) {
			let actualPromos = this.getPromotions({ enum: true }) || {};
			let actual = actualPromos[promoId] || 'default';
			promos[promoId] = actual;
		}
	},
	changePromotion(promoId, state, direction) {
		this._ensureBackendPromotions(promoId);
		if (direction) {
			this.setPromotion(promoId, state);
		} else {
			this.unsetPromotion(promoId, state);
		}
	},
	setPromotion(promoId, state){
		//this.set(`promotions.${promoId}`, state);
		let promos = this.get('promotions');
		promos[promoId] = state;
		let feed = this.getFeed();
		feed.setPromo({
			disallowed: state == 'preventPromotions',
			applied: state == 'forcedPromotion',
			promoId,
			id: this.id
		});	
		this.trigger('change:promotions:' + promoId, state);
		this.trigger('change:promotions',  { [promoId]: state });
		this.trigger('change',  this, promos);

	},
	unsetPromotion(promoId, state){
		let backend = this._backendPromotions;
		let backendState = backend[promoId] || 'default';
		let promos = this.get('promotions');
		promos[promoId] = backendState;
		let feed = this.getFeed();
		feed.setPromo({
			remove: true,
			disallowed: state == 'preventPromotions',
			applied: state == 'forcedPromotion',
			promoId,
			id: this.id
		});
		this.trigger('change:promotions:' + promoId, backendState);
		this.trigger('change:promotions',  { [promoId]: backendState });
		this.trigger('change',  this, promos);

	},
	/** <- new promotions */


	toggleOffer(value, offer) {
		let feed = this.getFeed();
		let custom = feed.getCustom(this.id);
		let excludedOffers = _.clone(custom.excludedOffers) || [];
		if(value) {
			excludedOffers.push(offer.id);
		}
		else {
			excludedOffers = _.filter(excludedOffers, o => offer.id != o);
		}
		custom.excludedOffers = excludedOffers;
		feed.setCustom(this.id, custom);
		offer.trigger('change', offer);
	},
	isOfferDisabled(id){
		let feed = this.getFeed();

		let custom = feed.getCustom(this.id);
		if (!custom) return false;
		let exc = custom.excludedOffers || [];
		return exc.indexOf(id) > -1;
	},
});

ActionStore.initialize(Ad, [

	//ready
	action('edit','редактировать описание',function(e, opts = {}){ 
		let feed = this.getFeed(); //opts.feed;
		let packs = feed.entity('packs');
		let pack = packs.get(this.get('packId'));
		let categories = feed.entity('categories');
		let category = categories.get(this.get('categoryId'));
		let id = this.id;
		let model = this;
		let custom = feed.getCustom(id);
		let desc = model.get('desc');
		let description = custom.description || desc.template; //.get('descriptionTemplate');
		//let modelView = opts.view;
		let schema = new PropertySchema({
			property:{
				value:{
					type: 'text',
					control: 'advert:template',
				},
			}
		});
		let content = new EditProperty({
			schema,
			value: description,
			edit:{
				feed,
				pack,
				category
			}
		});
		modals.show({
			header: 'редактирование объявления',
			content,
			promiseBar: true,
			onResolve(view){
				let value = view.getControlValue();
				custom.description = value;
				if(!value)
					custom.description = null;

				feed.setCustom(id, custom);
				desc.template = value;
				desc.isOwnTemplate = !((value || '').trim());
				model.trigger('change:desc:template', value);
				model.trigger('change', model);
				//modelView.updateDescription(value);
			}
		});

	}, { export: 'editFeeds'}),

	//ready
	action('edit:offers','редактировать предложения',function(e, opts = {}){ 

		modals.show({
			header: 'предложения объявления',
			content: AdOffers,
			contentOptions: {
				model: this,
			}
		});

	}, { export: 'editFeeds'}),




	//ready
	action('include','принудительно включить в выгрузку',function(e, opts = {}){ 

		this.getFeed().includeAd(this, true);
		this.trigger('change', this);

	}, { export: 'editFeeds'}, { 
		rule: function(model) {
			return !model.isExcluded() && !model.isIncluded();
		} 
	}),


	//ready
	action('cancel:include','отменить принудительное включение',function(e, opts = {}){
		
		this.getFeed().includeAd(this, false);
		this.trigger('change', this);		

	}, { export: 'editFeeds', }, { rule: model => model.isIncluded() }),

	//ready
	action('exclude','исключить из выгрузки',function(e, opts = {}){
		this.getFeed().excludeAd(this, true);
		this.trigger('change', this);		
	}, { export: 'editFeeds' }, { rule: model => !model.isExcluded() && !model.isIncluded() }),

	//ready
	action('cancel:exclude','отменить исключение из выгрузки',function(e, opts){
		
		this.getFeed().excludeAd(this, false);
		this.trigger('change', this);

	}, { export: 'editFeeds'}, { rule: model => model.isExcluded() }),

	//ready
	action('promote','управление продвижениями',function(e, opts){
		/*
		let feed = opts.feed;
		let id = this.id;
		let model = this;
		let view = opts.view;
		let schema = new PropertySchema({
			property:{
				value:{
					type:'enum',
					sourceValues: enums.get('promotionEnums'),
				},
			}
		});
		let content = new EditProperty({
			schema,
			onControlDone(promoId){

				feed.setPromo({
					applied: true,
					promoId,
					id
				});
				model.addPromo(promoId);
				view.render();
				this.trigger('done');
			}
		});*/
		modals.show({
			header: 'управление продвижениями',
			content: ManageAdPromotions,
			contentOptions: {
				model: this,
				feed: this.getFeed(),
			}
		});		
	}, { export: 'managePromotions'}),


	//ready
	action('_old_promote','добавить продвижение',function(e, opts){

		let feed = opts.feed;
		let id = this.id;
		let model = this;
		let view = opts.view;
		let schema = new PropertySchema({
			property:{
				value:{
					type:'enum',
					sourceValues: enums.get('promotionEnums'),
				},
			}
		});
		let content = new EditProperty({
			schema,
			onControlDone(promoId){

				feed.setPromo({
					applied: true,
					promoId,
					id
				});
				model.addPromo(promoId);
				view.render();
				this.trigger('done');
			}
		});
		modals.show({
			header: 'выберите продвижение',
			content,
		});		
	}, { export: 'managePromotions'}, { hidden: true }),


	//ready
	action('remove:disallow:promo','снять запрет продвижения',function(e, opts){

		let feed = opts.feed;
		let promoId = opts.promoId;
		let type = opts.type;
		let model = this;
		let view = opts.view;	

		if(type == 'manual'){
			feed.setPromo({
				remove: true,
				disallowed: true,
				promoId,
				id: model.id
			});
			model.removePromo(promoId, feed, { disallowed: true });
			view.render();
		} 

	}, { export: 'managePromotions'}, { hidden: true }),


	//ready
	action('apply:promo','закрепить продвижение',function(e, opts = {}){ 

		let feed = opts.feed;
		let promoId = opts.promoId;
		let type = opts.type;
		let model = this;
		let view = opts.view;		

		if(type != 'manual'){

			feed.setPromo({
				applied: true,
				promoId,
				id: model.id
			});
			model.addPromo(promoId);
			view.render();
		}

	}, { export: 'managePromotions'}, { hidden: true }),

	//ready
	action('remove:promotion','отменить продвижение',function(e, opts = {}){

		let feed = opts.feed;
		let promoId = opts.promoId;
		let type = opts.type;
		let model = this;
		let view = opts.view;

		if(type != 'manual'){
			feed.setPromo({
				disallowed: true,
				promoId,
				id: model.id
			});
			model.removePromo(promoId, feed);
			view.render();
		} else {
			feed.setPromo({
				remove: true,
				applied: true,
				promoId,
				id: model.id
			});
			model.removePromo(promoId, feed, { applied: true });
			view.render();
		}

	}, { export: 'managePromotions'}, { hidden: true }),

	//ready
	action('edit:auction','аукцион',function(e, opts = {}){ 
		
		let feed = opts.feed;
		let model = this;

		let content = new EditProperty({
			schema: new PropertySchema({
				property:{
					value:{
						type:'number',
					}
				}
			}),
			value: model.get('auction.bet'),
		});
		modals.show({
			header: 'редактирование объявления',
			content,
			promiseBar: true,
			onResolve(view){
				let value = view.getControlValue();
				feed.setAuction(model, value);
			}
		});

	}, { export: 'managePromotions'}, { rule: () => false }),


	
]);

export const Ads = Collection.extend({
	model: Ad
});
