import BasePage from 'pages/auth';
import { Feeds, Feed } from 'mod/export/feeds/models';
import Content from './content';
import { actionableMixin, action, ActionStore } from 'components/actions';
import { modals, ModelSchemas } from 'bbmn-components';
import { EditModel } from 'bbmn-controls';

import FeedPage from './feed';
import editor from '../../components/editor';

const ExportPage = actionableMixin(BasePage).extend({
	id:'export',
	routes: 'export',
	label: 'Экспорт',
	children:[FeedPage],
	Content,
	startPromises: [
		page => page.collection.fetch(),
	],
	initialize(){
		this.collection = new Feeds();
	},
	rights:{
		export:'view'
	}
});

ActionStore.initialize(ExportPage, [
	action('create:feed', 'создать фид', function(){
		let page = this;
		let schema = Feed.getSchema();
		editor.add({
			schema,
			propertiesToShow: ['name', 'format'],
			onResolve(values){
				let model = new Feed();
				model.save(values).then(() =>{
					page.collection.add(model);
					this.destroy();
				});
			}
		},'Добавление нового фида');
	}, ['export: createDeleteFeeds']),


]);

export default ExportPage;
