import '../api-config';
import { Model } from 'base';
import { paths } from 'helpers';
import { ModelSchemas } from 'bbmn-components';

paths.set('api', 'acc:forgotPass', 'forgotPass', { relative: true });

export const ForgotPass = Model.extend({
	urlRoot: paths.api('acc:forgotPass'),
});

ModelSchemas.initialize(ForgotPass, {
	email:{
		validation:{
			required:true,
		},
		value:{
			type:'email',
			inputType:'email',
		},
		display:{
			label:'укажите свой email'
		},
	},
});



export const ForgotPassConfirm = Model.extend({
	urlRoot: paths.api('acc:forgotPass'),
	defaults:{
		id:'confirm'
	}
});

ModelSchemas.initialize(ForgotPassConfirm, {
	email:{
		validation:{
			required:true,
		},
		value:{
			type:'email',
			inputType:'email',
		},
		display:{
			label:'укажите свой email'
		},
	},
	password:{
		validation:{
			required: true, 
			minLength: 6, 
			shouldBeEqual: values => values.passwordConfirm, 
			shouldBeEqualMessage: 'не совпадает с подтверждением'
		},
		value:{
			type:'text',
			inputType:'password',
		},
		display:{
			label:'укажите новый пароль'
		}
	},
	passwordConfirm:{
		display:{
			label:'подтверждение пароля'
		},
		value:{
			type:'text',
			inputType:'password',
		}
	}		
});
