import { View, CollectionView, Collection, Model } from 'base';
import { bus } from './bus';
import { _ } from 'vendors';

export const BusActionButton = View.extend({
	tagName: 'button',
	template: _.template('<%= text %>'),
	templateContext() {
		return {
			text: this.getOption('text')
		}
	},
	events: {
		'click'() {
			const guard = this.getOption('eventName')
			if (bus.get(guard)) { return; }
			bus.trigger(guard);
		}
	}
})