import _ from 'underscore';
import { View } from 'base';
import { Page, historyApi } from 'bbmn-routing';
import { PageLayout } from './views';
import { user } from 'app/context';
import { mix, hasFlag, buildViewByKey } from 'bbmn-utils';
//import { history } from 'bbmn-core';

const urlChangersMixin = Base => Base.extend({
	constructor: function(){
		this._initUrlChangers();
		Page.apply(this, arguments);

	},
	_initUrlChangers(){
		this.urlChangers = {};
		this.on('before:start', this._delegateUrlChangers);
		this.on('before:stop', this._undelegateUrlChangers);
	},
	_undelegateUrlChangers(){
		_.each(this.urlChangers, changer => this._undelegateUrlChanger(changer));
	},
	_undelegateUrlChanger(changer){
		changer && this.stopListening(changer);
	},
	_delegateUrlChangers(){
		_.each(this.urlChangers, changer => this._delegateUrlChanger(changer));
	},
	_delegateUrlChanger(changer){
		changer && this.listenTo(changer, 'change', this.changePageUrl);
	},
	addUrlChanger(name, changer){
		if(_.isObject(name)){
			changer = name;
			name = _.uniqueId('urlchanger');
		}
		if (!this.isStopped()) {
			this._undelegateUrlChanger(this.urlChangers[name]);
			this._delegateUrlChanger(changer);
		}
		this.urlChangers[name] = changer;
	},
	changePageUrl(){
		let hash = _.reduce(this.urlChangers, (memo, changer) => {
			_.extend(memo, changer.getQueryStringObject());
			return memo;
		}, {});
		let url = this._buildQsString(hash);
		historyApi.changeUrlQueryString(url);
		this.triggerMethod('url:change', { url, hash });
	},
	_buildQsString(data){
		return _.map(data, (value, key) => {
			return key + '=' + encodeURIComponent(value);
		}).join('&');
	},
});


function createFreezer() {
	let freezer = new View({
		className: 'freezer',
		template:() => '<div class="freezer-wrapper"><div></div></div>',
		onRender(){
			this.$el.appendTo($('body'));
		}
	});
	freezer.render();
	return freezer;
}


const Base = mix(Page).with(urlChangersMixin);
export default Base.extend({

	relativeRoutes: true,
	visibility: 'auth, anonym',
	pageLinksAsContent: true,
	Layout: PageLayout,
	// initialize() {
	// 	this.on('all', c => console.log('	PGE: ', c));
	// },
	onBeginStart(){
		let freezer = createFreezer();
		this.once('end:start', () => {
			freezer.destroy();
			freezer.$el.remove();
		});
	},

	checkRights(){
		return user.checkRights(this.rights);
	},
	childFilter(child){

		if (!child.checkRights()) {
			return false;
		}

		if (user.isLogged()) {
			return hasFlag(child.visibility, 'auth');	
		} else {
			return hasFlag(child.visibility, 'anonym');
		}

	},
	getDefaultData(ac = {}){
		return _.extend({}, ac.qs, ac.args);
	},
	getData(ac) {
		let custom = this.getOption('data', { args: [this, ac] });
		return _.extend({}, this.getDefaultData(ac), custom);
	},
	getAsideView(){
		let options = {
			page: this,
			model: this.model,
			collection: this.collection
		};
		return buildViewByKey(this, 'aside', { options });
	},
	getView(opts){
		let options = _.extend(_.pick(this, 'filterer', 'selector'), opts);
		return Base.prototype.getView.call(this, options);
	},
});
