import { Model } from 'base';
import { ModelSchemas } from 'bbmn-components';

export const Login = Model.extend({});

ModelSchemas.initialize(Login, {
	login:{
		// validation:{
		// 	required:true,
		// 	email: true,
		// },
		value:{
			type:'email',
			inputType:'email',
		},
		display:{
			label:'email'
		},
	},
	password:{
		// validation:{
		// 	required: true, 
		// },
		value:{
			type:'text',
			inputType:'password',
		},
		display:{
			label:'пароль'
		}
	},	
});

