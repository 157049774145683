export function displayPhone(phone) {
	if (!phone) { return ''; }
	let [country, code, number] = phone.split('.');
	country = country !== '8' ? '+' + country : country;
	number = normalizePhoneNumber(number);

	return country + ' ' + code + ' ' + number;

}

export function phoneValue(phone) {
	if (!phone) { return ''; }
	let [country, code, number] = phone.split('.');
	country = country !== '8' ? '+' + country : country;
	return country + code + number;
}

function normalizePhoneNumber(phone) {
	if (!phone) { return ''; }
	let chunks = [];
	while(phone.length) {
		if (phone.length > 3) {
			let chunk = phone.substring(phone.length - 2);
			chunks.unshift(chunk);
			phone = phone.substring(0, phone.length - 2);
		} else {
			chunks.unshift(phone);
			phone = '';
		}
	}
	return chunks.join('-');
}

export function phoneMagicDisplay(text){

	let [noadd = '', add = ''] = text.split(',');
	add = add.replace(/\D/g, '');
	text = noadd.replace(/-/g, ' ');
	text = text.replace(/[()]/g, ' ');
	text = text.replace(/\s+/g, '.');

	let chunks = text.split('.');
	let country = chunks.shift();
	let code = chunks.shift();
	let number = chunks.join('');
	
	if (!number) {
		if (country && !code) {
			
			number = country.substring(country.length - 7);
			let countrycode = country.substring(0, country.length - 7);
			code = countrycode.substring(countrycode.length - 3);
			country = countrycode.substring(0, countrycode.length - 3);
			// console.warn(number);
			// console.warn(countrycode);
			// console.warn(code);
			// console.warn(country);
		} else {
			return '**:' + text;
		}
	}

	let dphone = '';
	if (number.length <= 3) {
		dphone = number;
	} else {
		
		for(let x = number.length - 1; x >= 0; x--) {
			dphone = number[x] + dphone;
			if (x >= 2 && dphone.length >= 2 && (dphone.length + 1) % 3 == 0) {
				dphone = '-' + dphone;
			}
		}
	}
	if (add) {
		dphone = dphone + ', доб. ' + add;
	}

	if (!country.startsWith('+') && !country.startsWith('8')) {
		country = '+' + country;
	}

	return [country, code, dphone].join(' ');
}