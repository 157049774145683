import _ from 'underscore';
import { Model } from 'base';
import { paths } from 'helpers';
import { enums } from 'bbmn-utils';
import { modules } from 'mod/models';
import { filters } from 'mod/filters';

paths.set('api', 'appconfig', 'appconfig', { prefix: 'ent' });

const AppData = Model.extend({
	url: paths.api('appconfig'),
	initialize(){
		this.on('sync', this.refreshData);
	},
	refreshData(){
		
		this.setEnum();
		this.setModules();
		this.setFilters();
	},
	setEnum(){
		enums.set(this.get('enums'));
	},
	setModules(){
		let hash = (this.get('modules') || {});
		let modulesData = _.map(hash, (item, id) => {
			item.id = id;
			item.rights = enums.get(item.rightsType);
			return item;
		});
		modules.set(modulesData);
	},
	setFilters(){
		let hash = (this.get('filters') || {});
		_.extend(filters, hash);
	}
});

export default new AppData();
