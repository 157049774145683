import _ from 'underscore';
import IntlMessageFormat from 'components/intl-message-format';

/*
var test = 'У вас {numPhotos, plural, ' +
            '=0 {нет фоток.}' +
            'one {# фотка.}' +
						'few {# фотки.}' +
						'many {# фоток.}' +
            'other {# photos.}}';

var msg = new IntlMessageFormat(test, 'ru-RU');
var output = msg.format({ numPhotos: 2 });
console.log(output);
*/

const compiledPlurals = {};

function buildPlural(number, options, locale = 'ru'){
	if(!options.other){
		options.other = '{#}';
	}
	let args = _.map(options, (word, type) => { 
		if(!/{[^}]+}/g.test(word)) {
			word = `{# ${word}}`;
		}
		return type + ' ' + word;
	}).join('\r\n');

	let message = `{number, plural, ${args} }`;

	let msg = compiledPlurals[options.one];
	if (!msg) {
		msg = compiledPlurals[options.one] = new IntlMessageFormat(message, locale);		
	}
	return msg.format({ number });
}	

export default function plural(num, one, few, many){
	let options;
	if(_.isObject(one)){
		options = one;		
	} else {
		options = {
			one: $`{# ${one}}`,
			few: $`{# ${few}}`,
			many: $`{# ${many}}`,
		};
	}
	return buildPlural(num, options);
}
