import _ from 'underscore';
import { EditModel, EditProperty } from 'bbmn-controls';
import { modals } from 'bbmn-components';

export default {
	_show({ View, defs, opts } = {}, modalOpts){
		let inModal = modalOpts != null;

		let options = _.extend(defs, opts, {
			onResolve(values){
				let result;
				if(opts.onResolve){
					result = opts.onResolve.call(this, values);
				}
				if (inModal) {

					if (result == null || !_.isFunction(result.then)) {
						this.destroy();
					} else if (_.isFunction(result.then)) {
						result.then(() => {
							this.destroy();
						});
					}
				}
			}
		});
		let content = new View(options);
		console.log('EDIT OPTS', options);
		if (modalOpts) {
			if(_.isString(modalOpts)) {
				modalOpts = { header: modalOpts };
			}
			let modalOptions = _.extend({}, modalOpts, { content });
			
			return modals.show(modalOptions);
		} else {
			return content;
		}
	},
	edit(opts, modalOpts){
		let defs = {
			shouldShowPropertyError: true,
			cssClassModifiers: ['big'],
			propertiesToShow: ['name'],
			buttons: [{name:'resolve', text:'сохранить'}],
			//value: {},
		};

		return this._show({
			View: EditModel,
			defs,
			opts,
		}, modalOpts);
	},
	add(opts, modalOpts){

		let defs = {
			shouldShowPropertyError: true,
			cssClassModifiers: ['big'],
			propertiesToShow: ['name'],
			buttons: [{name:'resolve', text:'добавить'}],
			//value: {},
		};

		return this._show({
			View: EditModel,
			defs,
			opts,
		}, modalOpts);
		// let inModal = modalOpts != null;

		// let options = _.extend(defs, opts, {
		// 	onResolve(values){
		// 		let result;
		// 		if(opts.onResolve){
		// 			result = opts.onResolve.call(this, values);
		// 		}
		// 		if (inModal) {
		// 			if (result == null || !_.isFunction(result.then)) {
		// 				this.destroy();
		// 			} else if (_.isFunction(result.then)) {
		// 				result.then(() => {
		// 					this.destroy();
		// 				});
		// 			}
		// 		}
		// 	}
		// });
		// let content = new EditModel(options);
		// if (modalOpts) {
		// 	if(_.isString(modalOpts)) {
		// 		modalOpts = { header: modalOpts };
		// 	}
		// 	let modalOptions = _.extend({}, modalOpts, { content });
		// 	return modals.show(modalOptions);
		// } else {
		// 	return content;
		// }
	},
	editProperty(opts, modalOpts){
		let defs = {
			buttonsInFooter: true,
			shouldShowPropertyError: true,
			cssClassModifiers: ['big'],
			propertiesToShow: ['name'],
			buttons: [{name:'resolve', text:'изменить'}],
			destroyOnDone: true,
			//value: {},
		};
		return this._show({
			View: EditProperty,
			defs,
			opts,
		}, modalOpts);
	}
};
