import BasePage from 'pages/auth';
//import { RealtyList } from 'mod/realties/realty-lists/models';
import { Config } from 'mod/adm/configs/models';
import Content from './content';
import { getConfigSchemaAsync } from './schemas';

const Page = BasePage.extend({
	id:'adm-configs-config',
	routes: ':configId',
	isEntityPage: true,
	skipMenu: true,
	label: (p, data = {}) => p.model && p.model.get('id') || data.label,
	Content,
	async onBeforeStart(proc, ac){
		let model = this.model = new Config({ id: ac.args.configId });
		await model.fetchIfNot();
		model.schema = await getConfigSchemaAsync(model.id);
	},
	data(){
		return {
			label: this.model.display('id'),			
		};
	}
});


export default Page;
