import _ from 'underscore';
import { enums } from 'bbmn-utils';
import { filters } from 'mod/filters';
import { RealtyLists } from '../realty-lists/models'
import { RealtyListItemView } from '../realty-lists/views'
import busApp from 'bus/app';
export function getFilters({ old = false} = {}){

	const oldfilters = [
		{
			id: 'name',
			compareProperty: 'name',
			name: 'название содержит',
			valueType: 'text',
		},
		{
			id: 'address',
			name: 'адрес содержит',
			valueType: 'text',
		},
		{
			id: 'mt',
			name: 'есть предложения с типом рынка',
			valueType: 'enum',
			sourceValues: enums.get('realtyMarkets'),
			multiple: false,
		},
		{
			id: 'mo',
			name: 'есть предложения с типом операции',
			valueType: 'enum',
			control: 'select:inline',
			sourceValues: enums.get('realtyOperations'),
			multiple: false,
		},
		{
			id: 'cls',
			compareProperty: 'class',
			compareModelValue: model => model.getClass(false),
			name: 'класс',
			valueType: 'enum',
			sourceValues: enums.get('realtyClassesFlags'),
			control: 'select:inline',
			multiple: true,
		},
	];

	if(old) {
		return oldfilters;
	}

	const allowedFilters = []; //['RealtyName','RealtyAddress', 'RealtyBuildingClass', 'RealtyOfferOperation', 'RealtyOfferMarket'];
	const disallowedFilters = [];

	const realtyClassesInts = {
		ap: .5,
		a: 1,
		bp: 1.5,
		b: 2,
		cp: 2.5,
		c: 3,
		dp: 3.5,
		d: 4,
		ep: 4.5,
		e: 5
	};

	const overrides = {
		notinlistsatall: {
			multiple: false,
			sourceValues: { true: 'нет ни в одном списке', false: 'есть хотябы в одном' },
			valueType: 'enum',
			control: 'select:inline',
		},		
		notinlist: {
			multiple: false,
			sourceValues: () => new RealtyLists(),

			schemaEditOptions: {
				childView: RealtyListItemView,
				extractValue: model => model.id
			},
			schemaDisplayOptions: {
				transform: v => v && busApp.request('realtyList:name', v)
			},
		},
		inlist: {
			multiple: false,
			sourceValues: () => new RealtyLists(),

			schemaEditOptions: {
				childView: RealtyListItemView,
				extractValue: model => model.id
			},
			schemaDisplayOptions: {
				transform: v => v && busApp.request('realtyList:name', v)
			},
		},		
		RealtyBuildingClass:{
			name: 'класс',
			valueType: 'enum',
			sourceValues: enums.get('realtyClassesFlags'),
			control: 'select:inline',
			multiple: true,
		}
	};
	const additionals = {
		realty:[
			{
				id:'RealtyAddress',
				name: 'адрес содержит',
				valueType:'text',
				category: 'Realty'
			}
		]
	};


	const take = _.pick(filters, 'realty', 'realtyObject', 'realtyOffer');
	return _.reduce(take, (memo, arr, name) => {
		_.each(arr, item => {
			if(item.enumName) {
				item.sourceValues = enums.get(item.enumName);
			}
			if (allowedFilters.length && allowedFilters.indexOf(item.id) == -1) {
				return memo;
			}
			if (disallowedFilters.length && disallowedFilters.indexOf(item.id) > 0) {
				return memo;
			}
			if(overrides[item.id]){
				_.extend(item, overrides[item.id]);
			}
			memo.push(item);
		});
		memo.push(...(additionals[name] || []));
		return memo;
	}, []);

	//return oldfilters;
}
