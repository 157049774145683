import {_} from 'vendors';
import { View } from 'base';
import { customContactDisplayHtml, customContactDisplayTemplate } from './custom-contact-display';
import { ListItemView, ModelCoreView } from 'components/views';

const BaseCore = ModelCoreView.extend({
	text: m => customContactDisplayHtml(m.get('name'), m.get('value'), m.get('notVisible')),
});

export const SelectContactView = ListItemView.extend({
	modelView: BaseCore,
	mainAction: 'trigger:select:click',
	// constructor: function() {
	// 	View.apply(this, arguments);
	// 	console.warn('??---??', this);
	// },
	// className: 'select-item',
	// template: _.template(`<i></i>` + customContactDisplayTemplate),
	// initialize() {
	// 	console.warn('????', this);
	// }
});