import _ from 'underscore';
import { View } from 'base';
import { customContactDisplayHtml, customContactDisplayTemplate } from './custom-contact-display';
import { ListItemView, ModelCoreView } from 'components/views';
import { phoneMagicDisplay } from '../../../../helpers';

const BaseCore = ModelCoreView.extend({
	//text: m => customContactDisplayHtml(m.get('name'), m.get('value'), m.get('notVisible')),
	text() {
		let { phoneValue, displayPhone } = this.model.attributes;
		let v = displayPhone || phoneValue;
		return phoneMagicDisplay(v);
	}
});

export const SelectProxyPhoneView = ListItemView.extend({
	modelView: BaseCore,
	mainAction: 'trigger:select:click',
});