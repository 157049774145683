import _ from 'underscore';
import { CollectionView, View, Collection } from 'base';
import { displayItem } from '../../realty-helpers';
import { ListItemView, ModelCoreView, AtomTextView } from 'components/views';
import { ContactAtomItem } from '../../custom-contacts/views';
//import './realty-objects.less';

const siteRoot = 'http://rg.loc';

export const RealtyOfferInfo = ListItemView.extend({
	// initialize() {
	// 	console.warn('OFFER INFO INI')
	// },
	passDownModels: true,
	// passDownOptionsKeys: ['realty', 'object'],
	modelView: ModelCoreView,
	className: 'offer-list-item',
	cssClassModifiers: [
		m => m.get('status'),
		m => m.get('operation')
	],
	getBlockUrl() {
		const m = this.model;
		return `${BUILD_PUB_SITE}/${m.get('market')}/blocks/${m.id}`;
	},
	leftItems: [
		(mm, vv) => ({
			topText: (m,v) => m.get('status') === 'active' ? 'публикуется' : 'не публикуется',
			text: (m,v) => `<a href="${vv.getBlockUrl()}" data-noroute="true" target="_blank">${(m.display('operation') + ' ' + m.display('market'))}</a>`
		}),
		// {
		// 	text: (m,v) => m.display('market')
		// },
		{
			className: 'price-info',
			topText: (m,v) => m.display('priceMeter'),
			text: m =>  m.display('priceSquare')
		},
		// {
		// 	text: (m,v) => m.display('priceSquare')
		// },
		// {
		// 	text: (m,v) => `<a target="_blank" href="${siteRoot}/${m.get('market')}/blocks/${m.id}">открыть на сайте</a>`
		// },
	],
	rightItems: [
		//ContactAtomItem
		{ View: ContactAtomItem, clickable: 'trigger:edit:contacts:click' }
	],
	customViewOptions() {
		return {
			model: this.model,
			object: this.getOption('object'),
			realty: this.getOption('realty'),
			entityType: 'offer'
		}
	},
});