import _ from 'underscore';
import { User } from 'bbmn-components';
import token from './token';
import paths from 'helpers/paths';
import { hasFlag, mix } from 'bbmn-utils';

paths.set('api', 'identity', 'identity');

const RightsMixin = {
	checkRights(rights){
		if (rights == null) return true;
		rights = _.clone(rights);
		let any = rights.any;
		delete rights.any;
		
		if(!this.isLogged()) return false;
	
		let method = any ? 'some' : 'every';
	
		return _[method](rights, this._checkModuleRights.bind(this));
	},

	_checkModuleRights(moduleRights, module) {
		let userRights = this.getModuleRights(module);
		if (moduleRights === true || moduleRights === '' && !userRights) {
			return true;
		}
		return this._checkRightsEntry(userRights, moduleRights);
	},

	getModuleRights(module){
		let modules = this.get('modules') || {};
		return modules[module] || '';
	},

	_checkRightsEntry(currentRights, checkRights){
		let chunks = checkRights.split(/\s*:\s*/);
		if (chunks.length == 0) {
			return false;
		}
		let any = chunks.length == 2 && chunks[0] === 'any';
		let flags = chunks.length == 2 ? chunks[1] : chunks[0];
		return hasFlag(currentRights, flags, { all: !any } );
	},
};


const AppUser = mix(User).with(RightsMixin).extend({
	initialize() {
		this.initializeAuthPromise();
	},
	initializeAuthPromise() {
		let promise = new Promise((res) => {
			this.getReady().then(() => {
				if (this.isLogged()) {
					res();
					return;
				} else {
					let resolveAuth = () => {
						if (this.isLogged()) {
							this.off('change', resolveAuth);
							res();
						}
					};
					this.on('change', resolveAuth);
				}
			});
		});

		this.authPromise = promise.then(() => console.log('auth ok')).catch(() => console.error('auth error'));
	},

	displayName(){
		return this.get('name') || '****';
	},
	url: paths.api('identity'),
});

let user = new AppUser({}, { token });
//console.warn('user',user);
//user.on('change', () => console.log('%c	>USER','color:cornflowerblue'));

export default user;
