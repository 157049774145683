import _ from 'underscore';
import '../api-config';
import { Collection, ManagedCollection } from 'base';
import { CardModel, ActionModel } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';
import { paths } from 'helpers';
import { createSchemaNotifies } from 'components/notifier';
import { action, ActionStore } from 'components/actions';
//import { modals } from 'bbmn-components';
//import { EditModel } from 'bbmn-controls';
import ModelStore from 'backbone.store';
import editor from 'components/editor';
import { enums, getByPath, isEmptyValue } from 'bbmn-utils';
import { displayPhone } from '../../../helpers';

//import { RuleView } from '../feeds/views/feed-packs';
//import { enums } from 'bbmn-utils';

class KeyedSet {

	constructor(keyProperty, nameProperty) {
		this.keyProperty = keyProperty;
		this.nameProperty = nameProperty;
		this.array = [];
		this.set = new Map();
	}
	defaultKeyProperty = 'id'

	add(item) {
		this.array.push(item);
		this.set.set(item[this.keyProperty], item);		
	}

	name(id, def) {
		let item = this.set.get(id);
		if (!item) return def;
		let key = this.nameProperty;
		return item[key] || def;
	}

	byId(id) {
		return this.set.get(id);
	}

	find(predicate) {
		return this.array.filter(predicate)[0];
	}

}


class RealtyOwnerContactsEngine {

	constructor() {
		this.reset([]);
	}

	_createModel(contact, value) {
		console.warn('-', value, contact);
		
		let model = {
			value: value.displayValue,
			label: contact.name,
			ownerId: contact.ownerId
		};

		return model;
	}

	_firstValue(valuesArray) {
		valuesArray.sort((v1,v2) => {
			return v2.order - v1.order;
		});
		return valuesArray[0];
	}

	_contactComparator(c1, c2) {
		let prim = (c2.primary ? 1 : 0) - (c1.primary ? 1 : 0);
		if (prim !== 0) { return prim; }
		let ordr = c2.order - c1.order;
		if (ordr !== 0) { return ordr; }
		// todo: implement order by func
	}

	_firstContact(contactsArray) {
		contactsArray.sort(this._contactComparator);
		return contactsArray[0];
	}

	displayValue(type, id, defaultValue = '') {
		switch(type) {
			case 'owner':
				return this.owners.name(id, defaultValue);
			case 'contact':
				return this.contacts.name(id, defaultValue);
			case 'contactValue':
				return this.values.name(id, defaultValue);
			default:
				return defaultValue;
		}
	}

	reset(data) {

		let owners = new KeyedSet('id', 'name');
		let contacts = new KeyedSet('id', 'name');
		let values = new KeyedSet('id', 'displayValue');

		console.log('>>>>>> !!!!!', data);

		data.forEach(oc => {
			let moc = Object.assign({ name: oc.contactName || oc.personName }, oc);
			let contactValues = [];
			(oc.values || [])
				.filter(f => f.valueType.endsWith('hone'))
				.forEach(v => {
					const value = Object.assign({
						id: v.valueId,
						displayValue: displayPhone(v.value),
						ownerId: oc.ownerId,
						contactId: oc.id
					}, v);
					values.add(value);
					contactValues.push(value);
					//Object.assign({ displayValue: displayPhone(v.value) }, v)
				});
			moc.values = contactValues;
			contacts.add(moc);


			let owner = {
				id: moc.ownerId,
				name: moc.ownerName
			}
			owners.add(owner);


			// let owner = owners[oc.ownerId];
			// if (!owner) {
			// 	owner = {
			// 		name: oc.ownerName,
			// 		contacts: {}
			// 	}
			// 	owners[oc.ownerId] = owner;
			// }
			// owner.contacts[oc.id] = {
			// 	id: oc.id,
			// 	name: oc.contactName || oc.personName,
			// 	values: (oc.values || [])
			// 						.filter(f => f.valueType.endsWith('hone'))
			// 						.reduce((memo, val) => {
			// 							memo[val.valueId] = Object.assign({ displayValue: displayPhone(val.value) }, val)
			// 							return memo;
			// 						}, {})
			// }
		});

		this.contacts = contacts;
		this.owners = owners;
		this.values = values;
	}

	getSourceValues(options = {}) {
		//let { ownerId, contactId, entityType, sourceType } = options;

		switch(options.sourceType) {
			case 'owner':
				return this.getOwnerSourceValues(options);
			case 'contact':
				return this.getContactSourceValues(options);
			case 'contactValue':
				return this.getContactValueSourceValues(options);
			default:
				return [];
		}

	}

	getOwnerSourceValues() {
		return this.owners.array.map(item => ({ id: item.id, value: item.name }));
	}

	getContactSourceValues(options) {
		let { ownerId, contactId, entityType, sourceType } = options;
		if (!ownerId) { return []; }
		return this.contacts.array
			.filter(f => f.ownerId == ownerId)
			.map(c => ({ id: c.id, value: c.name }))
	}

	getContactValueSourceValues(options) {
		let { ownerId, contactId, entityType, sourceType } = options;
		//console.error(options, this.values)
		if (!ownerId || !contactId) { return []; }
		return this.values.array
			.filter(f => {
				// console.warn(f.ownerId == ownerId && f.contactId == contactId, f.ownerId == ownerId, f.contactId == contactId);
				// console.log(f.ownerId, ownerId, f.contactId, contactId);
				return f.ownerId == ownerId && f.contactId == contactId;
			})
			.map(c => ({ id: c.id, value: c.displayValue }))
	}

	getPhoneModel(ownerId, contactId, contactValueId) {
		if (!ownerId) { return console.error('ownerId not provided, need debug'); }

		if (contactValueId) {

			let contactValue = this.values.find(v => {
				return v.ownerId === ownerId && v.contactId === contactId && v.id === contactValueId
			});

			if (!contactValue) { return; } // missing value, should be handled by fallback phone;
			let contact = this.contacts.byId(contactId);
			if (!contact) { return console.error('missing contact. need debug'); }
			return this._createModel(contact, contactValue)
		}

		if (contactId) {
			let contact = this.contacts.byId(contactId);
			if (!contact) { return; } // missing contact, should be handled by fallback phone;
			let contactValue = this._firstValue(contact.values);
			if (!contactValue) { return; } // missing contactValue, should be handled by fallback phone;
			return this._createModel(contact, contactValue);
		}

		
		// full autodetect case

		let contacts = this.contacts.array.filter(item => {
			return item.ownerId === ownerId && (contactId == null || (item.id == contactId))
		});
		let contact = this._firstContact(contacts);
		if (!contact) { return; } // missing contact, should be handled by fallback phone;
		let contactValue = this._firstValue(contact.values);
		if (!contactValue) { return; } // missing contactValue, should be handled by fallback phone;


		return this._createModel(contact, contactValue);
	}
}


const BaseRealty = CardModel.extend({
	realtyEntityType: 'realty',
	urlRoot: paths.api('realties:realties'),
	cardUrlRoot: paths.url('realties:realties'),
	addOnSync(key, cb)	{
		if (!this._onsyncs) {
			this._onsyncs = {};
		}
		this._onsyncs[key] = cb;
	},
	getClass(userFriendly = true){
		let cls = this.get('class');
		if(!cls || userFriendly){
			return cls;
		}
		return cls.replace('+','p').toLowerCase();
	},
	getLandingUrl(){
		return 'http://' + this.get('idString') + '.pvt.ru';
	},
	getRgUrl(){
		return 'http://realty-guide.ru/realties/' + this.get('idString');
	},
	getNRGUrl(){
		return 'http://adm.nrg.plus/rlt/buildings/' + this.get('id');
	},
	initialize() {
		this.on('sync', () => {
			if (this.onSync) {
				this.onSync();
			}
			Object.keys(this._onsyncs || {}).forEach(key => {
				this._onsyncs[key].call(this);
			});
		})
	},
	getContactsData(skip) {
		if (!this.contactsData) {
			this.contactsData = new RealtyOwnerContactsEngine();
			if (!skip) {
				this._rebuildOwnerContacts(this.contactsData);
			}
		}
		return this.contactsData;
	},
	_rebuildOwnerContacts(oc) {
		const data = this.get('ownerContacts') || {};
		oc.reset(data);
	},
	rebuildOwnerContacts() {
		let oc = this.getContactsData(true);
		this._rebuildOwnerContacts(oc);
		// console.warn(oc);

		// if (!this.contactsData) {
		// 	this.contactsData = new RealtyOwnerContactsEngine();
		// }
		// const ownerContacts = this.get('ownerContacts') || {};
		// this.contactsData.reset(ownerContacts);


		// const owners = [];
		// const contacts = [];

		// ownerContacts.forEach(oc => {
		// 	let contactValues = (oc.values || []).map(v => Object.assign({}, v));
		// 	let moc = Object.assign({}, oc);
		// 	moc.values = contactValues;
		// 	contacts.push(moc);

		// 	let owner = {
		// 		id: moc.ownerId,
		// 		name: moc.
		// 	}

		// 	let owner = owners[oc.ownerId];
		// 	if (!owner) {
		// 		owner = {
		// 			name: oc.ownerName,
		// 			contacts: {}
		// 		}
		// 		owners[oc.ownerId] = owner;
		// 	}
		// 	owner.contacts[oc.id] = {
		// 		id: oc.id,
		// 		name: oc.contactName || oc.personName,
		// 		values: (oc.values || [])
		// 							.filter(f => f.valueType.endsWith('hone'))
		// 							.reduce((memo, val) => {
		// 								memo[val.valueId] = Object.assign({ displayValue: displayPhone(val.value) }, val)
		// 								return memo;
		// 							}, {})
		// 	}
		// });

		// this.ownersArr = owners;
		// this.ownersContactsArr = contacts;


	},
	getContactsInfo() {
		const { migrateContact = {} } = this.attributes;
		const { ownerId, contactId, contactValueId } = migrateContact;
		return { ownerId, contactId, contactValueId }
	},
});
ModelSchemas.initialize(BaseRealty, {
	name:{
		validation: {
			required:true
		},
		display:{
			label:'Название'
		}
	},
	'address.gd':{
		virtual: true,
		display: {
			transform(){
				let chunks = [
					getByPath(this, 'address.gd.route'),
					getByPath(this, 'address.gd.house'),
					getByPath(this, 'address.gd.locality')
				];
				chunks = _.filter(chunks, c => !isEmptyValue(c))
				return chunks.join(', ');
			}
		}
	},
	// 'customContactId': {

	// },
	// 'ownCustomContact.value': {

	// },
	// 'ownCustomContact.text': {

	// },
	// 'ownCustomContact.notVisible': {

	// },

});
export const Realty = ModelStore(BaseRealty);

export const Realties = ManagedCollection.extend({
	model: Realty,
	url: paths.api('realties:realties'),
	concurrentFetch:'allowed',
	fetchMethodName: 'POST',
});


const RealtyCards = Collection.extend({
	getOrCreate(id) {
		let model = this.get(id);
		if (!model) {
			model = new Realty({ id });
			this.add(model);
		}
		return model;
	},
	getName(id) {
		const m = this.get(id);
		return m?.get('name');
	}
});
export const realtyCards = new RealtyCards();

