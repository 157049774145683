import _ from 'underscore';
import { CollectionView, View } from 'base';
import { ListItemView, ModelCoreView, CardItemView, AtomTextView } from 'components/views';

import { UiBlockView } from 'components/views';
import { switcherInverse } from 'controls/boolean-switch';

export { ApiSelectRealties } from './selectRealties';

function getRealtyEditUrl(id) {
	let url = 'http://adm.nrg.plus/rlt/buildings/' + id;
	url = `https://app2.nrg.plus/s/rg/actualization/realties-list/${id}/edit`;
	return url;
}

function link(url, text) {
	return `<a href="${url}" target="_blank" class="realty-name-link">${text}</a>`;
}

function realtylink(id, name) {
	const url = '/rlts/realties/' + id;
	return link(url, name);
}

const BaseRealtyItemView = ModelCoreView.extend({
	topText: m => m.get('prefix') + ' класса ' + m.get('class'),
	text: m => realtylink(m.id, m.get('name')),
	smallText: (m,v) => m.display('address.gd'),
	_small(){
		let m = this.model;
		let landingUrl = m.get('idString') + '.pvt.ru';
		
		let rgUrl = getRealtyEditUrl(m.get('id'));
		//  'http://adm.nrg.plus/rlt/buildings/'+ m.get('id');
		// rgUrl = `https://app2.nrg.plus/s/rg/actualization/realties-list/${m.get('id')}/edit`;

		let landing = `<a href="${landingUrl}" class="spaced-link" data-applink="false" target="_blank">${landingUrl}</a>`;
		let rg = `<a href="${rgUrl}"  class="spaced-link" data-applink="false" target="_blank">редактировать в nrg</a>`;

		return [m.display('address.gd'), landing, rg].join('');
	}
});

export const RealtyThumbnail = View.extend({
	tagName: 'img',
	getThumbnail() {
		const thumb = this.getOption('thumbnail');
		if (thumb) {
			return thumb;
		}
		if (this.model) {
			return this.model.get('thumbnail');
		}
	},
	getThumbnailUrl(){
		let thumb = this.getThumbnail();
		if(!thumb) return;
		let size = 88;
		let sizeW = size;
		let sizeH = size;
		if(thumb.origin === 'nrg'){
			let url = `https://media2.nrg.plus/svc/rg/${thumb.hash}/w${sizeW}-h${sizeH}-c-rbc/jpg`;
			return url;
		}
	},
	onBeforeRender(){
		let attrs = {
			src: this.getThumbnailUrl()
		};
		this._setAttributes(attrs);
	}
});

export const RealtyItemView = ListItemView.extend({
	className: 'realty-item',
	//optionsButton: true,
	leftItems:[
		model => new RealtyThumbnail({ model })
	],
	modelView: BaseRealtyItemView,
	selectButton: (m,v) => v.isSelectable(),
	selectOnTheRight: true,
});


const UrlView = AtomTextView.extend({
	className: 'spaced-link',
	tagName: 'a',
	text: (m,v) => v.getOption('label'),
	initialize(){
		this._setAttributes({
			href: this.getOption('url'),
			'data-noroute': true,
			'target': '_blank'
		});
	},
});


const ListsView = View.extend({
	template: _.template('в списках: <%= lists %>'),
	templateContext() {		
		let lists = _.pluck(this.model.get('realtyLists') || [], 'name').join('; ');
		!lists && (lists = 'нет');
		return {
			lists
		};
	}
});


export const RealtyItemCard = CardItemView.extend({
	className: 'realty-card',
	modelView: RealtyItemView,
	modelViewOptions(){
		return {
			selectButton: this.isSelectable(),
		};
	},
	lines: () => ([
		v => new ListItemView({ 
			model: v.model, 
			modelView: ListsView, 
			//modelViewOptions: { label: v.model.getLandingUrl(), url: v.model.getLandingUrl() } 
		}),		
		v => new ListItemView({ 
			model: v.model, 
			modelView: UrlView, 
			modelViewOptions: { label: v.model.getLandingUrl(), url: v.model.getLandingUrl() } 
		}),
		v => new ListItemView({ 
			model: v.model, 
			modelView: UrlView, 
			modelViewOptions: { label: 'редактировать в nrg', url: getRealtyEditUrl(v.model.id), oldurl: v.model.getNRGUrl() } 
		}),
	]),
});

export const RealtiesView = CollectionView.extend({
	childView: RealtyItemView
});

