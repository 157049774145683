import './api-config';
// import _ from 'underscore';

// import { Collection, ManagedCollection } from 'base';
import { CardModel, /*ActionModel*/ } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';
import { paths } from 'helpers';
import schema from './newsModelSchema';

// import { createSchemaNotifies } from 'components/notifier';
// import { action, ActionStore } from 'components/actions';
//import { modals } from 'bbmn-components';
//import { EditModel } from 'bbmn-controls';
// import ModelStore from 'backbone.store';
// import editor from 'components/editor';
// import { enums, getByPath, isEmptyValue } from 'bbmn-utils';


export const NewsModel = CardModel.extend({
	urlRoot: paths.api('news'),
	cardUrlRoot: paths.url('news'),
	getThumbnail() {
		const rlt = this.getRealty();
		if (!rlt) { return; }
		return rlt.thumbnail;
	},
	getRealty() {
		return this.get('realty');
	},

});


ModelSchemas.initialize(NewsModel, schema);