import { CollectionView } from 'base';
import { ModelCoreView, ListItemView, AtomTextView } from 'components/views';
import { switcherInverse } from 'controls/boolean-switch';
import { paths } from 'helpers';
import { Offers } from 'mod/realties/offers/models';
import { comparator } from 'bbmn-utils';


const OfferCoreView = ModelCoreView.extend({
	topText: m => m.getPurposesNames() || 'ошибка: не указано назначение помещения!',
	text: m => m.getShortHumanId(),
});

const ShortOfferView = ListItemView.extend({
	className: 'ad-offer',
	modelView: OfferCoreView,
	cssClassModifiers:[
		(m,v) => v.isDisabled() ? 'disabled' : ''
	],
	leftItems: [
		(m, v) => switcherInverse(v.isDisabled(), function(value){ 
			v.trigger('toggle:disabled', value, m); 
		}),
	],
	rightItems: m => {
		let { primary, secondary } = m.getPrices();
		return [
			new AtomTextView({ text: primary.fullValue.toLocaleString('ru'), bottomText: primary.label }),
			new AtomTextView({ text: secondary.fullValue.toLocaleString('ru'), bottomText: secondary.label }),
		];
	},
	isDisabled(){
		let ad = this.getOption('ad');
		let feed = ad.getFeed();
		if (!feed) return false;
		if(!ad) return false;

		return ad.isOfferDisabled(this.model.id);
	}
});

export const AdOffers = CollectionView.extend({
	initialize(){
		let realtyId = this.model.get('realty.id');
		this.collection = new Offers();
		this.collection.url = paths.api('realties:realties', realtyId, 'offers');
		this.collection.fetch();
	},
	isOfferInList(id){
		let ids = this.model.getAllOffersIds();
		return ids.indexOf(id) > -1;
	},
	childView: ShortOfferView,
	childViewOptions(){
		return {
			feed: this.model.getFeed(),
			ad: this.model,
		};
	},
	viewFilter(v){
		return this.isOfferInList(v.model && v.model.id);
	},
	viewComparator(v1, v2){
		let cmpval = comparator(
			[v1, v2, m => !!m && this.model.isOfferDisabled(m.id) ? 1 : 0 ],
			[v1, v2, m => m && m.getSquare()]
		);
		return cmpval;
	},
	childViewEvents:{
		'toggle:disabled'(value, offer){
			this.model.toggleOffer(value, offer);
		}
	}
});
