import _ from 'underscore';
import domState from '../dom-state';
import { hasFlag } from 'bbmn-utils';
import { View, CollectionView, Collection, Model } from 'base';
import { openUrl, url } from 'helpers';
import { historyWatcher } from 'bbmn-routing';
import { notifies } from 'components/notifier';
import { user } from 'app/context';
import svg from './logo-cut.svg';
//console.log('SVG', svg);
const items = [
	{
		id:'logo',
		placement: 'top',
		view:{			
			className: 'logo',			
			template: _.template('<img src="' + svg + '" />'),
			events:{
				'click'(){
					openUrl('');
				}
			}
		},
	},
	{		
		id:'sidebar',
		placement: 'top, bottom',
		button:true,
		label:'меню',
		onClick: () => domState.toggle('sidebar-on')	
	},
	{
		id:'go-back',
		placement: 'top, bottom',
		button:true,
		label:'назад',
		filter(){
			return historyWatcher.canGoBack();
		},
		onClick: () => historyWatcher.goBack()
	},
	{
		id:'header',
		label:'',
		placement: 'top',
	},
	{
		id:'notifies',
		placement: 'top, bottom',
		button: true,
		visible: 'auth, anonym',
		label: () => notifies.getCountAsText(),
		listeners: [
			v => v.listenTo(notifies, 'update change reset', () => v.render())
		],
		states: [
			() => notifies.hasNotViewed() ? 'not-viewed' : ''
		],
		onClick: () => notifies.toggle()
	},
	{
		id:'login',
		placement: 'top, bottom',
		button:true,
		label:'войти',
		visible: 'anonym',
		onClick: (model, event) => openUrl('acc/login', event)
	},
	{
		id:'user',
		placement: 'top, bottom',
		button:true,
		label:() => {
			let name = user.get('name');
			if (!name) {
				return '***';
			}

			let chunks = name.split(/\s|\.|@/g);
			if(chunks.length == 1 && chunks[0]){
				return chunks[0];
			}
			name = ((chunks[0] || ' ')[0] + (chunks[1] || ' ')[0]).trim().toUpperCase();
			!name && (name = '***');
			
			return name;
		},
		visible: 'auth',
		onClick: (model, event) => openUrl('acc', event)
	}
];

const HeaderItem = Model.extend({
	isButton(){
		return !!this.get('button');
	},
	canBePlaced(flag){
		let placement = this.get('placement') || 'top, bottom';
		return hasFlag(placement, flag);
	},
	visibleFor(flag){
		if(!this.isButton()) {
			return true;
		}
		let visible = this.get('visible');
		return (visible == null) || hasFlag(visible, flag);
	},
	filter(){
		let fn = this.get('filter');
		return !_.isFunction(fn) || fn();
	}
});

const collection = new Collection(items, { model: HeaderItem });

const ButtonView = View.extend({
	tagName: 'button',
	template: _.template('<i></i><%if(label){%><b><%= label %></b><%}%>'),
	initialize(){
		let onClick = this.model.get('onClick');
		if(_.isFunction(onClick)) {
			this.onClick = onClick.bind(this);
		}
		let listeners = this.model.get('listeners');
		if (_.isArray(listeners)) {
			_.each(listeners, (setListener) => {
				if(_.isFunction(setListener)){
					setListener.call(this, this);
				}
			});
		}

		let states = this.model.get('states');
		if (_.isArray(states)) {
			_.each(states, (modifier) => {
				if(_.isFunction(modifier)){
					this.addCssClassModifier(modifier);
				}
			});
		}
	},
	events:{
		'click'(event){ this.triggerMethod('click', this.model, event); }
	},
	cssClassModifiers:[
		(m) => m.id
	],
	templateContext(){
		let label = this.model.get('label');
		if(_.isFunction(label)) {
			label = label();
		}
		return {
			label
		};
	},
});

const BreadCrumb = View.extend({
	tagName:'a',
	template: json => json.label,
	// initialize() {
	// 	console.log('BREADCRUMB', this.model.attributes);
	// },
	attributes(){
		let href = url(this.model.get('url'));
		return {
			href
		};
	}
});

const HeaderLabelView = View.extend({
	className:'header',
	template: _.template('<h1></h1><nav></nav>'),
	regions:{
		'header':'h1',
		'nav':'nav'
	},
	showPage(page, ac){
		let label = page.getLabel();
		let header = new View({ tagName: 'h1', template: () => label });
		this.showChildView('header', header, { replaceElement: true });
		// debugger;
		let data = page.getData(ac);
		let hashes = page.getHashes(data);
		const xxx = hashes.path.map(h => Object.assign({}, h));
		//console.warn('hashesh', xxx);
		var path = _.clone(hashes.path);
		if (!hashes.isParentHash) {
			path.pop();
		}
		//console.log('>>> hashes', hashes);

		let collection = new Collection(path);
		let crumbs = new CollectionView({
			tagName:'nav',
			emptyView: false,
			collection,
			childView: BreadCrumb
		});
		this.showChildView('nav', crumbs, { replaceElement: true });
	}
});

const HeaderView = CollectionView.extend({
	initialize(){
		this.listenTo(user, 'changed', () => {
			if(this.isRendered()){
				this.render();
			}
		});
	},
	tagName:'header',
	childView(model){
		if(model.id === 'header') {
			return HeaderLabelView;
		}else if(model.get('button')){
			return ButtonView;
		} else if(_.isObject(model.get('view'))) {
			return View.extend(model.get('view'));
		} else {
			return View.extend({ template:() => 'something' });
		}
	},
	collection,
	canBePlaced(v){
		let flag = this.getOption('placement') || 'top';
		return v.model.canBePlaced(flag);
	},
	isVisible(v){
		return v.model.visibleFor(user.getState());
	},
	viewFilter(v){
		let modelFiltered = !v.model || (v.model.filter());
		return modelFiltered && this.canBePlaced(v) && this.isVisible(v);
	},
	showPage(page, ac){
		this.sort();
		let model = this.collection.get('header');
		let view = this.children.findByModel(model);
		view && view.showPage(page, ac);
	}
});

const HeaderLayout = View.extend({
	tagName: 'header',
	template: _.template('<header></header><footer></footer>'),
	regions:{ header:'header', footer:'footer' },
	onRender(){
		this.headerView = new HeaderView();
		this.showChildView('header', this.headerView, { replaceElement: true });
		this.footerView = new HeaderView({ tagName: 'footer', placement: 'bottom' });
		this.showChildView('footer', this.footerView, { replaceElement: true });
	},
	showPage(page, ac){
		this.headerView.showPage(page, ac);
		this.footerView.showPage(page, ac);
	}
});

export default HeaderLayout;
