import { CollectionView } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';

import { busViews } from 'bus';

const BaseRoleItemView = ModelCoreView.extend({
	topText: m => m.display('id'),
	text: m => m.get('name'),
});

export const RoleItemView = ListItemView.extend({
	className:'realtylist-item',
	optionsButton: true,
	modelView: BaseRoleItemView,
});

busViews.class('RoleItemView', RoleItemView);

export const RolesView = CollectionView.extend({
	childView: RoleItemView
});
