import _ from 'underscore';
import { clone } from 'bbmn-utils';
import { Collection } from 'base';
import { busModels } from 'bus';
import { enums } from 'bbmn-utils';
import { ActionStore, action } from 'components/actions';
import { modals, PropertySchema } from 'bbmn-components';
import { EditProperty } from 'bbmn-controls';
import { ActionModel } from 'mod/model-mixins';


export const CianAd = ActionModel.extend({
	initialize(){
		this.initializePromotions();
	},
	getPhone({ display = false} = {}){
		let phones = this.get('phones') || [];
		let phone = _.first(phones);
		if(!display) return phone;
		let number = (phone.number || '');
		return `${phone.countryCode} ${number.substring(0,3)} ${number.substring(3)}`;
	},
	getFeed(){
		let Feed = busModels.getClass('Feed');
		return new Feed({ id: this.get('feedId') });
	},
	isEdited(feed){
		!feed && (feed = this.getFeed());
		return feed && feed.isRedefinedDescription(this.id);
	},
	hasAuction(){
		return this.get('auction.bet') > 0;
	},
	isTaked(){
		return this.get('skiped') != true;
	},
	isExcluded(feed){
		!feed && (feed = this.getFeed());

		let items = feed.get('skiped') || [];
		return items.indexOf(this.id) > -1;
	},
	isIncluded(feed){
		!feed && (feed = this.getFeed());

		let items = feed.get('taked') || [];
		return items.indexOf(this.id) > -1;
	},
	isValid(){
		return this.get('isValid') != false;
	},
	isPromoted(){
		let promos = this.get('jsonPromotions') || {};
		return _.some(promos, (promo) => {
			return promo.state;
		});
	},
	getPriceLabel(){
		return enums.get('bargainPriceTypes', this.get('bargainTerms.priceType'));
	},
	getPriceString(){
		return this.get('bargainTerms.price');
	},
	getMinArea(){
		return this.get('minArea') || '';
	},
	getVatType(){
		return enums.get('vatTypes', this.get('bargainTerms.vatType'));		
	},
	getBcClass(){
		return enums.get('buildingClasses', this.get('building.classType'));				
	},
	getCategory(){
		return enums.get('cianObjectCategories', this.get('category'));		
	},
	getPhotos() {
		let arr = (this.get('photos') || []).sort((a,b) => {
			let aa = a.isDefault ? 1 : 0;
			let bb = b.isDefault ? 1 : 0;
			return bb - aa;
		});
		return arr;
	},
	initializePromotions(){
		if(this.backendPromotions) return;

		let enumName = 'servicesEnum';

		let promoenums = enums.get(enumName);
		let defs = _.reduce(promoenums, (memo, label, id) => {
			memo[id] = {
				state: false,
				type:'auto'
			};
			return memo;
		},{});

		let modelPromos = this.get('jsonPromotions') || {};
		_.defaults(modelPromos, defs);
		if(!this.backendPromotions){
			this.backendPromotions = clone(modelPromos);
		}
		this.set('jsonPromotions', modelPromos, { silent: true});		
	},
	getPromos(){
		// есть два енума
		// PromotionEnums - мой, ограниченный
		// ServicesEnum - циановский полный.
		let enumName = 'servicesEnum';

		let modelPromotions = this.get('jsonPromotions');

		let models = _.reduce(modelPromotions, (memo, promo, id) => {

			if (!promo.state && !promo.wasAutoAllowed && !promo.wasDisallowed && !promo.wasAllowed)
				return memo;

			let model = {
				id,
				name: enums.get(enumName, id),
				state: promo.state,
				type: promo.type,
				add:'авто ',
			};
			

			model.manual = model.type == 'manual';
			if(model.manual) {
				model.state && (model.add = 'принудительно ');
				!model.state && (model.add = 'запрет ');
			}
			//return model;
			memo.push(model);
			return memo;
		}, []);

		return models;

	},

	_storeBackendPromo(){
		if (!this._backendPromo) {
			this._backendPromo = clone(this.get('jsonPromotions')) || {};
		}
	},
	addPromo(promoId){
		this._storeBackendPromo();

		let promotions = clone(this.get('jsonPromotions'));
		let promo = promotions[promoId];
		if(!promo) {
			promo = {};
			promotions[promoId] = promo;
		}
		promo.state = true;
		promo.type = 'manual';
		this.once('change', () => console.log('YEEEEEAH'));
		this.set('jsonPromotions', promotions);
		//console.log('* ', this.isPromoted());
	},
	removePromo(promoId, feed, opts){
		this._storeBackendPromo();

		!feed && (feed = this.getFeed());
		let backvalue = this._backendPromo[promoId] || {};


		let promotions = clone(this.get('jsonPromotions')) || {};
		if (promotions[promoId]) {
			if (!promotions[promoId].state && promotions[promoId].wasDisallowed) {
				promotions[promoId].wasDisallowed = false;
				promotions[promoId].state = backvalue.wasAutoAllowed;
				promotions[promoId].type = 'auto';
			} else if (promotions[promoId].state && promotions[promoId].type == 'auto') {
				promotions[promoId].wasDisallowed = true;
				promotions[promoId].state = false;
				promotions[promoId].type = 'manual';
			} else if (promotions[promoId].state && promotions[promoId].type == 'manual') {
				promotions[promoId].wasAllowed = true;
				promotions[promoId].state = backvalue.wasAutoAllowed;
				promotions[promoId].type = 'auto';

			}

		}
		this.set('jsonPromotions', promotions);
	},
	setPromo(promoId){
		let pt = _.clone(this.get('publishTerms')) || {};
		!pt.terms && (pt.terms = []);
		!pt.terms[0] && (pt.terms.push({ services: [] }));
		let data = pt.terms[0].services;
		if (data.indexOf(promoId) == -1) {
			data.push(promoId);
		}
		this.set('publishTerms', data);
	},
	setAuction(val){
		let auction = _.clone(this.get('auction')) || {};

		if(val == null || val === '')
			auction = null;
		
		val = parseFloat(val, 10);
		if(_.isNumber(val) && !isNaN(val))
			auction.bet = val;

		this.set('auction', auction);
	},
	getAuctionBet(){
		return this.get('auction.bet');
	},
	toggleOffer(value, offer, feed) {
		let custom = feed.getCustom(this.id);
		let excludedOffers = _.clone(custom.excludedOffers) || [];
		if(value) {
			excludedOffers.push(offer.id);
		}
		else {
			excludedOffers = _.filter(excludedOffers, o => offer.id != o);
		}
		custom.excludedOffers = excludedOffers;
		feed.setCustom(this.id, custom);
		offer.trigger('change', offer);
	},
	isOfferDisabled(id, feed){
		!feed && (feed = this.getFeed());
		let custom = feed.getCustom(this.id);
		if(!custom) return false;
		let exc = custom.excludedOffers || [];
		return exc.indexOf(id) > -1;
	},
});

ActionStore.initialize(CianAd, [

	//ready
	action('edit','редактировать',function(e, opts = {}){ 
		let feed = opts.feed;
		let id = this.id;
		let model = this;
		let custom = feed.getCustom(id);

		let description = custom.description || model.get('descriptionTemplate');
		let modelView = opts.view;
		let schema = new PropertySchema({
			property:{
				value:{
					type:'text',
					control:'textarea',
				},
			}
		});
		let content = new EditProperty({
			schema,
			value: description,
		});
		modals.show({
			header: 'редактирование объявления',
			content,
			promiseBar: true,
			onResolve(view){
				let value = view.getControlValue();
				custom.description = value;
				if(!value)
					custom.description = null;

				feed.setCustom(id, custom);
				modelView.updateDescription(value);
			}
		});

	}, { export: 'editFeeds'}),

	//ready
	action('include','принудительно включить',function(e, opts = {}){ 

		opts.feed.includeAd(this, true);
		opts.view.render();

	}, { export: 'editFeeds'}),


	//ready
	action('cancel:include','отменить принудительное включение',function(e, opts = {}){
		
		opts.feed.includeAd(this, false);
		opts.view.render();

	}, { export: 'editFeeds'}),

	//ready
	action('exclude','исключить',function(e, opts = {}){
		opts.feed.excludeAd(this, true);
		opts.view.render();
	}, { export: 'editFeeds'}),

	//ready
	action('cancel:exclude','отменить исключение',function(e, opts){
		opts.feed.excludeAd(this, false);
		opts.view.render();
	}, { export: 'editFeeds'}),



	//ready
	action('edit:auction','аукцион',function(e, opts = {}){ 
		
		let feed = opts.feed;
		let model = this;

		let content = new EditProperty({
			schema: new PropertySchema({
				property:{
					value:{
						type:'number',
					}
				}
			}),
			value: model.get('auction.bet'),
		});
		modals.show({
			header: 'редактирование объявления',
			content,
			promiseBar: true,
			onResolve(view){
				let value = view.getControlValue();
				feed.setAuction(model, value);
			}
		});

	}, { export: 'managePromotions'}),


	//ready
	action('promote','продвинуть',function(e, opts){

		let feed = opts.feed;
		let id = this.id;
		let model = this;
		let view = opts.view;
		let schema = new PropertySchema({
			property:{
				value:{
					type:'enum',
					sourceValues: enums.get('promotionEnums'),
				},
			}
		});
		let content = new EditProperty({
			schema,
			onControlDone(promoId){

				feed.setPromo({
					applied: true,
					promoId,
					id
				});
				model.addPromo(promoId);
				view.render();
				this.trigger('done');
			}
		});
		modals.show({
			header: 'выберите продвижение',
			content,
		});		
	}, { export: 'managePromotions'}),


	//ready
	action('remove:disallow:promo','снять запрет продвижения',function(e, opts){

		let feed = opts.feed;
		let promoId = opts.promoId;
		let type = opts.type;
		let model = this;
		let view = opts.view;	

		if(type == 'manual'){
			feed.setPromo({
				remove: true,
				disallowed: true,
				promoId,
				id: model.id
			});
			model.removePromo(promoId, feed, { disallowed: true });
			view.render();
		} 

	}, { export: 'managePromotions'}),


	//ready
	action('apply:promo','закрепить продвижение',function(e, opts = {}){ 

		let feed = opts.feed;
		let promoId = opts.promoId;
		let type = opts.type;
		let model = this;
		let view = opts.view;		

		if(type != 'manual'){

			feed.setPromo({
				applied: true,
				promoId,
				id: model.id
			});
			model.addPromo(promoId);
			view.render();
		}

	}, { export: 'managePromotions'}),

	//ready
	action('remove:promotion','отменить продвижение',function(e, opts = {}){

		let feed = opts.feed;
		let promoId = opts.promoId;
		let type = opts.type;
		let model = this;
		let view = opts.view;

		if(type != 'manual'){
			feed.setPromo({
				disallowed: true,
				promoId,
				id: model.id
			});
			model.removePromo(promoId, feed);
			view.render();
		} else {
			feed.setPromo({
				remove: true,
				applied: true,
				promoId,
				id: model.id
			});
			model.removePromo(promoId, feed, { applied: true });
			view.render();
		}

	}, { export: 'managePromotions'}),

]);

export const CianAds = Collection.extend({
	model: CianAd
});


