import _ from 'underscore';
import { BaseClass, Events } from 'bbmn-core';
import { mix } from 'bbmn-utils';
import { Collection } from 'base';
const BaseGrouper = mix(BaseClass).with(Events);

const Grouper = BaseGrouper.extend({
	constructor: function({ collection, groups } = {}){
		BaseGrouper.apply(this, arguments);
		this.groups = {};
		this.collection = collection;
		this.createGroups(groups);
		this.initializeBaseListeners();
		this.processAllModels();
	},
	createGroups(groups){
		_.each(groups, (filter, id) => {
			let collection = new Collection();
			let group = { id, filter, collection };
			if (!_.isFunction(filter) && _.isObject(filter)) {
				group = _.extend({}, { id }, filter, { collection });
			}
			this.groups[group.id] = group;
		});
	},
	initializeBaseListeners(){
		this.listenTo(this.collection, 'update reset', this.processAllModels);
		this.listenTo(this.collection, 'change', this.processOneModel);
	},
	processOneModel(model){
		_.each(this.groups, group => {
			if(group.filter(model)){
				group.collection.add(model);
			} else {
				group.collection.remove(model);
			}
		});
	},
	processAllModels(){
		let models = {};
		let groups = this.groups;
		let groupsArr = _.map(groups, group => {
			models[group.id] = [];
			return group;
		});
		let length = groupsArr.length;
		this.collection.each(model => {
			for(let x = 0;x < length;x++){
				let group = groupsArr[x];
				if(group.filter(model))
					models[group.id].push(model);
			}
		});
		_.each(models, (items, id) => {
			groups[id].collection.set(items);
		});
	},
	get(id){
		let group = this.getGroup(id);
		return group && group.collection;
	},
	getGroup(id){
		return this.groups[id];
	}
});

export default Grouper;
