import { CollectionView } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';
import editor from 'components/editor';

import user from 'app/user';
const CoreSubagentView = ModelCoreView.extend({
	//topText: m => m.display('id'),
	text: m => m.display('email')
});

export const FeedSubagentListItem = ListItemView.extend({
	getFeed(){
		return this.getOption('feed');
	},
	modelView: CoreSubagentView,
	editButton(){
		return user.checkRights({ export: 'editFeeds' });
	},
	removeButton(){
		return this._isModelView && user.checkRights({ export: 'editFeeds' });
	},
	onEditClick(){
		let model = this.model;
		console.log('EDIT', model.getSchema());
		let _this = this;
		editor.edit({
			schema: model.getSchema(),
			value: model.toJSON(),
			propertiesToShow: null,
			onResolve(values) {
				model.set(values);
				_this.updateSettings();
			}
		}, 'Изменение субагента');
	},
	onRemoveClick() {
		let col = this.model.collection;
		col && col.remove(this.model);
		this.updateSettings();
	},
	updateSettings() {
		let feed = this.getFeed();
		let settings = feed.entity('settings');
		feed.save({ settings: settings.toJSON() });
	}
});

export const FeedSubagentsList = CollectionView.extend({
	renderCollection: true,
	childView: FeedSubagentListItem,
	emptyView: null,
	initialize(){
		//this.collection = this.model.entity('subagents');
		//this.once('customs:render', this.sort);
	},
	viewComparator(v1,v2) {
		return v1._index - v2._index;
	},
	childViewOptions(){
		return {
			feed: this.collection.parent.parent
		};
	},
});
