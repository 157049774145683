import _ from 'underscore';
import { CollectionView, View, Collection } from 'base';
import { ListItemView, ModelCoreView, ButtonView } from 'components/views';

export const categoriesNames = {
	Realty: 'недвижимость',
	RealtyObject: 'площадь',
	RealtyOffer: 'предложение',
	Lists: 'списки'
};



const BaseFilterItemView = ModelCoreView.extend({
	topText: m => m.display('name'),
	text: m => m.display('value'),
});

export const FilterItemView = ListItemView.extend({
	cssClassModifiers:[
		m => m.isFilled() ? 'filled' : '',
		m => m.id,
	],
	className:'filter-item',
	modelView: BaseFilterItemView,
	rejectButton: true,
	mainAction:'edit',
	onRejectClick(){
		this.model.setValues();
	}
});

export const FiltersListView = CollectionView.extend({
	constructor: function(opts){
		CollectionView.apply(this, arguments);
		this.mergeOptions(opts, ['filterer']);
		//console.log('list >', this.collection.models);
	},
	className: 'filters-list',
	childView: FilterItemView,
	collectionEvents:{
		'replace:filter'(newFilter){
			this.setFilter(newFilter);
		}
	},
});

const FiltersButtons = CollectionView.extend({
	className: 'filters-buttons',
	renderCollection: false,
	renderAllCustoms: true,
	getCustoms(){
		return [
			{ view: ButtonView, options: { name:'pined' }},
			{ view: ButtonView, options: { name:'grouped' }},
			{ view: ButtonView, options: { name:'list' }},
			//{ view: ButtonView, options: { name:'all' }},
			{ view: ButtonView, options: { name:'clear' }},
		];
	},
	customViewOptions(){
		return {
			rightIcon: false,
			forceText: false
		};
	},
	childViewEvents:{
		'click'(data, options = {}){
			this.triggerMethod('click:' + options.name || 'default', data);
		}
	}
});

export const FiltersGroupView = View.extend({
	className: 'filters-group',
	template: _.template('<header><%= name %></header><section></section>'),
	templateContext(){
		let name = this.model.id;
		if(name == 'undefined') {
			name = 'общие характеристики';
		}
		return {
			name
		};
	},		
	regions:{
		items: 'section'
	},

	onRender(){
		let itemsView = new FiltersListView({
			collection: new Collection(this.model.get('items'), { model: this.getOption('FilterItemModel') }),
			filterer: this.getOption('filterer'),
		});
		this.showChildView('items', itemsView);
	},
	triggers: {
		'click > header':'toggle:collapse'
	}

});

export const FiltersGroupsView = CollectionView.extend({
	className: 'filters-groups',
	childView: FiltersGroupView,
	viewComparator(v) {
		switch(v.model.id){
		case 'undefined': return '';
		default: v.model.id;
		}
	},
	childViewOptions(){
		return _.pick(this.options, 'filterer', 'itemsCollection', 'FilterItemModel');
	},
	childViewEvents:{
		'toggle:collapse'(view){
			view.$el.toggleClass('un-collapsed');
			// if (this.uncollapsed) {
			// 	this.uncollapsed.$el.removeClass('un-collapsed');
			// }
			// if(this.uncollapsed != view) {
			// 	this.uncollapsed = view;
			// 	this.uncollapsed.$el.addClass('un-collapsed');
			// }
		}
	}	
});

export const FiltersCategoryView = View.extend({
	className: 'filters-category',
	template: _.template('<header><%= name %></header><section></section>'),
	templateContext(){
		console.log('FLTR>>', this.model.toJSON());
		return {
			name: categoriesNames[this.model.id] || this.model.get('group') || 'Без категории',
		};
	},	
	regions:{
		items: 'section'
	},
	onRender(){
		let groups = this.model.get('groups') || [];
		let itemsView;
		let opts = o => _.defaults(o, _.pick(this.options, 'filterer', 'itemsCollection', 'FilterItemModel'));
		if (groups.length > 1) {
			itemsView = new FiltersGroupsView(opts({
				collection: new Collection(groups),				
			}));
		} else {
			itemsView = new FiltersListView(opts({
				collection: new Collection(groups[0].items, { model: this.getOption('FilterItemModel') }),				
			}));
		}
		this.showChildView('items', itemsView);
	},
	triggers:{
		'click > header':'toggle:collapse'
	}
});

export const FiltersCategoriesView = CollectionView.extend({
	className: 'filters-categories',
	childView: FiltersCategoryView,
	childViewOptions(){
		return _.pick(this.options, 'filterer', 'itemsCollection', 'FilterItemModel');
	},
	viewComparator(v) {
		switch(v.model.id){
		case 'undefined': return '';
		default: v.model.id;
		}
	},
	childViewEvents:{
		'toggle:collapse'(view){
			view.$el.toggleClass('un-collapsed');
			// if (this.uncollapsed) {
			// 	this.uncollapsed.$el.removeClass('un-collapsed');
			// }
			// if(this.uncollapsed != view) {
			// 	this.uncollapsed = view;
			// 	this.uncollapsed.$el.addClass('un-collapsed');
			// }
		}
	}
});

const CounterView = View.extend({
	className: 'filters-count',
	template: _.template('<div><i></i><span>нашлось: <%= count %></span></div>'),
	templateContext() {
		return {
			count: this.count
		};
	},
	initialize() {
		let filterer = this.getOption('filterer');
		this.listenTo(filterer, 'after:filter', view => {
			this.count = view.paginator.get('entries');
			this.render();
		});
	},
});

export const FiltersView = CollectionView.extend({
	className: 'filters-container',
	renderCollection: false,
	renderAllCustoms: true,
	filtersViewType: 'categories',
	initialize(){
		let filterer = this.getOption('filterer');
		if (!filterer.isBackend()) {
			this.filtersViewType = 'list';
		}
	},
	getCustoms(){
		let type = this.filtersViewType;
		return [
			FiltersButtons,
			new CounterView({ filterer: this.getOption('filterer') }),
			() => {
				switch(type){
				case 'categories':
					return FiltersCategoriesView;
				default:
					return FiltersListView;
				}
			}
		];
	},
	//customs: [FiltersButtons, FiltersListView],
	getCategoriesCollection(){
		let col = this.getCollection();
		let categories = col.groupBy(m => m.category);
		let models = _.map(categories, (groups, id) => {
			let grp = _.groupBy(groups, m => m.group);
			return {
				id,
				groups: _.map(grp, (items, id) => ({ id, items }))
			};
		});
		return new Collection(models);
	},
	customViewOptions(){
		return {
			model: this.model,
			itemsCollection: this.getCollection(),
			collection: this.filtersViewType == 'categories' ? this.getCategoriesCollection() : this.getCollection(),
			filterer: this.getOption('filterer'),
			FilterItemModel: this.getCollection().model,
			viewFilter: this.filtersViewType == 'pinned' ? v => v.model.isPined() : undefined
		};
	},
	childViewEvents:{
		'click:pined'(){
			this.filtersViewType = 'pinned';
			this.render();
			//this.triggerOnCollection('replace:filter', v => v.model.isPined());
		},
		'click:list'(){
			this.filtersViewType = 'list';
			this.render();
			//this.triggerOnCollection('replace:filter', null);
		},
		'click:grouped'(){
			this.filtersViewType = 'categories';
			this.render();
			//this.triggerOnCollection('replace:filter', null);
		},		
		'click:clear'(){
			this.getOption('filterer').resetValues();
		},
	},
	triggerOnCollection(event, ...args){
		this.getCollection().trigger(event, ...args);
	}
});
