import { CollectionView } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';
import { buildActionsListView } from 'components/actions/views';



const BaseStateView = ModelCoreView.extend({
	text: m => m.get('count') || 0,
});

const StateView = ListItemView.extend({
	className:'selector-state',
	modelView: BaseStateView,
	leftButtons:[
		{name:'take-a-look'},
	],
	leftItems:['отобрано:'],
	removeButton: true,
	onRemoveClick(){
		this.model.clear();
	}
});


export const SelectorState = CollectionView.extend({
	className: 'selector-details',
	renderAllCustoms: true,
	constructor: function(options){
		CollectionView.apply(this, arguments);
		this.mergeOptions(options, 'selector');
	},
	getCustoms(){
		let actions = buildActionsListView(null, { actions: this.selector.actions });
		return [
			new StateView({ model: this.selector.model }),
			actions,
		];
	}
});
