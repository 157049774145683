import _ from 'underscore';
import { View, CollectionView, Collection } from 'base';

const _replaces = {
	'bc-address-full': 'полный адрес',
	'bc-class': 'класс бизнес центра',
	'bc-name': 'название БЦ',
	'bc-prefix-long': 'префикс Бизнес центра',
	'nds-presence': ' включая НДС (если ндс есть)',
	'of-class-word': 'класса',
	'of-market': 'офисных/складских/..',
	'of-square': 'помещения/помещений',
	'operation': 'название операции',
	'photo-warning': 'ВНИМАНИЕ! В объявлении представлены общие фотографии здания и стандартного помещения.Фото и планировку по данному блоку запрашивайте дополнительно.',
	'price-range': 'от XXX до XXX руб. в год',
	'square-max': 'максимальная площадь',
	'square-min': 'минимальная площадь',
	'square-range': 'от XXX до XXX кв. м'
};


const __replaces = {
	"{названиеБц}" : "Lotte plaza",
	"{префиксБц}" : "бизнес-центр",

	"{аренда}" : "аренда | продажа",
	"{помещения}" : "офисного помещеня | складского помещения | апартаментов",
	"{бизнесцентре}" : "бизнес-центре | складском комплексе",
	"{полныйАдрес}" : "Улица, дом, район, город, расстояние до метро",

	"{термин:названиеБц}" : "Название: Lotte plaza",
	"{термин:номерНалоговой}" : "Номер налоговой: 12",
	"{термин:полныйАдрес}" : "Адрес: Улица, дом, город",
	"{термин:метро}" : "Метро: расстояние до метро",
	"{термин:полезнаяПлощадьБц}" : "Полезная площади: 23 000 кв.м",
	"{термин:площадьБц}" : "Полная площадь: 40 000 кв. м",
	"{термин:классБц}" : "Класс: А",
	"{термин:годПостройки}" : "Год постройки: 2002",
	"{термин:годРеконструкции}" : "Год реконструкции: 2010",

	"{термин:площадьПомещения}" : "Площадь помещения: 534 кв.м",
	"{термин:помещениеДелениеОт}" : "Деление от: 50 кв.м",
	"{термин:стоимостьМетра}" : "Стоимость метра: 25 000 р. включая ндс",
	"{термин:налогообложение}" : "Налогообложение: НДС",
	"{термин:размерДепозита}" : "1 месяц",
	"{термин:планировкаПомещения}" : "Планировка помещения: смешанная",
	"{термин:состояниеПомещения}" : "Состояние помещения: готово к въезду",
}



const replaces = {
"{ПрефиксБц}": "бизнес-центр",
"{названиеБц}": "Lotte plaza",
"{классБц}": "B+",
"{термин:площадьПомещения}": "Площадь - 254 м2",
"{термин:стоимостьМетра}": "Арендная ставка - 18 000 р. за м2 в год, включая ндс",
"{термин:стоимостьПлощади}": "Стоимость площади в месяц - 381 000 р., включая ндс",
"{термин:встоимостьВключено}": "В стоимость включено - ...",
"{термин:встоимостьНеВключено}": "Оплачивается отдельно - ...",
"{термин:депозит}": "Депозит - 381 000 р., включая ндс",
"{термин:налогообложение}": "Налогообложение - НДС",
"{термин:этаж}": "Этаж - 3",
"{термин:планировкаПомещения}": "Планировка - кабинетная",
"{термин:кабинетовПомещения}": "Кабинетов - 3",
"{термин:готовностьПомещения}": "Готовность - готов к въезду",
"{термин:статусПомещения}": "Статус - свободен",
"{термин:высотаПотолковПомещения}": "Высота потолков, h - 3м",
"{термин:элМощностьПомещения}": "Выделенная мощность - 100 кВт",
"{термин:санузлыПомещения}": "Наличие с/у - 3шт, На этаже",
"{термин:типДоговора}": "Тип договора - Долгосрочная аренда",
"{термин:срокДоговора}": "Срок договора - 3 года",
"{термин:парковкаПомещения}": "Парковка - 10м/м, платная открытая",
"{термин:окнаПомещения}": "Без окон",
"{термин:отдельныйВходПомещения}": "Отдельный вход",
"{термин:юрАдресПомещения}": "Юридический адрес",
"{термин:полныйАдрес}": "Адрес - Галушкинский проспект, д1с12, Москва. До станции метро Шляпная примерно 789 метров.",
"{термин:адресОкруг}": "Округ - ЦАО",
"{термин:адресРайон}": "Район - Крапотскинский р-н",
"{термин:классЗдания}": "Класс - B+",
"{термин:площадьЗдания}": "Площадь здания - 32 000 м",
"{термин:полезнаяПлощадьЗдания}": "Полезная площадь - 30 000 м",
"{термин:ЭтажностьЗдания}": "Этажность - 16",
"{термин:налоговаяЗдания}": "Номер налоговой - 13",
"{термин:годПостройки}": "Год постройки - 1970",
"{термин:годРеконструкции}": "Год реконструкции - 2000",
"{термин:кондиционированиеЗдания}": "Кондиционирование - центральное",
"{термин:вентиляцияЗдания}": "Вентиляция - приточно-вытяжная",
"{термин:лифт}": "Лифты - 3 пас., 2 груз. (тоннаж 5т.)",
"{термин:электроснабжение}": "Электроснабжение - 800 кВт",
"{термин:телекоммуникации}": "Телекоммуникации - интернет, телефония",
"{термин:провайдеры}": "Провайдеры - Виленд",
"{термин:охрана}": "Охрана - Охрана, Контрольно-пропускной пункт, Сигнализация, Видеонаблюдение, Кодовый замок/Домофон, СКУД (электронные пропуска)",
"{термин:парковкаЗдания}": "Парковка - 1000м/м, Стихийная, Бесплатная открытая",
"{термин:внутренняяИнфраструктура}": "Инфраструктура - Фитнес клуб, Кафе/Ресторан/Столовая",
}

const ReplaceView = View.extend({
	className: 'replacement',
	template:_.template('<div class="id"><%= id %></div><div class="label"><%= label %></div>'),
});

export default CollectionView.extend({
	initialize()
	{
		let models = _.map(replaces, (label, id) => {
			return { id, label };
		});
		this.collection = new Collection(models);
	},
	className: 'replacements',
	childView: ReplaceView
});
