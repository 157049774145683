import { actionableMixin, action, ActionStore } from 'components/actions';
import editor from 'components/editor';
import { ModelSchemas } from 'bbmn-components';
import BasePage from 'pages/auth';
import { OfferLists, OfferList } from 'mod/realties/offer-lists/models';
import Content from './content';

const RealtyListsPage = actionableMixin(BasePage).extend({
	id:'realties-offerLists',
	routes: 'offer-lists',
	label: 'Черные и белые списки предложений',
	Content,
	children:[],
	startPromises: [
		page => page.collection.fetch(),
	],
	initialize(){
		this.collection = new OfferLists();
	},
	actions:[
		action('create:list', 'создать список предложений', function(event, page){

			editor.add({
				schema: ModelSchemas.get(OfferList),
				propertiesToShow: ['name','type'],
				onResolve(values){
					let model = new OfferList();
					model.save(values, { wait: true}).then(() =>{
						page.collection.add(model);
						this.destroy();
					});
				}
			}, 'Добавление нового списка предложений');
	
		}),
	]
});

// ActionStore.initialize(RealtyListsPage, [

// ]);

export default RealtyListsPage;
