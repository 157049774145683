export { Application, MnObject } from 'backbone.marionette';
export { Model, Collection, BaseClass } from 'bbmn-core';

import { history, historyApi } from 'bbmn-routing';

let oldCheckUrl = history.checkUrl;
const checkUrl = function(e){
	var current = this.getFragment(); // If the user pressed the back button, the iframe's hash will have
	current = historyApi.decodeFragment(current);
	// changed and we should use that for comparison.

	if (current === this.fragment && this.iframe) {
		current = this.getHash(this.iframe.contentWindow);
	}

	if (current === this.fragment) return false;
	if (this.iframe) this.navigate(current);
	this.loadUrl();
};
history.checkUrl = checkUrl.bind(history);

