import _ from 'underscore';
import { Action, ActionStore, actionableMixin, action as baseAction } from 'bbmn-components';
import { user } from 'app/context';
import { hasFlag } from 'bbmn-utils';
export * from './popover';

const MyAction = Action.extend({
	onActionMissing(){ console.log('ACTION function missing' , this.name); },
	checkRule(instance) {
		let rule = _.isFunction(this.options.rule) && this.options.rule;
		return !rule || rule.call(instance, instance);
	},
	isNotAllowed () { 
		let rights = this.getOption('rights');
		return !user.checkRights(rights);
	},
	getRights(){
		return this.getOption('rights');
	},
	checkPlacement(placement){
		if (placement == null) {
			return true;
		}
		let placements = this.getOption('placements');
		let hasPlacement = true;
		let declinePlacement = false;
		if (placements != null) {
			hasPlacement = hasFlag(placements, placement);
		}
		let exclude = this.getOption('excludePlacements');
		if(exclude != null) {
			declinePlacement = hasFlag(exclude, placement);
		}
		return hasPlacement && !declinePlacement;
	},

});

ActionStore.Action = MyAction;

ActionStore.filter = function(action, index, options = {}){
	if (options.hidden != true && action.isHidden()) {
		return false;
	}
	const ids = options.in;
	const instance = options.instance;
	if (ids && ids.indexOf(action.name) < 0) return false;
	if (!action.checkRule(instance)) return false;
	if (!action.checkPlacement(options.placement)) {
		return false;
	}
	return user.checkRights(action.getRights());
};

function action(name, label, callback, rights, opts = {}){
	if (_.isArray(rights)){
		opts.rights = parseRights(rights);
	} else if (_.isObject(rights)) {
		opts = _.extend({}, rights, opts);
	}
	return baseAction(name, label, callback, opts);
}

action.hidden = function (name, callback, rights, opts = {}) {
	opts.hidden = true;
	return action(name, name, callback, rights, opts);
};


function parseRights(arr){
	return _.reduce(arr, (memo, item) => {
		let chunks = item.split(':');
		let key = chunks.shift();
		let value = chunks.join('').trim();
		if(value === '') value = true;
		memo[key] = value;
		return memo;
	}, {});
}

export {
	MyAction as Action,
	ActionStore,
	actionableMixin,
	action
};
