import _ from 'underscore';
import { View } from 'base';
const itemTemplate = _.template(`
<span class="item" data-index="<%= index %>" data-key="<%= key %>"><%= label %></span>
`);

const keyLabels = {
	pack: 'шаблон группы объявлений',
	category: 'шаблон категории объявлений',
	feed: 'общий шаблон фида',
	base: 'общий шаблон всех выгрузок',
	ungroupedBase: 'общий шаблон для разгруппированных выгрузок'
};

export const TemplatesView = View.extend({
	className: 'templates-list',
	template:_.template(`
	<div class="items"></div>
	<div class="template-value"></div>
	`),
	ui:{
		list:'.items',
		value: '.template-value',
	},
	onRender(){
		this.showList();
		this.showTemplate();
	},
	getTemplates(){
		if (this._temps != null) {
			return this._temps;
		}
		let templates = this.getOption('templates') || {};
		let index = 0;
		this._temps = _.reduce(templates, (memo, value, key) => {
			let item = { value, key, index, label: keyLabels[key] };
			memo.push(item);
			index++;
			return memo;
		}, []);
		return this._temps;
	},
	showList(){
		let temps = this.getTemplates();

		if (!temps.length) {
			this.ui.list.html('<i>шаблоны не определены</i>');
		} else {
			let html = _.reduce(temps, (memo, item) => {
				let itemHtml = itemTemplate(item);
				memo += itemHtml;
				return memo;
			}, '');
			this.ui.list.html(html);
		}
	},
	showTemplate(template){

		this.$('.items .item').removeClass('active');		

		let temps = this.getTemplates();
		if (template == null) {
			template = _.first(temps);
		} else if (_.isNumber(template)) {
			template = temps[template];
		}

		if (!template) {
			this.ui.value.html('');
		} else {
			this.$(`.items .item[data-key="${template.key}"]`).addClass('active');
			this.ui.value.html(template.value);
		}

	},
	events:{
		'click .items .item'(e){
			var $e = $(e.target).closest('.item');
			var index = $e.data('index');
			let tmps = this.getTemplates();
			this.showTemplate(tmps[index]);
		}
	}
});
