import Page from './auth';
import acc from './acc';
import admin from './admin';
import news from './news';
import realties from './realties';
import _export from './export';
export default Page.extend({
	id:'root',
	routes:'',
	label: 'главная',
	children:[news, realties, _export, admin, acc],
	relativeRoutes: false,
	noAside: true,
});
