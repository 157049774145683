import _ from 'underscore';
import { Model as BaseModel, Collection as BaseCollection } from './vendors';
import { improvedFetchMixin, saveAsPromiseMixin, createAsPromiseMixin, urlPatternMixin } from 'bbmn-mixins';
import { syncWithNotifyMixin, modelSchemaMixin } from 'bbmn-components';
import { mix, getByPath, flat } from 'bbmn-utils';
import { collectionFiltererMixin } from 'components/filterer/collection-filterer-mixin';


const getByPathMixin = Base => Base.extend({
	getByPath: true,
	get(key , {gettingByPath} = {}){
		if (!gettingByPath && this.getByPath) {
			return getByPath(this, ...arguments);
		} else {
			return Base.prototype.get.apply(this, arguments);
		}
	},
});




export const Model = mix(BaseModel).with(getByPathMixin, urlPatternMixin, improvedFetchMixin, saveAsPromiseMixin, syncWithNotifyMixin, modelSchemaMixin).extend({
	defaultWait: true,
	saveReturnPromise: true,
	patchInsteadSave: true,
});



const DataManager = Model.extend({
	constructor: function(){
		Model.apply(this, arguments);
		this.on({
			'change:query':this.triggerQuery,
			'change:fetched': () => this.get('fetched') && this.trigger('fetched')
		});
	},
	triggerQuery(){
		this.trigger('query');
	},
	defaults:{
		take: 100,
	},
	getFetchData({ forQueryString = true, pagesKey = 'pages' } = {}){
		let query = this.get('query');
		let pages = { [pagesKey]: this.getPageData() };
		if (forQueryString) {
			pages = flat(pages);
		}
		return _.extend({}, query, pages);

	},
	changeFounded(val = 0){
		this.set('founded', this.get('founded') + val);
	},
	getPageData(){
		if (!this.get('paged')) return;
		return _.pick(this.attributes, 'skip', 'take');
	},
	isEof(){
		return this.get('skiped') + this.get('returned') >= this.get('founded');
	},
	takeNext(){
		!this.isEof() && this._take(1);
	},
	takePrev(){
		this._take(-1);
	},
	_take(arg){
		if (this.get('fetched')) {
			let skip = this.get('skip') + (this.get('take') * arg);
			skip < 0 && (skip = 0);
			this.set({ skip, fetched: false });
		}
	}
});

export const Collection = mix(BaseCollection).with(urlPatternMixin, improvedFetchMixin, createAsPromiseMixin, syncWithNotifyMixin, collectionFiltererMixin).extend({	
	defaultWait: true,
	createReturnPromise: true,	
});




export const ManagedCollection = Collection.extend({
	constructor: function(data, options){
		this._initializeManager(options);
		Collection.apply(this, arguments);
	},
	_initializeManager(opts = {}){

		let options = _.defaults(
			_.pick(opts, 'skip', 'take','paged'), 
			{ paged: this.paged }, 
			{ paged: true }
		);

		this.manager = new DataManager(options);
		this.listenTo(this.manager, 'query', this.handleQueryChange);
		// this.on('remove', () => this.manager.changeFounded(-1));
		// this.on('add', () => this.manager.changeFounded(1));
	},
	handleQueryChange(opts){
		this.trigger('query:change');
		return this.query(opts);
	},
	parse(data){
		if (data.items) {
			let hash = _.extend({ fetched: true }, _.omit(data, 'items', 'query'));
			this.manager.set(hash);
			return data.items;
		} else {
			return data;
		}
	},
	query(opts){

		let data = this.manager.getFetchData({ forQueryString: this.forQueryString });		
		let queryOptions = { data };
		if( this.fetchMethodName) {
			queryOptions.method = this.fetchMethodName;
			if(this.fetchMethodName == 'POST'){
				queryOptions.contentType = 'application/json; charset=UTF-8';
				if(_.isObject(queryOptions.data)) {
					queryOptions.data = JSON.stringify(queryOptions.data);
				}
			}
		}
		console.log('FetchData::', data);
		return this.fetch(_.extend(queryOptions, opts));
	},
	fetchNext(){
		if (this.manager.isEof()) {
			return Promise.resolve();
		} 
		this.manager.takeNext();
		return this.query({ remove: false, merge: true, add: true });
	}
});
