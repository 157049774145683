import { CollectionView, View } from 'base';
import { buildViewByKey } from 'bbmn-utils';

export default CollectionView.extend({
	constructor: function(){
		CollectionView.apply(this, arguments);
		this.addCssClassModifier('card-item');
		this.clickAction = this.getOption('clickAction', { force: false });
		if (this.clickAction) {
			this.addCssClassModifier('clickable');
			this.delegate('click', e => this.clickAction.call(this, this, e));
		}
	},
	renderCollection: false,
	renderAllCustoms: true,
	addModelView: true,
	getLines() {
		let lines = this.getOption('lines', { args: [ this ]}) || [];
		return lines;
	},
	getModelView() {
		let options = {
			model: this.model,
			collection: this.getCollection()
		};
		let modelView = buildViewByKey(this, 'modelView', { options });
		return modelView;
	},
	getCustoms() {

		let lines = this.getLines();
		let modelView = this.getModelView();

		let views = [
			modelView,
			...lines,
		];

		views = views.filter(f => !!f);
		
		return views;
	},
	isSelectable(){
		return this.getOption('selectable', { args: [this.model, this] }) === true;
	},
	childViewTriggers:{
		'toggle:select':'toggle:select'
	},
});
