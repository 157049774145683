import { View, CollectionView, Collection, Model } from 'base';
import { bus } from '../bus';
import { BusActionButton } from '../BusActionButton';

const FetchBtn = BusActionButton.extend({
	eventName: 'fetch:click',
	text: 'построить и показать таблицу'
});


const AutofillBtn = BusActionButton.extend({
	eventName: 'autofill:click',
	text: 'Распределить добавочные по новым контактам'
});


const ReplaceBtn = BusActionButton.extend({
	eventName: 'replace:asterisk:click',
	text: 'обновить данные на АТС'
});

export default CollectionView.extend({
	className: 'top-actions',
	renderCollection: false,
	renderAllCustoms: true,
	customs: [
		FetchBtn,
		AutofillBtn,
		ReplaceBtn
	]
})