import '../api-config';
import { Collection } from 'base';
import { CardModel, ActionModel } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';
import { paths } from 'helpers';
import { createSchemaNotifies } from 'components/notifier';
import { action, ActionStore } from 'components/actions';
//import { modals } from 'bbmn-components';
//import { EditModel } from 'bbmn-controls';
import ModelStore from 'backbone.store';
import editor from 'components/editor';
import { enums } from 'bbmn-utils';

//import { RuleView } from '../feeds/views/feed-packs';
//import { enums } from 'bbmn-utils';

paths.set('api', 'realties:offer-lists', 'offerlists', { relative: true });
paths.set('url', 'realties:offer-lists', 'offer-lists', { relative: true });

const BaseOfferList = CardModel.extend({
	urlRoot: paths.api('realties:offer-lists'),
	cardUrlRoot: paths.url('realties:offer-lists'),
});
ModelSchemas.initialize(BaseOfferList, {
	name:{
		validation: {
			required:true
		},
		display:{
			label:'Название'
		}
	},
	type: {
		value:{
			type:'enum',
			control:'select:inline',
			sourceValues: () => enums.get('realtyListTypes')
		},
		display:{
			label:'Тип списка'
		}
	}
});
export const OfferList = ModelStore(BaseOfferList);

export const OfferLists = Collection.extend({
	model: OfferList,
	url: paths.api('realties:offer-lists'),
});



