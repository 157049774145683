import { CollectionView } from 'base';
import { OwnerListItem } from './OwnerListItem';

export const OwnersList = CollectionView.extend({
	className: 'ui-list owners-list',
	childView: OwnerListItem,
	childViewOptions() {
		return {
			ownersInfo: this.getOption('ownersInfo')
		}
	},
	viewComparator(v1,v2) {
		const a1 = v1.model.get('objectsCount');
		const a2 = v2.model.get('objectsCount');
		return a2 - a1;
	},
	// initialize() {
	// 	console.log('PROVERKA', this);
	// }
});