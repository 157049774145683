import _ from 'underscore';
export default function xhrMessage(text, xhr){

	if(_.isObject(text)){
		xhr = text;
		text = null;
	}
	if (!xhr)
		return text;
	
	let response = error => (text ? text + '\r\n' : '') + error;
	let json = xhr.responseJSON || {};

	if (json.message) {
		return response(json.message);

	} else if (json.error_description) {
		return response(json.error_description);

	}else if (_.isString(json.error)) {
		return response(json.error);

	}else if (_.isString(xhr.error)){
		return response(xhr.error);
		
	}

	if(xhr.statusText)
		return response(xhr.statusText);

	return text;

}
