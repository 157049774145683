import _ from 'underscore';
import { Model, MnObject } from 'base';
import { camelCase } from 'bbmn-utils';

const StateModel = Model.extend({
	constructor: function(){
		Model.apply(this, arguments);
		this.on('change', this._onChange);
	},
	_invoke(key, ...args) {
		if(key == null) return;
		if(key != '_invoke' && _.isFunction(this[key])){
			return this[key](...args);
		}
	},
	_onChange(){
		this._invoke('onChange', this.changed);
		_.each(this.changed, this._onPropertyChange.bind(this));
	},
	_onPropertyChange(value, key){
		if(!key) return;
		let event = key.toString().replace(/-/g,':');
		this._invoke(camelCase('onChange', event), value, key);
		this._invoke(camelCase('onPropertyChange'), value, key);
	},
	toggle(key){
		this.set(key, !this.get(key));
	},
	true(key){
		this.set(key, true);
	},
	false(key){
		this.set(key, false);
	}
});


const DomStateChange = StateModel.extend({
	_initializeDom(){
		let old = this.dom;
		this.dom = document.body;
		if (old !== this.dom) {
			this.reflectClasses();
		}
	},
	onChange(){
		this._initializeDom();
	},
	onPropertyChange(value, key){
		if(value === true) {
			this.addClass(key);
		} else {
			this.removeClass(key);
		}
	},
	addClass(value){
		this.dom.classList.add(value);
	},
	removeClass(value){
		this.dom.classList.remove(value);
	},
	reflectClasses(){
		_.each(this.attributes, (value, key) => {
			if (value) {
				this.addClass(key);
			} else {
				this.removeClass(key);
			}
		});
	}
});

const Monitor = MnObject.extend({
	channelName: 'body',
	initialize(){
		this.model = new DomStateChange();
	},
	radioRequests:{
		'on'(value) {
			this.switchOn(value);
		},
		'off'(value){
			this.switchOff(value);
		},
		'toggle'(value){
			this.toggle(value);
		}
	},
	switchOn(value){
		this.model.true(value);
	},
	switchOff(value){
		this.model.false(value);
	},
	toggle(value){
		this.model.toggle(value);
	}
});

export default new Monitor();
