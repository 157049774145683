import _ from 'underscore';
import { View } from 'base';
import { url } from 'helpers';
export default View.extend({
	tagName: 'a',
	template: _.template('<i></i><span><%= _v.getText() %></span>'),
	getText(){
		return this.getOption('text', { args: [ this.model, this]});
	},
	getHref(){
		return url(this.getOption('href', { args: [ this.model, this]}));
	},
	onBeforeRender(){
		this._setAttributes(this.getAttributes());
	},
	getAttributes(){		
		return _.extend({}, this.getOption('attributes'), {
			href: this.getHref()
		});
	},
});
