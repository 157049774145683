import _ from 'underscore';
import '../api-config';
import { ManagedCollection, Model } from 'base';
import { CardModel } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';
import { paths } from 'helpers';
import ModelStore from 'backbone.store';
import busApp from 'bus/app';
import { enums } from 'bbmn-utils';
import { busViews } from 'bus';
//import user from 'app/user';

paths.set('api', 'realties:realty-lists', 'realtylists', { relative: true });
paths.set('url', 'realties:realty-lists', 'realty-lists', { relative: true });

const BaseRealtyList = CardModel.extend({
	urlRoot: paths.api('realties:realty-lists'),
	cardUrlRoot: paths.url('realties:realty-lists'),
	addItems(ids){
		if(!ids || !ids.length){
			return;
		}
		return this.save({ ids }, {
			method:'post',
			url: this.getItemsUrl()
		});
	},
	getItemsUrl(){
		return this.url() + '/items';
	},
	removeItems(ids, onSuccess){
		let removeModel = new Model();
		return removeModel.save({ ids }, {
			method:'delete',
			url: this.getItemsUrl(),
		}).then(() => {
			if(_.isFunction(onSuccess))
				return onSuccess(ids);
		});
	}
});
ModelSchemas.initialize(BaseRealtyList, {
	id: {
		value:{
			control:'select:api',
			sourceValues: () => new RealtyLists()
		},
		edit:{
			filtered: true,
			childView: () => busViews.getClass('RealtyListItemView')
		},		
		display:{
			label:'список',
			transform(){
				return this && this.display('name');
			},
			ifEmpty: '&ndash;'
		}
	},
	name:{
		validation: {
			required:true
		},
		display:{
			label:'Название'
		}
	},
	type: {
		value:{
			type:'enum',
			control:'select:inline',
			sourceValues: () => enums.get('realtyListTypes')
		},
		display:{
			label:'Тип списка'
		}
	}
});
export const RealtyList = ModelStore(BaseRealtyList);

export const RealtyLists = ManagedCollection.extend({
	model: RealtyList,
	url: paths.api('realties:realty-lists'),
	paged: false,
});

const alllists = new RealtyLists();
busApp.on('user:auth', () => {
	alllists.fetchIfNot();
});
busApp.reply('realtyList:name', (id) => alllists.get(id).get('name'));

