import { actionableMixin, action } from 'components/actions';
import { Model } from 'base';
import { mix, betterResult, getByPath, camelCase } from 'bbmn-utils';
import { openUrl } from 'helpers';
import { nestedEntitiesMixin } from 'bbmn-mixins';
import { busMsg } from 'bus';

const cardableMixin = Base => Base.extend({
	constructor: function(){
		
		Base.apply(this, arguments);

		if(!this.constructor._cardableAction){
			const actionMethod = function(options){
				let url = this.getCardUrl();
				openUrl(url, options);
			};
			let opencard = action({
				name:'open:card',
				label: this.cardUrlLabel || 'открыть',
				action: actionMethod,
				excludePlacements: 'card'
			});

			this.registerAction(opencard);
			this.constructor._cardableAction = true;
		}
		
	},
	hasCardUrl(){
		return !!(this.cardUrl || this.cardUrlRoot || this.cardUrlPattern);
	},
	getCardUrl(){
		let url;
		let addId = true;
		if (this.cardUrl) {
			url = betterResult(this, 'cardUrl', { args:[this]});
			addId = false;
		}		
		url = url
			|| betterResult(this, 'cardUrlPattern', { args:[this]})
			|| betterResult(this, 'cardUrlRoot', { args:[this]});
			
		if (!url) { return; }

		url = url.replace(/\{([^}]+)\}/g, (match, group) => {
			let value = getByPath(this, group, { includeModelProperty: true });
			if (value == null && this.collection) {
				value = getByPath(this.collection, group);
			}
			return value;
		});

		if(!this.isNew() && addId){
			url += '/' + this.get(this.idAttribute);
		}

		return url;
	},
	openCard(opts){
		this.executeAction('open:card', opts);
	}
});


export const socketChangeMixin = Base => Base.extend({
	constructor: function(){
		Base.apply(this, arguments);
		this._initializeSocketChange();
	},
	getSocketDataType(){
		return this.dataType;
	},
	_initializeSocketChange(){
		let dataType = this.getSocketDataType();
		if (!dataType) {
			this.once('change:id', this._initializeSocketChange);
			return;
		}
		let event = camelCase(dataType, 'change', this.id);
		this.listenTo(busMsg, event, this.handleSocketChange);
	},
	handleSocketChange(data){
		if (!data.dataId || data.dataId != this.id) return;
		this.set(data.data);
	}
});

export const ActionModel = mix(Model).with(actionableMixin, nestedEntitiesMixin).extend({
	ssetupNestedEntity(){}
});
export const CardModel = mix(ActionModel).with(cardableMixin);
