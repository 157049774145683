import { CollectionView } from 'base';
import { UiBlockView, ListItemView, ModelCoreView } from 'components/views';
import { plural } from 'helpers';

const InfoItemView = ModelCoreView.extend({
	text(){
		return plural(
			this.model.get('value'),
			this.model.get('plural')
		);
	},
});

const childView = ListItemView.extend({
	cssClassModifiers:[
		'clickable',
		m => m.get('active') ? 'active' : ''
	],
	modelView: InfoItemView,
	events:{
		'click'(){
			this.model.trigger('click', this.model);
		}
	}
});

const Infos = CollectionView.extend({
	childView,
	viewFilter: v => !v.model || v.model.get('value')
});

export default UiBlockView.extend({
	className: 'aside-feed-info',
	initialize(){
		this.infoCollection = this.model.getInfoCollection();
		let current = this.infoCollection.findWhere({ active: true });
		this.infoCollection.current = current;
	},
	content: Infos,
	contentOptions(){
		return {
			collection: this.infoCollection,
			collectionEvents:{
				'click'(model){
					model.set('active', true);
					this.model.trigger('show:collection', model);
				},
				'change:active'(model){
					if(!model.get('active')) return;

					if(model.collection.current){
						model.collection.current.set('active', false);
					}
					model.collection.current = model;
				},
				'change:value'(){
					this.filter();
				}
			}
		};
	}
});
