import keydown from './keydown.js';
import keyup from './keyup.js';
import paste from './paste.js';
import blur from './blur.js';
import focus from './focus.js';
import input from './input.js';

export default {
	keydown, 
	//keypress,
	keyup,
	paste,
	blur,
	focus,
	input,
	//'js:change': jsChange
};
