import $ from 'jquery';
import Bb from 'backbone';
import Mn from 'backbone.marionette';
import _ from 'underscore';
window.$ = $;
window._ = _;
window.Bb = Bb;
window.Mn = Mn;
// if (process.env.NODE_ENV === 'production') {
// 	let context = {};
// 	if (typeof window !== 'undefined') {
// 		context = window;
// 	} else if (typeof global !== 'undefined') {
// 		context = global;
// 	}
	
// 	delete context.Backbone;
// 	context.$ = () => {};
// } else {
// 	global.Bb = Bb;
// 	global.$ = $;
// 	global.Mn = Mn;
// }

