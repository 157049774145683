import _ from 'underscore';
import { defineControl, getControl, ControlView } from 'bbmn-controls';


const RangeControl = ControlView.extend({
	renderAllCustoms: true,
	isControlWrapper: false,
	className: 'range-control',
	getSchema(){
		return this.getOption('schema');
	},
	getEditOptions(name, opts){
		let schema = this.getSchema();
		if (!schema) return;
		//let edit = schema.getEdit();
		let options = {
			controlName: name,
			value: this.getControlValue(name),
			allValues: this.getControlValue(),
			schema,
			attributes:{
				placeholder: name == 'from' ? 'от' : 'до',
			}
		};
		return _.extend(options, schema.getEdit(), opts);
	},
	getRangeView(name){
		let schema = this.getSchema();
		let View = getControl(schema, { noRange: true });
		let options = this.getEditOptions(name);
		return new View(options);
	},
	getCustoms(){
		console.log('...', this);
		return [
			this.getRangeView('from'),
			this.getRangeView('to'),
		];
	},
	onControlChange(){
		console.log('#', this);
	},
});

defineControl('range', RangeControl);


export default RangeControl;
