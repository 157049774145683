import { View, CollectionView } from 'base';
import { RealtyOffersList } from '../../offers/views';
import { RealtyObjectInfo } from './RealtyObjectInfo';

export const RealtyObjectCardItem = CollectionView.extend({
	className: 'realty-object-item card-item fullwidth',
	// initialize() {
	// 	console.warn('OBJ INI');
	// },
	cssClassModifiers: [
		m => m.get('status'),
		m => (m.get('offers') || []).length > 0 ? 'has-offers' : 'no-offers'
	],
	
	customViewOptions() {
		return {
			model: this.model,
			realty: this.getOption('realty')
		}
	},
	renderCollection: false,
	renderAllCustoms: true,
	customs: [RealtyObjectInfo, RealtyOffersList],
	// customs: [ 
	// 	v => v.getRealtyObjectInfo(), 
	// 	v => v.getRealtyOffersList (),
	// ],
	// getRealtyObjectInfo() {
	// 	console.error('????');
	// 	return new RealtyObjectInfo(this.customViewOptions());
	// },
	// getRealtyOffersList() {
	// 	return new RealtyOffersList(this.customViewOptions());
	// },
	events: {
		click() { console.log(this.model); }
	}
});