import _ from 'underscore';
import { isView, buildViewByKey } from 'bbmn-utils';
import Popper from 'popper.js';
import { View } from 'base';
import { TextView } from 'bbmn-components';
import { renderInNode } from 'bbmn-utils';

function extractElement(arg){
	if (arg instanceof Element) {
		return arg;
	} else if (isView(arg)) {
		return arg.el;
	} else if(arg instanceof $){
		return arg[0];
	}
}

function createPopper(ref, el, options = {}){
	ref = extractElement(ref);
	el = extractElement(el);

	if (!ref || !el) return;	

	const inst = new Popper(ref, el, options);
	
	return inst;
}


const PopoverView = View.extend({
	regions:{ 'content':'section' },
	arrow: true,
	cssClassModifiers:[
		'popover-wrapper',
		(m,v) => v.getType()
	],
	getType(){
		return this.getOption('type') || 'default';
	},	
	onRender(){
		this._setAttributes({
			'data-popover': this.getType()
		});
		let view = buildViewByKey(this, 'content', { TextView });
		this.showChildView('content', view);
	},
	getTemplate(){
		let str = '<section data-popover-content-wrapper></section>';
		if(this.hasArrow()){
			str +='<i x-arrow></i>';
		}
		return _.template(str);
	},
	hasArrow(){
		return this.getOption('arrow');
	}
});


function createPopover(parent, content, { popper = {}, type, stack } = {}){
	const child = new PopoverView({
		content,
		type
	});

	child.listenTo(parent, 'destroy', () => child.destroy());

	renderInNode(child, { destroyOnEmpty: true });

	if (stack) {
		stack.add(child);
	}

	return createPopper(parent, child, popper);
	// const inst = createPopper(parent, child, popper);
	// if (!inst) {
	// 	return;
	// }
	
}

export {
	createPopper as popper,
	createPopover as popover,
};
