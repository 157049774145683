import BasePage from 'pages/auth';
import { Content } from './Content';
import { realtyCards } from 'mod/realties/realties/models';
import Contacts from './contacts';
import { customContactsCollection } from 'mod/realties/custom-contacts/models';
import { proxyPhones } from '../../../../modules/realties/custom-contacts/proxyPhones';
//import { getFilters } from 'mod/realties/realties/filters';
// import Content from './content';
// import { Selector } from 'bbmn-components';
// import { Filterer } from 'components/filterer';
// import realtiesActions from 'mod/realties/realties/mass-actions';

export default BasePage.extend({
	id: 'realties-realty',
	routes: ':realtyId',
	subdivisionId: 'root',
	children: [Contacts],
	Content,
	// label: p => p.model && p.model.get('name'),
	label (p, d) {
		if (this.model) {
			return this.model.get('name');
		}
		if (d && d.realtyId) {
			return realtyCards.getName(d.realtyId);
		}
	},
	startPromises: [
		page => customContactsCollection.fetchIfNot(),
		page => proxyPhones.fetchIfNot()
	],
	// Content,
	// LayoutOptions: p => ({
	// 	selector: p.selector,
	// 	filterer: p.filterer,
	// 	fetchNextEdge: 'bottom',
	// 	scrollHandlingEnabled: true,		
	// }),
	subdivisions: {
		'root': 'основное',
		'contacts': 'контакты'
	},
	initialize(){
		// this.filterer = new Filterer({ formatDataForQueryString: false });

		// this.collection = new Realties();
		// this.collection.setFilterer(this.filterer);

		// this.selector = new Selector({ 
		// 	source: this.collection, 
		// 	multiple: true,
		// });
		// this.selector.actions = realtiesActions(this.selector);
		
		// this.addUrlChanger(this.filterer);

	},
	onBeforeStart(p, ac){
		
		console.log('BEFORE START BEGINS', p, ac);
		const id = ac.args.realtyId
		this.model = realtyCards.getOrCreate(id);
		return this.model.fetchIfNot();
		// if (this.filterer._inited) {
		// 	console.log('not in first time, so skip');
		// 	return;
		// }
		// this.filterer.setFilters(getFilters(), { qs: ac.qs });
		// this.filterer._inited = true;
		// console.log('Filters settled', this.filterer);
		// if (!this.filterer.hasValues() && !this.collection.isFetching()) {
		// 	console.log('filterer is empty, and there is no fetch on coll, so doing fetch');
		// 	this.collection.query();
		// }

	},
	onBeforeStop(){
		console.log('BEFORE STOP!');
		this.stopListening();
	},
	//children:[RealtyLists, OffersLists],
});
