import BasePage from 'pages/auth';
import Content from './content';
// import { Imports } from 'mod/adm/import/models';

export default BasePage.extend({
	id:'admin-import',
	routes: 'import',
	label: 'Импорт из NRG',
	Content,
	children:[],
	rights:{
		admin: true,
	},
	// startPromises: [
	// 	page => page.collection.fetch(),
	// ],
	// initialize(){
	// 	this.collection = new Imports();
	// },	
});
