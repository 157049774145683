// import _ from 'underscore';
// import { CollectionView, View } from 'base';
import { ListItemView, ModelCoreView, CardItemView, AtomTextView } from 'components/views';
import { RealtyThumbnail } from 'mod/realties/realties/views';
import { moment } from 'vendors';

// import { UiBlockView } from 'components/views';
// import { switcherInverse } from 'controls/boolean-switch';


export const BaseNewsItemView = ModelCoreView.extend({
	// topText: m => m.get('prefix') + ' класса ' + m.get('class'),
	text: m => m.get('header'),
	smallText: (m,v) => m.get('anons'),
	// _small(){
	// 	let m = this.model;
	// 	let landingUrl = m.get('idString') + '.pvt.ru';
	// 	let rgUrl = 'http://adm.nrg.plus/rlt/buildings/'+ m.get('id');
	// 	let landing = `<a href="${landingUrl}" class="spaced-link" data-applink="false" target="_blank">${landingUrl}</a>`;
	// 	let rg = `<a href="${rgUrl}"  class="spaced-link" data-applink="false" target="_blank">редактировать в синей</a>`;
	// 	console.log('[:::]');
	// 	return [m.display('address.gd'), landing, rg].join('');
	// }
});

export const NewsListItemView = ListItemView.extend({
	className: 'news-item',
	//optionsButton: true,
	leftItems:[
		//model => new RealtyThumbnail({ thumbnail: model.getThumbnail() }),
		model => ({ className: 'date', text: moment(model.get('date')).format('DD.MM.yyyy') })
	],
	modelView: BaseNewsItemView,
	// selectButton: (m,v) => v.isSelectable(),
	// selectOnTheRight: true,
});


// const UrlView = AtomTextView.extend({
// 	className: 'spaced-link',
// 	tagName: 'a',
// 	text: (m,v) => v.getOption('label'),
// 	initialize(){
// 		this._setAttributes({
// 			href: this.getOption('url'),
// 			'data-noroute': true,
// 			'target': '_blank'
// 		});
// 	},
// });


// const ListsView = View.extend({
// 	template: _.template('в списках: <%= lists %>'),
// 	templateContext() {		
// 		let lists = _.pluck(this.model.get('realtyLists') || [], 'name').join('; ');
// 		!lists && (lists = 'нет');
// 		return {
// 			lists
// 		};
// 	}
// });


export const NewsCardView = CardItemView.extend({
	className: 'news-card',
	modelView: NewsListItemView,
	clickAction: (v) => v.model.openCard(),
	// modelViewOptions(){
	// 	// return {
	// 	// 	model: this.model,
	// 	// 	// selectButton: this.isSelectable(),
	// 	// };
	// },
	lines: [
		v => v.model.get('realtyId') ? new ListItemView({ 
			model: v.model, 
			modelView: ModelCoreView, 
			modelViewOptions: { text: v.model.get('realty.name') },
			leftItems: [
				new RealtyThumbnail({ thumbnail: v.model.getThumbnail() })
			] 
		}) : null,
	],
	// onRender() {
	// 		console.log('#', this.el.innerHTML);
	// }
	// lines: () => ([
	// 	v => new ListItemView({ 
	// 		model: v.model, 
	// 		modelView: ListsView, 
	// 		//modelViewOptions: { label: v.model.getLandingUrl(), url: v.model.getLandingUrl() } 
	// 	}),		
	// 	v => new ListItemView({ 
	// 		model: v.model, 
	// 		modelView: UrlView, 
	// 		modelViewOptions: { label: v.model.getLandingUrl(), url: v.model.getLandingUrl() } 
	// 	}),
	// 	v => new ListItemView({ 
	// 		model: v.model, 
	// 		modelView: UrlView, 
	// 		modelViewOptions: { label: 'редактировать в nrg', url: v.model.getNRGUrl() } 
	// 	}),
	// ]),
});