import cfg from 'app/config';
export default function url(url) {
	if(url == null) return '';
	if(url.startsWith('/') || url.startsWith('#'))
		return url;
	if(cfg.pushState === false) {
		url = '#' + url;
	}
	return '/' + url;
}
