import { View } from 'base';
import { ListItemView, ModelCoreView, AtomTextView } from 'components/views';
import { getByPath } from 'bbmn-utils';
import { ContactAtomItem } from '../../custom-contacts/views';
import { gbp, displayItem as i, displayValue as d } from '../../realty-helpers';

//const gbp = getByPath;

// const d = function(m, key, postfix) {
// 	if (postfix === 'м2') {
// 		postfix = 'м<sup>2</sup>';
// 	}
// 	if (postfix) {
// 		postfix = `<small class="postfix">${postfix}</small>`;		
// 	}
// 	let value = gbp(m, key);
// 	if (value == null) {
// 		return '&ndash;'
// 	}
// 	return `<span class="val">${value}</span>` + postfix;
// }

// const i = function(m, key, postfix, cls = '') {
// 	const val = d(m,key,postfix);
// 	if (!val) {
// 		return '';
// 	}
// 	return `<span class="${cls}">${val}</span>`;
// }

const ObjectModelCoreView = ModelCoreView.extend({
	text: m => i(m, 'info.floor', 'эт.', 'elem floor')
		+ i(m, 'info.squareOffer', 'м2', 'elem square'),
	bottomText: (m,v) => {
		let rlt = v.getOption('realty') || m.collection.realty;
		let owners = rlt?.get('ownersInfo') || {};
		let ownerId = m.get('ownerId');
		let name = owners[ownerId]?.name;
		if (!name) {
			console.warn('missing owner info', ownerId, rlt?.get('ownersInfo'))
		}
		return name;
	}
});

// const Phone = AtomTextView.extend({
// 	type: 'labeledText',
// 	header: (m, v) => v.getPhoneHeader(),
// 	text: (m, v) => v.getPhone().value || 'нет контактов',
// 	getPhone() {
// 		const own = this.model.attributes.ownCustomContact;
// 		if (own?.value) {
// 			return own; 
// 		}
// 		const book = this.model.attributes.customContact;
// 		if (book?.value) {
// 			return book;
// 		}
// 		return {};
// 	},
// 	getPhoneHeader() {
// 		const p = this.getPhone();
// 		return p.label || p.name;
// 	}
// })

export const RealtyObjectInfo = ListItemView.extend({
	// initialize() {
	// 	console.warn("INFO INI")
	// },
	modelView: ObjectModelCoreView,
	passDownModels: true,
	disableCustomsEarlyPrepare: true,
	rightItems: [
		{ View: ContactAtomItem, clickable: 'trigger:edit:contacts:click' }
	],
	customViewOptions() {
		const opts = {
			model: this.model,
			realty: this.getOption('realty'),
			entityType: 'object'
		}
		return opts;
	},
});