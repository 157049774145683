import _ from 'underscore';
import { ListItemView, ModelCoreView } from 'components/views';
import { CollectionView } from 'base';
import { hasFlag, clone } from 'bbmn-utils';
import { Collection, Model } from 'base';
import { switcher } from 'controls/boolean-switch';

const RightsItem = ModelCoreView.extend({
	text: m => m.get('label')
});

export const ModuleRightsItem = ListItemView.extend({
	modelView: RightsItem,
	cssClassModifiers:[
		'module-rights-item',
		m => m.get('enabled') ? '':'disabled',
	],	
	leftItems: [
		m => switcher(m, 'enabled'),
	],		
});

export const ModuleRights = CollectionView.extend({
	initialize(opts){
		this.mergeOptions(opts, 'module');
		this.initializeRightsCollection();
	},
	initializeRightsCollection(){
		this.collection = this.getRightsCollection();
	},
	getRightsCollection(){
		let rights = this.module.get('rights') || {};
		let entityModuleRights = this.getEntityRights(this.module.id);
		let models = _.map(rights, (label, id) => ({ id, label, enabled: hasFlag(entityModuleRights, id) }));
		let collection = new Collection(models, { model: Model });
		collection.on('change', () => this.handleChange(collection));
		return collection;
	},
	handleChange(col){
		let rights = col.filter(m => m.get('enabled')).map(r => r.id).join(', ');
		this.triggerMethod('rights:change', rights);
	},
	getEntityRights(id){
		let rights = clone(this.model.get('rights')) || {};
		if(arguments.length == 1) {
			return rights[id] || '';
		} else {
			return rights;
		}
	},
	changeEntityRights(moduleId, mask){
		let options = this.getOption('changeRightsOptions');
		let method = this.getOption('actionOnChange');
		let rights = this.getEntityRights();
		rights[moduleId] = mask;
		let data = options.dontWrap ? rights : { rights };
		this.model[method](data, options);

	},
	childView: ModuleRightsItem,
	actionOnChange: 'set',
	onRightsChange(mask){
		this.changeEntityRights(this.module.id, mask);
	}
});
