import _ from 'underscore';
import Radio from 'backbone.radio';
//console.warn('radio: ', Radio);

_.extend(Radio.Channel.prototype, {
	class(name, definition){
		this.reply(name, () => definition);
	},
	getClass(name, extend){
		let def = this.request(name);
		if(!def) return;

		if(_.isFunction(extend)){
			return extend(def);	
		} else if(_.isObject(extend)){
			return def.extend(extend);
		}
		return def;
	}
});


export default Radio;
