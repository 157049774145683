import BasePage from 'pages/anon';
import Layout from './layout';
export default BasePage.extend({
	routes: 'forgotPass/confirm',
	Layout,
	label: 'подтверждение восстановления пароля',
	hidden: true,
	canNotStart(ac){
		if(!ac || !ac.qs.code)
			return 'not:found';
	}
});
