
import { View, CollectionView, Collection, Model } from 'base';
import { _ } from 'vendors';

export const bus = new Model();

export const offersSearch = new Model();
export const realtiesSearch = new Model();

export const FetchButton = View.extend({
	className: 'submit',
	template: _.template(`
	<label>&nbsp;</label>
	<div>
		<button>получить список</button>
	</div>
	`), 
	events: {
		'click button'() {
			bus.trigger('fetch:click');
		}
	}
});

const TextField = View.extend({
	className: 'search-field single',
	template: _.template(`
		<label><%= label %></label>
		<div>
			<input class="input" value="<%= value %>"/>
		</div>
	`),
	templateContext() {
		return {
			label: this.getLabel(),
			value: this.getInitialValue()
		}
	},
	getLabel() {		
		return this.getOption('label') || '';
	},
	getInitialValue() {
		const property = this.getOption('property');
		const val = this.model.get(property);
		return val || '';
	},
	events: {
		'input input'(e) {
			let val = e.target.value;
			this.setValue(val);
		}
	},
	setValue(val) {
		val = this.normalizeValue(val);
		const prop = this.getOption('property');
		this.model.set(prop, val);
	},
	normalizeValue(val) {
		if (val === '') {
			val = null;
		}
		return val;
	}
});


const RealtyName = TextField.extend({ property: 'realtyText', label: 'название или адрес' });

const OffersSearch = CollectionView.extend({
	className: 'search-fields',
	renderCollection: false,
	renderAllCustoms: true,
	customViewOptions() {
		return {
			model: this.model
		}
	},
	model: offersSearch,
	customs: [
		RealtyName
	]
});

const RealtiesSearch = CollectionView.extend({
	className: 'search-fields',
	renderCollection: false,
	renderAllCustoms: true,
	customViewOptions() {
		return {
			model: this.model
		}
	},
	model: realtiesSearch,
	customs: [
		RealtyName
	]
});

export const SearchBlock = CollectionView.extend({
	renderCollection: false,
	renderAllCustoms: true,
	className: 'search-block',
	customs: [
		v => v.getOption('searchType') == 'offers' ? OffersSearch : RealtiesSearch,
		FetchButton
	]
})
