import _ from 'underscore';
import './api-config';


import { Collection, Model } from 'base';
import { CardModel } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';
import { paths } from 'helpers';
import { createSchemaNotifies } from 'components/notifier';
import { enums, flat } from 'bbmn-utils';

import { busModels } from 'bus';

import { CianAds } from './cian-models';
import { Ads } from './ads';
import Grouper from 'components/grouper';



export const FeedStats = Model.extend({
	parse(data){
		return data;
		/*return _.reduce(flat(data), (memo, val, _key) => {
			let chunks = _key.split('.');
			let key = _key;
			if (chunks[0] == 'total') {
				key = 'ВСЕГО ОБЪЯВЛЕНИЙ';
			}
			else if (chunks[0] == 'commons') {
				key = enums.get('cianObjectCategories', chunks[1]);
			} else if (chunks[0] == 'auctions') {
				key = 'на аукционе';
			} else if (chunks[0] == 'promotions') {
				key = enums.get('promotionEnums', chunks[1]) + ' ' + enums.get('cianObjectCategories', chunks[2]);
			}
			memo[key] = val;
			return memo;
		}, {});*/
	}
});
busModels.class('FeedStats', FeedStats);


const ObjectsTabItem = Model.extend({
	constructor: function(){
		Model.apply(this, arguments);
		this.updateValue();
		this.listenToChanges();
	},
	listenToChanges(){
		let col = this.get('collection');
		this.listenTo(col, 'update reset', this.updateValue);
	},
	updateValue(){
		//console.log('VALUE UPDATED', this);
		let col = this.get('collection');
		this.set('value', col.length);
	},
});

const objectsGroups = {
	objects: {
		filter: model => model.isTaked(),
		plural: {
			one:'объявление',
			few: 'объявления',
			many: 'объявлений'
		}
	},
	promotions: { 
		filter: model => model.isTaked() && model.isPromoted(),
		plural: {
			one:'продвижение',
			few: 'продвижения',
			many: 'продвижений'
		},
	},
	skiped:{ 
		filter: model => !model.isTaked() && model.isValid(),
		plural: {
			one:'пропущенное',
			few: 'пропущенных',
			many: 'пропущенных'
		}
	},
	invalids: { 
		filter: model => model.isInvalid(),
		plural: {
			one:'ошибка',
			few: 'ошибки',
			many: 'ошибок'
		},
	},
	auction: { 
		filter: model => model.isTaked() && model.isValid() && model.hasAuction(),
		plural: {
			one:'на аукционе',
			few: 'на аукционе',
			many: 'на аукционе'
		}
	},
	excluded: {
		filter: model => model.isExcluded(),
		plural: {
			one: 'исключенное',
			few: 'исключенных',
			many: 'исключенных',
		}
	},
	included: {
		filter: model => model.isIncluded(),
		plural: {
			one: ' принудительно включено',
			few: 'принудительно включеных',
			many: 'принудительно включеных',
		}
	}

};


const groupsV2 = {
	inFeed: {
		id: 'inFeed',
		filter: model => model.isTaked() && model.isValid(),
		plural: {
			one:'объявление',
			few: 'объявления',
			many: 'объявлений'
		}
	},
	forcedIcluded: {
		id: 'forced-included',
		filter: model => model.isIncluded(),
		plural: {
			one: 'принудительно включено',
			few: 'принудительно включено',
			many: 'принудительно включено'
		}
	}, 
	forcedExcluded: {
		id: 'forced-excluded',
		filter: model => model.isExcluded(),
		plural: {
			one: 'запрет публикации',
			few: 'запрет публикации',
			many: 'запрет публикации'
		}
	},
	skiped: {
		id: 'skiped',
		filter: model => !model.isTaked() && model.isValid(),
		plural: {
			one: 'пропущенное объявление',
			few: 'пропущенных объявлений',
			many: 'пропущенных объявлений'
		}
	},
	forcedPromotions: {
		id: 'forced-promotions',
		filter: model => _.some(model.get('promotions'), value => value == 'forcedPromotion'),
		plural: {
			one: 'ручное продвижение',
			few: 'ручных продвижения',
			many: 'ручных продвижений'
		}
	},
	declinedPromotions: {
		id: 'declined-promotions',
		filter: model => _.some(model.get('suggestedPromotions'), value => value == 'preventPromotions'),
		plural: {
			one: 'запрещенное продвижение',
			few: 'запрещенных продвижения',
			many: 'запрещённых продвижений'
		}
	},
	invalids: {
		id: 'invalids',
		filter: model => model.isInvalid(),
		plural: {
			one: 'с ошибками',
			few: 'с ошибками',
			many: 'с ошибками'
		}
	},
};


export const FeedResults = CardModel.extend({
	initialize(data, opts = {}) {
		this.feed = opts.feed;
	},
	noApi: true,	
	cardUrl: paths.url('export:feeds:feed:results'),
	nestedEntities:{
		objects: Ads,
	},
	getDefaultCollection(){
		let groups = this.getObjectGroups();
		return groups.inFeed.collection;
	},
	createObjectsGrouper(){
		let objects = this.entity('objects');
		let groups = [
			groupsV2.inFeed
		];
		// let groups = _.map(groupsV2, (g,id) => { g.id = id; return g; });		
		// let first = groups.shift();

		let _cats = this.feed.getFeedConfigCategories({ enum: true });
		let categories = _.map(_cats, (label, id) => {
			return {
				id: 'category-' + id,
				filter: model => model.get('categoryId') == id && model.isTaked() && model.isValid(),
				plural: {
					one: label,
					few: label,
					many: label
				}
			};
		});

		groups.push(...categories);

		let _promos = this.feed.getFeedConfigPromotions({ enum: true });
		let promotions = _.reduce(_promos,(memo, promoLabel, promoId) => {
			let arr = _.map(_cats, (label, id) => {
				return {
					id: promoId + '-' + id,
					filter: model => model.get('categoryId') == id && model.isTaked() && model.isValid() && model.isPromoted(promoId),
					plural: {
						one: promoLabel + ' ' + label,
						few: promoLabel + ' ' + label,
						many: promoLabel + ' ' + label
					}
				};
			});
			memo.push(...arr);
			return memo;
		}, []);

		groups.push(...promotions);

		groups.push(
			groupsV2.forcedPromotions,
			groupsV2.declinedPromotions,
			groupsV2.forcedIcluded,
			groupsV2.forcedExcluded,
			groupsV2.skiped,
			groupsV2.invalids
		);
		//console.log('GROUPS', groups);
		this.objectsGrouper = new Grouper({ collection: objects, groups });
	},
	getObjectGroups({ asArray } = {}){
		if(!this.objectsGrouper) {
			this.createObjectsGrouper();
		}
		let groups = this.objectsGrouper.groups;

		return asArray ? _.toArray(groups) : groups;
	},
	getInfoCollection(){
		let models = this.getObjectGroups({ asArray: true });
		//console.log('~~~', models);
		return new Collection(models, { model: ObjectsTabItem });
	},

});

ModelSchemas.initialize(FeedResults, {

}, {
	notifies: createSchemaNotifies({ name: 'объявления', generate: true, many: true })
});
