import { Model, CollectionView, View, Collection } from 'base';
import InlineSelect from '../../../../controls/select';
import { DisplayPropertySchemaView, DisplayModelSchemaView } from 'components/display-schema';
import { ModelSchemas } from 'bbmn-components';

const offerStatusesEnum = {
	'': 'не важно',
	active: 'опубликовано',
	inactive: 'снято с публикации'
}

export const objectsFilterSchema = ModelSchemas.initialize('realtyObjectsListFilter', {
	ownerId: {
		display: {
			label: 'собственник',
			ifEmpty: 'не выбран',
			transform(v){
				if (!v) {
					return 'не выбран';
				}
				const info = this.realty.get('ownersInfo') || {};
				return info[v] && info[v].name || v;
			}
		},
		value: {
			valueType: 'enum',
			sourceValues() {
				
				const info = this.realty.get('ownersInfo') || {};
				const hash = Object.keys(info).reduce((memo, id) => {
					memo[id] = info[id].name ||  '<без названия>';
					return memo;
				}, { '': 'не выбран' })
				return hash
			}
		}
	},
	'offerStatus': {
		display: {
			label: 'статус публикации предложения',
			ifEmpty: 'статус публикации не указан',
			transform(v) {
				return offerStatusesEnum[v];
			},
		},
		value: {
			valueType: 'enum',
			sourceValues:offerStatusesEnum,
		}
	},

	// id:{
	// 	display:{
	// 		label: 'название (идентификатор)',
	// 		transform(v){
	// 			return v == 'default' ? 'основной' 
	// 				: v == 'promotions' ? 'для продвижений'
	// 					: v;
	// 		}
	// 	}
	// },
	// country: {
	// 	validation: {
	// 		charPattern: /\d/gmi
	// 	},
	// 	value:{
	// 		type:'text',
	// 	},
	// 	display:{
	// 		label: 'код страны',
	// 		transform(v){
	// 			if (v == null) return '';
	// 			return '+' + v;
	// 		}
	// 	}
	// },
	// code: {
	// 	validation: {
	// 		charPattern: /\d/gmi
	// 	},
	// 	value:{
	// 		type:'text',
	// 	},
	// 	display:{
	// 		label: 'код региона'
	// 	}
	// },
	// number: {
	// 	validation: {
	// 		charPattern: /\d/gmi
	// 	},
	// 	value:{
	// 		type:'text',
	// 	},
	// 	display:{
	// 		label: 'номер',
	// 		transform(v){
	// 			if(!v) return '';
	// 			v = v.split('');
	// 			let res = '';
	// 			let taked = 0;
	// 			while(v.length){
	// 				let last = v.pop();
	// 				res = last + res;
	// 				taked++;
	// 				if (v.length <= 1) {
	// 					continue;
	// 				} else if (taked >= 2) {
	// 					res = '-' + res;
	// 					taked = 0;
	// 				}
	// 			}
	// 			return res;
	// 		}
	// 	}
	// }
});


const SelectOwner = InlineSelect.extend({
	getSource() {
		return {
			true: 'opapapa'
		}
	}
})

const FilterPropertyView = DisplayPropertySchemaView.extend({})


const FilterSchemaView = DisplayModelSchemaView.extend({
	schema: objectsFilterSchema,
	mainPropertyAction:'trigger:edit:click',
	propertyOnResolve(value, view) {
		const path = view.getOption('property');
		if (value === '') {
			value = undefined;
		}
		view.model.set(path, value);
		console.log('property on resolve', path, value, view.model.attributes);
	}
})

export const RealtyObjectsListHeader = CollectionView.extend({
	className: 'realty-objects-list-header',
	renderCollection: false,
	renderAllCustoms: true,
	customs: [
		FilterSchemaView,
		//{ property: 'offer.status', schema: objectsFilterSchema },
	],
	//customView: FilterPropertyView,
	customViewOptions() {
		return {
			model: this.getOption('filterModel'),
			realtyModel: this.model,
			
		}
	}
});