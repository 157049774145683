import { CollectionView, View, Collection } from 'base';
import { RealtyOfferInfo } from './RealtyOfferInfo';
import { RealtyOfferModel } from '../models';

export const RealtyOffersList = CollectionView.extend({
	className: 'realty-offers-list',
	modelEvents: {
		'change':'render',
	},
	initialize() {
		
		this.initializeCollection();
	},
	initializeCollection() {
		this.collection = new Collection([], { model: RealtyOfferModel });
		this.collection.object = this.model;
		this.collection.realty = this.getOption('realty');
		this._setModels();
		this.listenTo(this.model, 'sync change', this._fetchModels);		
	},
	_fetchModels() {
		let url = this.model.url() + '/offers';
		this.collection.url = url;
		this.collection.fetch();
		//console.error('URLI', url);
	},
	_setModels() {
		const models = this.model.get('offers') || [];
		if (models.length > 0) {
			//console.warn("OFFERS INI", this.model);
		}
		this.collection.add(models, { remove: true });
	},
	childView: RealtyOfferInfo,
	childViewOptions() {
		return {
			object: this.model,
			realty: this.getOption('realty')
		}
	}
});