import _ from 'underscore';
export function mainAction(view){

	let actionName = view.getOption('mainAction');
		
	if (!actionName) return;

	if(_.isString(actionName) && actionName.startsWith('trigger:')){
		return actionName;
	}

	if (!view.model || !_.isFunction(view.model.getAction)) return;

	let action = view.model.getAction(actionName, { hidden: true });

	return action;

}
