import { BearerToken, store } from 'bbmn-components';
import paths from 'helpers/paths';

paths.set('api', 'token', 'auth/token', { version: false });

const Token = BearerToken.extend({
	initialize(){
		this.restoreToken();
	},
	endPoint: paths.api('token'),
	secondsOffset:80,
	parse(data) {
		
		if(data == null) return {};

		if (data.myExpiration) {

			this.setExpiration(new Date(data.myExpiration));
			data.myExpiration = this.getExpiration();
			return data;

		} else {

			let seconds = parseInt(data.expires_in, 10);

			if (isNaN(seconds) || seconds < 3)
				seconds = 90;

			this.setExpiration({ seconds });
			data.myExpiration = this.getExpiration();
			return data;

		}
	},
	storeToken(){
		let name = this.get('userName');
		if (!name) {
			store.expire('lastUser');
			return;
		} 
		store.set('lastUser', name, { days: 1 });
		store.set(name + ':token', this.toJSON(), { days:1 });
	},
	restoreToken(){

		let name = store.get('lastUser');
		if(!name) return;
		let token = store.get(name + ':token');
		this.update(token);

	},
	handleError(xhr){
		if(xhr.responseJSON && xhr.responseJSON.error && xhr.responseJSON.error == 'invalid_grant'){
			this.trigger('invalid:grant');
			return { error: xhr.responseJSON.error_description };
		}
	},
});

const token = new Token();
//console.warn('token', token);
export default token;
