import { View, CollectionView, Collection, Model } from 'base';
//import { bus } from './bus';
import { BusActionButton } from '../BusActionButton';

const FetchBtn = BusActionButton.extend({
	eventName: 'fetch:asterisk:click',
	text: 'загрузить данные АТС'
});
// View.extend({
// 	tagName: 'button',
// 	template: () => 'загрузить данные',
// 	events: {
// 		'click'() {
// 			if (bus.get('fetchAsteriskLocked')) { return; }
// 			bus.trigger('fetch:asterisk:click');
// 		}
// 	}
// })

const ClearBtn = BusActionButton.extend({
	eventName: 'clear:asterisk:click',
	text: 'стереть данные на АТС'
});
// View.extend({
// 	tagName: 'button',
// 	template: () => 'стереть данные на АТС',
// 	events: {
// 		'click'() {
// 			if (bus.get('resetAsteriskLocked')) { return; }
// 			bus.trigger('reset:asterisk:click');
// 		}
// 	}
// })

export default CollectionView.extend({
	className: 'top-actions',
	renderCollection: false,
	renderAllCustoms: true,
	customs: [
		FetchBtn,
		ClearBtn
	]
})