import BasePage from 'pages/auth';
import { commonModuleEditMixin } from '../common/edit/moduleMixin';
import { NewsModel } from '../../../modules/news/NewsModel';
export default BasePage.extend({
	id: 'create',
	routes: 'create',
	label: 'Добавление новости',
	// noAside: true,
	relativeRoutes: true,
	// children: [Import, Roles, Users, Configs],
	rights:{
		news: 'view',
	},
	onBeforeStart(p, ac) {
		const now = new Date();
		const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		this.model = new NewsModel({ status: 'hidden', date: date.toJSON() });

	},
	createMode: true,
	...commonModuleEditMixin,
});
