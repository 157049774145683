import _ from 'underscore';
import { CollectionView, View } from 'base';

const PromotionItem = View.extend({
	className: 'advert-promotion-item',
	initialize(options){
		this.mergeOptions(options, ['advert', 'feed']);
	},
	template: _.template(`
<div class="name"><%= label %>:</div>
<div class="current"><%= current %></div>
<div class="actions"><%= actions %></div>
`),
	templateContext(){
		let current = this.getCurrentHtml();
		let actions = this.getActionsHtml();
		return {
			actions,
			current
		};
	},
	getCurrentState(){
		let promos = this.advert.getPromotions({ enum: true });
		let state = promos[this.model.id];
		return state;
	},
	getCurrentHtml(){
		let state = this.getCurrentState();
		let badge = (label, cls = 'none') => `<span class="badge ${cls}">${label}</span>`;
		switch(state){
		case 'autoPromotion':
			return badge('авто-продвижение', 'auto');
		case 'preventPromotions':
			return badge('запрет продвижения', 'prevent');
		case 'forcedPromotion':
			return badge('ручное продвижение', 'forced');
		default:
			return badge('без продвижения');
		}
	},
	buildActionHtml(state, direction, label){
		return `<button data-state="${state}" data-dir="${direction?1:0}">${label}</button>`;
	},
	getActionsHtml(){
		let actions = [];
		let state = this.getCurrentState();

		if (!state || state == 'autoPromotion' || state == 'default') {

			actions.push(this.buildActionHtml('forcedPromotion', true, state == 'autoPromotion' ? 'закрепить' : 'применить'));
			actions.push(this.buildActionHtml('preventPromotions', true, 'запретить'));

		} else if (state == 'forcedPromotion') {

			actions.push(this.buildActionHtml('forcedPromotion', false, 'отменить'));

		} else if (state == 'preventPromotions') {

			actions.push(this.buildActionHtml('preventPromotions', false, 'отменить'));

		}
		return actions.join('');		
	},
	events:{
		'click .actions button'(e){
			console.log('click');
			e.stopImmediatePropagation();
			let $b = $(e.target).closest('button');
			let state = $b.data('state');
			let dir = parseInt($b.data('dir'),10) == 1;
			this.advert.changePromotion(this.model.id, state, dir);
		},
	}
});

export const ManageAdPromotions = CollectionView.extend({
	className: 'advert-promotions-edit',
	initialize(options){
		this.mergeOptions(options, ['feed']);
		this.collection = this.feed.getFeedConfigPromotions({ collection: true });
	},
	childView: PromotionItem,
	viewComparator: 'index',
	modelEvents:{
		'change':'render'
	},
	childViewOptions(){
		return {
			feed: this.feed,
			advert: this.model,
		};
	}
});
