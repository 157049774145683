import { paths } from 'helpers';

export function realtyCustomContactUrl(id) {
	let url = paths.api('realties:realties') + '/' + id + '/custom-contact';
	//console.error('get realties url', url)
	return url;
}

export function objectCustomContactUrl(id) {
	let url = paths.api('realties:objects') + '/' + id + '/custom-contact';
	//console.error('get object url', url)
	return url;
}

export function offerCustomContactUrl(id) {
	let url = paths.api('realties:offers') + '/' + id + '/custom-contact';
	//console.error('get offers url', url)
	return url;
}

export function getCustomContactUrl(type, id) {
	switch(type) {
		case 'realty':
			return realtyCustomContactUrl(id);
		case 'object':
			return objectCustomContactUrl(id);
		case 'offer':
			return offerCustomContactUrl(id);
	}
}