import { View } from 'base';
import BasePage from './base';

const ErrorPage = BasePage.extend({
	shouldCreateRouter: false,
	shouldRegisterAllRoutes: false,
});

function create(label, content){
	return new ErrorPage({
		label,
		Content: View.extend({ template: () => content})	
	});
}

export const notAllowed = create(
	'В доступе отказано',
	'Вам запрещен доступ к этой странице.'
);

export const notFound = create(
	'404 страница не найдена',
	'Такой страницы не существует. Воспользуйтесь меню для перехода на другую страницу.'
);

export const apiErrorZero = create(
	'ошибка API',
	'Скорее всего этот раздел еще в разработке.'
);



