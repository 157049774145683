import BasePage from 'pages/auth';
import login from './login';
import forgotPass from './forgotPass';
import forgotPassConfirm from './forgotPass-confirm';
import logoff from './logoff';
//import Layout from './layout';

export default BasePage.extend({
	id:'acc',
	routes: 'acc',
	label: 'аккаунт',
	relativeRoutes: true,
	order: 1000,
	children:[login, forgotPass, forgotPassConfirm, logoff], // register, forgotPass, logoff, forgotPassConfirm, registerConfirm],
	noAside: true,
});
