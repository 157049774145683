import _ from 'underscore';
import { CollectionView, View } from 'base';
import { camelCase } from 'bbmn-utils';





const ButtonView = View.extend({
	tagName:'button',
	constructor: function(){
		View.apply(this, arguments);
		this.addCssClassModifier('paginator-button');
		let type = this.getOption('type');
		if (type) {
			this.addCssClassModifier('paginator-'+type);
		}
		if(this.getOption('current')){
			this.addCssClassModifier('current');
		}

		if(this.getOption('disabled')){
			this.addCssClassModifier('disabled');
		}
	},
	getTemplate(){
		let icon = '<i></i>';
		let template = '<span><%= text %></span>';
		if(this.getOption('leftIcon')){
			template = icon + template;
		}
		if(this.getOption('rightIcon')){
			template += icon;
		}
		return _.template(template);
	},
	templateContext(){
		return {
			text: this.getOption('text'),
		};
	},
	events:{
		'click'(){
			if (this.getOption('disabled')) return;
			let pag = this.getOption('paginator');
			pag && pag.request(this.getOption('name'));
		}
	}
});

export const PaginatorView = CollectionView.extend({
	className:'paginator',
	renderAllCustoms: true,
	renderCollection: false,
	modelEvents:{
		'change:skip':'render',
		'rebuild': 'render',
	},
	hasPaginator(){
		return !!this.model;
	},
	getPaginator(){
		return this.model;
	},

	getCustoms(){
		if (this.hasPaginator())
			return [
				...this.getGoPrevViews(),
				...this.getPagesViews(),
				...this.getGoNextViews(),
			];
	},

	getGoPrevViews(){
		return this._getPaginatorValues('prevs');
	},
	getGoNextViews(){
		return this._getPaginatorValues('nexts');
	},
	getPagesViews(){
		return this._getPaginatorValues('pages');
	},


	_getPaginatorValues(name){
		let pag = this.getPaginator();
		let method = camelCase('get', name);
		let items = pag[method]() || [];
		return items;
	},
	buildCustom(name){
		let paginator = this.getPaginator();
		
		let current = paginator.isCurrent(name);
		let type = _.isString(name) ? name
			: paginator.isHelper(name) ? 'helper' : 'page';
		return new ButtonView({
			paginator,
			name,
			text: _.isString(name) ? '' : name,
			current,
			disabled: current,
			type,
			leftIcon: name == 'start' || name == 'prev',
			rightIcon: name == 'end' || name == 'next',
		});		
	}

});
