import '../api-config';
import { Collection } from 'base';
import { ActionModel } from 'mod/model-mixins';
import { paths } from 'helpers';
import { ModelSchemas } from 'bbmn-components';
import { createSchemaNotifies } from 'components/notifier';
import { socketChangeMixin } from 'mod/model-mixins';
paths.set('api', 'admin:import', 'import', { relative: true });

const datatypes = {
	
	realtyOffers: 'realtyOffersMigration',
	realtyObjects: 'realtyObjectsMigration',
	owners: 'ownersMigration',
	realties: 'realtiesMigration',
	realtyImages: 'realtiesImagesMigration',
	realtyObjectImages: 'realtyObjectsImagesMigration',
	realtyAddresses: 'realtyAddressesMigration',
	metroStations: 'metroStationsMigration',
	metroLines: 'metroLinesMigration',

};


export const Import = socketChangeMixin(ActionModel).extend({
	getSocketDataType(){
		if (!this.id) return;

		return datatypes[this.id];
		
	},
	getLastRunOffset(){
		let lr = this.get('lastRun');
		if(!lr) {
			return '&mdash;';
		} else {
			let d = new Date(lr);
			return d.toLocaleString('ru');
		}
	},
	run(data = {}){
		// let addToUrl = options.difference !== false ? 'difference' : '';
		// let data = {};
		// if (options.single) {
		// 	data.single = true;
		// }
		this.save({},{
			method: 'POST',
			addToUrl:'start?' + $.param(data),
		});
	}

});

export const Imports = Collection.extend({
	model: Import,
	url: paths.api('admin:import'),
});


ModelSchemas.initialize(Imports, {}, {
	notifies: createSchemaNotifies({ name: 'блоки импорта', generate: true, many: true })
});
