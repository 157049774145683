import { View, CollectionView, Collection, Model } from 'base';
import { _ } from 'vendors';
import { bus } from '../bus';
import { phoneMagicDisplay } from '../../../../helpers';
import { paths } from 'helpers';
import { BaseTBody } from '../TBody';

const Cell = View.extend({
	tagName: 'td',
	template: _.template('<span><%= value %></span>'),
	templateContext() {
		return {
			value: this.getValue() || '',
		}
	},
	getValue() {
		return this.getOption('text');
	}
})
const Id = Cell.extend({
	getValue() {
		return this.model.id;
	}
})

const Incoming = Cell.extend({
	className: 'incoming',
	getValue() {
		return phoneMagicDisplay(this.model.get('incomingNumber'));
	}
});
const Extension = Cell.extend({
	className: 'extension',
	getValue() {
		return this.model.get('extension');
	}

});
const Outgoing = Cell.extend({
	className: 'outgoing',
	getValue() {
		let v = this.model.get('outgoingNumber');
		return phoneMagicDisplay(v);
	}

});

const DbItem = CollectionView.extend({
	tagName: 'tr',
	renderCollection: false,
	renderAllCustoms: true,
	className: 'db-table-entry',
	customs: [
		Id,
		Incoming,
		Extension,
		Outgoing
	],
	customViewOptions() {
		return {
			model: this.model
		}
	}
	
});

const _TBody = CollectionView.extend({
	tagName: 'tbody',
	childView: DbItem,	
	initialize() {
		this.listenTo(bus, 'fetch:asterisk:click', this._onFetchClick);
		this.collection = new Collection();
		this.collection.url = paths.apiUrl('rlts/redirectPhones/asterisk-table');
	},
	async _onFetchClick() {
		bus.set('fetchAsteriskLocked', true);
		try {
			await this.collection.fetch();
		} catch {

		}
		bus.set('fetchAsteriskLocked', false);
	}
})


const TBody = BaseTBody.extend({
	eventName: 'fetch:asterisk:click',
	childView: DbItem,
	initializeCollection() {
		this.collection = new Collection();
		this.collection.url = paths.apiUrl('rlts/redirectPhones/asterisk-table');
	}
});

const THead = CollectionView.extend({
	tagName: 'thead',
	renderCollection: false,
	renderAllCustoms: true,
	customs: [
		{ class: Cell, text: 'Id', tagName: 'th' },
		{ class: Cell, text: 'Входящий', tagName: 'th' },
		{ class: Cell, text: 'Добавочный', tagName: 'th' },
		{ class: Cell, text: 'Переадресация', tagName: 'th' },
	]
});

export default CollectionView.extend({
	tagName: 'table',
	renderCollection: false,
	renderAllCustoms: true,
	className: 'db-table',
	customs: [
		THead,
		TBody
	],
})
