import _ from 'underscore';
import { CollectionView, Collection, View } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';
import { switcherInverse } from 'controls/boolean-switch';
import { busViews } from 'bus';
import { DisplayPropertySchemaView } from 'components/display-schema';
import { enums, buildViewByKey } from 'bbmn-utils';
import { PropertySchema } from 'bbmn-components';
const PromotionCoreView = ModelCoreView.extend({
	topText: m => m.display('disabled'),
	text: m => m.display('id'),
});
const FeedPromotionView = ListItemView.extend({
	modelView: PromotionCoreView,
	cssClassModifiers:[
		'feed-common-item-list',
		m => m.isDisabled() ? 'disabled' : '',
	],	
	leftItems: [
		m => switcherInverse(m, 'disabled'),
	],
	mainAction:'show:details'	
});


export const FeedPromotionsView = CollectionView.extend({
	//renderCollection: false,
	//renderAllCustoms: true,
	childView: FeedPromotionView,
	initialize(){
		this.collection = this.model.getPromotions();
	},
});


export const FeedPromotionDetailsViewBefore = CollectionView.extend({
	renderAllCustoms: true,
	getCustoms(){
		
		return [
			this.buildPropertyView('disabled'),
			this.buildPropertyView('weekendBehavior'),
			this.buildPropertyView('applyQuota'),
			this.buildPropertyView('addUsualAdds'),
			...this.buildQuotasViews()
		];
	},
	buildQuotasViews(){
		let quotas = this.model.entity('quotas');
		
		let feed = this.model.parent.parent;

		let categories = feed.getFeedConfigCategories({ enum: true });
		return _.map(categories, (label, id) => {
			return this.buildQuotaView(id, quotas, label);
		});
	},
	buildQuotaView(property, model, label){
		if(!model){
			model = this.model;
		}
		let schema = new PropertySchema({
			name: property,
			property:{
				value:{
					type:'number'
				},
				display:{
					label: 'квота ' + label,
					ifEmpty:'&ndash;'
				}
			}
		});
			//model.getPropertySchema(property);

		let options = {
			schema,
			property,
			model,
			mainAction: 'trigger:edit:click',
			saveOnResolveEditProperty: false,
		};
		
		return new DisplayPropertySchemaView(options);

	},
	buildPropertyView(property, model){
		if(!model){
			model = this.model;
		}
		let schema = model.getPropertySchema(property);

		let options = {
			schema,
			property,
			model,
			mainAction: 'trigger:edit:click',
			saveOnResolveEditProperty: false,
		};
		
		return new DisplayPropertySchemaView(options);
	}
});


const BaseDetails = CollectionView.extend({
	renderAllCustoms: true,
	buildPropertyView(property, model){
		if(!model){
			model = this.model;
		}
		let schema = model.getPropertySchema(property);
		let options = {
			schema,
			property,
			model,
			mainAction: 'trigger:edit:click',
			saveOnResolveEditProperty: false,
		};
		return new DisplayPropertySchemaView(options);
	}
});

const MainDetails = BaseDetails.extend({
	getCustoms(){
		
		return [
			this.buildPropertyView('disabled'),
			this.buildPropertyView('weekendBehavior'),
		];
	},
	
});
const QuotasDetails = BaseDetails.extend({
	getCustoms(){
		
		return [
			this.buildPropertyView('applyQuota'),
			this.buildPropertyView('addUsualAdds'),
			...this.buildQuotasViews()
		];
	},
	buildQuotasViews(){
		let quotas = this.model.entity('quotas');
		
		let feed = this.model.parent.parent;

		let categories = feed.getFeedConfigCategories({ enum: true });
		return _.map(categories, (label, id) => {
			return this.buildQuotaView(id, quotas, label);
		});
	},
	buildQuotaView(property, model, label){
		if(!model){
			model = this.model;
		}
		let schema = new PropertySchema({
			name: property,
			property:{
				value:{
					type:'number'
				},
				display:{
					label: 'квота ' + label,
					ifEmpty:'&ndash;'
				}
			}
		});
			//model.getPropertySchema(property);

		let options = {
			schema,
			property,
			model,
			mainAction: 'trigger:edit:click',
			saveOnResolveEditProperty: false,
		};
		
		return new DisplayPropertySchemaView(options);

	},
});

const PhonesDetails = BaseDetails.extend({
	getCustoms(){
		return [...this.buildPhonesViews()];
	},
	buildPhonesViews(){
		let phones = this.model.entity('phones');
		let feed = this.model.parent.parent;
		let categories = feed.getFeedConfigCategories({ enum: true });
		console.log('PHONEs', phones);
		return _.map(categories, (label, id) => {
			return this.buildPhoneView(id, phones, label);
		});
	},
	buildPhoneView(property, model, label){
		let feed = this.model.parent.parent;
		let customPhones = feed.getCustomPhonesEnum();
		let schema = new PropertySchema({
			name: property,
			property:{
				value:{
					type:'enum',
					multiple: false,
					sourceValues: customPhones
				},
				display:{
					label: 'телефон для ' + label,
					ifEmpty:'&ndash;',
					transform(v){
						let phone = feed.getCustomPhone(v);
						if (!phone) return '';
						return phone.display();
					}
				}
			}
		});
			//model.getPropertySchema(property);

		let options = {
			schema,
			property,
			model,
			mainAction: 'trigger:edit:click',
			saveOnResolveEditProperty: false,
		};
		
		return new DisplayPropertySchemaView(options);

	},	
});

export const FeedPromotionDetailsView = View.extend({
	className:'feed-promotion-details',
	template: _.template(`<div class="tabs-triggers">
		<a href="javascript:" data-tab="main">основное</a>
		<a href="javascript:" data-tab="quotes">квоты</a>
		<a href="javascript:" data-tab="phones">телефоны</a>
	</div>
	<div class="content"></div>`),
	regions:{
		content: '.content'
	},
	onRender(){
		let tab = this.currentTab || 'main';
		this.showTab(tab);
	},
	showTab(name){
		this.$('[data-tab]').removeClass('active');
		this.$(`[data-tab="${name}"]`).addClass('active');

		this.currentTab = name;
		let view = buildViewByKey(this, name, { options: { model: this.model } });

		if (view)
			this.showChildView('content', view);
		else
			this.getRegion('content').empty();
	},
	events:{
		'click [data-tab]'(e){
			let $e = $(e.target).closest('[data-tab]');
			let tab = $e.data('tab');
			this.showTab(tab);
		}
	},
	main: MainDetails,
	quotes: QuotasDetails,
	phones: PhonesDetails
});


busViews.class('FeedPromotionDetailsView', FeedPromotionDetailsView);
