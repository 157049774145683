import BasePage from 'pages/auth';
import Import from './import';
import Roles from './roles';
import Users from './users';
import Configs from './configs';
import Phones from './phones';

export default BasePage.extend({
	id:'admin',
	routes: 'admin',
	label: 'Администрирование',
	noAside: true,
	relativeRoutes: true,
	children: [Import, Roles, Users, Configs, Phones],
	rights:{
		admin: 'view',
	}
});
