import { Collection } from 'base';
import { ActionModel } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';

export const Subagent = ActionModel.extend({

});
ModelSchemas.initialize(Subagent, {
	firstName: {
		validation:{
			required: true,
		},
		display: {
			label:'Имя'
		}
	},
	lastName: {
		validation:{
			required: true,
		},
		display: {
			label:'Фамилия'
		}
	},
	phone: {
		validation:{
			required: true,
		},
		display: {
			label:'Телефон'
		}
	},	
	email: {
		validation:{
			required: true,
		},
		display: {
			label:'Email адрес'
		}
	},
});

export const Subagents = Collection.extend({
	model: Subagent
});
