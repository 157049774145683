import './expose-jquery';
import 'signalr';
import './remove-jquery';


import load from './load-signalr';
import cfg from 'app/config';
import ready from './ready-promise';
import Handler from './handler';

export default {
	start(){
		if(cfg.socketsLoadScripts){
			load();
		}else{
			ready.resolve();
		}
		ready.promise.then(() => {
			
			console.log('sockets ok');
			var handler = this.handler = new Handler({
				hubName: cfg.socketsName,
			});
			handler.start();

		}).catch((er) => {
			
			console.error('sockets failed to start',er);
		});

	}
};
