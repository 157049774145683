import { AtomTextView } from 'bbmn-components';
import { mainAction } from './helpers';

export const ModelCoreView = AtomTextView.extend({
	constructor: function(){
		AtomTextView.apply(this, arguments);
		this._initializeMainAction();
		if (this.model) {
			this.listenTo(this.model, 'change', this.render);
		}
	},
	_initializeMainAction(){
		let action = mainAction(this);
		if(action) {
			this.delegate('click', (event) => this.model.executeAction(action, event));
		}
	},
});
