
//import { _ } from 'vendors';
import { View, CollectionView, Collection, Model } from 'base';
import { paths } from 'helpers';

import TopActions from './TopActions';
import AsteriskTable from './AsteriskTable';



export default CollectionView.extend({
	className: 'phone-redirect-table-page',
	renderCollection: false,
	renderAllCustoms: true,
	customs: [
		TopActions,
		AsteriskTable,
		// RealtyItemInfo,
		// ContactAtomItem
	],
})