import _ from 'underscore';
import { historyApi, history } from 'bbmn-routing';
import getEventFields from './get-event-fields';

import cfg from 'app/config';
// console.warn('historyApi:', historyApi);
// console.warn('history:', history);

function navigate(url, opts = {}){
	_.extend(opts, {trigger:true});
	historyApi.navigate(url, opts);
}

function open(url, opts = {}){
	if(!opts.altKey && !opts.ctrlKey){
		navigate(url);
	} else if(opts.altKey){
		if (url.startsWith('/')) {
			url = url.substring(1);
		}
		if (url.startsWith('#')) {
			url = url.substring(1);
		}
		
		let page = this.getPageByUrl(url);

		if(page && !this.isCurrentPage(page)){
			page.openInModal(url, opts);
		}
		
	}else if(opts.ctrlKey){

		!cfg.pushState && !url.startsWith('#') && ( url = '#' + url );
		cfg.pushState && !url.startsWith('/') && ( url = '/' + url );

		let win = window.open(url,'_blank');
		win.focus();
		return;
	}
}

export default function openUrl(url, arg){
	let opts = arg;
	if(arg instanceof Event || arg instanceof $.Event)
		opts = getEventFields(arg);
	return open(url, opts);
}
