import _ from 'underscore';
import { CollectionView } from 'pages/views';
import { UiBlockView } from 'components/views';
import DisplaySchema from 'components/display-schema';
import { FeedPacksListView, FeedPromotionsView, FeedCategoriesView, FeedPhonesList, FeedSubagentsList } from 'mod/export/feeds/views';

export default CollectionView.extend({
	className: 'ui-blocks',
	renderAllCustoms: true,
	getCustoms(){
		return [
			this.getModelView(),
			this.getSetupView(),
			this.getPhonesView(),
			this.getSubagentsView(),
			this.getCategoriesView(),
			this.getPromotionsView(),
			this.getPacksView(),
		];
	},
	getPhonesView(){
		let feed = this.model;
		let sets = this.model.entity('settings');
		let phonesModel = sets.entity('phones');
		return new UiBlockView({
			header: 'телефоны',
			feed: this.model,
			model: this.model,
			content: FeedPhonesList,
			contentOptions:{
				feed: this.model,
				model: phonesModel,
				modelEvents:{
					'change'(){
						feed.save(null, { attrs: { settings: sets.toJSON() }, wait: true, method: 'patch' });
					}
				}
			},
			actions: ['add:phone'],
			// contentOptions:{
			// 	model: this.model, 
			// 	propertiesToShow: ['name', 'url', 'disabled', 'public', 'lastAccess'],
			// },
			// actions:['show:results', 'get:counts']
		});		
	},
	getModelView(){
		let feed = this.model;
		return new UiBlockView({
			model: this.model,
			content: DisplaySchema,
			contentOptions:{
				model: this.model, 
				propertiesToShow: [
					'name', 'url', 'disabled', 
					{ 
						name: 'descriptionTemplate',  
						edit: {
							feed
						}
					},
					/*{
						name: 'settings.descriptionTemplate',
						onResolve(value){
							var setts = feed.entity('settings');
							setts.set('descriptionTemplate', value);
							feed.save(null, { attrs: { settings: setts.toJSON() }, method: 'patch' });
							feed.trigger('change:settings.descriptionTemplate', value);
							this.destroy();
						},
						edit: {
							feed
						}
					},*/ 
					'public', 'lastAccess'
				],
			},
			actions:['show:results', 'get:counts']
		});
	},
	getSetupView(){
		return new UiBlockView({
			model: this.model,
			header: 'настройки',
			content: DisplaySchema,
			contentOptions:{
				model: this.model,
				propertiesToShow: ['applyGrouping', 'weekendAsUsual', 'takeOnlyPromoted', 'maximumAd'],
			}
		});
	},
	getPromotionsView(){
		let feed = this.model;
		let model = feed.entity('promotions');
		var promos = feed.getFeedConfigPromotions({ enum: true });
		
		if (!_.size(promos)) return;
		return new UiBlockView({
			model,
			header: 'продвижения',
			content: FeedPromotionsView,
			onInitialized(){
				this.listenTo(feed, 'change:promotions', (model, changes, { changeInitiator } = {}) => {
					if (changeInitiator != null) {
						feed.save({ promotions: feed.get('promotions') });
					}
				});
			},
			contentOptions:{
				feed,
				model,
			},			
		});
	},
	getCategoriesView(){
		let feed = this.model;
		let model = feed.entity('categories');

		return new UiBlockView({
			model,
			header: 'категории объявлений',
			content: FeedCategoriesView,
			onInitialized(){
				this.listenTo(feed, 'change:categories', (model, changes, { changeInitiator } = {}) => {
					if (changeInitiator != null) {
						feed.save({ categories: feed.get('categories') });
					}
				});
			},			
			contentOptions:{
				feed,
				model,
				collection: model.getCategories()
			},			
		});
	},	
	getPacksView(){
		return new UiBlockView({
			model: this.model,
			header: 'группы объявлений',
			content: FeedPacksListView,
			contentOptions:{
				model: this.model,
				collection: this.model.entity('packs'),
				childViewOptions:{
					mainAction: 'open:card'
				},
			},
			actions:['create:pack'],
		});
	},
	getSubagentsView() {
		if (this.model.get('format') !== 'cian2') return;
		let collection = this.model.entity('settings').entity('subagents');
		console.log('MY COLLECTION', FeedSubagentsList);
		return new UiBlockView({
			header: 'субагенты',
			feed: this.model,
			model: this.model,
			content: FeedSubagentsList,
			contentOptions:{
				feed: this.model,
				collection
				// model: phonesModel,
				// modelEvents:{
				// 	'change'(){
				// 		feed.save(null, { attrs: { settings: sets.toJSON() }, wait: true, method: 'patch' });
				// 	}
				// }
			},
			actions: ['add:subagent'],
			// contentOptions:{
			// 	model: this.model, 
			// 	propertiesToShow: ['name', 'url', 'disabled', 'public', 'lastAccess'],
			// },
			// actions:['show:results', 'get:counts']
		});		
		//console.log(this.model.attributes);
	}
});
