import { Collection, ManagedCollection, Model } from 'base';
import { paths } from 'helpers';
import { ModelSchemas } from 'bbmn-components';
import { SelectContactView } from './views/SelectContactView';



export const CustomContact = Model.extend({});

export const EntityCustomContact = Model.extend({

});



export const CustomContacts = ManagedCollection.extend({
	
});


export const customContactsCollection = new Collection([]);
customContactsCollection.url = paths.apiUrl('rlts/customContacts');

const schema = {
	'customContactId': {
		value:{
			control:'select',
			sourceValues: () => {
				customContactsCollection.fetchIfNot();
				return customContactsCollection;
			}
		},
		edit: {
			extractValue: value => value.id,
			childView: SelectContactView,
			rejectHard: true,
			// filtered: true,
			// childView: () => busViews.getClass('RealtyListItemView')
		},		
		display:{
			label:'телефон из справочника',
			transform(v) {
				const model = customContactsCollection.get(v);
				if (model) {
					const { name, value, notVisible } =  model.attributes;
					return `<span class="custom-contact-display${(notVisible ? ' not-visible' : ' visible')}">${name}: ${value}<i class="eye-icon"></i></span>`;
				}
				return '&ndash;';
			},
			ifEmpty: '&ndash;'
		}
	},
	'ownCustomContact.label':{
		// validation: {
		// 	required:true
		// },
		edit: {
			rejectHard: true,
		},

		display:{
			ifEmpty: '&ndash;',
			label:'Текст подписи'
		}
	},
	'ownCustomContact.value':{
		// validation: {
		// 	required:true
		// },
		edit: {
			rejectHard: true,
		},
		display:{
			ifEmpty: '&ndash;',
			label:'Телефон'
		}
	},
	'ownCustomContact.notVisible':{
		value: {
			type: 'boolean',
			control: 'select:inline',
			sourceValues: { true: 'Скрыт', false: 'Отображается' },
			nullAllowed: true,
		},
		// validation: {
		// 	required:true
		// },
		display:{
			ifEmpty: 'не установлено',
			label: 'отображение на странице сайта',
			// transform(v) {
			// 	return v;
			// 	// const cc = this.get('ownCustomContact') || this.get('customContact') || {};
			// 	// if (cc.value) {
			// 	// 	return v;
			// 	// } else {
			// 	// 	return '&ndash;';
			// 	// }
			// }
		}
	},
	'ownCustomContact.inheritanceDisabled': {
		value: {
			type: 'boolean',
			control: 'select:inline',
			sourceValues: { true: 'Не наследуется', false: 'Наследуется' },
			nullAllowed: true,
		},
		// validation: {
		// 	required:true
		// },
		display:{
			ifEmpty: 'не установлено',
			label:'наследование',
			// transform(v) {
			// 	return v;
			// 	// const cc = this.get('ownCustomContact') || this.get('customContact') || {};
			// 	// if (cc.value) {
			// 	// 	return v;
			// 	// } else {
			// 	// 	return '&ndash;';
			// 	// }
			// }
		}
	},
	'migrateContact.autoDetect': {
		value: {
			type: 'boolean',
			nullAllowed: true,
			sourceValues: { true: 'Включена', false: 'Отключена' },
		},
		display: {
			ifEmpty: 'значение не установлено',
			label: 'Автопубликация телефона',
		}
	},
	'migrateContact.autoDetectOwner': {
		value: {
			type: 'boolean',
			nullAllowed: true,
			sourceValues: { true: 'Включено', false: 'Отключено' },
		},
		display: {
			ifEmpty: '&ndash;',
			label: 'Автоопределение собственника'
		}
	},
	'migrateContact.autoDetectContact': {
		value: {
			type: 'boolean',
			nullAllowed: true,
			sourceValues: { true: 'Включено', false: 'Отключено' },
		},
		display: {
			ifEmpty: '&ndash;',
			label: 'Автоопределение контакта'
		}
	},
	'migrateContact.autoDetectValue': {
		value: {
			type: 'boolean',
			nullAllowed: true,
			sourceValues: { true: 'Включено', false: 'Отключено' },
		},
		display: {
			ifEmpty: '&ndash;',
			label: 'Автоопределение номера'
		}
	},
	'migrateContact.ownerId': {
		value: {
			sourceValues(model) {
				const src = model.getContactSourceValues('owner');
				return src;
			}
		},
		edit: {
			rejectHard: true,
		},
		display: {
			refreshOn: 'change:migrateContact',
			ifEmpty: 'не указан',
			label: 'Собственник',
			transform(v, options = {}) {
				return options.model.displayValue('owner', v, this.ifEmpty);
			}
		}
	},
	'migrateContact.contactId': {
		value: {
			sourceValues(model) {
				const src = model.getContactSourceValues('contact');
				return src;
			}
		},
		edit: {
			rejectHard: true,
		},
		display: {
			ifEmpty: 'не указан, работает автоопределение если разрешено',
			label: 'Контакт',
			refreshOn: 'change:migrateContact',
			transform(v, options = {}) {
				return options.model.displayValue('contact', v, this.ifEmpty);
			}
		}
	},
	'migrateContact.contactValueId': {
		value: {
			sourceValues(model) {
				const src = model.getContactSourceValues('contactValue');
				return src;
			}
		},
		edit: {
			rejectHard: true,
		},
		display: {
			refreshOn: 'change:migrateContact',
			ifEmpty: 'не указан, работает автоопределение если разрешено',
			label: 'Телефон',
			transform(v, options = {}) {
				return options.model.displayValue('contactValue', v, this.ifEmpty);
			}
		}
	},
	'migrateContact.useProxyNumber': {
		display: {
			ifEmpty: 'не указано, используется глобальная настройка',
			label: 'Использовать номер переадресации'
		},
		value: {
			type: 'boolean',
			nullAllowed: true,
			sourceValues: { true: 'Переадресация включена', false: 'Без переадресации' },
		},
	},	
	'migrateContact.label': {
		display: {
			ifEmpty: '&ndash;',
			label: 'Подпись телефона'
		}
	},
	'migrateContact.fallbackPhone': {
		display: {
			ifEmpty: '&ndash;',
			label: 'Телефон для подстановки, если автоопределение не сработало'
		}
	},
	'migrateContact.fallbackLabel': {
		display: {
			ifEmpty: '&ndash;',
			label: 'подпись телефона для подстановки (если не указана, будет использована подпись телефона)'
		}
	},
}
ModelSchemas.initialize(EntityCustomContact, schema)
