import _ from 'underscore';
import { enums } from 'bbmn-utils';

export function getFilters(feed){

	const _filters = [
		{
			id: 'name',
			compareProperty: 'building.name',
			name: 'название bc содержит',
			valueType: 'text',
		},
		{
			id: 'address',
			compareProperty: 'address',
			name: 'адрес содержит',
			valueType: 'text',
		},
		{
			id: 'category',
			compareProperty: 'category',
			name: 'категория',
			valueType: 'enum',
			sourceValues: enums.get('cianObjectCategories'),
			multiple: false,
		},

	];

	const filters = [
		{
			id: 'name',
			compareProperty: 'realty.name',
			name: 'название bc содержит',
			valueType: 'text',
		},
		{
			id: 'address',
			compareProperty: 'realty.fullAddress',
			name: 'адрес содержит',
			valueType: 'text',
		},
		{
			id: 'rgadminarea1',
			compareProperty: 'realty.gdAdminArea1',
			name: 'Регион / область',
			valueType: 'text',
		},		
		{
			id: 'category',
			compareProperty: 'categoryId',
			name: 'категория',
			valueType: 'enum',
			sourceValues: feed.getFeedConfigCategories({ enum: true }), //enums.get('cianObjectCategories'),
			multiple: false,
		},
		{
			id: 'promotion',
			compareModelValue: (model, filter) => {
				let promos = model.get('promotions') || {};
				return _.keys(promos).join();
			},
			name: 'тип продвижения',
			valueType: 'enum',
			sourceValues: feed.getFeedConfigPromotions({ enum: true }), //enums.get('cianObjectCategories'),
			multiple: false,
		},
		{
			id: 'promotionType',
			compareModelValue: (model, filter) => {
				let promo = filter.collection.get('promotion');
				let promotion = promo.getValue();
				let promos = model.get('promotions') || {};
				if (promotion == null) {
					return _.map(promos, (v,k) => v).join();
				} else {
					return promos[promotion];
				}
				// console.log('[*]', promo.getValue());

				// return _.keys(promos).join();
			},
			name: 'особенность продвижения',
			valueType: 'enum',
			sourceValues: { autoPromotion:'автопродвижение', forcedPromotion: 'ручное продвижение', preventPromotions: 'запрет продвижения' }, //enums.get('cianObjectCategories'),
			multiple: false,
		},
		{
			id:'ownTemplate',
			compareProperty: 'desc.isOwnTemplate',
			valueType: 'boolean',
			name: 'собственное описание',
			sourceValues: { true: 'Да', false: 'Нет'}
		}

	];

	return filters;
}
