
import { View, CollectionView, Collection, Model } from 'base';
import { _ } from 'vendors';


export const RealtyItemInfo = View.extend({
	className: 'realty-info',
	template: _.template(`
		<div class="name"><a href="/rlts/realties/<%= realtyId%>"><%= realtyName %></a></div>
		<div class="address"><%= fullAddress %></div>
		<div>
			<a href="<%= url %>" class="small" data-noroute="true" target="_blank">открыть страницу сайта в новой вкладке</a>
		</div>
	`),
	templateContext() {
		return {
			url: this.getUrl()
		}
	},
	getUrl() {
		let market = this.model.get('market') || 'offices';
		let id = this.model.get('realtyId');
		let path = `/${market}/objects/${id}/`;
		return BUILD_PUB_SITE + path;
	}
});
