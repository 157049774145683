import { View, CollectionView } from 'base';
//import { ContactAtomItem, ContactEditBox } from 'mod/realties/custom-contacts/views';
import { setByPath } from 'bbmn-utils';
import { ContactEditBox } from './ContactEditBox';
import { ContactAtomItem } from './ContactAtomItem';
import { TextView, modals } from 'bbmn-components';
import { OwnerContactsBox } from './OwnerContactsBox';

const ContactInfo = CollectionView.extend({
	className: 'contacts-info card-item white-box',
	renderCollection: false,
	renderAllCustoms: true,
	customs: v => [
		ContactAtomItem
	],
	customViewOptions() {
		return {
			model: this.getOption('entity'),
			entityType: this.getOption('entityType'),
			object: this.getOption('object'),
			realty: this.getOption('realty')
		}
	},
});

export const ContactEditForm = CollectionView.extend({
	className: 'contacts-form',
	renderCollection: false,
	renderAllCustoms: true,


	
	customViewOptions() {
		return {
			entity: this.model,
			entityType: this.getOption('entityType'),
			object: this.getOption('object'),
			realty: this.getOption('realty')
		}
	},
	getRealty() {
		const type = this.getOption('entityType');
		if (type === 'realty') {
			return this.model;
		} else {
			return this.getOption('realty');
		}
	},	
	//DEBUGGER: true,
	getMigrateContactSchemaProperties() {
		if (this.getOption('entityType') === 'realty') {
			return [
				'migrateContact.autoDetect',
				// 'migrateContact.autoDetectOwner',
				// 'migrateContact.autoDetectContact',
				// 'migrateContact.autoDetectValue',
				'migrateContact.ownerId',
				'migrateContact.contactId',
				'migrateContact.contactValueId',
				'migrateContact.useProxyNumber',
				'migrateContact.label',
				'migrateContact.fallbackPhone',
				'migrateContact.fallbackLabel',
			];
		} else {
			return [
				'migrateContact.autoDetect',
				// 'migrateContact.autoDetectContact',
				// 'migrateContact.autoDetectValue',
				'migrateContact.contactId',
				'migrateContact.contactValueId',
				'migrateContact.useProxyNumber',
				'migrateContact.label',
				'migrateContact.fallbackPhone',
				'migrateContact.fallbackLabel',
			];
		}
	},
	customs: [
		v => ContactInfo,
		{ class: ContactEditBox, header: 'собственный телефон' },
		{ class: ContactEditBox, properties: ['customContactId'], header: 'телефон из справочника' },
		v => ({ 
						class: ContactEditBox, editModelType: 'migrate', 
						onBeforeSaveChanges(attrs) {
							if ('migrateContact.ownerId' in attrs) {
								if (attrs['migrateContact.ownerId'] === undefined) {
									attrs['migrateContact.ownerId'] = null;
								}
								attrs['migrateContact.contactId'] = null;
							}
							if ('migrateContact.contactId' in attrs) {
								if (attrs['migrateContact.contactId'] === undefined) {
									attrs['migrateContact.contactId'] = null;
								}
								attrs['migrateContact.contactValueId'] = null;
							}
							console.log('onBeforeSaveChanges', attrs);
						},
						properties: v.getMigrateContactSchemaProperties(), 
						header: 'Настройка публикации телефонов из миграции' 
					}),
		{ class: OwnerContactsBox, header: 'контакты собственников' }
	],
	childViewEvents: {
		'contact:saved'(attrs) {
			console.warn('contact:saved=', attrs);
			Object.keys(attrs).forEach(key => {
				setByPath(this.model, key, attrs[key], { silent: true });
			});

			this.model.trigger('change', this.model);
			//console.log('~~~~ model CHANGES ~~~~~', this.model.attributes.migrateContact);
		}
	}
});

export function contactModalEditForm(options) {

	let content = new ContactEditForm(options);

	let header = new TextView({ tagName: 'header', text: 'редактирование контакта' });

	let className = 'fullscreen';

	modals.show({ className, header, content });

}