import _ from 'underscore';
import { BooleanSwitchControl } from 'bbmn-controls';
import { isModel } from 'bbmn-utils';

function normalizeArguments(value, onControlChange, arg){
	let model;
	if (isModel(value)) {
		model = value;
		let property = onControlChange;
		let method = arg || 'set';
		value = model.get(property);
		onControlChange = value => model[method](property, value);
	} else if(_.isObject(value)){
		let { method, property } = value;
		model = value.model;
		value = model.get(property);
		onControlChange = value => model[method](property, value);
	}
	return {
		value,
		onControlChange,
		model,
	};
}

export function switcher(value, onControlChange, arg, arg2){
	let options = normalizeArguments(value, onControlChange, arg, arg2);
	return new BooleanSwitchControl(options);
}

export function switcherInverse(value, onControlChange, arg, arg2){
	let options = normalizeArguments(value, onControlChange, arg, arg2);
	options.className = 'inverse-bool';
	return new BooleanSwitchControl(options);
}
