import { CollectionView, View } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';
import { phoneSchema } from 'components/phone';
import PhoneControl from 'controls/phone';
import editor from 'components/editor';
import modals from 'components/modals';
import user from 'app/user';
const CorePhoneView = ModelCoreView.extend({
	topText: m => m.display('id'),
	text: m => m.display()
});

export const FeedPhonesListItem = ListItemView.extend({
	getFeed(){
		return this.getOption('feed');
	},
	modelView: CorePhoneView,
	editButton(){
		return user.checkRights({ export: 'editFeeds' });
	},
	removeButton(){
		return this._isModelView && user.checkRights({ export: 'editFeeds' });
	},
	onEditClick(){
		let model = this.model;
		modals.control('phone', { 
			value: model.toJSON(),
			header: 'изменение номера',
			onResolve(data){
				model.set(data);
				this.destroy();
			},
			onReject(){
				this.destroy();
			}
		});
	},
	onRemoveClick() {
		let col = this.model.collection;
		col && col.remove(this.model);
	}
});

export const FeedPhonesList = CollectionView.extend({
	renderCollection: true,
	renderAllCustoms: true,
	childView: FeedPhonesListItem,
	emptyView: null,
	initialize(){
		this.collection = this.model.entity('customs');
		this.once('customs:render', this.sort);
	},
	// onCustomsRender(){
	// 	console.log('*	*	*:');
	// 	this.sort();
	// },
	viewComparator(v1,v2) {
		return v1._index - v2._index;
	},
	childViewOptions(){
		return {
			feed: this.model.parent.parent
		};
	},
	getCustoms(){
		return [
			[new FeedPhonesListItem({
				model: this.model.entity('default'),
				feed: this.model.parent.parent,
			}), -2],
			[new FeedPhonesListItem({
				model: this.model.entity('promotions'),
				feed: this.model.parent.parent,
			}), -1],
		];
	},	
});
