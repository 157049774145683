import './api-config';
// import _ from 'underscore';
import {  ManagedCollection } from 'base';
// import { CardModel, ActionModel } from 'mod/model-mixins';
// import { ModelSchemas } from 'bbmn-components';
import { paths } from 'helpers';
// import { createSchemaNotifies } from 'components/notifier';
// import { action, ActionStore } from 'components/actions';
//import { modals } from 'bbmn-components';
//import { EditModel } from 'bbmn-controls';
// import ModelStore from 'backbone.store';
// import editor from 'components/editor';
// import { enums, getByPath, isEmptyValue } from 'bbmn-utils';
import { NewsModel } from './NewsModel';


export const NewsCollection = ManagedCollection.extend({
	model: NewsModel,
	// url: paths.api('news'),
	concurrentFetch:'allowed',
	fetchMethodName: 'POST',
});

export const CompanyNewsCollection = NewsCollection.extend({
	url: paths.api('news:company'),
});

export const BuildingNewsCollection = NewsCollection.extend({
	url: paths.api('news:building'),
});