import _ from 'underscore';
import { CollectionView, Collection, View } from 'base';
import { ListItemView, ModelCoreView, AtomTextView } from 'components/views';
import { switcherInverse } from 'controls/boolean-switch';
import { busViews } from 'bus';
import { DisplayPropertySchemaView } from 'components/display-schema';
import { enums } from 'bbmn-utils';
import { PropertySchema, TextView } from 'bbmn-components';

const CategoryCoreView = ModelCoreView.extend({
	topText: m => m.get('disabled') ? 'запрещены' : 'публикуются',
	text: m => m.display('id'),
});

const FeedCategoryView = ListItemView.extend({
	modelView: CategoryCoreView,
	cssClassModifiers:[
		'feed-category-item-list',
		m => m.isDisabled() ? 'disabled' : '',
	],	
	leftItems: [
		m => switcherInverse(m, 'disabled'),
	],
	rightItems: [
		m => new AtomTextView({ 
			renderOnModelChange: true,
			model: m, 
			text: m => m.get('maximum') != null ? m.get('maximum') : '&ndash;' 
		})
	],
	mainAction:'show:details'	
});


export const FeedCategoriesView = CollectionView.extend({
	//renderCollection: false,
	renderAllCustoms: true,
	childView: FeedCategoryView,
});


export const FeedCategoryDetailsView = CollectionView.extend({
	renderAllCustoms: true,
	getCustoms(){
		let feed = this.model.parent.parent;
		return [
			this.buildPropertyView('disabled'),
			this.buildPropertyView('maximum'),
			this.buildPropertyView('phoneId'),
			this.buildPropertyView('descriptionTemplate', { propertyOptions: { edit: { feed } } })
			//...this.buildQuotasViews()
		];
	},
	/*
	buildQuotasViews(){
		let quotas = this.model.entity('quotas');
		return _.map(enums.get('cianObjectCategories'), (label, id) => {
			return this.buildQuotaView(id, quotas);
		});
	},
	buildQuotaView(property, model){
		if(!model){
			model = this.model;
		}
		let schema = new PropertySchema({
			name: property,
			property:{
				value:{
					type:'number'
				},
				display:{
					label: 'квота ' + enums.get('cianObjectCategories', property),
					ifEmpty:'&ndash;'
				}
			}
		});
			//model.getPropertySchema(property);

		let options = {
			schema,
			property,
			model,
			mainAction: 'trigger:edit:click',
			saveOnResolveEditProperty: false,
		};
		
		return new DisplayPropertySchemaView(options);

	},
	*/
	buildPropertyView(property, opts, model){
		if(!model){
			model = this.model;
		}
		let schema = model.getPropertySchema(property);

		let options = _.extend({
			schema,
			property,
			model,
			mainAction: 'trigger:edit:click',
			saveOnResolveEditProperty: false,
		}, opts);
		console.log('>>>>', options, this);
		return new DisplayPropertySchemaView(options);
	}
});
busViews.class('FeedCategoryDetailsView', FeedCategoryDetailsView);
