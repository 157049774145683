import { Model, CollectionView, View, Collection } from 'base';
import { RealtyObjectCardItem } from './RealtyObjectCardItem';
import { getByPath } from 'bbmn-utils';
import { RealtyObjectModel } from '../models';
import { RealtyObjectsListHeader } from './RealtyObjectsListHeader';
import { ObjectsFilterModel } from './ObjectsFilter';

function gmv(v, key, def) {

	const res = getByPath(v.model, key);
	if (res == null) {
		return def;
	}
	return res;
}

function cmp(v1, v2) {
	const res = v1 === v2 ? 0
	: v1 < v2 ? -1
	: 1;
	//console.error(v1,v2,res);
	return res;
}

function compare(v1, v2, arg, def) {
	if (typeof arg === 'function') {
		v1 = arg(v1);
		v2 = arg(v2);
		return cmp(v1, v2);
	}
	if (typeof arg === 'string') {
		v1 = gmv(v1, arg, def);
		v2 = gmv(v2, arg, def);
		return cmp(v1,v2);
	}
	return 0;
}

function compareItems(v1, v2, comparers) {
	for(let x = 0; x < comparers.length; x++) {
		const comparer = comparers[x];
		const res = comparer(v1, v2);
		if (res !== 0) {
			return res;
		}
	}
	return 0;
}

export const RealtyObjectsList = CollectionView.extend({
	className: 'realty-objects-list card-items',
	initialize() {
		//console.warn(" OBJECTS INI ")
		this.initializeCollection();
		this.initializeFilterModel();
	},
	initializeFilterModel() {
		this.filterModel = this.getOption('filterModel');
		if (!this.filterModel) { return; }
		this.listenTo(this.filterModel, 'change', this.tryFilter)
	},
	tryFilter() {
		if (!this.filterModel) { return; }
		this.filter();
	},
	initializeCollection() {
		this.collection = new Collection([], { model: RealtyObjectModel });
		this.collection.realty = this.model;
		this.collection.url = this.model.url() + '/objects';
		this.collection.fetch();
		console.warn(this.collection);
		// this._setModels();
		// this.listenTo(this.model, 'sync', this._setModels);		
	},
	// _setModels() {
	// 	const models = this.model.get('objects') || [];
	// 	this.collection.add(models, { remove: true });
	// },
	childView: RealtyObjectCardItem,
	childViewOptions() {
		return {
			realty: this.model
		}
	},
	viewFilter(view) {
		if (!this.filterModel) { return true; }
		return this.filterModel.filter(view.model, this.model);
	},
	viewComparator(v1, v2) {
		return compareItems(v1, v2, [
			(v1, v2) => compare(v2, v1, v => gmv(v, 'offers', []).some(s => s.status === 'active') ? 1 : 0),
			(v1, v2) => compare(v2, v1, v => gmv(v, 'offers', []).length),
			(v1, v2) => compare(v1, v2, 'info.floor', Infinity),
			(v1, v2) => compare(v1, v2, 'info.squareOffer', Infinity)
		]);
	}
});

export const RealtyObjectsListContainer = CollectionView.extend({
	className: 'realty-objects-list-container',
	renderCollection: false,
	renderAllCustoms: true,
	customs: [
		RealtyObjectsListHeader,
		RealtyObjectsList
	],
	initialize() {
		this.filterModel = new ObjectsFilterModel();
		this.filterModel.realty = this.model;
	},
	customViewOptions() {
		return {
			collection: this.collection,
			model: this.model,
			filterModel: this.filterModel,
		}
	}
})