import BasePage from 'pages/auth';


import { actionableMixin, action } from 'components/actions';
import editor from 'components/editor';
import { ModelSchemas } from 'bbmn-components';

import { Users, User } from 'mod/adm/users/models';
import Content from './content';


export default actionableMixin(BasePage).extend({
	id:'admin-users',
	routes: 'users',
	label: 'Управление пользователями',
	children:[],
	Content,
	rights:{
		admin: true,
	},
	startPromises: [
		page => page.collection.fetch(),
	],
	initialize(){
		this.collection = new Users();
	},	
	actions:[
		action('invite', 'пригласить пользователя', function(e, page){

			//let parent = this;
			editor.add({
				schema: ModelSchemas.get(User),
				propertiesToShow: ['email'],
				onResolve(values){
					let model = new User();
					model.invite(values).then(() => {
						page.collection.add(model);
						this.destroy();
					});
				}
			}, 'Приглашение нового пользователя');
	
		}, ['admin: manageUsers']),
	],	

});
