import _ from 'underscore';
import { View, CollectionView } from 'base';

export default CollectionView.extend({
	onRender(){
		this.showView();
	},
	showView(){
		let view = this.getView();
		view && this.addChildView(view);
	},
	getView(){
		if (this._jobDone) {
			return this.getPostFormView();
		} else {
			return this.getFormView();
		}
	},
	getFormView(){},
	getPostFormView(){
		let template = this.getPostFormTemplate();
		if(_.isString(template)) {
			template = _.template(template);
		}
		return new View({ template });
	},
	getPostFormTemplate(){
		return this.getOption('postFormTemplate', { force:false });
	},
	job(){},
	submit(){
		let result = this.job.apply(this, arguments);
		let promise = Promise.resolve();		
		if(result && result.then) {
			promise = result;
		}
		return promise.then(() => this.onSubmit());
	},
	onSubmit(){
		this._jobDone = true;
		this.render();
	}
});
