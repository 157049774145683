import BasePage from 'pages/anon';
import Layout from './layout';

export default BasePage.extend({
	routes: 'forgotPass',
	label: 'восстановление пароля',
	menuLabel: 'забыли пароль?',
	Layout,
	visibility:'anonym',
});
