import BasePage from 'pages/anon';
import Content from './layout';
export default BasePage.extend({
	routes: 'login',
	label: 'вход в систему',
	relativeRoutes: true,
	menuLabel: 'войти',
	visibility:'anonym',
	Content,
	// onStart(){
	// 	Console.log('i am login');
	// }
});
