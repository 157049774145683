import { CollectionView } from 'pages/views';
import { Button } from 'bbmn-controls';
import { ConfigItemView } from 'mod/adm/configs/views';

export default CollectionView.extend({
	name: 'adm-roles',
	className: 'roles-cards cards',
	renderAllCustoms: true,	
	childView: ConfigItemView,
	//customs:[v => [v.createListButton(), { index: Infinity }]],
	childViewOptions:{
		mainAction: 'open:card'
	},
	createListButton(){

		let action = this.page.getAction('add');
		if(action){
			return new Button({
				text: 'добавить роль',
				onClick: (e) => {
					action.exec(this.page, e, this.page);
				}
			});
		}
		
	}
});
