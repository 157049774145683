import _ from 'underscore';
// import { CollectionView, View } from 'base';
import { ListItemView, ModelCoreView, CardItemView, AtomTextView } from 'components/views';
import { editOneContactAction, removeOneContactAction } from '../actions';
import { phoneMagicDisplay } from '../../../../helpers';

// import { UiBlockView } from 'components/views';
// import { switcherInverse } from 'controls/boolean-switch';

const BaseContactItemView = ModelCoreView.extend({
	type: 'full',
	text() {
		let { name = '', proxyPhoneId, proxyPhoneExtension } = this.model.attributes;
		if (proxyPhoneId && proxyPhoneExtension) {
			name += ' (c редиректом)'
		}
		return name;
	}, //: m => m.get('name'),
	smallText() {
		const label = this.model.get('label');
		let dval = this.model.get('displayValue');
		if (!dval) {
			let { value, isRedirection, extension } = this.model.attributes;
			if (isRedirection && extension) {
				value += ',' + extension;
			}
			dval = phoneMagicDisplay(value);
		}
		return [label, dval].filter(f => f).join(': ');
	}, //: m => console.log('small?', m.get('value')) || m.get('value'),
	// topText: m => m.get('prefix') + ' класса ' + m.get('class'),
	// text: m => m.get('name'),
	// smallText: (m,v) => m.display('address.gd'),
	// _small(){
	// 	let m = this.model;
	// 	let landingUrl = m.get('idString') + '.pvt.ru';
	// 	let rgUrl = 'http://adm.nrg.plus/rlt/buildings/'+ m.get('id');
	// 	let landing = `<a href="${landingUrl}" class="spaced-link" data-applink="false" target="_blank">${landingUrl}</a>`;
	// 	let rg = `<a href="${rgUrl}"  class="spaced-link" data-applink="false" target="_blank">редактировать в синей</a>`;
	// 	console.log('[:::]');
	// 	return [m.display('address.gd'), landing, rg].join('');
	// }
	events:{
		click() {
			console.log(this.model);
		}
	}
});

export const ContactItemView = ListItemView.extend({
	className: 'contact-item',
	//optionsButton: true,
	cssClassModifiers: [
		m => m.get('notVisible') ? 'not-visible' : 'visible'
	],
	editButton: true,
	removeButton: true,
	leftItems: [
		'<i class="eye"></i>',
		// model => new RealtyThumbnail({ model })
	],
	modelView: BaseContactItemView,
	selectButton: (m,v) => v.isSelectable(),
	selectOnTheRight: true,
	onEditClick(event) {
		editOneContactAction(event, this.model);
	},
	onRemoveClick(event) {
		removeOneContactAction(event, this.model);
	}
});


export const ContactCard = CardItemView.extend({
	className: 'contact-card',
	modelView: ContactItemView,
	modelViewOptions(){
		return {
			selectButton: this.isSelectable(),
		};
	},
	// lines: () => ([
	// 	v => new ListItemView({ 
	// 		model: v.model, 
	// 		modelView: ListsView, 
	// 		//modelViewOptions: { label: v.model.getLandingUrl(), url: v.model.getLandingUrl() } 
	// 	}),		
	// 	v => new ListItemView({ 
	// 		model: v.model, 
	// 		modelView: UrlView, 
	// 		modelViewOptions: { label: v.model.getLandingUrl(), url: v.model.getLandingUrl() } 
	// 	}),
	// 	v => new ListItemView({ 
	// 		model: v.model, 
	// 		modelView: UrlView, 
	// 		modelViewOptions: { label: 'редактировать в nrg', url: v.model.getNRGUrl() } 
	// 	}),
	// ]),
});