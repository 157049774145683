
import { View, CollectionView, Collection, Model } from 'base';
import { paths } from 'helpers';
import { _ } from 'vendors';
import { ContactAtomItem } from 'mod/realties/custom-contacts/views';
import { SearchBlock, bus, offersSearch, realtiesSearch } from '../search-block';
import { SearchList } from '../list-view';
import { RealtyItemInfo } from '../RealtyItemInfo';
import { enums } from 'bbmn-utils';
import { ResultInfoView } from '../result-info';

//const bus = new Model();
const offers = new Collection();


// const RealtyItemInfo = View.extend({
// 	className: 'realty-info',
// 	template: _.template(`
// 		<div class="name"><%= name %></div>
// 		<div class="address"><%= fullAddress %></div>
// 	`),
// });
const OfferItemInfo = View.extend({
	template: _.template(`
	<div>
		<span><%= floor %> Эт.</span>
		<big><%= squareOffer %> м<sup>2</sup></big>
		<span>каб: <%= roomNumber %></span>
	</div>
	<div>
		<span><%= market%> <%= operation%> <%= status %></span>
	</div>
	<div>
		<a href="<%= url %>" class="small" data-noroute="true" target="_blank">открыть страницу сайта в новой вкладке</a>
	</div>
	`),
	templateContext() {
		return {
			roomNumber: this.model.get('roomNumber'),
			floor: this.model.get('floor'),
			squareOffer: this.model.get('squareOffer'),
			status: enums.get('realtyOfferStatuses', this.model.get('status')),
			market: enums.get('realtyMarkets', this.model.get('market')),
			operation: enums.get('realtyOperations', this.model.get('operation')),
			url: this.getUrl()
		}
	},
	getUrl() {
		let market = this.model.get('market');
		let id = this.model.id;
		let path = `/${market}/blocks/${id}/`;
		return BUILD_PUB_SITE + path;
	}
});

const OfferItemCard = CollectionView.extend({
	className: 'card-item white-box realty-contact-entry',
	renderCollection: false,
	renderAllCustoms: true,
	customs: [
		OfferItemInfo,
		RealtyItemInfo,
		ContactAtomItem
	],
	customViewOptions() {
		return {
			model: this.model
		}
	}

})

const OffersList = SearchList.extend({
	collection: offers,
	model: offersSearch,
	childView: OfferItemCard,
	apiUrl: 'contacts/offers'
});

export const OffersContent = CollectionView.extend({
	initialize() {
		offers.reset();
	},
	renderCollection: false,
	renderAllCustoms: true,
	customViewOptions: {
		searchType: 'offers'
	},
	customs:[
		SearchBlock,
		ResultInfoView,
		OffersList
	],
});