import BasePage from 'pages/auth';
import Realties from './realties';
import Offers from './offers';
// import RealtyLists from './realty-lists';
// import OffersLists from './offer-lists';
// import Realties from './realties';
// import CustomContacts from './custom-contacts';

export default BasePage.extend({
	id:'pages-with-contacts',
	routes: 'pages-contacts',
	label: 'Страницы сайта с опубликованными контактами',
	relativeRoutes: true,
	children: [Realties, Offers],
	//noAside: true,
});
