import { CollectionView } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';

import { UiBlockView } from 'components/views';
import { switcherInverse } from 'controls/boolean-switch';


const BaseOfferListItemView = ModelCoreView.extend({
	text: m => m.get('name'),
});

export const OfferListItemView = ListItemView.extend({
	optionsButton: true,
	modelView: BaseOfferListItemView,
});

export const OfferListsView = CollectionView.extend({
	childView: OfferListItemView
});
