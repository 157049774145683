import _ from 'underscore';
import { UiBlockView } from 'components/views';
import { DisplayPropertySchemaView } from 'components/display-schema';
import { modals, TextView, ModelSchemas } from 'bbmn-components';
import { RealtyList } from '../realty-lists/models';
import { ActionStore, action } from 'components/actions';
import { ControlView, controlMixin } from 'bbmn-controls';

const PropView = controlMixin(DisplayPropertySchemaView);

let AddToListView = ControlView.extend({
	buttonsInFooter: true,
	validateOnReady: true,
	className:'edit-model-control',
	getControlView(){
		let modelSchema = ModelSchemas.get(RealtyList);
		let schema = modelSchema.getProperty('id');

		const options = {
			controlName: 'list',
			property:'id',
			schema,
			value: this.getControlValue('list'),
			editButton: true,
			mainAction:'trigger:edit:click',
			destroyEditPropertyOnResolve: true,
			propertyOnResolve(value, parent){
				parent.setControlValue(value).then(() => {
					parent.model = value;
					parent.render();
				});
			}
		};

		let prop = new PropView(options);
		console.log('	PROP view', prop);
		return prop;
	},
	
	buttons:[{name:'resolve', text:'Прикрепить'}],

	controlValidate(newvalue){
		let value = arguments.length == 0 ? this.getControlValue('id') : newvalue;
		if(!_.isObject(value)) {
			return 'выберите список';
		}
	}
});



ActionStore.initialize('realties-mass-actions', [
	action('add:to:realty:list', 'добавить в список недвижимости', function(){
		let sel = this;
		let content = new AddToListView({
			onResolve(value){
				let models = sel.getValue();
				let ids = _.pluck(models, 'id');
				value.addItems(ids).then(() => {
					this.destroy();
				});
			}
		});

		let modalOptions = {
			header: 'Добавление отобранного в список',
			content
		};
		return modals.show(modalOptions);
	})
]);
export default (instance) => ActionStore.getActions('realties-mass-actions', { asModels: true, instance });
