import { _ } from 'vendors';
import { defineControl, getControl, ControlView, EditProperty, EditModel } from 'bbmn-controls';
import { phoneSchema } from 'components/phone';

import { ModelSchemas } from 'bbmn-components';
import { Model } from 'base';

/* hey ho */

const DateModel = Model.extend({
	constructor: function (date) {
		let hash;
		if (date) {
			hash = {
				day: date.getDate().toString().padStart(2, '0'),
				month: (date.getMonth() + 1).toString().padStart(2, '0'),
				year: date.getFullYear()
			};
		}
		Model.call(this, hash);
	},
	date() {
		let { year, month='', day='' } = this.attributes;
		// if (month) {
		// 	month = parseInt(month, 10);
		// 	month--;
		// 	if (month < 0 || month > 11) {
		// 		month = undefined;
		// 	}
		// }
		const txt = `${year}-${month.padStart(2,'0')}-${day.padStart(2,'0')}`;
		console.log('date:', txt);
		let date = new Date(txt);
		return date;
	},
	toJSON() {
		const date = this.date();
		if (isNaN(date.valueOf())) {
			return;
		}
		return date.toJSON();
	},
	display(){
		if (arguments.length) {
			return Model.prototype.display.apply(this, arguments);
		}
		let str = [
			this.display('day'),
			this.display('month'),
			this.display('year')
		].join('.');
		return str.trim() || 'не установлено';
	}
});

const schema = ModelSchemas.initialize(DateModel, {
	year: {
		validation: {
			charPattern: /\d/gmi,
			maxLength: 4,
		},
		value:{
			type:'text',
		},
		display:{
			label: 'год',
		}
	},
	month: {
		validation: {
			charPattern: /\d/gmi
		},
		value:{
			type:'text',
		},
		display:{
			label: 'месяц'
		}
	},
	day: {
		validation: {
			charPattern: /\d/gmi
		},
		value:{
			type:'text',
		},
		display:{
			label: 'день',
		}
	}
});



const TextNumberView = ControlView.extend({
	isControlWrapper: true,
	renderAllCustoms: true,
	controlView: () => getControl('text'),
	controlViewOptions(){
		return {
			foo:'bar',
			placeholder: 'kuku-v-ruku'
		};
	}
});



const _DateSimple = EditModel.extend({
	className: 'date-simple-control',
	isControlWrapper: false,
	constructor: function(){
		EditModel.apply(this, arguments);
		this.schema = this.options.schema = schema;
	},
	propertiesToShow: ['day', 'month', 'year'],
	schema,
	getEditPropertyOptions(defaultOptions){
		const beforeResolve = (aaa) => {
			console.log('!!!! wow', aaa);
		}
		return _.extend({ className: defaultOptions.schema.name, beforeResolve }, defaultOptions, this.getOption('editPropertyOptions'));
	}
	/*renderAllCustoms: true,
	isControlWrapper: false,
	renderCollection: false,
	className: 'phone-control',
	customs: [
		(v) => v.getCountryView(),
		(v) => v.getCodeView(),
		(v) => v.getNumberView(),
	],
	_getPropertyView(propertyName){
		let schema = phoneSchema.getProperty(propertyName);
		let value = this.getControlValue(propertyName);
		return new EditProperty({ schema, value });
	},
	getCountryView(){
		return this._getPropertyView('country');
	},
	getCodeView(){
		return this._getPropertyView('code');
	},
	getNumberView(){
		return this._getPropertyView('number');
	},*/
});

const DateSimple = ControlView.extend({
	renderAllCustoms: true,
	isControlWrapper: false,
	className: 'date-simple-control',
	initialize() {
		var date = this.getDateValue();
		this.dateModel = new DateModel(date);
		this.setControlValue(this.dateModel.date());
		this._changeControlValue = _.debounce(this._changeControlValue.bind(this), 300);
	},
	getSchema(name) {
		return schema.getProperty(name);
	},
	getDateValue(v, key) {

		const dateArg = this.getControlValue();
		if (dateArg == null) { return; }
		var date = new Date(dateArg);
		if (isNaN(date.valueOf())) { return; }		

		return date;

	},
	getEditOptions(name, opts){

		// var date = this.getDateValue();
		var value = this.dateModel.get(name);
			// this.getDateValue(date, name);

		
		let schema = this.getSchema(name);
		if (!schema) return;
		//let edit = schema.getEdit();
		let options = {
			controlName: name,
			value,
			// allValues: this.getControlValue(),
			schema,
			attributes: {
				placeholder: name == 'day' ? 'день' 
					: name == 'month' ? 'месяц'
						: 'год',
			}
		};
		return _.extend(options, schema.getEdit(), opts);
	},
	getView(name){
		let schema = this.getSchema(name);
		let View = getControl(schema, { noRange: true });
		let options = this.getEditOptions(name);
		const view = new View(options);
		this.listenTo(view, 'control:change', value => this.triggerMethod('child:control:change', { [name]: value }));
		return view;
	},
	getCustoms(){
		console.log('...', this);
		return [
			this.getView('day'),
			this.getView('month'),
			this.getView('year'),
		];
	},
	onChildControlChange(hash) {
		this.dateModel.set(hash);
		this._changeControlValue();
	},
	_changeControlValue() {
		const date = this.dateModel.date();
		console.log(date);
		this.setControlValue(date);
		setTimeout(() => {
			console.warn('?', this.getControlValue());
		}, 500);
	}
});

defineControl('date:simple', DateSimple);

export default DateSimple; 
