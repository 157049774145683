import editor from 'components/editor';
import { newContactSchema } from './schemas';
import { modals } from 'bbmn-components';
import { paths } from 'helpers';

export function addNewContactAction(event, page) {
	return editor.edit({
		schema: newContactSchema,
		value: {},
		propertiesToShow: null,
		onResolve(values) {
			// let vals = _.pick(values, 'isDisabled', 'ownOrder');
			// model.set(vals);
			// view.updateImages();
			console.warn(paths);
			api.createAsync(values, page.collection);

			// page.collection.add(values);
			// console.error(values);
		}
	}, 'Добавление нового контакта');
	
}

export function editOneContactAction(event, model) {

	const attrs = model.toJSON();
	delete attrs.id;

	return editor.edit({
		schema: newContactSchema,
		value: attrs,
		propertiesToShow: null,
		onResolve(values) {
			Object.keys(values).forEach(key => {
				if (values[key] === undefined) {
					values[key] = null;
				}
			})
			api.updateAsync(values, model);
			// model.set(values);
		}
	}, 'Добавление нового контакта');

}

export function removeOneContactAction(event, model) {
	modals.confirm({ header: 'удаление контакта', text: "Внимание, действие не обратимое, так же будут удалены все привязки этого контакта к недвижимости", resolveText: 'Да', rejectText: 'Отмена' }).then(() => {
		api.deleteAsync(model);
		console.log('o da!')
	}, () => console.log('o net'));
}

const api = {
	baseUrl: 'rlts/customContacts/',
	url(...chunks) { return paths.apiUrl(this.baseUrl + chunks.join('/')) },
	async createAsync(attrs, collection) {
		if (!collection.url) {
			collection.url = this.url();
		}
		await collection.create(attrs, { wait: true });
	},
	async updateAsync(attrs, model) {
		console.warn('TRYING TO SEND', attrs);
		if (!model.urlRoot) {
			model.urlRoot = this.url();
		}
		await model.save(null, { attrs, wait: true, method: 'patch' });
	},
	async deleteAsync(model) {
		model.urlRoot = this.url();
		await model.destroy({ wait: true });
	}
}