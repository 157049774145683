import _ from 'underscore';
import { View, Model } from 'base';
import { CollectionView } from 'pages/views';
import { paths } from 'helpers';
import { modals } from 'bbmn-components';
import { ImportItemView } from 'mod/adm/import/views';
import { Imports } from 'mod/adm/import/models';
import { Collection } from 'backbone';
import { busMsg } from 'bus';
//paths.set('api', 'importall', 'ent/admin/import/realtyOffers/difference');
paths.set('api', 'importall', 'ent/admin/import/realtyOffers/start');

const LaunchView = View.extend({
	className: 'launch-import-button',
	template:_.template('<button>запустить импорт</button>'),
	events:{
		click(event){	
			let diff = !!event.shiftKey;
			let single = !!event.ctrlKey;
			let model = new Model();
			model.url = paths.api('importall') + `?single=${single}&diff=${diff}`;
			console.error(model.url);
			model.save({}).then(() => {
				modals.show({ content: 'Импорт запущен. он идёт минут 6, а это окно можно закрыть.' });
			});
		}
	}
});

const ItemsView = CollectionView.extend({
	name: 'imports',
	className: 'imports-cards cards',	
	// renderAllCustoms: true,
	// customs:[() => new LaunchView()],
	childView: ImportItemView,
});


const InfoItemView = View.extend({
	template: _.template(`
<pre style="tab-size:2">
<% if (stage !== "end") { %><%= tabs %><%= migration %>(<%= count %>): <%= stage %><% } else { %><%= migration %>: <%= count %><% } %>
</pre>
	`),
	modelEvents: {
		'change':'render'
	},
	templateContext() {
		let { stage, migration, count, tabs, noCount } = this.model.attributes;
		if (count == null) {
			count = noCount ? '' : '?';
		}
		return {
			tabs: tabs ? "\t".repeat(tabs) : '',
			stage, migration,
			count,
			text: migration + ': ' + stage
		}
	}
});

const InfoView = CollectionView.extend({
	initialize() {
		this.collection = new Collection();
		this.listenTo(busMsg, 'server:message', this.handleMessage);
		this.hashes = {};
		this.tabs = 0;
	},
	childView: InfoItemView,
	handleMessage(data) {
		if (!data) { return; }
		// console.error(data);
		const { dataType, dataAction } = data;
		const actionData = data.data || {};
		// const additional = data;
		if (dataType !== 'migration' || !dataAction) { 
			console.log(data)
			return; 
		}
		
		let count = actionData.count;
		if (dataAction == "migration:end") {
			count = "done";
		}
		const chunks = dataAction.split(':');
		const stage = chunks.pop();
		const migration = chunks.pop();
		const id = chunks.join(':');
		const error = data.error || data.data?.error;
		const tabs = chunks.length;
		const console_tabs = tabs > 0 ? tabs - 1 : 0;
		const console_tabstext = "\t".repeat(console_tabs);
		console.warn(console_tabstext, migration, stage)
		if (error) {
			console.error(console_tabstext, error);
		}
		
		if (dataAction == "migration:start") {
			this.collection.remove();
			this.tabs = 0;
			this.hashes = {};
			return;
		}

		const model = this.collection.add({ id, migration, stage, tabs }, { merge: true });
		if (count != null) {
			model.set('count', count);
		}
		// if (dataAction == 'start') {
		// 	this.collection.remove();
		// 	this.tabs = 0;
		// 	this.hashes = {};
		// 	console.warn(dataAction, data.data, data);
		// 	return;
		// }

		// const chunks = dataAction.split(':');
		// const restChunks = chunks.filter((it, ind) => ind !== 0);
		// const restEvent = restChunks.join(':');


		// const error = data.error || data.data?.error;
		// console.warn(...chunks, data.data?.count, error);
		
		// const model = this.collection.add({ id: chunks[0], text: restChunks.join(' ') }, { merge: true });
		// if ((model.id in this.hashes) === false) {
		// 	this.tabs++;
		// 	this.hashes[model.id] = this.tabs;
		// 	model.set('tabs', this.tabs);
		// }
		// if (data.data?.count != null) {
		// 	model.set('count', data.data?.count);
		// }
		
	},
});

export default CollectionView.extend({
	// className: 'imports-cards cards',	
	className: 'imports',
	initialize() {
		this.itemsCollection = new Imports();
		this.itemsCollection.fetch();
		
	},

	renderAllCustoms: true,
	customs:[
		() => new LaunchView(),
		v => new ItemsView({ collection: v.itemsCollection }),
		() => new InfoView()
	],
});

