import _ from 'underscore';
import { Collection } from 'base';

export function getModels(instance, opts = {}){
	if(!instance || !instance.getActions) return;

	let actions = instance.getActions(_.extend(opts, { asModels: true }));
	if (!actions || !actions.length) return;

	return actions;

}

export function getCollection(instance, opts = {}){

	let models = opts.actions || getModels(instance, opts);

	if(!models) return;

	return new Collection(models);

}

