import { View, CollectionView } from 'base';
import { ListItemView, ModelCoreView, AtomTextView } from 'components/views';
import { displayPhone } from 'helpers';

const BaseModelView = ModelCoreView.extend({
	topText(){
		let name = this.model.get('ownerName');
		let id = this.model.get('ownerId');
		let info = this.getOption('ownersInfo') || {};
		let ownerInfo = info[id] || {};
		let objectsCount = ownerInfo.objectsCount;
		//console.warn(id, objectsCount, info, this.model.attributes)
		if (!objectsCount) {
			return name;
		}
		return name + ` (площадей: <strong>${objectsCount}</strong>)`;
	}, //: m => m.get('ownerName'),
	text: m => m.get('contactName') || m.get('personName') || '[имя не указано]'
})

const PhoneView = AtomTextView.extend({
	initialize() {
		this.contactValue = this.getPrimaryPhone();
	},
	text() {
		if (!this.contactValue) { return ' --- '}
		let html = displayPhone(this.contactValue.value);
		const ch = [html];
		if (this.contactValue.addValue) {
			ch.push(this.contactValue.addValue);
		}
		return ch.join(', ');
	},
	getPrimaryPhone() {
		let values = this.model.get('values') || [];
		console.log('телефоны', values)
		values = values.filter(f => (f.valueType || '').toLowerCase().includes('phone'));
		values.sort((v1,v2) => {
			let prim = ((v2.primary && 1) || 0) - ((v1.primary && 1) || 0);
			if (prim != 0) { return prim; }
			return v2.order - v1.order;
		});
		return values[0];
	}
})

export const ContactListItem = ListItemView.extend({
	modelView: BaseModelView,
	modelViewOptions() {
		return {
			ownersInfo: this.getOption('ownersInfo')
		}
	},
	passDownModels: true,
	rightItems: [
		PhoneView
	]
});



// const BaseStateView = ModelCoreView.extend({
// 	text: m => m.get('count') || 0,
// });

// const StateView = ListItemView.extend({
// 	className:'selector-state',
// 	modelView: BaseStateView,
// 	leftButtons:[
// 		{name:'take-a-look'},
// 	],
// 	leftItems:['отобрано:'],
// 	removeButton: true,
// 	onRemoveClick(){
// 		this.model.clear();
// 	}
// });