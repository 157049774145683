import { View, CollectionView, Collection, Model } from 'base';
import { _ } from 'vendors';
import { bus } from './bus';

export const BaseTBody = CollectionView.extend({
	tagName: 'tbody',
	initialize() {
		const guard = this.getOption('eventName');
		this.listenTo(bus, guard, this._onFetchClick);
		this.initializeCollection();
	},
	initializeCollection() {},
	async _onFetchClick() {
		const guard = this.getOption('eventName');
		bus.set(guard, true);
		try {
			await this.collection.fetch();
		} catch {

		}
		bus.set(guard, false);
	}
})