import BasePage from 'pages/auth';
//import { RealtyList } from 'mod/realties/realty-lists/models';
import { Config } from 'mod/adm/configs/models';
import Content from '../../configs/config/content';
import { getConfigSchemaAsync } from '../../configs/config/schemas';
//import { getConfigSchemaAsync } from './schemas';

const Page = BasePage.extend({
	id:'adm-phones-config',
	routes: 'config',
	//isEntityPage: true,
	skipMenu: true,
	label: 'Настройки поумолчанию',
	Content,
	async onBeforeStart(proc, ac){
		let model = this.model = new Config({ id: 'contactsMigration' });
		await model.fetchIfNot();
		model.schema = await getConfigSchemaAsync(model.id);
	},
	// data(){
	// 	return {
	// 		label: this.model.display('id'),			
	// 	};
	// }
});


export default Page;
