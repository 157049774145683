import { CollectionView } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';

import { busViews } from 'bus';

const BaseUserItemView = ModelCoreView.extend({
	topText: m => m.display('id'),
	text: m => m.get('userName'),
});

export const UserItemView = ListItemView.extend({
	className:'realtylist-item',
	optionsButton: true,
	modelView: BaseUserItemView,
});

busViews.class('UserItemView', UserItemView);

export const UsersView = CollectionView.extend({
	childView: UserItemView
});
