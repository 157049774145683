import { actionableMixin, action, ActionStore } from 'components/actions';
import editor from 'components/editor';
import { ModelSchemas } from 'bbmn-components';
import BasePage from 'pages/auth';
import { RealtyLists, RealtyList } from 'mod/realties/realty-lists/models';
import Content from './content';
import RealtyListPage from './list';

const RealtyListsPage = actionableMixin(BasePage).extend({
	id:'realties-realtyLists',
	routes: 'realty-lists',
	label: 'Черные и белые списки недвижимости',
	Content,
	children:[RealtyListPage],
	startPromises: [
		page => page.collection.fetch(),
	],
	initialize(){
		this.collection = new RealtyLists();
	},
	actions: [
			action('create:list', 'создать список недвижимости', (event, page) => {

				editor.add({
					schema: ModelSchemas.get(RealtyList),
					propertiesToShow: ['name','type'],
					onResolve(values){
						let model = new RealtyList();
						
						model.save(values, { wait: true }).then(() =>{
							page.collection.add(model);
							this.destroy();
						});
					}
				}, 'Добавление нового списка недвижимости');
		
			}),
		]
});

// ActionStore.initialize(RealtyListsPage, [

// ]);

export default RealtyListsPage;
