import { openUrl } from 'helpers';
import cfg from 'app/config';

$(() => {

	if (!cfg.pushState) {
		return;
	}

	$(document).on('click', 'a', function(event){ 
		console.warn('HATABKA')
		let $a = $(event.target).closest('a');
		let href = $a.attr('href');
		let skip = $a.data('noroute') == 'true' || $a.data('noroute') == true;
		console.log('LINKED', href, skip, $a.data('noroute'));
		if (skip || href.startsWith('#')) {
			return;
		}

		event.preventDefault();
		event.stopPropagation();

		return openUrl(href, event);

	});
});
