import { Model } from 'base';
import '../api-config';
export const RealtyObjectModel = Model.extend({
	realtyEntityType: 'object',
	getContactsInfo() {
		const { migrateContact = {} } = this.attributes;
		const { contactId, contactValueId } = migrateContact;
		let ownerId = this.get('ownerId');
		return { ownerId, contactId, contactValueId };
	},

});