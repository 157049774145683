import _ from 'underscore';
import '../api-config';
import { Collection } from 'base';
import { CardModel } from 'mod/model-mixins';
import { ModelSchemas, modals } from 'bbmn-components';
import { paths } from 'helpers';
import { action } from 'components/actions';
import { busModels, busViews } from 'bus';



paths.set('api', 'admin:roles', 'roles', { relative: true });
paths.set('url', 'admin:roles', 'roles', { relative: true });


function addRemove(opts){
	let options = _.extend({
		value: this.entity('users').models,
		valueOptions:{
			sourceValues: busModels.getClass('Users'),
			multiple: true,
		},
		onRejectSoft(){
			this.destroy();
		},
		childView: busViews.getClass('UserItemView'),
		buttonsInFooter: true,
	}, opts);
	return modals.control('select:api',options);
}

export const Role = CardModel.extend({
	urlRoot: paths.api('admin:roles'),
	cardUrlRoot: paths.url('admin:roles'),
	nestedEntities:() => ({
		users: Collection.extend({ model: busModels.getClass('User') })
	}),
	actions:[
		action('add:users','добавить пользователей', function(){
			console.log('>>**', arguments);
			let model = this;
			return addRemove.call(this, {
				onResolve(models){
					console.log('123123');
					let ids = _.pluck(models, 'id');
					model.save({ ids }, {			
						method: 'POST',
						addToUrl:'users'
					});
					this.destroy();
				},
			});
		}, { admin: 'manageUserRoles'}),
		action('remove:users','исключить пользователей', function(event, selector){
			let models = selector && selector.getValue() || [];
			if(!models || !models.length) return;
			let ids = _.pluck(models, 'id');
			let model = this;
			model.save({ ids }, {
				method: 'DELETE',
				addToUrl:'users'
			});
			selector.clear();


		}, { admin: 'manageUserRoles'})
	]
});
ModelSchemas.initialize(Role, {

});


export const Roles = Collection.extend({
	url: paths.api('admin:roles'),
	model: Role
});
