import _ from 'underscore';
import { modals } from 'bbmn-components';
import { getControl } from 'bbmn-controls';

modals.control = function(controlName, opts = {}){
	
	let Control = getControl(controlName);
	console.log('CONTROL', Control.prototype.tagName);

	let buttons = [{
		name: 'resolve',
		text: opts.resolveText || 'сохранить'
	}, {
		name: 'rejectSoft',
		text: 'отмена'
	}];

	let contentOptions = _.extend({ buttons }, _.omit(opts, 'header'));

	return modals.show({
		header: opts.header,
		content: Control,
		contentOptions
	});
};
