import { View } from 'backbone.marionette';
import template from './index.html';
import HeaderView from './header';
import AsideView from './aside';

export default View.extend({
	tagName: 'section',
	id: 'app',
	template,
	regions:{
		'header':'header',
		'content':'article',
		'aside':'aside',
	},
	onRender(){
		this.showHeader();
		this.showAside();
	},
	showHeader(){
		let view = this.headerView = new HeaderView();
		this.showChildView('header', view, { replaceElement: true });
	},
	showContent(view){
		if(!view) return;
		this.showChildView('content', view, { replaceElement: false });
	},
	showAside(page, ac){
		
		let asideView = page && page.getAsideView() || new AsideView();
		this.asideView = asideView;
		this.showChildView('aside', asideView);
		if (page && asideView.showPage) {
			asideView.showPage(page, ac);
		}
	},
	showDefaultAside(page,ac){
		if (!this.asideView || this.asideView.isDestroyed()) {
			let aside = this.asideView = new AsideView();
			this.showChildView('aside', aside);
		}
		if (page != null) {
			this.asideView.showPage(page,ac);
		}
	},
	showPage(page, ac){
		this.previousContext = { page, ac };

		this.headerView.showPage(page, ac);
		this.showAside(page, ac);
		//this.asideView.showPage(page,ac);

		let view = page.getView({ ac });
		this.showContent(view);
		// let asideView = page.getAsideView();
		// this.showAside(asideView);
	},
	reShowPage(){
		if (!this.previousContext) return;
		let { page, ac} = this.previousContext;
		this.showPage(page, ac);
	}
});
