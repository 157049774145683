import { takeFirst } from 'bbmn-utils';

export const collectionViewFiltererMixin = Base => Base.extend({
	constructor: function(options = {}){
		this._initializeFilterer(options);
		Base.call(this, options);
		
	},
	_initializeFilterer(options){
		let filterer = takeFirst('filterer', options, this);
		if (!filterer) return;
		
		let viewFilter = takeFirst('viewFilter', options, this);
		options.viewFilter = filterer.createViewFilter(this, viewFilter);

		if(!filterer.isBackend()) {
			this.listenTo(filterer, 'change', () => {
				this.filter();
				filterer.afterFilter();
				filterer.trigger('after:filter', this);
			});
		}

	}
});
