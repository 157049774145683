import { CollectionView } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';
import ApiSelect from 'controls/select-api';
import { defineControl, SelectControl } from 'bbmn-controls';
import { isCollectionClass } from 'bbmn-utils';

import { UiBlockView } from 'components/views';
import { switcherInverse } from 'controls/boolean-switch';
import { Realties, RealtyLists } from './models';
import { busViews } from 'bus';

const BaseRealtyItemView = ModelCoreView.extend({
	topText: m => m.display('prefix'),
	text: m => m.get('name'),
});

export const SelectRealtyItemView = ListItemView.extend({
	className:'realty-item',
	optionsButton: true,
	modelView: BaseRealtyItemView,
});

// busViews.class('RealtyListItemView', RealtyListItemView);

export const RealtiesView = CollectionView.extend({
	childView: SelectRealtyItemView
});


export const ApiSelectRealties = ApiSelect.extend({
	getSource() {
		return new Realties();
	}
});
defineControl('select:api:realties', ApiSelectRealties);
defineControl('select:api:realty', ApiSelectRealties);
