import '../api-config';
import { Collection } from 'base';
import { CardModel } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';
import { paths } from 'helpers';
import { busModels } from 'bus';



paths.set('url', 'admin:users', 'users', { relative: true });

export const User = CardModel.extend({
	urlRoot: paths.api('users'),
	invite(data){
		return this.save(data);
	},
});
ModelSchemas.initialize(User, {
	email:{
		validation:{
			required: true
		},
		value:{
			type:'email',
			inputType:'email',
		},
		display:{
			label:'email'
		}
	}
});
busModels.class('User', User);

export const Users = Collection.extend({
	url: paths.api('users'),
	model: User
});
busModels.class('Users', Users);
