import { CollectionView } from 'base';
import { ContactListItem, OwnerListItem } from './ContactListItem';

export const ContactsList = CollectionView.extend({
	className: 'ui-list',
	childView: ContactListItem,
	childViewOptions() {
		return {
			ownersInfo: this.getOption('ownersInfo')
		}
	}
	// initialize() {
	// 	console.log('PROVERKA', this);
	// }
});