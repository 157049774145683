//import version from './version.json';

let type = process.env.NODE_ENV === 'production' ? 'release' : 'debug';

const config = {
	release: {
		pushState: true,
		apiProtocol: 'http:/',
		apiServer: 'api.realty-guide.ru',
		apiVersion: 'v1',
		apiPrefix:'',
		tokenUrl: 'auth/token',
		refreshTokenUrl: 'auth/refreshToken',
		//socketsJsApiUrl: 'scripts/jquery.signalR-2.2.3.min.js',
		socketsJsHubUrl: '_aws/hubs',
		socketsName: 'appWsHub',
		socketsLoadScripts: false,
	},
	debug:{
		pushState: true,
		apiProtocol: 'http:/',
		apiServer: 'api.rg.loc',
		apiVersion: 'v1',
		apiPrefix:'',
		tokenUrl: 'api/auth/token',
		refreshTokenUrl: 'auth/refreshToken',
		//socketsJsApiUrl: 'scripts/jquery.signalR-2.2.3.min.js',
		socketsJsHubUrl: '_aws/hubs',
		socketsName: 'appWsHub',
		socketsLoadScripts: false,
	},
};

export default config[type];
