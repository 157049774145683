import _ from 'underscore';
import './api-config';

import { Collection } from 'base';
import { CardModel } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';
import { paths } from 'helpers';
import { createSchemaNotifies } from 'components/notifier';
import { action, ActionStore } from 'components/actions';
import { modals } from 'bbmn-components';
import ModelStore from 'backbone.store';
import editor from 'components/editor';

//import { EditModel } from 'bbmn-controls';

import { busViews } from 'bus';
import { busModels } from 'bus';

import { AdCategories } from './ad-categories';

import { FeedPacks, FeedPack } from './feed-packs';
import { FeedPromotions } from './feed-promotions';
import { FeedStats, FeedResults } from './feed-result';
import { enums } from 'bbmn-utils';
import { Subagent } from './subagents';
import { FeedSettings } from './feed-settings';

/************/
/*	FEED    */


const BaseFeed = CardModel.extend({
	urlRoot: paths.api('export:feeds'),
	cardUrlRoot: paths.url('export:feeds'),
	nestedEntities:{
		'packs': () => ({
			class: FeedPacks,
			saveOnChange: false,
		}),
		'promotions': () => ({
			class: FeedPromotions,
			build(raw = {}, context, parentEntity){
				let definedPromos = parentEntity.getFeedConfigPromotions();
				let index = 0;
				_.each(raw, (promo) => {
					if (promo.index == null) {
						promo.index = index;
					}
					index++;
				});
				_.each(definedPromos, (promo, id) => {
					_.extend(promo, raw[id]);
				});
				let builded = new context.class(definedPromos);
				return builded;
			}
		}),
		'categories': () => ({
			class: AdCategories,
			build(raw = {}, context, parentEntity){
				let definedCats = parentEntity.getFeedConfigCategories();
				let index = 0;
				_.each(raw, (promo) => {
					if (promo.index == null) {
						promo.index = index;
					}
					index++;
				});
				_.each(definedCats, (promo, id) => {
					_.extend(promo, raw[id]);
				});
				console.log('DEFINED', definedCats);
				let builded = new context.class(definedCats);
				return builded;
			},
			//data: getDefaultCategories()
		}),
		settings: FeedSettings
	},
	getFeedConfig(key, options = {}){
		let cfg = this.get('config') || {};
		if (!arguments.length) {
			return cfg;
		}
		let value = cfg[key];
		if (value == null && options.default) {
			value = options.default;
		}
		return value;
	},
	getFeedConfigPromotions(opts = {}){
		let cfg = this.getFeedConfig('promotions', { default: {} });
		if (opts.enum) {
			return cfg;
		}
		
		let data = _.reduce(cfg, (memo, label, id) => {
			memo[id] = {
				id,
				disabled: true,
				index: Infinity,
				label
			};
			return memo;
		}, {});

		if (!opts.array && !opts.collection) {
			return data;
		}

		data = _.map(data, item => item);
		if (!opts.collection) {
			return data;
		}
		return new Collection(data);
	},
	getFeedConfigCategories(opts = {}){
		let cfg = this.getFeedConfig('categories', { default: {} });
		if (opts.enum) {
			return cfg;
		}		
		return _.reduce(cfg, (memo, label, id) => {
			memo[id] = {
				id,
				disabled: true,
				index: Infinity,
				label
			};
			return memo;
		}, {});
	},
	isDisabled(){
		return this.get('disabled') === true;
	},
	getFeedUrl({ publish = false, add = '' } = {}){
		let url = paths.api('exporturl',this.get('format'), this.id);
		url = paths.api('exporturl', this.id);
		if (publish) {
			return url;
		}
		let debug = {
			debug: true,
		};
		let params = $.param(debug);
		return url + '/json'+add+'?' + params;
	},
	getPromotions(){
		let proms = this.get('promotions') || {};
		return _.clone(proms);
	},
	getCategories(){
		return this.entity('categories');
	},
	getCustomPhone(id)
	{
		let phonesCol = this.entity('settings').entity('phones').entity('customs');
		if (!arguments.length) return phonesCol;
		return phonesCol.get(id);
	},
	
	getCustomPhonesEnum()
	{
		let phonesCol = this.getCustomPhone();
		let phonesEnum = phonesCol.reduce((memo, model) => {
			memo[model.id] = model.display('id');
			return memo;
		}, {});
		return phonesEnum;
	},
	setPromo(hash){
		let promotions = this.getPromotions();
		let stored = _.clone(promotions[hash.promoId]) || {};
		let key = hash.applied ? 'applied' : 'disallowed';
		let promos = _.clone(stored[key]) || [];
		let newpromos;
		if(hash.remove){
			newpromos = _.filter(promos, promo => promo != hash.id);
		} else {
			promos.push(hash.id);
			newpromos = _.unique(promos);
		}
		stored[key] = newpromos;
		promotions[hash.promoId] = stored;
		this.save('promotions', promotions, { wait: false });
	},	
	getCustoms(){
		return _.clone(this.get('customs')) || {};
	},
	getCustom(id){
		let customs = this.getCustoms();
		return _.clone(customs[id]) || {};
	},
	setCustom(id, custom){
		let customs = this.getCustoms();
		if(custom == null){
			delete customs[id];
		} else {
			customs[id] = custom;
		}
		this.save('customs', customs, { wait: false });
	},
	isRedefinedDescription(id){
		return this.getCustom(id).description != null;
	},
	excludeAd(model, action){
		return this._forceAd('skiped', model, action);
	},
	includeAd(model, action){
		return this._forceAd('taked', model, action);
	},
	_forceAd(key, model, action){
		let id = model.id;
		let items = _.clone(this.get(key)) || [];
		if(action){
			items.push(id);
		} else {
			items = _.filter(items, m => m != id);
		}
		this.save(key, items, { wait: false });
		model.trigger('change', model);
	},
	setAuction(model, val){
		model.setAuction(val);
		let custom = this.getCustom(model.id);
		custom.auction = model.get('auction');
		this.setCustom(model.id, custom);		
	},
	createFeedStatsModel(){
		let model = new FeedStats({
			feedId: this.id,
		});
		model.feed = this;
		model.url = this.getFeedUrl({ add: '/counts' });
		return model;
	}
});
export const Feed = ModelStore(BaseFeed);
Feed.getSchema = function(){
	return ModelSchemas.get(BaseFeed);
};

busModels.class('Feed', Feed);

//	FEED SCHEMA
ModelSchemas.initialize(BaseFeed, {
	name: {
		validation:{
			required: true,
		},
		display:{
			label:'Название'
		}
	},
	format: {
		value: {
			type: 'enum',
			sourceValues: () => enums.get('feedFormats'),
			control: 'select:inline',
		},
		display: {
			label: 'формат',
		}
	},
	disabled: {
		value:{
			type:'boolean',
			control: 'select:inline',
			sourceValues: ['Работает','Отключен']
		},
		display:{
			label:'состояние',
		}
	},
	applyGrouping: {
		value:{
			type:'boolean',
			control: 'select:inline',
			sourceValues: ['Без группировки','Объявления группируются']
		},
		display:{
			label: 'Группировка объявлений'
		}
	},
	weekendAsUsual: {
		value:{
			type:'boolean',
			control: 'select:inline',
			sourceValues: ['Только продвинутые объявления','Как в рабочие дни']
		},
		display:{
			label: 'Выгрузка на выходных'
		}
	},
	takeOnlyPromoted: {
		value:{
			type:'boolean',
			control: 'select:inline',
			sourceValues: ['Все подряд','Только продвинутые']
		},
		display:{
			label: 'Выгружаются объявления'
		}
	},
	maximumAd: {
		value: {
			type:'number',
		},
		display:{
			label: 'объявлений не более',
			ifEmpty: 'без ограничений'
		}
	},
	url:{
		edit: false,
		display:{
			label:'ссылка',
			transform(){
				let url = this.getFeedUrl({ publish: true });
				return `<a href="${url}">${this.id}</a>`;
			}
		}
	},
	public: {
		value:{
			type:'boolean',
			control: 'select:inline',
			sourceValues: ['нет','да']
		},
		display:{
			label: 'опубликован'
		}
	},
	lastAccess: {
		edit: false,
		display: {
			label: 'дата последнего скачивания',
			transform(v){
				if (v == null) {
					return 'нет данных';
				}
				let d = new Date(v);
				return d.toLocaleString('ru');
			}
		}
	},
	'descriptionTemplate': {
		value:{
			type: 'text',
			control: 'advert:template'
		},
		display: {
			label: 'основной шаблон описания',
			ifEmpty: '&ndash;',
			ifNotEmpty: 'установлен собственный шаблон',
		}
	},
	/*'settings.phones.default': {
		value:{
			control: 'phone',
		},
		display:{
			label: 'основной телефон',
			ifEmpty: 'не установлен, будет подставлен телефон по умолчанию'
		}
	},
	'settings.phones.promotionsDefault': {
		value:{
			control: 'phone',
		},
		display:{
			label: 'телефон для продвижений',
			ifEmpty: 'будет использован основной телефон'
		}
	}*/
}, {
	notifies: createSchemaNotifies({ name: 'фид', generate: true })
});

//	FEED ACTIONS
ActionStore.initialize(BaseFeed, [
	action('create:pack', 'добавить группу объявлений', function(){
		
		let parent = this;
		let packs = parent.entity('packs');
		editor.add({
			schema: ModelSchemas.get(FeedPack),
			onResolve(values){
				return packs.create(values);
			}
		}, 'Добавление новой группы объявлений');

	}, ['export: editFeeds']),

	action('show:results', 'посмотреть объявления', function(){
		
		let model = new FeedResults();
		model.feedId = this.id;
		model.openCard();

	}, ['export: view']),

	action('add:phone', 'добавить номер', function(){
		let sets = this.entity('settings');		
		let phones = sets.entity('phones');
		let customs = phones.entity('customs');

		modals.control('phone-with-editableId', { 
			value: {},
			header: 'добавление номера',
			onResolve(data){
				if (data.id == null) {
					console.log('no phone ID');
					return;
				}
				customs.add(data);
				this.destroy();
			},
			onReject(){
				this.destroy();
			}
		});

	}, ['export: editFeeds']),

	action('add:subagent', 'добавить субагента', function(){
		let sets = this.entity('settings');		
		let subagents = sets.entity('subagents');
		//let customs = phones.entity('customs');
		let schema = ModelSchemas.get(Subagent);
		let _this = this;
		editor.add({
			propertiesToShow: null, //['email'],
			schema,
			onResolve(values){
				subagents.add(values);
				_this.save({ settings: sets.toJSON() });
			}
		}, 'Добавление субагента');

	}, ['export: editFeeds']),
	
	action('get:counts', 'узнать количество', function(){
		
		modals.show({
			header: 'информация о количестве объявлений',
			content: busViews.getClass('FeedStatsView'),
			contentOptions:{
				feed: this,
			}
		});


	}, ['export: view'])

]);


export const Feeds = Collection.extend({
	model: Feed,
	url: paths.api('export:feeds'),
});
