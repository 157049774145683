import { enums } from 'bbmn-utils';
import { ApiSelectRealties, SelectRealtyItemView } from '../realties/realties/selectRealties';
import { Realties } from '../realties/realties/models';
import { paths } from 'helpers';
import SelectApiSearch from '../../controls/select-api-search';
import { moment } from 'vendors';

export default {
	status: {
		value: {
			type: 'enum',
			sourceValues: () => enums.get('newsItemStatuses'),
			control: 'select:inline',
		},
		display: {
			label: 'статус',
			ifEmpty: '&mdash;'
		}
	},	
	relatedToMarkets: {
		value: {
			type: 'enum',
			multiple: true,
			sourceValues: () => enums.get('realtyMarkets'),
			//control: 'select:inline',
		},
		display: {
			label: 'относится к рынкам',
			ifEmpty: '&mdash;'
		}
	},	
	date: {
		validation: {
			required: true
		},
		value:{
			type: 'date',
			control: 'date:simple'
		},
		display:{
			label:'Дата',
			transform(){
				let date = this.get('date');
				if (!date) { return; }
				return moment(date).format('DD.MM.YYYY');
			},
			ifEmpty: '&mdash;'
		}
	},
	showFrom: {
		value:{
			type: 'date',
			control: 'date:simple'
		},
		edit: {
			rejectHard: true,
		},
		display:{
			label:'публиковать с',
			transform(){
				let date = this.get('showFrom');
				if (!date) { return; }
				return moment(date).format('DD.MM.YYYY');
			},
			ifEmpty: '&mdash;'
		}
	},
	showTo: {
		value:{
			type: 'date',
			control: 'date:simple'
		},
		edit: {
			rejectHard: true,
		},
		display:{
			label:'публиковать по',
			transform(){
				let date = this.get('showTo');
				if (!date) { return; }
				return moment(date).format('DD.MM.YYYY');
			},
			ifEmpty: '&mdash;'
		}
	},
	realtyId: {
		value: {
			control: 'select:api:search',
			sourceValues: () => new Realties()
		},
		edit: {
			searchUrl: paths.api('realties:realties') + '/by-text',
			childView: SelectRealtyItemView,
			rejectHard: true,
			beforeResolve(val, all, model) {
				if (val && val.get) {
					const { id, prefix, name, thumbnail } = val.attributes;
					model.set('realty', { id, prefix, name, thumbnail });
				}
				return val.id;
			},
			beforeRejectHard(val, model) {
				model.set('realty', undefined);
			},

		},
		display: {
			label: 'строение',
			transform(){
				return this.get('realty.name');
				// debugger;
				// return this.getValueLabel();
			},
			ifEmpty:'&mdash;'
		}
	},
	header:{
		validation: {
			required: true
		},
		display:{
			label:'Заголовок',
			ifEmpty: '&mdash;',
		}
	},

	anons:{
		value:{
			type:'bigtext',
		},
		validation: {
			required: true
		},
		display:{
			label:'Анонс',
			ifEmpty: '&mdash;',
		}
	},
	text: {
		value:{
			type:'bigtext',
		},
		// valueType: 'bigtext',
		validation: {
			required: true
		},
		display:{
			label:'Текст',
			ifEmpty: '&mdash;',
		}
	},
}