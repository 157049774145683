import { _ } from 'vendors';
import { CollectionView } from 'base';
import { ListItemView } from 'components/views';
import { TextView, modals } from 'bbmn-components';
import { EditProperty } from 'bbmn-controls';
import { buildViewByKey, camelCase } from 'bbmn-utils';

export const EditPropertyExtended = EditProperty.extend({
	getEditControlOptions(opts = {}){

		if (opts.valueOptions && _.isFunction(opts.valueOptions.sourceValues)) {
			if (opts.model) {
				opts.valueOptions.sourceValues = opts.valueOptions.sourceValues.call(opts.model, opts.model);
			}
		}
		_.extend(opts, this.getOption('edit'));
		//console.log('edit opa!');
		return opts;
	}
});

const PropertyCore = CollectionView.extend({
	constructor: function(options){
		CollectionView.apply(this, arguments);
		this.mergeOptions(options, 'property', 'schema', 'propertyOptions');
		this._initializePropertyModel();
		this.initializeRefresh();
	},
	cssClassModifiers: [
		(m,v) => {
			let val = v.getValue();
			if (val == null) {
				return 'no-value';
			} else {
				return 'with-value';
			}
		}
	],
	renderAllCustoms: true,
	renderCollection: false,
	_initializePropertyModel(){
		if (this.model) {
			return;
		}
		this.allValues = this.getOption('allValues');
	},
	initializeRefresh() {
		if (!this.model) { return; }
		let refreshOn = this.schema?.schema?.display?.refreshOn;
		if (typeof(refreshOn) === 'string') {
			this.listenTo(this.model, refreshOn, this.render);
		}
		// if (this.property == 'migrateContact.ownerId') {
		// 	console.log('migrateContact.ownerId', display);
		// }
	},
	getCustoms(){
		return [
			this.getTopView(),
			this.getCoreView(),
			this.getBottomView()
		];
	},
	getTopView(){
		let label = this.getLabel();
		if (!label) return;
		let options = this.getTextViewOptions('top', { tagName:'i' });
		if (!_.has(options, 'value')) {
			options.value = label;			
		}
		if(!_.has(options, 'customValue')) {
			delete options.property;
			delete options.schema;
		}
		return new TextView(options);
	},
	getCoreView(){
		let options = this.getTextViewOptions('core', { tagName:'span', shouldEscapeValue:false });
		if(!options.model && options.schema && !options.value){
			options.value = this.schema.getDisplayValue(this.getValue(), undefined, { allValues: this.allValues});
		}
		return new TextView(options);
	},
	getBottomView(){
		let options = this.getTextViewOptions('bottom', { tagName: 'b' }, true);
		if (options) {
			return new TextView(options);
		}
	},

	getKeyedOptions(key){
		if (!this.propertyOptions) return;
		let options = this.propertyOptions[key];

		if(_.isFunction(options)) {
			return {
				customValue: options
			};
		} else if (_.isObject(options)) {
			return options;
		}

	},
	getTextViewOptions(key, opts, keyedFirst = false){
		let keyedOptions = this.getKeyedOptions(key);
		if (keyedFirst && !keyedOptions) return;
		return _.extend({
			model: this.model, 
			property: this.property, 
			schema: this.schema 
		}, opts, keyedOptions);
	},
	getValue(){
		if(this.model){
			return this.model.get(this.property);
		} else if (this.allValues) {
			return this.allValues[this.property];
		}
	},
	getLabel(){
		if (this.schema) {
			return this.schema.getLabel(this.model);
		} else {
			return this.property;
		}
	},
});


export default ListItemView.extend({
	constructor: function(){
		ListItemView.apply(this, arguments);
		this._initializePropertyModel();
		let property = this.getPropertyName();
		if(this.model && property){
			this.listenTo(this.model, 'change:' + property, this.render);
		}
	},
	_initializePropertyModel(){
		if(this.model) return;
		this.allValues = this.getOption('allValues');
	},	
	getModelView(){
		
		let options = _.extend({
			model: this.model,
			allValues: this.allValues
		}, 	_.pick(this.options, 'property', 'propertyOptions', 'schema'));

		let view = new PropertyCore(options);
		if(view){
			view.addCssClassModifier('item-core atom-text');
		}

		return view;
	},
	hasOptionsButton(){
		let schema = this.getSchema();
		if(!schema) return;
		return schema.getDisplay().options == true;
	},
	// rejectButton: true,
	defaultEditPropertyHeader: 'Изменение свойства',
	editPropertyView: EditPropertyExtended,
	editPropertyViewOptions: {
		buttonsInFooter: true,
		shouldShowError: true,
		cssClassModifiers: ['big'],		
	},
	editButton(){
		return this.isEditable();
	},
	isEditable(){
		let schema = this.getSchema();
		if(schema) {
			return schema.getEdit() !== false;
		} else {
			return this.getOption('editable') != false;
		}
	},
	getSchema(){
		return this.getOption('schema');
	},
	getPropertyName(){
		return this.getOption('property');
	},
	getAllValues(){
		if(this.model){
			return this.model.toJSON();
		} else {
			return this.allValues || {};
		}
	},
	getPropertyValue(){
		let property = this.getPropertyName();
		if(this.model) {
			return this.model.get(property);
		} else if(this.allValues){
			return this.allValues[property];
		} else {
			return this.getOption('value');
		}
	},
	buildEditPropertyView(){
		//console.log('	> BUILD!');
		let property = this.getPropertyName();
		let schema = this.getSchema();
		let onResolve = this.getEditPropertyOnResolve();
		let onRejectHard = this.getEditPropertyOnRejectHard();
		let buttons = [{name:'resolve', text:'сохранить изменения'}, { name: 'rejectSoft', text: 'отмена' }];
		let allValues = this.getAllValues();
		let model = this.model;
		if (schema) {
			let value = this.getPropertyValue();
			let edit = schema.getEdit({ value, allValues, model });
			if (edit === false){ return; }

			if (edit.rejectHard) {
				buttons.push({
					name:'rejectHard',
					text: 'сбросить'
				});
			}
			if(_.isFunction(edit.valueOptions.sourceValues)) {
				edit.valueOptions.sourceValues = edit.valueOptions.sourceValues.call(model, model);
			}
		}
		let options = {
			schema,			
			value: this.getPropertyValue(),
			allValues,
			buttons,
			onResolve,
			model,
			onRejectSoft(){
				this.destroy();
			},
			onRejectHard,
		};
		let propOpts = (this.getOption('propertyOptions') || {});
		_.extend(options, propOpts);
		//console.log('BUIDING', options);
		return buildViewByKey(this, 'editPropertyView', { options });
	},

	saveOnResolveEditProperty: true,
	destroyEditPropertyOnResolve: true,
	getEditPropertyOnResolve(){
		//console.log('#getEditPropertyOnResolve');
		//let schema = h
		let schema = this.getSchema();
		let model = this.model;
		let allValues = this.allValues;
		let propertyName = this.getPropertyName();
		let view = this;
		let method = this.getOption('saveOnResolveEditProperty') ? 'save' : 'set';
		let setByPath = this.getOption('shouldUseSetByPath');
		let destroyOnResolve = this.getOption('destroyEditPropertyOnResolve');
		let beforeResolve;
		if (schema.schema && schema.schema.edit) {
			beforeResolve = schema.schema.edit.beforeResolve;
		}
		//console.error(beforeResolve, schema);
		let onResolve = this.getOption('propertyOnResolve', { force: false })
		|| function(value) {
			//console.log('STANDARD ONRESOLVE', value)
			if (model) {
				if (setByPath && method === 'set') {
					model.setByPath(propertyName, value);
				} else {
					model[method](propertyName, value);
				}
			} else if(allValues) {
				allValues[propertyName] = value;
				schema.triggerMethod('property:change', propertyName, {
					allValues,
					value
				});
				view.trigger('property:change', propertyName, value);
			}
		};
		let parent = this;
		return function(value){
			if (beforeResolve) {
				value = beforeResolve.call(this, value, allValues, model);
			}
			onResolve.call(this, value, parent);
			destroyOnResolve && this.destroy();
		};
	},
	getEditPropertyOnRejectHard(){
		let model = this.model;
		let propertyName = this.getPropertyName();
		let schema = this.getSchema();
		let beforeRejectHard;
		if (schema.schema && schema.schema.edit) {
			beforeRejectHard = schema.schema.edit.beforeRejectHard;
		}

		let method = this.getOption('saveOnResolveEditProperty') ? 'save' : 'set';
		let destroyOnResolve = this.getOption('destroyEditPropertyOnResolve');
		let onRejectHard = this.getOption('propertyOnRejectHard', { force: false })
		|| function() {
			model[method](propertyName, null);
		};
		let parent = this;
		return function(value){
			if (beforeRejectHard) {
				beforeRejectHard.call(this, value, model);
			}
			onRejectHard.call(this, value, parent);
			destroyOnResolve && this.destroy();
		};
	},
	getEditPropertyViewHeader(){
		let schema = this.getSchema();
		let defaultHeader = this.getOption('defaultEditPropertyHeader');
		return schema && schema.getLabel() || this.getOption('property') || defaultHeader;
	},
	showEditPropertyView(){
		let content = this.buildEditPropertyView();
		if (!content) return;
		let header = this.getEditPropertyViewHeader();
		//console.log('content', content);

		const schema = this.getSchema();
		let className = '';
		if (schema?.schema?.value?.type === 'bigtext') {
			className = 'fullscreen';
		}

		modals.show({ className, header, content });
	},
	onEditClick(event){
		//console.log('#model', this.model);
		this.showEditPropertyView(event);
	},
	onOptionsClick(){
		let schema = this.getSchema();
		if(schema && schema.getDisplay().onOptionsClick) {
			return schema.getDisplay().onOptionsClick.apply(this, arguments);
		} else {
			return ListItemView.prototype.onOptionsClick.call(this, arguments);
		}
	},
	onRejectClick() {
		
		modals.confirm({ text: "Сбросить значение?", resolveText: 'Да', rejectText: 'Отмена' })
		.then(() => {
			console.log('resolved')
		}, () => console.log('rejected'));
	},
	onRejectHardClick() {
		
		modals.confirm({ text: "Сильно Сбросить значение?", resolveText: 'Да', rejectText: 'Отмена' }).then(() => {
			console.log('resolved')
		}, () => console.log('rejected'));
	}
});
