import _ from 'underscore';
import { Paginator } from './models';
import { PaginatorView } from './views';
export const viewWithPaginatorMixin = Base => {
	const protoSetFilter = Base.prototype.setFilter;
	const Mixin = Base.extend({
		constructor: function(){
			Base.apply(this, arguments);
			this._initializePaginator();
		},
		paginatorEnabled: true,
		_initializePaginator(){
			if (!this.getOption('isStaticCollection')) return;
			let col = this.getCollection();
			let size = this.getOption('pageSize') || 10;

			this.paginator = new Paginator({
				entries: col.length,
				size
			});

			if (this.getOption('filterer')) {

				let filterer = this.getOption('filterer');
				!filterer.isBackend() && this.on('before:filter', () => {
					
					if (this.paginator.getState()) return;

					this.paginator.rebuildPages();

					this.once('filter', () => {
						this.paginator.stopRebuildPages();						
					});

				});

			}
			this.listenTo(this.paginator, 'change:skip', () => {
				this.paginator.setState('swaping');
				this.filter();
				this.paginator.setState(null);
			});
			this.setFilter(this.getFilter(), { preventRender: true });
			this.addCustom(
				() => ([ new PaginatorView({ model: this.paginator }), -Infinity]),
				() => ([ new PaginatorView({ model: this.paginator }), Infinity])
			);
		},
		
		setFilter(filter, opts){

			if (!this.paginatorEnabled) {
				return protoSetFilter.call(this, filter, opts);
			}

			if (!_.isFunction(filter)) {

				filter = (v, index) => this.isInPage(v, index);

			} else {
				let userFilter = filter;

				let total = 0;
				let skiped = 0;
				let takedIndex = 0;

				filter = (v, index) => {
					if (index == 0) {
						total = skiped = takedIndex = 0;
					}
					if (v._isModelView) {
						if (!userFilter.call(this, v, index)) {
							return false;
						}
						total++;
					}
					let inPage = this.isInPage(v, total);
					inPage ? ++takedIndex : ++skiped;
					return inPage;
				};
			}

			return protoSetFilter.call(this, filter, opts);

		},
		isInPage (view, index) {
			return !view._isModelView || this.paginator.isInPage(index);
		}
	});

	return Mixin;
};
