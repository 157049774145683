import FormAction from 'components/form-action';
import { EditModel } from 'bbmn-controls';

//import busNotify from 'bus/notify';
import xhrMessage from 'helpers/xhr-message';
import { user, token } from 'app/context';

import { Login as LoginModel } from 'mod/acc/models';
import smartOpen from 'helpers/open-url';
//import LayoutMixin from 'pages/layout-mixin';
import { ModelSchemas } from 'bbmn-components';
import { notify } from 'components/notifier';
import { openUrl } from 'helpers';


const LoginView = FormAction.extend({
	getFormView(){
		return new EditModel({
			shouldShowPropertyError: true,
			cssClassModifiers: ['big login'],
			schema: ModelSchemas.get(LoginModel),
			buttons: [{name:'resolve', text:'войти'}],
			value: {},
			onResolve: values => this.submit(values)
		});
	},
	job(values = {}) {
		
		let { login, password } = values;
		let waitId = 'login:wait';
		notify.wait({
			id: waitId,
			text: 'Подождите пожалуйста. \r\nИдет проверка',
			store: false
		});

		return new Promise((resolve, reject) => {
			user.once('changed', () => resolve());
			token.login(login, password, { preventRestart: true }).catch(xhr => reject(xhr));
		}).then(
			() => {
				notify.message({
					id: waitId,
					header: undefined,
					text: 'добро пожаловать!'
				});
				console.log('open url');
				openUrl('/?welcome');
			},
			(xhr) => {
				notify.error({
					id: waitId,
					header: 'ошибка',
					text: xhrMessage('', xhr)
				});
				return Promise.reject(xhr);
			}
		);
	},
	onSubmit(){
		smartOpen('');
	}
});

export default LoginView;
