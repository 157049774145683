import BasePage from 'pages/auth';
import company from './compnay';
import bc from './bc';
import create from './create';
import edit from './edit';

// import Import from './import';
// import Roles from './roles';
// import Users from './users';
// import Configs from './configs';
// 
export default BasePage.extend({
	id: 'news',
	routes: 'news',
	label: 'Новости',
	// noAside: true,
	relativeRoutes: true,
	children: [company, bc, create, edit],
	rights:{
		news: 'view',
	}
});
