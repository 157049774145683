import { token } from 'app/context';
import paths from 'helpers/paths';
import { BaseObject } from 'base';
import busEvents from 'bus/app-events';
import { busMsg } from 'bus';
import { camelCase } from 'bbmn-utils';
import { notify as busNotify } from 'components/notifier';
import { user } from 'app/context';

var SocketHandler = BaseObject.extend({
	channelName:'sockets',
	initialize(){
		this.initConnection();
		this.initProxy();
		this.listenTo(token, 'change:token', this.onTokenChange);
	},
	initConnection(){
		this.connection = $.hubConnection('/_aws', { useDefaultPath: false });
		this.connection.qs = { 'token': token.getToken() };
		this.connection.url = paths.api('socketsHub');
		this.connection.error((...args) => this.triggerMethod('error', ...args));
	},
	initProxy(){
		var hub = this.hubProxy = this.connection.createHubProxy(this.getOption('hubName'));
		hub.on('appEvent',(...args) => this.triggerAppEvent(...args));
		hub.on('messageFromServer',(...args) => this.triggerServerMessage(...args));


		//hub.on('appEvent',(...args) => this.triggerAppEvent(...args));
		//hub.on('appLog',(...args) => this.triggerAppLog(...args));
		this.connection.connectionSlow((...args) => this._triggerConnectionEvent('connection:slow', args));
		this.connection.reconnecting((...args) => {
			
			this._reconnection = new Promise((resolve, reject) => {
				this.once('reconnected',() => {
					resolve();
					delete this._reconnection;					
				});
				this.once('disconnected',() => {
					reject();
					delete this._reconnection;
				});
			});
			this._triggerConnectionEvent('reconnecting', args);
		});
		this.connection.reconnected((...args) => this._triggerConnectionEvent('reconnected', args));
		this.connection.disconnected((...args) => this._triggerConnectionEvent('disconnected', args));

	},
	_triggerConnectionEvent(event, args){
		console.log('>	WS:', event, args);
		this.triggerMethod(event, ...args);
	},
	onReconnecting(){
		busNotify.message({
			id:'socket',
			text:'Утеряно соединение с сервером.<br/> Пробуем восстановить...',
			viewed: false,
			//viewedDelay: 0,
		});
	},
	onReconnected(){
		busNotify.message({
			id:'socket',
			text:'Соединение с сервером восстановлено',
			viewedDelay: 3,
			viewed: false,
			//store:false,
		});
	},	
	onDisconnected(){
		
		user.isLogged() && busNotify.error({
			id:'socket',
			text:'Утеряно соединение с сервером.<br/> Для корректной работы перезагрузите страницу',
			viewed: false,
			viewedDelay: 0,
			//store: false,
		});
		
	},	
	onTokenChange(){
		//$.connection.hub.qs.token = token.getToken();
		this.connection.qs.token = token.getToken();
	},
	start(){
		this.connection.start({ withCredentials: false })
			.then(
				(...args) => this.triggerMethod('start:success',...args),
				(...args) => this.triggerMethod('start:error',...args)
			);
	},
	onStartSuccess(){
		console.log('socket started');
	},
	onStartError(){
		console.warn('socket failed to start');
	},
	triggerAppEvent(appEvent){
		console.log('APP:EVENT', appEvent);
		var radio = this.getChannel();
		radio.trigger('app:event', appEvent);
		busEvents.trigger(appEvent.event, appEvent);
		console.log('	>', (appEvent.data || {}).type, (appEvent.data || {}).message || appEvent.event);
	},
	triggerServerMessage(data){
		let event = camelCase(data.dataType, data.dataAction);
		busMsg.trigger(event, data.Id, data);
		if (data.dataId) {
			let eventWithId = camelCase(event, data.dataId);
			busMsg.trigger(eventWithId, data);
		}
		busMsg.trigger('server:message', data);

		// const error = data.error || (data.data || {}).error;
		// if (error) {
		// 	console.error('[sckt]:', data.dataAction, { error, data });
		// } else {
		// 	if (data.dataType !== 'migration') {
		// 		// пустой IF блок
		// 		// console.log('[sckt]:', data.dataType, data.dataAction, { data: data.data, msg: data });
		// 	} else {
		// 		console.log('[sckt]:', data.dataAction, { data: data.data, msg: data });
		// 	}
		// }

	}
});

export default SocketHandler;
