import BasePage from 'pages/auth';


import { actionableMixin, action } from 'components/actions';
import editor from 'components/editor';
import { ModelSchemas } from 'bbmn-components';

import { Roles, Role } from 'mod/adm/roles/models';
import Content from './content';
import RolePage from './role';

export default actionableMixin(BasePage).extend({
	id:'admin-roles',
	routes: 'roles',
	label: 'Управление ролями',
	Content,
	children:[RolePage],
	rights:{
		admin: true,
	},
	startPromises: [
		page => page.collection.fetch(),
	],
	initialize(){
		this.collection = new Roles();
	},
	actions:[
		action('add', 'добавить роль', function(event, page){
			let parent = this;
			editor.add({
				schema: ModelSchemas.get(Role),
				propertiesToShow: ['name'],
				onResolve(values){
					page.collection.create(values, { wait: true }).then(() => {
						this.destroy();
					});
				}
			}, 'Добавление роли');
	
		}, ['admin: manageRoles']),
	],		
});
