import { Model } from 'base'

const filters = {
	offers: {
		active: (offs) => offs.some(off => off.status === 'active'),
		inactive: offs => offs.length === 0 || offs.every(off => off.status !== 'active'),
		'': () => true,
	}
}

export const ObjectsFilterModel = Model.extend({
	filter(obj, rlt) {
		const { ownerId, offerStatus } = this.attributes;
		if (ownerId && obj.get('ownerId') !== ownerId) {
			return false;
		}
		if (offerStatus) {
			const offs = obj.get('offers') || [];
			const cb = filters.offers[offerStatus];
			if (cb && !cb(offs)) {
				return false;
			}
		}
		return true;
	}
});