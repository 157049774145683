import BasePage from 'pages/auth';
import { Feed } from 'mod/export/feeds/models';
import Content from './content';
import PackPage from './pack';
import ResultsPage from './results';

const FeedPage = BasePage.extend({
	id:'export-feed-page',
	routes: 'feeds/:feedId',
	isEntityPage: true,
	skipMenu: true,
	label: (p, data = {}) => p.model && p.model.get('name') || data.feedLabel,
	Content,
	children: [PackPage, ResultsPage],
	onBeforeStart(proc, ac){
		this.model = new Feed({ id: ac.args.feedId });
		let fetch = this.model.fetch();
		fetch.then(() => {
			console.log('FETCHED', this.model);
			//this.trigger('refresh', this);
		});
		return fetch;
	},
	onStart(){
		console.log('on:start',this.model.toJSON());
	}
});


export default FeedPage;
