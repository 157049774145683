import BasePage from 'pages/auth';
import { realtyCards } from 'mod/realties/realties/models';
import { customContactsCollection } from 'mod/realties/custom-contacts/models';
import { Content } from './Content';
import { proxyPhones } from '../../../../../modules/realties/custom-contacts/proxyPhones';

//import { getFilters } from 'mod/realties/realties/filters';
// import Content from './content';
// import { Selector } from 'bbmn-components';
// import { Filterer } from 'components/filterer';
// import realtiesActions from 'mod/realties/realties/mass-actions';

export default BasePage.extend({
	id:'realties-realty-contacts',
	routes: 'contacts',
	subdivisionId: 'contacts',
	Content,
	label: p => p.model && p.model.get('name'),
	startPromises: [
		page => customContactsCollection.fetchIfNot(),
		page => proxyPhones.fetchIfNot()
	],
	onBeforeStart(p, ac){
		
		const id = ac.args.realtyId
		this.model = realtyCards.getOrCreate(id);
		this.model.addOnSync('ownercontacts', this.model.rebuildOwnerContacts);
		return this.model.fetchIfNot();

	},
	onBeforeStop(){
		this.stopListening();
	},
});
