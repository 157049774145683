import '../api-config';
import { Collection } from 'base';
import { CardModel } from 'mod/model-mixins';
import { paths } from 'helpers';

paths.set('api', 'admin:configs', 'configs', { relative: true });
paths.set('url', 'admin:configs', 'configs', { relative: true });

export const Config = CardModel.extend({
	urlRoot: paths.api('admin:configs'),
	cardUrlRoot: paths.url('admin:configs'),
});

export const Configs = Collection.extend({
	url: paths.api('admin:configs'),
	model: Config
});
