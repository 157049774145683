import { CollectionView } from 'pages/views';
import { Button } from 'bbmn-controls';
import { FeedItemView } from 'mod/export/feeds/views';
import { comparator } from 'bbmn-utils';

export default CollectionView.extend({
	name: 'feeds',
	className: 'feeds-cards cards',
	renderAllCustoms: true,	
	childView: FeedItemView,
	customs:[v => [v.createFeedButton(), { index: Infinity }]],
	childViewOptions:{
		mainAction: 'open:card'
	},
	viewComparator(v1,v2){
		return comparator(
			[v1, v2, m => !m],
			[v2, v1, m => m && m.get('public')]
		);
	},
	createFeedButton(){

		let action = this.page.getAction('create:feed');
		if(action){
			return new Button({
				text: 'создать новый фид',
				onClick: () => {
					action.exec(this.page);
				}
			});
		}
		
	}
});
