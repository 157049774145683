import BasePage from 'pages/auth';
import { Filterer } from 'components/filterer';
import { customContactsCollection } from 'mod/realties/custom-contacts/models';
import { addNewContactAction } from 'mod/realties/custom-contacts';
// import RealtyLists from './realty-lists';
// import OffersLists from './offer-lists';
// import Realties from './realties';
import Content from './content';
import { proxyPhones } from '../../../modules/realties/custom-contacts/proxyPhones';

export default BasePage.extend({
	id: 'custom-contacts',
	routes: 'custom-contacts',
	label: 'Справочник контактных телефонов',
	relativeRoutes: true,
	// children:[RealtyLists, OffersLists, Realties],
	// noAside: true,
	actions: [
		{ label: 'добавить новый контакт', action: addNewContactAction }
	],
	startPromises: [
		page => proxyPhones.fetchIfNot()
	],	
	Content,
	LayoutOptions: p => ({
		selector: p.selector,
		filterer: p.filterer,
		fetchNextEdge: 'bottom',
		scrollHandlingEnabled: true,		
	}),

	initialize(){
		// this.filterer = new Filterer({ formatDataForQueryString: false });

		this.collection = customContactsCollection
			// new CustomContacts();
		// this.collection.setFilterer(this.filterer);

		// this.selector = new Selector({ 
		// 	source: this.collection, 
		// 	multiple: true,
		// });
		// this.selector.actions = realtiesActions(this.selector);
		
		// this.addUrlChanger(this.filterer);

	},

	onBeforeStart() {
		this.collection.fetchIfNot();
	}

});
