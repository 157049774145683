import BasePage from 'pages/auth';

import { Configs } from 'mod/adm/configs/models';
import Content from './content';
import ConfigPage from './config';

export default BasePage.extend({
	id:'admin-configs',
	routes: 'configs',
	label: 'Управление конфигурациями',
	Content,
	children:[ConfigPage],
	rights:{
		admin: true,
	},
	startPromises: [
		page => page.collection.fetch(),
	],
	initialize(){
		this.collection = new Configs();
	},
	// actions:[
	// 	action('add', 'добавить роль', function(){
	// 		let parent = this;
	// 		editor.add({
	// 			schema: ModelSchemas.get(Role),
	// 			propertiesToShow: ['name'],
	// 			onResolve(values){
	// 				parent.collection.create(values).then(() => {
	// 					this.destroy();
	// 				});
	// 			}
	// 		}, 'Добавление роли');
	
	// 	}, ['admin: view']),
	// ],		
});
