import { CollectionView } from 'pages/views';
import { UiBlockView } from 'components/views';
import DisplaySchema from 'components/display-schema';
import { modules } from 'mod/models';
import { ModuleRights } from 'mod/views';
import { UsersView } from 'mod/adm/users/views';
import { Selector, initSelectorMixin } from 'bbmn-components';

//const UiBlockWithSelector = initSelectorMixin(UiBlockView);

//import { RulesItemsView } from 'mod/export/feeds/views';
export default CollectionView.extend({
	className: 'ui-blocks',
	renderAllCustoms: true,
	getCustoms(){
		return [
			this.getModelView(),
			...this.getModulesViews(),
			this.getUsersView()
		];
	},

	getModelView(){
		return new UiBlockView({
			model: this.model,
			content: DisplaySchema,
			contentOptions:{
				model: this.model, 
				propertiesToShow: ['name'],
			}
		});
	},
	getModulesViews(){
		return modules.map(module => this.buildModuleView(module));
	},
	buildModuleView(module){
		
		return new UiBlockView({
			header: module.get('label'),
			content: ModuleRights,
			contentOptions:{
				module,
				model: this.model,
				actionOnChange: 'save',
				changeRightsOptions:{
					addToUrl:'rights',
					method:'POST',
					dontWrap: true,
				}
			}
		});
	},
	getUsersView(){
		let collection = this.model.entity('users');
		let selector = new Selector({ 
			source: collection, 
			multiple: true,
		});
		this.model.on('change:users', () => console.log('users changed', this.model.get('users'), this.model.entity('users').toJSON()));
		this.model.on('change', () => console.log('model changed', 'role'));

		return new UiBlockView({
			renderOnModelChange: true,
			model: this.model,
			header: 'пользователи с этой ролью',
			content: initSelectorMixin(UsersView),
			contentOptions:{
				collection,
				selector
			},
			actions: ['add:users', 'remove:users'],
			actionArguments:[selector],
		});
	}
});
