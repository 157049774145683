import BasePage from 'pages/auth';
import { user } from 'app/context';
import { openUrl } from 'helpers';

export default BasePage.extend({
	routes: 'logoff',
	label: 'До скорых встреч!',
	menuLabel: 'выйти',
	canNotStart(){},
	onStart(){
		user.logoff({ preventRestart: true }).then(() => openUrl('acc/login'));
	}
});
