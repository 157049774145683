import { Collection, Model } from 'base';
import { CardModel } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';
import { paths } from 'helpers';
import { PhoneModel } from 'components/phone';
import { ActionModel } from 'mod/model-mixins';
import { Subagents } from './subagents';

const Phones = Collection.extend({
	model: PhoneModel,
});

const FeedPhones = ActionModel.extend({
	nestedEntities: {
		default: {
			class: PhoneModel,
			data: { id: 'default' }
		},
		promotions: {
			class: PhoneModel,
			data: { id: 'promotions' }
		},
		customs: Phones
	}
});

export const FeedSettings = ActionModel.extend({
	nestedEntities: {
		phones: FeedPhones,
		subagents: Subagents
	}
});
