import { AdsList } from 'mod/export/feeds/views';
import { View } from 'pages/views';

export default View.extend({
	className: 'export-feed-results',
	template: () => '<article></article>',
	regions:{
		content: 'article'
	},
	onRender(){
		console.warn('FEED', this.model);
		this.showContent();
	},
	showContent(groupModel){
		let collection = this.model.getDefaultCollection();
		if (groupModel) {
			collection = groupModel.get('collection');
		}

		collection.setFilterer(this.filterer);

		let view = new AdsList({
			feed: this.page.feed,
			collection,
			filterer: this.filterer,
		});

		this.showChildView('content', view, { replaceElement: true });
	},
	modelEvents:{
		'show:collection'(groupModel){
			this.showContent(groupModel);
		}
	},
});



