import { Model, View } from 'base';
import { paths } from 'helpers';
import { modals } from 'bbmn-components';
import { _ } from 'vendors';

const ModalState = View.extend({
	template: _.template(`<div><%= text %></div>`),
	modelEvents: {
		change: 'render'
	},
	templateContext() {
		return {
			text: this.model.get('text')
		}
	}
})

const Command = Model.extend({
	parse(data) {
		if (data == null) {
			data = {
				value: data
			}
		} else if (Array.isArray(data)) {
			data = {
				entries: data
			}
		} else if(typeof(data) !== 'object') {
			data = {
				value: data
			}
		}
		return data;
	}
})

const Bus = Model.extend({
	initialize() {
		this.on({
			'replace:asterisk:click': this._onReplaceClick,
			'clear:asterisk:click': this._onClearAsteriskClick,
			'autofill:click': this._onAutofillClick
		});
	},
	async _onAutofillClick() {
		await this._processCommand({
			guard: 'autofill:click',
			modalHeader: 'Автораспределение добавочных номеров по новым контактам',
			path: 'rlts/proxyPhones/autofill',
			successText: 'добавочные номера распределены',
			onSuccess: model => console.log(model.attributes),
		});
	},
	async _onReplaceClick() {
		await this._processCommand({
			guard: 'replace:asterisk:click',
			modalHeader: 'Обновление данных на АТС',
			path: 'rlts/redirectPhones/replace-asterisk',
			successText: 'данные на АТС обновлены',
			onSuccess: model => console.log(model.attributes),
		});
	},
	async _onClearAsteriskClick() {
		await this._processCommand({
			guard: 'clear:asterisk:click',
			modalHeader: 'Обнуление данных на АТС',
			path: 'rlts/redirectPhones/clear-asterisk',
			successText: 'данные на АТС стёрты',
			onSuccess: model => console.log(model.attributes),
		});
	},
	async _processCommand(cmnd) {

		if (this.get(cmnd.guard)) { return; }
		this.set(cmnd.guard, true);

		const state = new Model({ text: 'подождите, запрос выполняется' });
		const content = new ModalState({ model: state });

		modals.show({ header: cmnd.modalHeader, content });

		const command = new Command();
		try {
			let url = cmnd.url || paths.apiUrl(cmnd.path);
			await command.save(null, {
				url,
				method: cmnd.method || 'POST',
				parse: true
			});
			state.set('text', cmnd.successText || 'запрос выполнен');
			if (cmnd.onSuccess) {
				cmnd.onSuccess(command);
			}
			setTimeout(() => content.destroy(), 2000);
		} catch(exc) {
			state.set('text', 'Во время выполнения запроса произошла ошибка');
			if (exc.responseJSON) {
				console.error(exc.responseJSON)
			} else {
				console.error(exc);
			}
		}

		this.set(cmnd.guard, false);

	}
})

export const bus = new Bus();
