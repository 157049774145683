import { CollectionView } from 'base';
import { ContactAtomItem, ContactEditBox, ContactEditForm } from 'mod/realties/custom-contacts/views';
import { setByPath, unflat } from 'bbmn-utils';

// const ContactInfo = CollectionView.extend({
// 	className: 'contacts-info card-item white-box',
// 	renderCollection: false,
// 	renderAllCustoms: true,
// 	customs: [
// 		ContactAtomItem
// 	],
// 	customViewOptions() {
// 		return {
// 			model: this.getOption('entity'),
// 			entityType: 'realty',
// 		}
// 	},
// });

export const Content = CollectionView.extend({
	className: 'contacts-subdiv',
	renderCollection: false,
	renderAllCustoms: true,
	customViewOptions() {
		return {
			model: this.model,
			entityType: 'realty',
		}
	},
	customs: [
		ContactEditForm,
		// ContactInfo,
		// { class: ContactEditBox, header: 'собственный телефон' },
		// { class: ContactEditBox, properties: ['customContactId'], header: 'телефон из справочника' },
	],
	// childViewEvents: {
	// 	'contact:saved'(attrs) {
			
	// 		Object.keys(attrs).forEach(key => {
	// 			setByPath(this.model, key, attrs[key]);
	// 		})
	// 		this.model.trigger('change', this.model);
	// 	}
	// }
});