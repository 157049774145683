import { AtomTextView } from 'components/views';
import { contactModalEditForm } from './ContactEditForm';


const typeSymb = Symbol('type');

function setType(model, type) {
	if (model != null) { 
		model[typeSymb] = type;
	}
	return model;
}

function getType(model) {
	if (model != null) { 
		return model[typeSymb];
	}
}


function phoneMagic(text) {
	let [noadd = '', add = ''] = text.split(',');
	add = add.replace(/\D/g, '');
	text = noadd.replace(/-/g, ' ');
	text = text.replace(/[()]/g, ' ');
	text = text.replace(/\s+/g, '.');

	let chunks = text.split('.');
	let country = chunks.shift();
	let code = chunks.shift();
	let number = chunks.join('');
	
	if (!number) {
		if (country && !code) {
			
			number = country.substring(country.length - 7);
			let countrycode = country.substring(0, country.length - 7);
			code = countrycode.substring(countrycode.length - 3);
			country = countrycode.substring(0, countrycode.length - 3);
			// console.warn(number);
			// console.warn(countrycode);
			// console.warn(code);
			// console.warn(country);
		} else {
			return '**:' + text;
		}
	}

	let dphone = '';
	if (number.length <= 3) {
		dphone = number;
	} else {
		
		for(let x = number.length - 1; x >= 0; x--) {
			dphone = number[x] + dphone;
			if (x >= 2 && dphone.length >= 2 && (dphone.length + 1) % 3 == 0) {
				dphone = '-' + dphone;
			}
		}
	}
	if (add) {
		dphone = dphone + ', доб. ' + add;
	}

	if (!country.startsWith('+') && !country.startsWith('8')) {
		country = '+' + country;
	}

	return [country, code, dphone].join(' ');
}

export const ContactAtomItem = AtomTextView.extend({
	type: 'labeledText',
	className: 'contacts-on-site-item',
	// cssClassModifiers: [
	// 	v => v._phone?.noPage === true ? 'no-page' : (v._phone?.notVisible === true ? 'hidden-on-site' : 'visible-on-site')
	// ],
	constructor: function() {
		
		AtomTextView.apply(this, arguments);
		this.buildPhone();		
		this.addCssClassModifier((m,v) => v._phone.noPage === true ? 'no-page' : (v._phone.notVisible ? 'hidden-on-site' : (v._phone.value ? 'visible-on-site' : 'no-page')));
		this.refreshCssClass();
		this._initializeOnClick();
	},
	_initializeOnClick() {
		let clickable = this.getOption('clickable');
		if (typeof(clickable) === 'string' && clickable.startsWith('trigger:')) {
			let chunks = clickable.split(":");
			chunks.shift();
			let eventName = chunks.join(':');
			this.addCssClassModifier('clickable');
			this.delegate('click', null, (e) => {
				e.stopPropagation();
				this.triggerMethod(eventName);
			});
		}
	},
	_getChildOptions() {
		let opts = {
			model: this.model,
			entityType: this.getOption('entityType'),
			realty: this.getRealty(),
			object: this.getObject(),
			offer: this.getOffer()
		}
		opts.entity = opts[opts.entityType];
		return opts;
	},
	onEditContactsClick() {
		let opts = this._getChildOptions();
		//console.warn('ON EDIT CLICK!', opts);
		contactModalEditForm(opts);
	},

	header: (m, v) => v.getPhoneHeader(),
	text: (m, v) => `<div class="phone-placeholder">
		<div class="phone-label" title="${v.getLabelSource()}">${v.getPhoneLabel()}</div>
		<div class="phone-value" title="${v.getPhoneTitle()}">${v.getPhoneText()}</div>
	</div>`,
	onBeforeRender() {
		this.buildPhone();
	},
	getRealty() {
		const type = 'realty';
		if (this.getOption('entityType') !== 'realty') {
			return this.getOption('realty');
		} else if (this.getOption('entityType') === 'realty') {

			return this.model;
		}
	},

	getObject() {
		
		if (this.getOption('entityType') !== 'object') {
			return this.getOption('object');
		}
		return this.model;
	},
	getOffer() {
		if (this.getOption('entityType') === 'offer') {
			return this.model;
		}
	},
	getEntities() {

		return {
			offer: this.getOffer(), 
			object: this.getObject(), 
			realty: this.getRealty()
		}
	},

	getEntitiesPhones() {
		let entities = this.getEntities();
		let realty = entities.realty;
		entities.realty = this._getEntityPhone(entities.realty, 'realty', undefined, realty);
		entities.object = this._getEntityPhone(entities.object, 'object', entities.realty, realty);
		entities.offer = this._getEntityPhone(entities.offer, 'offer', entities.object, realty);
		return entities;
	},

	_getEntityPhone(entity, type, parent, realty) {
		const phone = this.model.get('calculatedPhone');
		return phone;
		// if (entity == null) { return; }
		// let ownerIdExt = getEntityOwnerIdExtension(entity);
		// let attrs = toAttrs(entity);
		// let typeObj = {
		// 	ref: { ...attrs.customContact, ...ownerIdExt }, //toAttrs(attrs.customContact),
		// 	own: { ...attrs.ownCustomContact, ...ownerIdExt },
		// 	mig: buildMigratePhone(entity, realty),
		// };
		// let res = extractPhone(typeObj, type);
		// applyParentPhone(res, parent, type === 'object')
		// return res;
	},

	getLabelSource() {
		let phone = this._phone;
		return 'источник подписи: ' + sourceText(phone.labelSource);
	},
	getPhoneTitle() {
		let phone = this._phone;
		if (!phone.value) { return ''; }
		let src = 'источник номера: ' + sourceText(phone.valueSource);
		let pt = phoneMagic(phone.redirectValue || phone.value);
		return pt + ", " + src;
	},

	buildPhone() {
		let entityType = this.getOption('entityType');
		// let phones = this.getEntitiesPhones();
		// this._phone = phones[type] || {};
		// return this._phone;
		this._phone = Object.assign({}, this.model.get('calculatedContact'), { entityType, noPage: entityType === 'object' });
		//console.log('*', this._phone, this.model);
		return this._phone;
	},
	getPhoneHeader() {
		const entity = this.getOption('entityType');
		const p = this._phone;
		const src = p.notVisibleSource;
		const msg = p.notVisible ? '<span class="red" title="' + src + '">скрыты</span>' : (p.value ? '<span class="green">показываются</span>' : '<span>отсутствуют</span>')

		// return p.label || p.name;
		return entity === 'object' ? '&mdash;' : 'на сайте: ' + msg;
	},
	getPhoneText() {
		let phone = this._phone;
		if (phone.value) {
			if (phone.displatValue) {
				return phone.displatValue;
			}
			return phoneMagic(phone.value);
			// const chunks = phone.value.split(',');
			// const add = chunks[1] ? ', доб. ' + chunks[1] : '';
			// let phone = "";
			// for(let x = chunks[0].length; x > 0; x--) {
			// 	phone = chunks[0][x] + phone;
			// 	if (phone.length >= 2 && phone.length % 2 === 0 && x >= 2) {
			// 		phone = '-' + phone
			// 	}
			// }
			// chunks[0]
			// return phone.value;
		} else {
			return 'не определён'
		}		
	},
	getPhoneLabel() {
		let phone = this._phone;
		if (phone.value && !phone.notVisible) {
			return (phone.label || '<без подписи>') + ': ';
		}
		return '';
	},
	getSmallText() { 
		let phone = this._phone;
		let reason;
		if (phone.value && !phone.notVisible) {
			return 'источник: ' + sourceText(phone.valueSource);
		} else if (phone.notVisible) {
			reason = 'номер скрыт';
		} 
		else {
			reason = 'номер не установлен';
		}
		return 'причина: ' + reason;
	},
	modelEvents: {
		change: 'render'
	}
});



const SOURCES = {
	'realty': 'Здание',
	'realty.own': 'Здание, собственный номер',
	'realty.notebook': 'Здание, номер из справочника',
	'realty.migrate': 'Здание, номер из миграции',
	'object': 'Помещение',
	'object.own': 'Помещение, собственный номер',
	'object.notebook': 'Помещение, номер из справочника',
	'object.migrate': 'Помещение, номер из миграции',
	'offer': 'Предложение',
	'offer.own': 'Предложение, собственный номер',
	'offer.notebook': 'Предложение, номер из справочника',
	'offer.migrate': 'Предложение, номер из миграции',
}

function sourceText(key) {
	return SOURCES[key] || '';
}



function toAttrs(arg) {	
	if (arg && typeof(arg) === 'object') {
		let attrs = Object.assign({}, arg.attributes ? arg.attributes : arg);
		return attrs;
	} 
}



function extractPhone(entPhone, type) {
	let own = Object.assign({ [typeSymb]: type + '.' + 'own' }, entPhone.own);
	let ref = Object.assign({ [typeSymb]: type + '.' + 'ref' }, entPhone.ref);
	let mig = Object.assign({ [typeSymb]: type + '.' + 'mig' }, entPhone.mig);
	
	// let def = { [typeSymb]: type };
	// extractProperties(def, own);
	// extractProperties(def, ref);
	// extractProperties(def, mig);

	let res = [own, ref, mig].reduce((memo, ph) => {
		extractProperties(memo, ph);
		return memo;
	}, { [typeSymb]: type });
	return res;
}

function extractProperties(memo, ph) {
	extractProperty(memo, ph, 'value', (cfg, obj) => !cfg.value && !!obj.value);
	extractProperty(memo, ph, 'label', (cfg, obj) => !cfg.label && !!obj.label);
	extractProperty(memo, ph, 'notVisible', (cfg, obj) => 'notVisible' in cfg === false && obj.notVisible != null);
	extractProperty(memo, ph, 'inheritanceDisabled', (cfg, obj) => 'inheritanceDisabled' in cfg === false && obj.inheritanceDisabled != null);
}

function extractProperty(cfg, obj, key, condition) {
	if (condition(cfg, obj)) {
		cfg[key] = obj[key];
		if (key + 'Source' in obj) {
			cfg[key + 'Source'] = obj[key + 'Source'];
		} else {
			cfg[key + 'Source'] = getType(obj);
		}
	}
}

function applyParentPhone(phone, parent, compareOwnerId) {
	if (
		parent == null || parent.inheritanceDisabled
		|| (compareOwnerId && phone.ownerId !== parent.ownerId)
	) { return; }
	extractProperties(phone, parent);
}

function buildMigratePhone(entity, realty) {
	const contactInfo = entity.getContactsInfo();
	const { ownerId, contactId, contactValueId } = contactInfo;

	if (!contactInfo.ownerId) {
		console.log('migratePhone: no ownerId');
		return;
	}

	let parentMc;
	if (entity.realtyEntityType === 'offer') {
		parentMc = entity.collection.object.get('migrateContact');
	}
	const mc = entity.get('migrateContact');

	let autodetect = takeFirst('autoDetect', mc, parentMc)
	//  in mc ? mc.autoDetect
	// : parentMc?.autoDetect;

	if (autodetect) {
		let data = realty.getContactsData();
		let phone = data.getPhoneModel(ownerId, contactId, contactValueId);
		if (phone) {
			return phone;
		} else {
			// нужно сбацать номер из фолбэков
			let _value = takeFirst('fallbackPhone', mc, parentMc);
			let _label = takeFirst('fallbackLabel', mc, parentMc);
			if (_value || _label) {
				return {
					value: _value,
					label: _label,
					ownerId
				}
			}
		}
		
	}


}


function takeFirst(key, ...objs) {
	for(let x = 0; x < objs.length; x++) {
		let obj = objs[x];
		if (obj && typeof(obj) === 'object' && key in obj) {
			return obj[key];
		}
	}
}


function getEntityOwnerIdExtension(entity) {
	switch(entity.realtyEntityType) {
		case 'realty':
			return {};
		case 'object':
			return { ownerId: entity.get('ownerId') };
		case 'offer':
			return { ownerId: entity.collection.object.get('ownerId') };
		default:
			return {};
	}
}