import {_} from 'vendors';
import { CollectionView } from 'pages/views';
import { UiBlockView } from 'components/views';
import DisplaySchema from 'components/display-schema';
//import { RulesItemsView } from 'mod/export/feeds/views';
export default CollectionView.extend({
	className: 'ui-blocks',
	renderAllCustoms: true,
	getCustoms(){
		let views = this.getModelView();
		if (!Array.isArray(views)) {
			views = [views]
		}
		return views;
	},
	/*getPropertiesRules(){
		let model = this.model;
		return new UiBlockView({
			model: this.model,
			header: 'правила',
			content: RulesItemsView,
			contentOptions:{
				collection: model.entity('rules'),
				childViewOptions:{
					mainAction:'view:rule'
				}
			},
			actions:['create:rule'],
		});

	},*/
	getModelView(){
		//let props = _.keys(this.model.attributes);
		const builder = this.forms[this.model.id] || this.getSimpleForm;
		return builder.call(this);

	},
	forms: {
		contactsMigration() {
			let props = Object.keys(this.model.attributes).filter(k => k !== 'id' && k !== 'name');
			let offers = [];
			offers.name = "Предложение";
			let objects = [];
			objects.name = "Помещение";
			let realties = [];
			realties.name = "Здание";
			let others = [];
			others.name = "Прочие настройки";
			let rawviews = [
				realties, objects, offers, others
			]
			props.forEach(prop => {
				if (prop.includes('Offer')) {
					offers.push(prop);
				}
				else if (prop.includes('Object')) {
					objects.push(prop);
				}
				else if (prop.includes('Realty')) {
					realties.push(prop)
				} else {
					others.push(prop);
				}
			})

			const contentOptions = {
				model: this.model, 
				schema: this.model.schema,
			}
			const opts = {
				model: this.model,
				schema: this.model.schema,
				content: DisplaySchema,
			}

			return rawviews.map(propertiesToShow => {
				const viewOptions = Object.assign({ header: propertiesToShow.name }, opts)
				delete propertiesToShow.name;
				viewOptions.contentOptions = Object.assign({ propertiesToShow }, contentOptions);
				return new UiBlockView(viewOptions);
			})
		}
	},
	getSimpleForm() {
		let props = Object.keys(this.model.attributes).filter(k => k !== 'id' && k !== 'name');

		console.log('props', props);
		return new UiBlockView({
			model: this.model,
			schema: this.model.schema,
			content: DisplaySchema,
			// propertiesToShow: props,
			contentOptions:{
				model: this.model, 
				schema: this.model.schema,
				propertiesToShow: props
			}
		});
	}
});
