module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="main-1">\r\n	<span class="identify">\r\n		<span>\r\n			<code class="category" data-role="show-actions">'+
((__t=( categoryName ))==null?'':__t)+
'</code>\r\n			<span class="badge offers" data-action="edit:offers">'+
((__t=( _m.getOffersCount()))==null?'':__t)+
' пред.</span>\r\n			<span class="badge meters">'+
((__t=( _m.get('desc.data.square-range') || '' ))==null?'':__t)+
'</span>\r\n			<span class="badge price-meter">'+
((__t=( disp_priceMeter ))==null?'':__t)+
'</span>\r\n			<span class="badge price-square">'+
((__t=( disp_priceSquare ))==null?'':__t)+
'</span>\r\n		</span>\r\n		<span>\r\n			<code>'+
((__t=( id))==null?'':__t)+
'</code>\r\n			<code>'+
((__t=( _j.phone ))==null?'':__t)+
'</code>\r\n		</span>\r\n	</span>\r\n	<span class="index" data-role="show-actions">'+
((__t=( _v._index + 1 ))==null?'':__t)+
'</span>\r\n	<div class="name" data-role="show-actions">\r\n		<div>\r\n			'+
((__t=( _m.get('realty.name') ))==null?'':__t)+
', <b>'+
((__t=( _m.getBcClass() ))==null?'':__t)+
'</b>			 \r\n		</div>\r\n	</div>\r\n	<span class="address">'+
((__t=( _j.realty.fullAddress ))==null?'':__t)+
'</span>\r\n	<span class="folder"><i></i></span>\r\n	<div class="icons">\r\n		'+
((__t=( _v.getForced() ))==null?'':__t)+
'\r\n		'+
((__t=( _v.getPromos() ))==null?'':__t)+
'\r\n		'+
((__t=( _v.getIndicators() ))==null?'':__t)+
'		\r\n	</div>\r\n</div>\r\n<div class="full-info"></div>\r\n';
 if(hideAdditional) {
__p+='\r\n<div class="photos">\r\n	<div>\r\n		';
/*_v.getPhotos()*/
__p+='\r\n	</div>\r\n</div>\r\n<div class="props">\r\n	<div class="square range-with-label">\r\n		<label>метраж</label>\r\n		<div>';
/*= _v.areaHtml() */
__p+='</div>\r\n	</div>\r\n	<div class="price single-with-label">\r\n		<label>';
/*= _m.getPriceLabel() */
__p+='</label>\r\n		<div>';
/*= _m.getPriceString()  */
__p+='</div>\r\n	</div>	\r\n	<div class="nds">\r\n		<label>ндс</label>\r\n		<div>';
/*= _m.getVatType() */
__p+='</div>\r\n	</div>\r\n</div>\r\n<div class="desc">'+
((__t=( _m.get('error') || _m.get('description') ))==null?'':__t)+
'</div>\r\n';
 } 
__p+='\r\n';
 if(hideActions) { 
__p+='\r\n<div class="promotions">\r\n	';
/*= _v.getPromos() */
__p+='\r\n</div>\r\n<div class="actions">\r\n	';
 if (true || !_v.isExcluded()) {
__p+='\r\n	<button class="exclude"> принудительно исключить </button>\r\n	';
 } 
__p+='\r\n	';
 if (true || _v.isExcluded()) {
__p+='\r\n		<button class="cancel-exclude"> отменить исключение </button>\r\n	';
 } 
__p+='\r\n	';
 if (true || !_v.isIncluded()) {
__p+='\r\n		<button class="include"> принудительно включить </button>\r\n	';
 } 
__p+='\r\n	';
 if (true || _v.isIncluded()) {
__p+='\r\n		<button class="cancel-include"> отменить включиние </button>\r\n	';
 } 
__p+='\r\n\r\n	<button class="promote"> продвинуть </button>\r\n	<button class="edit"> редактировать </button>\r\n	<button class="edit-auction"> аукцион';
/*= _v.getAuctionBet() */
__p+='</button>\r\n</div>\r\n';
 } 
__p+='\r\n';
}
return __p;
};
