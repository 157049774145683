
//import { _ } from 'vendors';
import { View, CollectionView, Collection, Model } from 'base';
import { paths } from 'helpers';

import TopActions from './TopActions';
import DbTable from './DbTable';

// import { ContactAtomItem } from 'mod/realties/custom-contacts/views';
// import { SearchBlock, bus, realtiesSearch } from '../search-block';
// import { SearchList } from '../list-view';
// import { RealtyItemInfo } from '../RealtyItemInfo';
// import { ResultInfoView } from '../result-info';

export default CollectionView.extend({
	className: 'phone-redirect-table-page',
	renderCollection: false,
	renderAllCustoms: true,
	customs: [
		TopActions,
		DbTable,
		// RealtyItemInfo,
		// ContactAtomItem
	],
})