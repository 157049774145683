import BasePage from 'pages/auth';
import { OffersContent } from './Content';
// import RealtyLists from './realty-lists';
// import OffersLists from './offer-lists';
// import Realties from './realties';
// import CustomContacts from './custom-contacts';

export default BasePage.extend({
	id:'offers-with-contacts',
	routes: 'offers',
	label: 'Предложения',
	relativeRoutes: true,
	//children: [RealtyLists, OffersLists, Realties, CustomContacts],
	//noAside: true,
	Content: OffersContent
});
