import BasePage from 'pages/auth';
import { Feed } from 'mod/export/feeds/models';
import Content from './content';

const FeedPackPage = BasePage.extend({
	id:'export-feed-pack-page',
	routes: 'p/:packId',
	isEntityPage: true,
	skipMenu: true,
	label: (p, data = {}) => p.model && p.model.get('name') || data.feedPackLabel,
	Content,
	onBeforeStart(proc, ac){
		let feed = this.feed = new Feed({ id: ac.args.feedId });
		return this.feed.fetchIfNot().then(() => {
			let packs = feed.entity('packs');
			let model = packs.get(ac.args.packId);
			if(!model){
				return Promise.reject('not:found');
			}
			this.model = model;
			return Promise.resolve(model);
		});
	},
	//accepts: ac
	data(){
		return {
			feedLabel: this.feed.display('name'),
			feedPackLabel: this.model.display('name'),
		};
	}
});


export default FeedPackPage;
