import { View, CollectionView, Collection } from 'base';
import { ListItemView, ModelCoreView, AtomTextView } from 'components/views';
import { displayPhone } from 'helpers';
import { ContactListItem } from './ContactListItem';


const BaseOwnerView = ModelCoreView.extend({
	topText: 'собственник',
	text: (m,v) => m.get('name'), //v.getOption('ownerName')
})

const OwnerInfo = ListItemView.extend({
	className: 'owner-list-item',
	getOwnerName(){
		console.warn('getOwnerName', this.model)
		let name = this.model.get('ownerName');
		let id = this.model.get('ownerId');
		let info = this.getOption('ownersInfo') || {};
		let ownerInfo = info[id] || {};
		let objectsCount = ownerInfo.objectsCount;
		//console.warn(id, objectsCount, info, this.model.attributes)
		if (!objectsCount) {
			return name;
		}
		return name + ` (площадей: <strong>${objectsCount}</strong>)`;
	},
	//text: (m,v) => v.getOwnerName(),
	modelView: BaseOwnerView,
	modelViewOptions() {
		return {
			ownersInfo: this.getOption('ownersInfo'),
			//ownerName: this.getOwnerName()
		}
	},
	passDownModels: true,
	rightItems: [
		{
			topText: 'плмещений:',
			text: m => m.get('objectsCount')
		},
		{
			topText: 'контактов:',
			text: m => (m.get('contacts') || []).length
		},
	]
});


const ContactsList = CollectionView.extend({
	className: 'owner-contacts-list',
	initialize() {
		const contacts = this.model.get('contacts') || [];
		this.collection = new Collection(contacts);
	},
	childView: ContactListItem,
})

export const OwnerListItem = CollectionView.extend({
	className: 'owner-list-item-container',
	renderCollection: false,
	renderAllCustoms: true,
	passDownModels: true,
	customs: [
		OwnerInfo,
		ContactsList
	],
	customViewOptions() {
		return {
			model: this.model,
			
		}
	}
})