import BasePage from 'pages/auth';
import { CompanyNewsCollection } from 'mod/news';
import { commonModuleListMixin } from '../common/moduleMixin';

export default BasePage.extend({
	id: 'comp',
	routes: 'comp',
	label: 'Новости компании',
	noAside: true,
	relativeRoutes: true,
	// children: [Import, Roles, Users, Configs],
	rights:{
		news: 'view',
	},
	NewsCollection: CompanyNewsCollection,
	...commonModuleListMixin,

});
