import loadScript from 'helpers/load-script';
import cfg from 'app/config';
import paths from 'helpers/paths';
import readyPromise from './ready-promise';

paths.set('api', 'socketsScripts', cfg.socketsJsApiUrl, {relative: false, version:false, prefix:false});
paths.set('api', 'socketsHub', cfg.socketsJsHubUrl, {relative: false, version:false, prefix:false});

//this part is for loading all signalR scripts if they stored outside
//returns promise
export default function startSockets()
{

	var jq = loadScript(paths.api('socketsScripts'));
	var hb = loadScript(paths.api('socketsHub'), jq);
	let promise = new Promise((resolve, reject) => {
		Promise.all([jq, hb]).then(
			() => { 
				readyPromise.resolve();
				return resolve(); 
			},
			(err) => reject(err)
		);
	});
	return promise;
}
