import { Model } from 'base';
import { paths } from 'helpers';
import { ModelSchemas } from 'bbmn-components';

const readySchemas = {};


export async function getConfigSchemaAsync(id) {
	
	if (readySchemas[id]) {
		return readySchemas[id];
	}

	let model = new Model();
	model.url = paths.api('admin:configs') + '/schema/' + id;

	await model.fetch()

	let rawschema = Object.assign({}, model.attributes);

	if (Object.keys(rawschema).length == 0) {
		return;
	}

	var keys = Object.keys(rawschema);
	var reworked = {};
	keys.forEach(key => {
		const prop = rawschema[key];
		const display = {
			label: prop.caption
		}
		const value = {
			type: prop.valueType
		}

		if (value.type == "boolean") {
			value.sourceValues = { true: 'Да', false: 'Нет' }
		}

		reworked[key] = {
			display,
			value
		};

	});

	
	const schema = ModelSchemas.initialize(id, reworked)
	readySchemas[id] = schema;

	return schema;

}