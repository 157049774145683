import _ from 'underscore';
import { popover } from 'components/popover';
import { ViewStack } from 'bbmn-components';
import { buildActionsListView } from './views';

const actionsStack = new ViewStack({ clearBeforeAdd: true });




export function actionsPopover(instance, parent){
	console.log('1');
	if (!instance || !_.isFunction(instance.getActions)) {
		return;
	}
	console.log('2');
	let view = buildActionsListView(instance, { placement: 'popup' });
	if (!view) return view;
	console.log('3');
	return popover(parent, view, { stack: actionsStack });
}
