import BasePage from 'pages/auth';
import { NewsModel } from '../../../modules/news/NewsModel';
import { commonModuleEditMixin } from '../common/edit/moduleMixin';


export default BasePage.extend({
	id: 'edit',
	routes: ':newsId',
	label: 'Изменение новости',
	isEntityPage: true,
	skipMenu: true,
	// noAside: true,
	relativeRoutes: true,
	// children: [Import, Roles, Users, Configs],
	rights:{
		news: 'view',
	},
	onBeforeStart(p, ac) {
		
		let model = this.model = new NewsModel({ id: ac.args.newsId });

		return model.fetchIfNot().then(() => {
			this.model = model;			
		});		
		
	},
	...commonModuleEditMixin,
});
