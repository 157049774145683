import _ from 'underscore';
import { Model } from 'base';
import { nestedEntitiesMixin } from 'bbmn-mixins';
import { Items } from './items';
import { mix, flat } from 'bbmn-utils';





const Base = mix(Model).with(nestedEntitiesMixin);
export default Base.extend({

	isBackend(){
		return this.get('backend') !== false;
	},

	nestedEntities:{
		items: Items
	},
	afterFilter(){
		console.log('AFTER FLTR');
	},
	updateCollectionInfo (){},
	
	setFilters(filters, { qs } = {}){
		let items = this.entity('items');
		console.log('SET FLTRS:', filters);
		items.set(filters, { parse: true, qs });
	},
	isQueryString(){
		return this.get('formatDataForQueryString') !== false;
	},
	getData(){
		if (this.isQueryString()) {
			return this.getQueryStringObject();
		} else {
			return this.toObject();
		}
	},
	toObject(opts){
		return this.entity('items').toObject(opts);
	},
	getQueryStringObject(){
		let hash = this.toObject();
		let res = _.reduce(hash, (memo, item, key) => {
			if(!_.isArray(item)){
				memo[key] = flat(item);
				return memo;
			} else {
				let i = 0;
				let processed = {};
				while(item.length){
					let chunk = item.shift();
					let processedKey = i > 0 ? `${key}[${i}]` : key;
					processed[processedKey] = chunk;
					i++;
				}
				_.extend(memo, processed);
			}
			return memo;
		}, {});
		var qs = flat(res);		
		return qs;
	},
	createViewFilter(view, viewFilter){
		if (!_.isFunction(viewFilter)) {
			viewFilter = false;
		}
		return view => {
			if (!viewFilter || viewFilter(view))
				return this.modelFilter(view.model);
			else 
				return !viewFilter;
		};
	},
	modelFilter(arg){
		let items = this.entity('items');
		if (!items) return true;
		if (arg == null) {
			return false;
		}
		return items.modelFilter(arg);
	},
	resetValues() {
		let items = this.entity('items');
		items.resetValues();
	},
	hasValues() {
		return this.entity('items').hasValues();
	}
});
