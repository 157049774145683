import { paths } from 'helpers';
paths.set('api', 'realties', 'rlts', { prefix: 'ent' });
paths.set('url', 'realties', 'rlts');

paths.set('api', 'realties:realties', 'realties', { relative: true });
paths.set('url', 'realties:realties', 'realties', { relative: true });

paths.set('api', 'realties:objects', 'objects', { relative: true });
paths.set('url', 'realties:objects', 'objects', { relative: true });

paths.set('api', 'realties:offers', 'offers', { relative: true });
paths.set('url', 'realties:offers', 'offers', { relative: true });