import BasePage from 'pages/auth';
import RealtyLists from './realty-lists';
import OffersLists from './offer-lists';
import Realties from './realties';
import CustomContacts from './custom-contacts';
import PagesWithContacts from './pages-with-contacts';

export default BasePage.extend({
	id:'realties',
	routes: 'rlts',
	label: 'Недвижимость',
	relativeRoutes: true,
	children: [RealtyLists, OffersLists, Realties, CustomContacts, PagesWithContacts],
	noAside: true,
});
