import _ from 'underscore';
import { DisplayPropertySchemaView } from 'components/display-schema';
import { View, CollectionView } from 'base';
import { EntityCustomContact } from '../models';
import { setByPath, getByPath, unflat } from 'bbmn-utils';
import { getCustomContactUrl } from '../urls';
import { EditBoxHeader } from './ContactEditBoxHeader';
import { displayPhone } from '../../../../helpers';



export const ContactEditBox = CollectionView.extend({
	className: 'contacts-edit-box card-item fullwidth',
	renderCollection: false,
	renderAllCustoms: true,
	customs() {
		const model = this.getContactEditModel();
		const props = this.getOption('properties') || ['ownCustomContact.label', 'ownCustomContact.value', 'ownCustomContact.displayValue', 'ownCustomContact.inheritanceDisabled', 'ownCustomContact.notVisible'];
		const customs = props.reduce((views, prop) => {
			let schema = model.getPropertySchema(prop);
			if (!schema) { return views; }

			views.push({
				class: DisplayPropertySchemaView,
				saveOnResolveEditProperty: false,
				propertyOnResolve: value => {
					if (value === undefined || value === '') {
						value = null;
					}
					setByPath(model, prop, value);
					model.trigger('edit:complete', prop, model)
				},
				propertyOnRejectHard: value => {
					setByPath(model, prop, null);
					model.trigger('edit:complete', prop, model);
				},
				modelEvents: {
					'edit:complete':'render'
				},
				shouldUseSetByPath: true,
				schema,
				property: prop,
				model,
				mainAction: 'trigger:edit:click',
			});


			return views;

		}, []);
		customs.unshift({ class: EditBoxHeader, header: this.getOption('header') })
		
		return customs;
	},
	getContactEditModel() {
		let model;
		if (this.getOption('editModelType') === 'migrate') {
			model = this.getMigrateContactModel();
		} else {
			model = this.getCustomContactModel();
		}
		this.listenTo(model, 'edit:complete', this.saveChanges);
		return model;
	},
	getCustomContactModel() {
		const entity = this.getOption('entity');
		const type = this.getOption('entityType');
		let url = getCustomContactUrl(type, entity.id);
		const { customContactId, ownCustomContact = {}, customContact = {} } = entity.attributes;
		const model = new EntityCustomContact({ customContactId, customContact, ownCustomContact });
		model.url = url;
		
		return model;
	},
	getMigrateContactModel() {

		const type = this.getOption('entityType');
		const entity = this.getOption('entity');
		let url = getCustomContactUrl(type, entity.id);
		const { migrateContact = {} } = entity.attributes;
		const model = new EntityCustomContact({ migrateContact });
		model.url = url;
		//model.on('all', (e,m) => console.log(`[model]`, e, m && m.attributes && JSON.stringify(m.attributes.migrateContact)));
		const rlt = this.getRealty();
		const contactsData = rlt.getContactsData();
		model.displayValue = contactsData.displayValue.bind(contactsData);
		model.getContactSourceValues = function(sourceType) {

			if (rlt == null) { return; }
			let options = Object.assign({ entityType: type, sourceType }, entity.getContactsInfo());
			return contactsData.getSourceValues(options);

		}
		return model;
	},
	getRealty() {
		const type = this.getOption('entityType');
		if (type === 'realty') {
			return this.getOption('entity');
		} else {
			return this.getOption('realty');
		}
	},
	saveChanges(property, model) {
		const attrs = { [property]: model.get(property) };

		this.triggerMethod('before:save:changes', attrs);
		model
			.save(null, { wait: true, method: 'patch', attrs })
			.then(data => {
				this.triggerMethod('contact:saved', data);
			});

	}

});

// function reverseObject(hash) {
// 	return Object.keys(hash).map(key => {
// 		return { id: key, value: hash[key] };
// 	});
// }

// buildPropertyView(property, opts, model){
// 	if(!model){
// 		model = this.model;
// 	}
// 	let schema = model.getPropertySchema(property);

// 	let options = _.extend({
// 		schema,
// 		property,
// 		model,
// 		mainAction: 'trigger:edit:click',
// 		saveOnResolveEditProperty: false,
// 	}, opts);
// 	console.log('>>>>', options, this);
// 	return new DisplayPropertySchemaView(options);
// }