import _ from 'underscore';
import './api-config';


import { Collection } from 'base';
import { CardModel, ActionModel } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';
import { paths } from 'helpers';
import { createSchemaNotifies } from 'components/notifier';
import { action, ActionStore } from 'components/actions';

import editor from 'components/editor';

import { enums } from 'bbmn-utils';

import { busViews } from 'bus';

import { DisplayModelSchemaView } from 'components/display-schema';

import { modals } from 'bbmn-components';

import { RuleView } from 'mod/export/feeds/views/feed-packs';
import { RealtyLists, RealtyList } from 'mod/realties/realty-lists/models';



/**
 * Property
 */


const offerPropertiesPaths = {
	'Market':'тип рынка',
	'Operation':'тип операции',
	'SquareValue': 'площадь',
	'SquareMin': 'минимальная площадь',
	'SquareMax': 'максимальная площадь',
	'ForCustomer.PriceMeter': 'цена за метр',
	'ForCustomer_PriceSquare': 'цена за площадь',
	'Object.Info.Purposes' : 'назначение',
};

const Property = ActionModel.extend({});


ModelSchemas.initialize(Property, {
	path:{
		value:{
			type:'enum',
			sourceValues: offerPropertiesPaths
		},		
		display:{
			label:'свойство',
			ifEmpty: '&ndash;',
		}
	},
	compareRule:{
		dependOn:{
			path:{
				value(val){
					if (['Market','Operation'].indexOf(val) > -1){
						return 'equal';
					} 
				}
			}
		},
		edit({ allValues = {} } = {}){
			if (['Market','Operation'].indexOf(allValues.path) > -1) {
				return false;
			}
		},
		value(){
			console.log('VALUE??', enums.get('propertyComparers'));
			console.log('** ', ...arguments);
			return {
				type:'enum',
				sourceValues: () => enums.get('propertyComparers')
			};
		},
		display:{
			label:'оператор сравнения',
			ifEmpty: '&ndash;',
		}
	},
	value:{
		dependOn:'path',
		values:{
			Market: {
				type:'enum',
				sourceValues: () => enums.get('realtyMarkets')
			},
			Operation: {
				type:'enum',
				sourceValues: () => enums.get('realtyOperations')
			},
			'Object.Info.Purposes': {
				type:'enum',
				multiple: true,
				sourceValues: () => enums.get('realtyPurposes')
			}
		},
		value({ allValues = {} } = {}){
			return this.values[allValues.path];
		},
		display: {
			label:'значение',
			ifEmpty: '&ndash;',
		}
	},
	disabled: {
		value: {
			type:'boolean',
			control: 'select:inline',
			sourceValues: ['Работает','Отключено']
		},
		display: {
			label:'состояние',
			ifEmpty: '&ndash;',
		}
	},
});


const Properties = Collection.extend({
	model: Property
});

/**
 * Rule
 */

const Rule = ActionModel.extend({
	nestedEntities:{
		properties: Properties
	},
	isDisabled(){
		return this.get('disabled') === true;
	}
});

ModelSchemas.initialize(Rule, {
	name: {
		validation:{
			required: true,
		},
		display:{
			label:'Название'
		}
	},
	disabled: {
		value:{
			type:'boolean',
			control: 'select:inline',
			sourceValues: ['Работает','Отключено']
		},
		display:{
			label:'состояние',
		}
	},
}, {
	notifies: createSchemaNotifies({ name: 'правило', generate: true, gender: 'it' })
});
ActionStore.initialize(Rule, [
	action('view:rule', 'открыть правило', function(){
		let content = new RuleView({ model: this });
		modals.show({ header: this.display('name'), content });
	}, ['export: editFeeds']),
	action('add:property', 'добавить свойство', function(){
		
		let parent = this;
		let props = parent.entity('properties');


		
		modals.show({
			header:'Добавление нового свойства',
			promiseBar: true,
			onResolve(content){
				//props.add(_.extend({ id: _.uniqueId('prop') }, content.value ));
				var ids = props.pluck('id');
				!ids && (ids = []);
				!ids.length && (ids.push(0));
				let newId = _.max(ids) + 1;
				
				let newProp = _.extend({ id: newId }, content.value);
				props.add(newProp);
				console.log('newProp', newProp, ids, newId);
			},
			content: DisplayModelSchemaView,
			contentOptions:{
				value:{},
				schema: ModelSchemas.get(Property),
				mainPropertyAction:'trigger:edit:click'
			}
		});
		
	}, ['export: editFeeds']),
]);

const Rules = Collection.extend({
	model: Rule
});




/**
 * FeedPack
 */

const BaseFeedPack = CardModel.extend({
	urlPattern: paths.api('export:feeds') + '/{parent.id}/packs',
	cardUrlPattern: paths.url('export:feeds') + '/{parent.id}/p',
	nestedEntities:{
		rules: {
			class: Rules,
			saveOnChange: false,
		}
	},
	getRealtiesList(id)
	{
		id || (id = this.get('realtiesListId'));
		if(!id) return;
		let list = new RealtyList({ id });
		if(!list.isFetched()){
			list.fetch().then(() => this.trigger('change:realtiesListId', this));
		}
		return list;
	},
	isDisabled(){
		return this.get('disabled') === true;
	},
	getRulesNames(){
		let rules = this.get('rules') || [];
		return _.map(rules, rule => rule.name);
	},
	hasNoRules(){
		let names = this.getRulesNames();
		return !names.length;
	},
	patchRules(){
		this.save(null, { wait: true, attrs: { rules: this.get('rules') }, method:'patch'});
	}
});
ActionStore.initialize(BaseFeedPack, [
	action('create:rule', 'добавить правило', function(){
		
		let parent = this;
		let rules = parent.entity('rules');
		editor.add({
			schema: ModelSchemas.get(Rule),
			onResolve(values){
				var ids = rules.pluck('id');
				!ids && (ids = []);
				!ids.length && (ids.push(0));
				let newId = _.max(ids) + 1;
				let val = _.extend({ id: newId }, values);
				console.log('newRule', val, ids, newId);
				rules.add(val);
			}
		}, 'Добавление нового правила');

	}, ['export: editFeeds']),

]);
export const FeedPack = BaseFeedPack;

export const FeedPacks = Collection.extend({
	urlPattern: paths.api('export:feeds') + '/{parent.id}/packs',
	model: FeedPack
});

ModelSchemas.initialize(BaseFeedPack, {
	name: {
		validation:{
			required: true,
		},
		display:{
			label:'Название'
		}
	},
	disabled: {
		value:{
			type:'boolean',
			control: 'select:inline',
			sourceValues: ['Работает','Отключен']
		},
		display:{
			label:'состояние',
		}
	},
	realtiesListId: {
		value:{
			control:'select:api',
			sourceValues: () => new RealtyLists()
		},
		edit:{
			filtered: true,
			childView: () => busViews.getClass('RealtyListItemView'),
			extractValue: value => value.id,
			rejectHard: true,
		},		
		display:{
			label(model){
				let label = 'список';
				if (model) {
					let list = model.getRealtiesList();
					if(list){
						let add = list.display('type');
						if(add){
							label = add + ' ' + label;
						}
					}
				}
				return label;
			},
			transform(v){
				if(!v) {
					return;
				}
				let list = this.getRealtiesList(v);
				return list && list.display('name') || v;
			},
			ifEmpty: '&ndash;',
			options: true,
			onOptionsClick(){
				let list = this.model.getRealtiesList();
				list && this.showActionsPopover(list);
			}
		},

	},
	descriptionTemplate: {
		value:{
			type: 'text',
			control: 'advert:template'
		},
		display: {
			label: 'шаблон для группы объявлений',
			ifEmpty: '&ndash;',
			ifNotEmpty: 'установлен собственный шаблон',
		}
	}	
}, {
	notifies: createSchemaNotifies({ name: 'группа объявлений', generate: true, gender: 'female' })
});
